// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Checkbox, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { useSelector } from 'react-redux';
import TextArea from 'components/commonComponents/TextArea';

const AddTeam = (props) => {
  const { userDetails, teamData } = useSelector((store) => store.commonData);
  const { onClose, open } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { teamName, teamObjectiveLongDesc } = data;
  const [checked, setChecked] = useState(true)
  const [messageApi, contextHolder] = message.useMessage();


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError({})
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {
    const errors = {
      teamName: !teamName,
      teamObjectiveLongDesc : !teamObjectiveLongDesc
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);
    const payload = {
      teamName,
      teamObjectiveLongDesc,
      autoCreate: checked
    }
    const res = await fetchData(restAPIs.createShadeTeam(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      teamData?.listTeams();
      handleClose();
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const onChangeCheckbox = (e) => {
    setChecked(!checked)
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content' style={{color:'#fff'}}>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Create Team</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("teamName")}
              value={teamName}
              placeholder={'Enter team name'}
              label='Name'
              error={error.teamName}
            />

            <TextArea
              onChange={handleOnChange("teamObjectiveLongDesc")}
              value={teamObjectiveLongDesc}
              type="text"
              placeholder={'Description'}
              label={'Description'}
              error={error.teamObjectiveLongDesc}
            />

           <div style={{margin:'10px 0'}}>
           <Checkbox onChange={onChangeCheckbox} checked={checked}>Auto create members</Checkbox>
           </div>

            <Button className='__extra_btn_cls' style={{ border: '1px solid #fff2', color: '#fff', marginTop:'10px' }} loading={loading} onClick={() => handleSubmit()} >Create</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddTeam;

AddTeam.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
