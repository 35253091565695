// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// Assets
import { EyeOutlined } from '@ant-design/icons';
import { message, Table, Collapse, Select, Button, Tooltip } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import QuestionView from 'components/modals/QuestionView';

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

const LoginSession = () => {
    const [loading, setLoading] = useState(true);
    const [listData, setListData] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [paginationData, setPaginationData] = useState({
        page: 1,
        totalItems: 0
    });
    const { totalItems, page } = paginationData;
    const [listQustions, setListQuestions] = useState([]);
    const [listDataMart, setListDataMart] = useState([]);

    const dispatch = useDispatch();
    const [showModalQuickView, setShowModalQuickView] = useState(false)
    const { userDetails } = useSelector((store) => store.commonData);

    useEffect(() => {
        getUsers();
        getUserQuestions();
        getUserDatamart();
    }, []);

    const getUsers = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.listUsers());
        if (res?.statusCode === 200) {
            setLoading(false)
            setListData(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const getUserQuestions = async () => {
        let payload = {
            "username": userDetails
        }

        setLoading(true)
        const res = await fetchData(restAPIs.listUserQuestions(payload));
        if (res?.statusCode === 200) {
            setLoading(false)
            setListQuestions(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }


    const getUserDatamart = async () => {
        setLoading(true)
        let payload = {
            "username": userDetails,
            "type": "rawdata"
        }
        const res = await fetchData(restAPIs.listUserDatamart(payload));
        if (res?.statusCode === 200) {
            setLoading(false)
            setListDataMart(res?.body)
        }
        else {
            messageApi.open({
                type: 'error',
                content: res?.message,
            });
        }
    }

    const config = {
        pagination: {
            total: totalItems,
            // current: page,
            defaultCurrent: 1,
            defaultPageSize: 8,
            showSizeChanger: false,
            // onChange: handlePageChange
        }
    }

    const handleAccessChange = (value, record) => {
        record.access = value; // Update the access value in the record
        setListData([...listData]); // Update the listData state to reflect the change

    }

    const handleUpdateAccess = async (record) => {
        // setLoading(true);
        // const res = await postData(restAPIs.updateUserAccess(record.id), { access: record.access });
        // if (res?.statusCode === 200) {
        //     setLoading(false);
        //     messageApi.success('Access updated successfully');
        // } else {
        //     setLoading(false);
        //     messageApi.error('Failed to update access');
        // }
    }

    const options = [
        { value: 'admin', label: 'Query Analyzer' },
        { value: 'user', label: 'Settings' },
        { value: 'guest', label: 'Login Activity' },
        { value: 'guest2', label: 'Presentation' }
    ];



    const handleView = (data) => {
        dispatch({
            type: "questionQuickView",
            value: data,
        });
        setShowModalQuickView(true)
    };

    return (
        <>
            {contextHolder}
            <div className='___logSes_wrap'>
                <div className='___loginSession_sec_new'>
                    <Collapse ghost className='__collapse__items' accordion defaultActiveKey={['0']}>
                        <Panel key={0} header={'User Details'}>
                            <div className='__table_sec'>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listData} loading={loading} className="__market_table">
                                    <Column
                                        title={'Username'}
                                        dataIndex="username"
                                        key="username"
                                        render={(username) => <p className='__query'>{username}</p>}

                                    />

                                    <Column
                                        title={'Role'}
                                        dataIndex="access"
                                        key="access"
                                        render={(access, record) => (
                                            <Select
                                                className='__select'
                                                style={{ width: '300px' }}
                                                placeholder="Select role"
                                                value={access}
                                                onChange={(value) => handleAccessChange(value, record)}
                                                maxTagCount={1}
                                                maxTagPlaceholder={(omittedValues) => (
                                                    <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                                        <span>+ {omittedValues.length}...</span>
                                                    </Tooltip>
                                                )}
                                            >
                                                <Option value="admin">Administrator</Option>
                                                <Option value="dataAnalyst">Data Analyst</Option>
                                                <Option value="dataScientist">Data Scientist</Option>
                                                <Option value="businessLead">Business Lead</Option>
                                            </Select>

                                        )}
                                    />



                                    <Column
                                        title='Login Count'
                                        dataIndex="login_count"
                                        key="login_count"
                                        className='___col'
                                    />

                                    <Column
                                        title="Actions"
                                        key="actions"
                                        render={(text, record) => (
                                            <Button type="primary" onClick={() => handleUpdateAccess(record)}>Update Role</Button>
                                        )}
                                    />

                                </Table>
                            </div>
                        </Panel>

                        <Panel key={1} header={'Usage Report'}>
                            <div className='__table_sec'>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listData} loading={loading} className="__market_table">
                                    <Column
                                        title={'Username'}
                                        dataIndex="username"
                                        key="username"
                                        render={(username) => <p className='__query'>{username}</p>}

                                    />

                                    <Column
                                        title='Login Count'
                                        dataIndex="login_count"
                                        key="login_count"
                                        className='___col'
                                    />

                                </Table>
                            </div>
                        </Panel>

                        <Panel key={2} header={'Anomalies Report'}>
                            <div className='__table_sec'>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listData} loading={loading} className="__market_table">
                                    <Column
                                        title={'Username'}
                                        dataIndex="username"
                                        key="username"
                                        render={(username) => <p className='__query'>{username}</p>}

                                    />

                                    <Column
                                        title='Login Count'
                                        dataIndex="login_count"
                                        key="login_count"
                                        className='___col'
                                    />

                                </Table>
                            </div>
                        </Panel>

                        <Panel key={3} header={'User Questions'}>
                            <div className='__table_sec'>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listQustions} className="__market_table">
                                    <Column
                                        title='Question'
                                        dataIndex="Question"
                                        key="Question"
                                        className='___qtsn'
                                    />

                                    <Column
                                        title='Status'
                                        dataIndex={'Status'}
                                        key={'Status'}
                                        render={(Status) => <>
                                            <span style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                {Status === 'inprogress' && (
                                                    <div className='__inprogress'>
                                                        <div className="circles">
                                                            <div className="circle1"></div>
                                                            <div className="circle2"></div>
                                                            <div className="circle3"></div>
                                                        </div>
                                                    </div>
                                                )}
                                                {Status === 'registered' && <div className='__registered'></div>}
                                                {Status === 'new' && <div className='__registered' style={{ backgroundColor: 'yellow' }}></div>}
                                            </span>
                                        </>
                                        }
                                    />

                                    <Column
                                        title='Actions'
                                        key="action"
                                        render={(text, record) => (
                                            <div className="table-action">
                                                <EyeOutlined
                                                    onClick={() => handleView(record)}
                                                    style={{ fontSize: "18px", color: '#03A9F4' }}
                                                />
                                            </div>
                                        )}
                                    />

                                </Table>
                            </div>
                        </Panel>

                        <Panel key={4} header={'User Datamart'}>
                            <div className='__table_sec'>
                                <Table {...config} rowKey={(record, index) => index} dataSource={listDataMart} className="__market_table">
                                    <Column
                                        title={'Data Source'}
                                        dataIndex="dataSource"
                                        key="dataSource"
                                        className='___col'
                                    />
                                    <Column
                                        title='Type'
                                        dataIndex="type"
                                        key="type"
                                        className='___col'
                                    />


                                </Table>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
            <QuestionView
                open={showModalQuickView}
                onClose={() => setShowModalQuickView(false)}
            />
        </>
    );
};

export default LoginSession;
