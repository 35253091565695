// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, Radio, Upload, message, notification } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import SelectBox from 'components/commonComponents/SelectBox';
import TextArea from 'components/commonComponents/TextArea';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};



const SystemIntegrationModal = (props) => {
  const { userDetails, systemIntegration } = useSelector((store) => store.commonData);
  const { onClose, open } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { URL, type, payload = `{
  "key1": "value1",
  "key2": "value2",
} `, name, description, headers = `{
  "key1": "value1",
  "key2": "value2",
} ` } = data;
  const [messageApi, contextHolder] = message.useMessage();
  const [valueRadio, setValueRadio] = useState('api')
  const [file, setFile] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError({})
      setFile(null)
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {
    let errors = {}
    let payload2 = {}
    if (valueRadio == 'api') {
      errors = {
        URL: !URL,
        type: !type,
        payload: !payload,
        headers: !headers
      };
    }
    else {
      messageApi.open({
        type: 'error',
        content: 'Upload a file',
      });
    }

    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    let formData = new FormData();


    if (valueRadio == 'api') {
      formData.append('name', name);
      formData.append('description', description);
      formData.append('URL', URL);
      formData.append('methodType', type);
      formData.append('payload', payload);
      formData.append('headers', headers);
      formData.append('type', valueRadio);
    }
    else {
      formData.append('name', name);
      formData.append('description', description);
      formData.append('file', file);
      formData.append('type', valueRadio);
    }
    const res = await fetchData(restAPIs.registerSystemIntegration(formData));
    if (res.statusCode === 200) {
      systemIntegration?.dataIntegration();
      handleClose();
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const types = [
    {
      id: 'get',
      label: 'Get',
    },
    {
      id: 'post',
      label: 'Post',
    }
  ]


  const onChangeRadio = (e) => {
    setValueRadio(e.target.value)
  }

  const uploadProps = {
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (file) => {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        openNotificationWithIcon('Error', 'File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }
      setFile(file);
      return false;
    },
    file,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content __stsy'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>System Integration</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("name")}
              value={name}
              placeholder={'Enter the name'}
              label='Name'
              error={error.name}
            />

            <TextArea
              label="Description"
              placeholder="Enter the description"
              value={description}
              onChange={handleOnChange('description')}
              height={40}
            />

            <div style={{ marginBottom: '10px' }}>
              <Radio.Group onChange={onChangeRadio} value={valueRadio}>
                <Radio value={'api'}>API</Radio>
                <Radio value={'connector'}>Connector</Radio>
              </Radio.Group>
            </div>

            {valueRadio === 'api' ? <>

              <InputField
                onChange={handleOnChange("URL")}
                value={URL}
                placeholder={'Enter your url'}
                label='URL'
                error={error.URL}

              />
              <div style={{ marginBottom: '10px' }}>
                <SelectBox
                  options={types}
                  value={type}
                  handleOnChange={handleOnChange('type')}
                  label='Type'
                />
              </div>

              <TextArea
                label="Headers"
                placeholder="Enter the headers"
                value={headers}
                onChange={handleOnChange('headers')}
                height={50}
              />

              <TextArea
                label="Payload"
                placeholder="Enter JSON here"
                value={payload}
                onChange={handleOnChange('payload')}
                height={50}
              />
            </>
              :
              <>
                <div style={{ fontSize: '12px', marginBottom: '10px' }}>File</div>
                <Upload {...uploadProps} maxCount={1} multiple={false} >
                  <Button className="__extra_btn_cls_upload">Select File to upload</Button>
                </Upload>
              </>
            }
            <Button className='__extra_btn_cls' style={{ border: 0, color: '#fff', marginTop: '10px' }} loading={loading} onClick={() => handleSubmit()} >Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SystemIntegrationModal;

SystemIntegrationModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
