// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faAdd, faArrowLeft, faArrowRight, faArrowRightArrowLeft, faArrowUpRightFromSquare, faCircleQuestion, faCubesStacked, faEllipsis, faFilePowerpoint, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import UserIcon from 'assets/images/man.png'
import { useNavigate } from 'react-router-dom';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import { Avatar, Badge } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';

const Actions = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [users, setUsers] = useState([])

    const questions = [
        "Did you know that the youngest patient in the dataframe is 0 years old?",
        "Did you know that the ages and their frequencies of unique patient IDs in the dataframe are as follows",
        "Did you know that in the given dataframe, there is only 1 patient who claimed more than 1500 times?",
        "Did you know that there are 218 unique patients for psoriasis in the dataset?",
        "Did you know that the youngest patient in the dataframe is 0 years old?",
        "Did you know that the ages and their frequencies of unique patient IDs in the dataframe are as follows",
        "Did you know that in the given dataframe, there is only 1 patient who claimed more than 1500 times?",
        "Did you know that there are 218 unique patients for psoriasis in the dataset?"
    ]

    useEffect(()=>{
        dispatch({
            type: "headerName",
            value: '',
          });
    },[])

    useEffect(() => {
        fetchDummy();
    }, [])

    const fetchDummy = async () => {
        const res = await fetchData(restAPIs.userListing());
        setUsers(res.data)
    }


    return (
        <>
            <div className='__actions'>
                <div className='__common_header_top'>
                    <div></div>
                    <div className='__btns_'>
                        <button onClick={() => navigate('/home')}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                </div>

                <div style={{ marginTop: '20px' }}>
                    <Swiper
                        scrollbar={{
                            hide: false,
                        }}
                        slidesPerView={3}
                        modules={[Scrollbar]}
                        className="mySwiper"
                        breakpoints={{
                            '@0.00': {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            '@0.75': {
                                slidesPerView: 2,
                            },
                            '@1.00': {
                                slidesPerView: 3,
                            },
                            '@2.00': {
                                slidesPerView: 4,
                            },
                        }}
                    >

                        {questions.map((item, idx) => {
                            return (
                                <SwiperSlide>
                                    <div className='__action_single_card'>
                                        <div className='__head'>
                                            <FontAwesomeIcon icon={faCircleQuestion} />
                                            <button className='common_card'><FontAwesomeIcon icon={faAdd} /> Add</button>
                                        </div>
                                        <div className='__content'>
                                            <h1>{item}</h1>
                                            <p>The youngest patient in the dataframe is zero years old </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>

                <div className='__selected_teams_sec common_card'>
                    <h1>Selected Teams</h1>
                    <div className='__user_list_grid'>
                        {users.map((item, idx) => {
                            return (
                                <div className='__item'>
                                    <Badge count={(idx + 1) * 2} offset={[-5, 7]}>
                                        <Avatar size={70} src={item?.avatar} />
                                    </Badge>
                                </div>

                            )
                        })}

                        <div className='__item'>
                            <button>+</button>
                        </div>
                    </div>
                    <div className='__assign_to_all'>
                        <button>Assign to all<FontAwesomeIcon icon={faArrowRight}/></button>
                    </div>
                </div>
                <div className='__all_teams_sec'>
                <h1>All Teams</h1>
                    <div className='__user_list_grid'>
                        {users.map((item, idx) => {
                            return (
                                <div className='__item'>
                                    <Badge count={(idx + 1) * 2} offset={[-5, 7]}>
                                        <Avatar size={70} src={item?.avatar} />
                                    </Badge>
                                </div>

                            )
                        })}

                        <div className='__item'>
                            <button><FontAwesomeIcon icon={faEllipsis}/></button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Actions;
