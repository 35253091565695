// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar, Pie, Radar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);

const GraphView = (props) => {
  const { onClose, open, chartData, chartType,options, title, description } = props;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __lilac_blue'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{marginBottom:'10px'}}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose}/></button>
          </div>
          <div>
            {chartData?.labels.length > 0 && <div id="chart" >

              {chartType && chartType === 'radar' && <Radar data={chartData} options={options} />}
              {chartType && chartType === 'pie' && <Pie data={chartData} options={options} />}
              {chartType && chartType === 'line' && <Line data={chartData} options={options} />}
              {chartType && chartType === 'bar' && <Bar data={chartData} options={options} />}
              {chartType && chartType === 'area' && <Line data={chartData} options={{
                ...options,
                plugins: {
                  filler: {
                    propagate: true
                  },
                },
                fill: true
              }} />}
            </div>}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GraphView;

GraphView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
