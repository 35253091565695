
import Header from 'components/commonComponents/Header';
import SideBar from 'components/commonComponents/SideBar';
import Actions from 'components/pages/Actions';
import CardDetail from 'components/pages/CardDetail';
import Dashboard from 'components/pages/Dashboard';
import Chat from 'components/pages/Chat';
import Login from 'components/pages/Login';
import Presentation from 'components/pages/Presentation';
import Settings from 'components/pages/Settings';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import SignUp from 'components/pages/SignUp';
import Subscription from 'components/pages/Subscription';
import { isAuthenticated } from 'utils/helpers';
import Payment from 'components/pages/Payment';
import AWSKey from 'components/pages/AWSKeys';
import UploadMeta from 'components/pages/UploadMeta';
import InstallApp from 'components/pages/InstallApp';
import DataMarketPlace from 'components/pages/DataMarketPlace';
import SchemaDiagram from 'components/pages/SchemaDiagram';
import QueryAnalyzer from 'components/pages/QueryAnalyzer';
import LoginSession from 'components/pages/LoginSession';
import Agents from 'components/pages/Agents';
import FileChat from 'components/pages/FileChat';
import ConfigDarkMater from 'components/pages/ConfigDarkMater';
import MyInsight from 'components/pages/MyInsight';


const AppRouter = () => {

  const [isAdmin, setAdmin] = useState('')
  const { userDetails } = useSelector((store) => store.commonData);
  
  // const noHeader = location.pathname.includes('login') || location.pathname.includes('signup') || location.pathname.includes('susbcribe') || location.pathname.includes('payment') || location.pathname.includes('aws-key') || location.pathname.includes('upload-meta') || location.pathname.includes('install-app');
  useEffect(() => {
    setAdmin(userDetails)
  }, [userDetails])


  return (
    <>
      <BrowserRouter >
        <div >
          {isAuthenticated() && <><Header />
            <SideBar /> </>}
          <div className='__wrapper'>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/chat" element={<Chat />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/presentation" element={<Presentation />} />
                <Route path="/actions" element={<Actions />} />
                <Route path="/card-detail" element={<CardDetail />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/my-insight" element={<MyInsight />} />
                <Route path="/marketplace" element={<DataMarketPlace />} />
                <Route path="/query-analyzer" element={<QueryAnalyzer />} />
                <Route path="/login-count" element={<LoginSession />} />
                <Route path="/shades" element={<Agents />} />

                <Route path="/chat-document/:documentName" element={<FileChat />} />

                <Route path="/configure-dark-mater" element={<ConfigDarkMater />} />

                <Route path="*" element={<QueryAnalyzer />} />
                <Route path="/" element={<QueryAnalyzer />} />

                <Route path="/schema" element={<SchemaDiagram />} />

              </Route>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/susbcribe" element={<Subscription />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/aws-key" element={<AWSKey />} />
              <Route path="/upload-meta" element={<UploadMeta />} />
              <Route path="/install-app" element={<InstallApp />} />
              
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;
