// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, Switch, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import SelectBox from 'components/commonComponents/SelectBox';

const AddToReport = (props) => {
  const { onClose, open, title, description, questionId } = props;
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [inputList, setList] = useState([])
  const [messageApi, contextHolder] = message.useMessage();
  const [typeSelected, setTypeSelected] = useState([])
  const [typeList, setTypeList] = useState('')
  const [bundleList, setBundleList] = useState('')
  const [bundleSelected, setBundleSelected] = useState('')
  const [typeSelectedFull, setTypeSelectedFull] = useState([])

  const [selType, setSelType] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setInputValue('')
      setBundleList('')
      setBundleSelected('')
      setTypeList('')
      setTypeSelected('')
      setList('')
      setIsLoading(false)
      onClose();
    }
  };

  const onSelectDropDown = (data, option) => {
    setInputValue(option.label);
  };

  const onChangeVal = (data, option) => {
    setInputValue(data);
  };

  const handleSubmit = async () => {
    setIsLoading(true)
    const payload = {
      Qstn_id: questionId,
      reportBookName: inputValue,
      formTemplateList: typeList.filter(item => typeSelected.includes(item.name)),
      reportBundleTemplate:bundleSelected
    }

    const res = await fetchData(restAPIs.addToReportBook(payload));
    if (res?.statusCode === 200) {
      setIsLoading(false);
      handleClose();
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const getListReportBooks = async () => {
    const res = await fetchData(restAPIs.listReportBooks());
    if (res.statusCode === 200) {
      let list = []
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        list.push({ value: element, id: (i + 1), label: element })
      }
      setList(list)
    }
  }

  const getListReportFormTemplates = async () => {
    const res = await fetchData(restAPIs.listReportFormTemplates());
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name, ...element }));
      setTypeList(items)
    }
  }

  const getListReportBundles = async () => {
    const res = await fetchData(restAPIs.listReportBundleTemplates());
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name, ...element }));
      setBundleList(items)
    }
  }

  useEffect(() => {
    getListReportBooks();
    getListReportFormTemplates();
    getListReportBundles();
  }, [])

  const handleOnType = (value) => {
    setTypeSelected(value)
  }

  const handleOnBundle = (value) =>{
    setBundleSelected(value)
  }

  const onChangeSwitch =(checked)=>{
    setSelType(!selType)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content __searchBxx'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div>
            <h4 style={{ color: '#fff' }}>Enter a title for report book</h4>
            <AutoComplete
              value={inputValue}
              options={inputList}
              placeholder={'Enter title'}
              autoFocus={true}
              className="__auto_com_serch"
              filterOption={(inputValue, option) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={onSelectDropDown}
              onChange={onChangeVal}
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop:'20px' }}>
              {/* <label htmlFor="Status" style={{ marginBottom: '10px' }}>Status</label> */}
              <Switch
                checkedChildren={'Template'}
                unCheckedChildren={'Bundle'}
                onChange={onChangeSwitch}
              />
            </div>


            {selType ? <div style={{ marginTop: '20px' }}>
              <SelectBox
                options={bundleList}
                value={bundleSelected}
                handleOnChange={handleOnBundle}
                label='Choose bundle'
                // showSearch={true}
              />
            </div>
:
            <div style={{ marginTop: '20px' }}>
              <SelectBox
                options={typeList}
                value={typeSelected}
                handleOnChange={handleOnType}
                label='Choose Template'
                // showSearch={true}
                multiple={true}
              />
            </div>}

            <Button className='__extra_btn_cls' disabled={!inputValue} style={{ border: ' 1px solid #eee2', color: '#fff' }} loading={isLoading} onClick={() => handleSubmit()} >Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddToReport;

AddToReport.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  questionId: PropTypes.string,
};
