import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Button, Modal, Upload, notification } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectBox from 'components/commonComponents/SelectBox';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const TalkFileUpload = (props) => {
  const { onClose, open, title, description } = props;
  const { userDetails, dataMartItem } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [file, setFile] = useState(null);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const { datamartList } = dataMartItem;

  const handleUpload = async () => {
    const errors = {
      documentName: !documentName,
      file: !file
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;

    setLoading(true);
    let formd = new FormData();
    formd.append('file', file);
    formd.append('documentName', documentName);

    const res = await fetchData(restAPIs.loadDocument(formd));
    if (res.body) {
      setLoading(false);
      datamartList();
      onClose();
    } else {
      setLoading(false);
      openNotificationWithIcon(res?.message);
    }
  };

  const uploadProps = {
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (file) => {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        openNotificationWithIcon('Error', 'File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }
      setFile(file);
      return false;
    },
    file,
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const { documentName } = data;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setFile(null);
      setData({});
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Multimodal</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc'>
            <div style={{ marginTop: '20px' }}>
              <InputField
                onChange={handleOnChange("documentName")}
                value={documentName}
                type="text"
                placeholder={'Document Name'}
                label={'Document Name'}
                error={error.documentName}
              />
            </div>
            <>
              <div style={{ fontSize: '12px', marginBottom: '10px' }}>File</div>
              <Upload {...uploadProps} maxCount={1} multiple={false} accept=".pdf,.txt">
                <Button className="__extra_btn_cls_upload">Select File to upload</Button>
              </Upload>
            </>
            <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleUpload}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

TalkFileUpload.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TalkFileUpload;
