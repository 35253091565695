// // Core
// import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';

// // Styles
// import './styles.scss';
// import { Button, Modal, Tabs, Tooltip, message } from 'antd';
// import { faClose, faCopy, faDatabase, faRotateRight } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchData } from 'utils/fetch';
// import { restAPIs } from 'utils/restAPIs';
// import AnsiToHtml from 'ansi-to-html';
// import ReactMarkdown from 'react-markdown';

// const { TabPane } = Tabs;
// const convert = new AnsiToHtml();

// const GreyAnswerQuickView = ({ onClose, open }) => {
//   const { questionGreyQuickView } = useSelector((store) => store.commonData);
//   const dispatch = useDispatch();
//   const [logs, setLogs] = useState([]);
//   const [messageApi, contextHolder] = message.useMessage();
//   const logsEndRef = useRef(null);
//   const textAreaRefLog = useRef(null)

//   const handleClose = (event, reason) => {
//     if (reason === "backdropClick") {
//       console.log(reason);
//     } else {
//       dispatch({ type: 'questionGreyQuickView', value: null });
//       onClose();
//     }
//   };

//   const getLogs = async () => {
//     const payload = { Qstn_id: questionGreyQuickView?.Qstn_id };
//     const res = await fetchData(restAPIs.getCollabRunLogs(payload));
//     if (res) {
//       setLogs(res?.body);
//       setTimeout(scrollToBottom, 1000);
//     } else {
//       messageApi.error(res?.message);
//     }
//   };

//   const scrollToBottom = () => {
//     logsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };

//   useEffect(() => {
//     if (questionGreyQuickView?.Qstn_id) {
//       getLogs();
//     }
//   }, [questionGreyQuickView?.Qstn_id]);


//   const copyToClipboardAns = (e) => {
//     navigator.clipboard.writeText(questionGreyQuickView?.Answer)
//     messageApi.open({
//       type: 'success',
//       content: 'Copied!',
//     });
//   };

//   const copyToClipboardLog = (e) => {
//     navigator.clipboard.writeText(textAreaRefLog?.current?.innerText)
//     messageApi.open({
//       type: 'success',
//       content: 'Copied!',
//     });
//   };

//   const convertAnsiToHtml = (text) => {
//     return convert.toHtml(text);
//   };

//   return (
//     <>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         className='__common_model_class __qustinview_model_grey'
//         footer={null}
//         closable={false}
//       >
//         {contextHolder}
//         <div className='__common_model_content'>
//           <div className='____common_model_head'>
//             <h3 style={{ marginBottom: '10px' }}>Quick View </h3>
//             <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
//           </div>
//           <Tabs className='__tabs2' tabPosition={'top'} defaultActiveKey='1'>

//             {questionGreyQuickView?.Status === 'registered' && <TabPane tab={'Answers'} key='1'>
//               <>
//                 <span className='__current_db'>
//                   <a>
//                     <button className='__button'><FontAwesomeIcon icon={faDatabase} /></button>
//                     {questionGreyQuickView?.Datamart}
//                   </a>
//                 </span>
//                 <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'baseline' }}>
//                   <h2>{questionGreyQuickView?.Question}</h2>
//                   <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                     {questionGreyQuickView?.Status === 'inprogress' && (
//                       <div className='__inprogress'>
//                         <div className="circles">
//                           <div className="circle1"></div>
//                           <div className="circle2"></div>
//                           <div className="circle3"></div>
//                         </div>
//                       </div>
//                     )}
//                     {['registered', 'new'].includes(questionGreyQuickView?.Status) && (
//                       <div className={`__registered ${questionGreyQuickView?.Status === 'new' ? 'new-status' : ''}`}></div>
//                     )}
//                   </span>
//                 </div>
//                 <div className=''>
//                   <div style={{ display: 'flex', justifyContent: 'end' }}>
//                     <Tooltip title={'Copy'}>
//                       <Button
//                         type="primary"
//                         ghost
//                         style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
//                         icon={<FontAwesomeIcon icon={faCopy} />}
//                         onClick={copyToClipboardAns}
//                       >
//                       </Button>
//                     </Tooltip>
//                   </div>
//                   <div className='report-container'>
//                     {/* {questionGreyQuickView && <span className="__log-line" dangerouslySetInnerHTML={{ __html: convert?.toHtml(questionGreyQuickView?.Answer) }}></span>} */}
//                     <ReactMarkdown>{convert?.toHtml(questionGreyQuickView?.Answer)}</ReactMarkdown>
//                   </div>
//                   <img src={questionGreyQuickView?.ImageString} alt="" style={{ width: '100%', maxHeight: '400px', borderRadius: '20px' }} />
//                 </div>
//               </>
//             </TabPane>}
//             <TabPane tab={questionGreyQuickView?.Status === 'inprogress' ? 'Active Collaborations' : 'Collaborations'} key='2'>
//               <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px', alignItems: 'center' }}>
//                 <Tooltip title={'Copy'}>
//                   <Button
//                     type="primary"
//                     ghost
//                     style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
//                     icon={<FontAwesomeIcon icon={faCopy} />}
//                     onClick={copyToClipboardLog}
//                   >
//                   </Button>
//                 </Tooltip>
//                 <Button
//                   type="primary"
//                   ghost
//                   style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
//                   className="__extra_btn_cls"
//                   icon={<FontAwesomeIcon icon={faRotateRight} />}
//                   onClick={getLogs}
//                 >
//                   Refresh
//                 </Button>
//               </div>
//               {/* <div className='report-container' ref={textAreaRefLog}>
//                 {logs.map((item, idx) => (
//                   <ReactMarkdown >{convert?.toHtml(item?.output)}</ReactMarkdown>
//                 ))}
//                 <div ref={logsEndRef} />
//               </div> */}

//               <div className='__logs' ref={textAreaRefLog}>
//                 {logs.map((item, idx) => (
//                   <span key={idx} className="__log-line" dangerouslySetInnerHTML={{__html:convert.toHtml(item?.output)}}></span>
//                 ))}
//                 <div ref={logsEndRef} />
//               </div>
//             </TabPane>
//           </Tabs>
//           <div style={{ display: 'flex', justifyContent: 'end' }}>
//             <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" onClick={handleClose}>Close</Button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// GreyAnswerQuickView.propTypes = {
//   onClose: PropTypes.func.isRequired,
//   open: PropTypes.bool.isRequired,
// };

// export default GreyAnswerQuickView;



// Core
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Tabs, Tooltip, message } from 'antd';
import { faClose, faCopy, faDatabase, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import AnsiToHtml from 'ansi-to-html';
import ReactMarkdown from 'react-markdown';

const { TabPane } = Tabs;
const convert = new AnsiToHtml();

const GreyAnswerQuickView = ({ onClose, open }) => {
  const { questionGreyQuickView } = useSelector((store) => store.commonData);
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const logsEndRef = useRef(null);
  const textAreaRefLog = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      dispatch({ type: 'questionGreyQuickView', value: null });
      onClose();
    }
  };

  const getLogs = async () => {
    const payload = { Qstn_id: questionGreyQuickView?.Qstn_id };
    const res = await fetchData(restAPIs.getCollabRunLogs(payload));
    if (res) {
      setLogs(res?.body);
      setTimeout(scrollToBottom, 1000);
    } else {
      messageApi.error(res?.message);
    }
  };

  const scrollToBottom = () => {
    logsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (questionGreyQuickView?.Qstn_id) {
      getLogs();
    }
  }, [questionGreyQuickView?.Qstn_id]);

  const copyToClipboardAns = (e) => {
    navigator.clipboard.writeText(questionGreyQuickView?.Answer)
    messageApi.open({
      type: 'success',
      content: 'Copied!',
    });
  };

  const copyToClipboardLog = (e) => {
    navigator.clipboard.writeText(textAreaRefLog?.current?.innerText)
    messageApi.open({
      type: 'success',
      content: 'Copied!',
    });
  };

  const convertAnsiToHtml = (text) => {
    return convert.toHtml(text);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __qustinview_model_grey'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Quick View </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <Tabs className='__tabs2' tabPosition={'top'} defaultActiveKey='1'>
            {questionGreyQuickView?.Status === 'registered' && <TabPane tab={'Answers'} key='1'>
              <>
                <span className='__current_db'>
                  <a>
                    <button className='__button'><FontAwesomeIcon icon={faDatabase} /></button>
                    {questionGreyQuickView?.Datamart}
                  </a>
                </span>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'baseline' }}>
                  <h2>{questionGreyQuickView?.Question}</h2>
                  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {questionGreyQuickView?.Status === 'inprogress' && (
                      <div className='__inprogress'>
                        <div className="circles">
                          <div className="circle1"></div>
                          <div className="circle2"></div>
                          <div className="circle3"></div>
                        </div>
                      </div>
                    )}
                    {['registered', 'new'].includes(questionGreyQuickView?.Status) && (
                      <div className={`__registered ${questionGreyQuickView?.Status === 'new' ? 'new-status' : ''}`}></div>
                    )}
                  </span>
                </div>
                <div className=''>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Tooltip title={'Copy'}>
                      <Button
                        type="primary"
                        ghost
                        style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={copyToClipboardAns}
                      >
                      </Button>
                    </Tooltip>
                  </div>
                  <div className='report-container markdown-body'>
                    <ReactMarkdown>{convert?.toHtml(questionGreyQuickView?.Answer)}</ReactMarkdown>
                  </div>
                  <img src={questionGreyQuickView?.ImageString} alt="" style={{ width: '100%', maxHeight: '400px', borderRadius: '20px' }} />
                </div>
              </>
            </TabPane>}
            <TabPane tab={questionGreyQuickView?.Status === 'inprogress' ? 'Active Collaborations' : 'Collaborations'} key='2'>
              <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px', alignItems: 'center' }}>
              {questionGreyQuickView?.Status === 'inprogress' && (
                      <div className='__inprogress'>
                        <div className="circles">
                          <div className="circle1"></div>
                          <div className="circle2"></div>
                          <div className="circle3"></div>
                        </div>
                      </div>
                    )}
                    {['registered', 'new'].includes(questionGreyQuickView?.Status) && (
                      <div className={`__registered ${questionGreyQuickView?.Status === 'new' ? 'new-status' : ''}`}></div>
                    )}
                <Tooltip title={'Copy'}>
                  <Button
                    type="primary"
                    ghost
                    style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
                    icon={<FontAwesomeIcon icon={faCopy} />}
                    onClick={copyToClipboardLog}
                  >
                  </Button>
                </Tooltip>
                <Button
                  type="primary"
                  ghost
                  style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }}
                  className="__extra_btn_cls"
                  icon={<FontAwesomeIcon icon={faRotateRight} />}
                  onClick={getLogs}
                >
                  Refresh
                </Button>
              </div>
              <div className='__logs' ref={textAreaRefLog}>
                {logs.map((item, idx) => (
                  <span key={idx} className="__log-line" dangerouslySetInnerHTML={{ __html: convert.toHtml(item?.output) }}></span>
                ))}
                <div ref={logsEndRef} />
              </div>
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" onClick={handleClose}>Close</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

GreyAnswerQuickView.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default GreyAnswerQuickView;
