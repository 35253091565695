// Core
import React, { forwardRef, useEffect, useRef, useState } from "react";

// Styles
import "./styles.scss";
import {
  Button,
  Collapse,
  Dropdown,
  Popconfirm,
  Select,
  Table,
  Tooltip,
  message,
  AutoComplete,
  Menu,
  Switch,
  Tabs,
  Input,
} from "antd";
import {
  faCircleQuestion,
  faPaperPlane,
  faRotateRight,
  faMagnifyingGlass,
  faPenToSquare,
  faXmark,
  faUpRightAndDownLeftFromCenter,
  faClock,
  faSliders,
  faCartShopping,
  faReceipt,
  faVolumeHigh,
  faVolumeXmark,
  faCircleDot,
  faBackward
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { restAPIs } from "utils/restAPIs";
import { fetchData } from "utils/fetch";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "components/commonComponents/TextArea";

import {
  RadarChartOutlined,
  PieChartOutlined,
  PushpinOutlined,
  LoadingOutlined,
  DeleteOutlined,
  LineChartOutlined,
  ConsoleSqlOutlined,
  FileWordOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  MacCommandOutlined,
  DotChartOutlined,
  TableOutlined,
  AntCloudOutlined,
  FieldTimeOutlined,
  FunnelPlotOutlined,
  AudioOutlined,
  BorderOutlined,
  RightOutlined,
  LeftOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import ImageDisplay from "components/modals/ImageDisplay";
import LilacBlue from "components/modals/LilacBlue";
import PinToDashboard from "components/modals/PinToDashboard";
import AddToStory from "components/modals/AddToStory";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import html2canvas from "html2canvas";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar, Pie, Radar, Scatter } from "react-chartjs-2";
import SelectBox from "components/commonComponents/SelectBox";
import GraphView from "components/modals/GraphView";
import Warning from "components/modals/Warning";
import ReactApexChart from "react-apexcharts";
import WordCloud from "components/modals/WordCloud";
import Timeline from "components/modals/Timeline";
import Funnel from "components/modals/Funnel";
import AddToReport from "components/modals/AddToReport";
import Speaking from 'assets/images/voice.png'
import { useSpeechSynthesis } from "react-speech-kit";

import AnsiToHtml from 'ansi-to-html';
import ReactMarkdown from 'react-markdown';
import useSpeechRecogintion from 'utils/useSpeechRecogintion';

// import ReactIdSwiper from "react-id-swiper";


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import { useSwipeable } from "react-swipeable";
import { useMediaQuery } from "react-responsive";
import InsightForm from "components/modals/InsightForm";


ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);

const { Column } = Table;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const convert = new AnsiToHtml();

const CustomInput = forwardRef(
  ({ value, onChange, onKeyDown, placeholder, autoFocus }, ref) => {
    const renderHighlightedText = (text) => {
      if (typeof text !== "string") {
        text = String(text);
      }

      // Regular expression to match @<name>(<role>)
      const regex = /(@[^()]+?\([^()]+\))/g;
      const parts = text.split(regex);

      return parts.map((part, index) => {
        if (regex.test(part)) {
          return (
            <span key={index} className="highlight">
              {part}
            </span>
          );
        }
        return (
          <span key={index} className="normal">
            {part}
          </span>
        );
      });
    };

    return (
      <div className="custom-input-wrapper">
        <Input
          ref={ref}
          value={value}
          onChange={onChange} // Ensure onChange prop is correctly passed
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          autoFocus={autoFocus}
          style={{
            backgroundColor: "#00000075",
            color: "#00000082",
            border: "none",
            height: "50px",
            opacity: "0.2",
          }}
        />
        <div className="custom-input-overlay">
          {renderHighlightedText(value)}
        </div>
      </div>
    );
  }
);

const transformData = (data, xAxisField) => {
  const content = data[0];

  // Identify the y-axis fields by excluding the x-axis field
  const yAxisFields = Object.keys(content).filter((key) => key !== xAxisField);

  // Transform the data for series
  const series = yAxisFields.map((field) => ({
    name: field,
    data: Object.keys(content[field]).map((key) =>
      parseFloat(content[field][key])
    ),
  }));

  // Transform the data for labels using the given x-axis field
  const labels = Object.keys(content[xAxisField]).map(
    (key) => content[xAxisField][key]
  );

  return {
    series,
    options: {
      chart: {
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "curve",
      },
      title: {
        text: "Dynamic Data Analysis",
        align: "left",
      },
      labels: labels,
      xaxis: {
        type: "category",
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
      },
      yaxis: {
        title: {
          text: "Values",
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };
};

const QueryAnalyzer = () => {
  const { userDetails, setDataScientistValue, domainName, userPermissions, isSpeakEnable, isMultipleStore } = useSelector(
    (store) => store.commonData
  );
  const [question, setQuestion] = useState("");
  const [loadingSimilar, setLoadingSimilar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    totalItems: 0,
  });
  const { totalItems, page } = paginationData;
  const [newQuery, setQuery] = useState("");
  const [isBetterLoading, setIsBetterLoading] = useState(false);
  const [isQueryLoading, setIsQueryLoading] = useState(false);
  const [inputTagValue, setInputTagValue] = useState("");
  const [tagList, setTagList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [isEdit, setIsEdit] = useState(false);
  const [isEditButtonShow, setIsEditButtonShow] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [filterTags, setFilterTags] = useState("");
  const [loadingBlue, setLoadingBlue] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");
  const [show, setShow] = useState(false);

  const [datamartList, setDataMartList] = useState([]);
  const [currentDatamart, setCurrentDataMart] = useState([]);

  const [isSQLEnabled, setSQLEnabled] = useState(false);
  const [cardSelected, setCardSelected] = useState("");
  const [showPinDashboard, setShowDashboard] = useState(false);
  const [showAddtoStory, setShowAddtoStory] = useState(false);
  const [showAddtoReport, setShowAddtoReport] = useState(false);
  const chartRef = useRef(null);
  const simpleRef = useRef(null);
  const sliderRef = useRef(null);
  const [isRefresh, setIsRefresh] = useState(false);
  const [defaultColor, setDefaultColor] = useState("#000000");
  const [defaultChart, setDefaultChart] = useState("area");
  const [isPinned, setIsPinned] = useState(false);
  const [isUnpinLoading, setIsUnpinLoading] = useState(false);
  const [isUpdateChart, setIsUpdateChart] = useState(false);
  const [isUpdateChartLoading, setIsUpdateChartLoading] = useState(false);
  const [inputSearchValue, setInputSeacrhValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [isOpenLargeView, setOpenLargeView] = useState(false);
  const [isOpenWarning, setOpenWarning] = useState(false);
  const [dataFound, setDataFound] = useState(false);
  const [themeToggle, setThemeToggle] = useState(false);
  const [showQueryOnSub, setQueryOnSub] = useState(false);
  const colorsFixed = [
    [
      "#003f5c",
      "#2f4b7c",
      "#665191",
      "#a05195",
      "#d45087",
      "#f95d6a",
      "#ff7c43",
      "#ffa600",
    ],
    [
      "#004c6d",
      "#236081",
      "#3b7695",
      "#528ca9",
      "#68a2be",
      "#7fb9d3",
      "#96d1e9",
      "#aee9ff",
    ],
    [
      "#00876c",
      "#57a18b",
      "#8cbcac",
      "#bed6ce",
      "#f1c6c6",
      "#ec9c9d",
      "#e27076",
      "#d43d51",
    ],
  ];
  const [colorModesset, setColorModes] = useState(colorsFixed[0]);
  const [colorModessetIndex, setColorModesIndex] = useState(0);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [listActAgnts, setActiveAgents] = useState([]);
  const [showTableInfo, setShowTableInfo] = useState(false);
  const [showAgentInfo, setShowAgentInfo] = useState(false);
  const [parsedDataNew, setParsedDataNew] = useState([]);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [cardIndex, setCardIndex] = useState(0);
  const [isMultiple, setIsMultiple] = useState(false);
  const [wordcloud, setWordCloud] = useState(false)
  const [timeline, setTimeline] = useState(false)
  const [funnel, setFunnel] = useState(false)

  const [fontSize, setFontSize] = useState('16px')
  const { listening, startListening, stopListening } = useSpeechRecogintion(setQuestion);
  const [activeKey, setActiveKey] = useState("1");
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1394px)' });
  const intervalIdRef = useRef(null);

  const [isRefreshEnabled, setIsRefreshEnabled] = useState(true);

  const onBoundary = (event) => {
    console.log(`${event.name} boundary reached after ${event.elapsedTime} milliseconds.`);
  };
  const onError = (event) => {
    console.warn(event);
  };

  const { cancel, speak, speaking, supported, voices } = useSpeechSynthesis({
    onBoundary,
    onError,
  });


  const handleSubmitSimilarQuestion = async () => {
    setIsRefreshEnabled(!isRefreshEnabled);

    setCardSelected("");
    setLoadingSimilar(true);
    setDataFound(false);
    let payload = {
      question: question,
      Tag: filterTags,
      username: userDetails,
    };
    const res = await fetchData(restAPIs.submitQuestionSimilar(payload));
    if (res?.statusCode === 200) {
      setIsRefresh(false);
      setLoadingSimilar(false);
      const dataArray = Object.keys(res?.body).map((key) => ({
        key,
        value: res?.body[key],
      }));
      setQuestions(dataArray);
      setQuery(cardSelected?.Query);
      setInputTagValue(cardSelected?.Tag);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleCardClick = (item, index) => {
    setCardIndex(index);
    setDataFound(false);
    if (item) {
      if (item?.access) {
        setChartData({
          labels: [],
          datasets: [],
        });
        if (item?.Status === "temp") {
          setQuestion(item?.Question);
        } else {
          dispatch({
            type: "cardSelected",
            value: item,
          });


          cancel();
          if (isSpeakEnable)
            speak({ text: item?.Answer, voice: voices[2] })


          // setDefaultColor(item?.ChartColour)
          setDefaultChart(item?.ChartType);
          setCardSelected(item);
          setQuery(item?.Query);
          setInputTagValue(item?.Tag);
          setIsPinned(item?.isPinned);
          setIsEdit(false);
          setSQLEnabled(false);
          setIsUpdateChart(false);
          setColorModes(colorsFixed[+item?.ChartColour]);
          setColorModesIndex(+item?.ChartColour);

          if (item?.Status === "registered")
            fetchGraphStructure(
              item?.Qstn_id,
              item?.ImageString,
              +item?.ChartColour
            );
        }
      } else {
        setOpenWarning(true);
        // alert("Sorry, you don't have access to view this details.")
      }
    }
  };

  const getQuestions = async (ismulti) => {

    // setLoading(true)
    const payload = {
      pageNo: 1,
      pageSize: 10,
      triggerTimeFlag: true,
      username: userDetails,
    };

    const res = ismulti
      ? await fetchData(restAPIs.listQuestionForMultiple(payload))
      : await fetchData(restAPIs.listQuestion(payload));
    if (res?.statusCode === 200) {
      setIsRefresh(false);
      setLoading(false);
      const dataArray = Object.keys(res?.body).map((key) => ({
        key,
        value: res?.body[key],
      }));
      setQuestions(dataArray);


      // if (cardSelected) {
      //   const selectedIndex = dataArray[0]?.value.questions.findIndex((item) => item.Qstn_id === cardSelected?.Qstn_id);
      //   if (cardSelected?.Status !== dataArray[0]?.value.questions[selectedIndex]?.Status)
      //     handleCardClick(dataArray[0]?.value.questions[selectedIndex], cardIndex)
      // }

      // else {
      //   if (dataArray[0]?.value.questions.length > 0)
      //     handleCardClick(dataArray[0]?.value.questions[0], 0);
      // }

    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  useEffect(() => {
    listDatamart();
    dispatch({
      type: "headerName",
      value: 'Ask ' + domainName && domainName?.darkmaterDomain,
    });
  }, []);



  useEffect(() => {
    listSearchItems();
  }, [currentDatamart]);

  const config = {
    pagination: {
      total: totalItems,
      // current: page,
      defaultCurrent: 1,
      defaultPageSize: 8,
      showSizeChanger: false,
      // onChange: handlePageChange
    },
  };

  const handleOnChangeQuery = (e) => {
    setQuery(e);
  };

  const handleBetterAnswerCancel = async () => {
    if (!question?.startsWith("@")) {
      setIsBetterLoading(true);
      const payload = {
        question: question,
        username: userDetails,
      };
      const res = await fetchData(restAPIs.getQuestionQuery(payload));
      if (res?.statusCode === 200) {
        dispatch({
          type: "cardSelected",
          value: res?.body,
        });
        setQuestion("");

        const payload2 = {
          question: res?.body?.Question,
          query: res?.body?.Query,
          Qstn_id: res?.body?.Qstn_id,
          username: userDetails,
          // "Tag": inputTagValue
        };
        const res2 = await fetchData(restAPIs.submitQuestionQuery(payload2));
        if (res2?.statusCode === 200) {
          setIsBetterLoading(false);
          let card = {
            Answer: res?.body?.Answer,
            ProcessedTime: res?.body?.ProcessedTime,
            Query: res?.body?.Query,
            Question: res?.body?.Question,
            Status: res?.body?.Status,
            Tag: res?.body?.Tag,
            TriggerTime: res?.body?.TriggerTime,
            displayText: res?.body?.displayText,
          };
          setCardSelected({ ...cardSelected, ...card })
          getQuestions(isMultiple);
        }
      } else {
        messageApi.open({
          type: "error",
          content: res?.message,
        });
      }
    }
  };

  const handleBetterAnswer = async () => {
    setChartData({
      labels: [],
      datasets: [],
    });
    setCardSelected("");
    setIsBetterLoading(true);

    const regex = /(@[^()]+?\([^()]+\))/g;
    const parts = question.split(regex);
    const part = parts[1];
    const obj =
      listActAgnts && listActAgnts?.find((item) => item.label === part);

    const payload = {
      question: question,
      username: userDetails,
    };
    if (question?.startsWith("@")) payload.manager = obj;
    const res = question?.startsWith("@")
      ? await fetchData(restAPIs.submitForCollaborative(payload))
      : await fetchData(restAPIs.getQuestionQuery(payload));
    if (res?.statusCode === 200) {
      setQuestion("");
      dispatch({
        type: "cardSelected",
        value: res?.body,
      });
      if (res?.body?.Status == "new") setIsEdit(true);
      setCardSelected(res?.body);
      setIsBetterLoading(false);
      setQuery(res?.body?.Query);
      setInputTagValue(res?.body?.Tag);
    } else if (res?.statusCode === 201) {
      messageApi.open({
        type: "warning",
        content: res?.message,
      });
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleSubmitQuery = async () => {
    setIsQueryLoading(true);
    const payload = {
      question: cardSelected?.Question,
      query: newQuery,
      Qstn_id: cardSelected?.Qstn_id,
      // "Tag": inputTagValue
    };
    const res = await fetchData(restAPIs.submitQuestionQuery(payload));
    if (res?.statusCode === 200) {
      let card = {
        Answer: cardSelected?.Answer,
        ProcessedTime: cardSelected?.ProcessedTime,
        Query: newQuery,
        Question: cardSelected?.Question,
        Status: cardSelected?.Status,
        Tag: inputTagValue,
        TriggerTime: cardSelected?.TriggerTime,
        displayText: cardSelected?.displayText,
        ChartColour: cardSelected?.ChartColour,
        isPinned: cardSelected?.isPinned,
        ChartType: cardSelected?.ChartType,
      };
      setCardSelected(card);
      dispatch({
        type: "cardSelected",
        value: card,
      });
      getQuestions(isMultiple);
      setIsEdit(false);
      setIsQueryLoading(false);
      messageApi.open({
        type: "success",
        content: res?.message,
      });
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleAddPresentation = (item) => {
    setShowAddtoStory(true);
  };

  const handleAddReport = (item) => {
    setShowAddtoReport(true);
  };

  const canceling = (e) => {
    console.log(e);
  };

  const listAllTags = async () => {
    const res = await fetchData(restAPIs.listTags());
    if (res?.statusCode === 200) {
      let tags = [];
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        tags.push({
          value: element.Tag,
          id: element.Tag,
          label: element.Tag,
          ...element,
        });
      }
      setTagList(tags);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleResetInput = () => {
    setQuestion("");
    getQuestions(isMultiple);
    setIsRefreshEnabled(!isRefreshEnabled);
  };

  const openBlueHtmlContent = async () => {
    setLoadingBlue(true);
    let payload = {
      question: cardSelected?.Question,
      Qstn_id: cardSelected?.Qstn_id,
      Query: newQuery,
    };
    const res = await fetchData(restAPIs.iframeLoader(payload));
    setLoadingBlue(false);
    setHtmlContent(res);
    setShow(true);
  };

  const handleCloseBlue = () => {
    setShow(false);
  };

  const refreshTable = () => {

    setIsRefresh(true);
    console.log("isRefreshEnabled", isRefreshEnabled);

    if (isRefreshEnabled === true)
      getQuestions(isMultipleStore);


    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    // Start a new interval
    intervalIdRef.current = setInterval(() => {
      refreshTable();
    }, 30000);
  };

  const currentDataMart = async () => {
    const res = await fetchData(restAPIs.getCurrentDataMart());
    setCurrentDataMart(res?.body?.datamartName);
    setLoading(false);
    if (res?.body?.datamartName) {
      setLoading(true);
      getQuestions();
      listActAgents();
      listAllTags();
    }
    // else{
    //   messageApi.open({
    //     type: 'error',
    //     content: res?.message,
    //   });
    // }
  };

  const listDatamart = async () => {
    const res = await fetchData(
      restAPIs.listDataMart({ showDocuments: false })
    );
    if (res?.statusCode === 200) {
      let items = [];
      for (let i = 0; i < res?.body.items.length; i++) {
        const element = res?.body.items[i];
        if (element?.isActive) {
          let obj = {
            label: element.datamartName,
            key: element.datamartName,
            disabled: true,
          };
          items.push(obj);
        }
      }

      if (items.length > 0) {
        setIsMultiple(prev => res?.body?.isMultiple);
        setLoading(true);
        dispatch({
          type: "isMultipleStore",
          value: res?.body?.isMultiple,
        });
        getQuestions(res?.body?.isMultiple);
        listActAgents();
        listAllTags();
      }

      setDataMartList(items);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const [dataImg, setDataImg] = useState("");
  const [showImg, setShowImg] = useState(false);

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  const handleOnkeyDown = (event) => {
    if (event.key === "Enter") {
      if (question) handleSubmitSimilarQuestion();
    }
  };


  // bar,histogram,area,scatter,line
  const items = [
    {
      label: "Bar",
      key: "bar",
      icon: <BarChartOutlined />,
    },
    {
      label: "Pie",
      key: "pie",
      icon: <PieChartOutlined />,
    },
    {
      label: "Area",
      key: "area",
      icon: <AreaChartOutlined />,
    },
    {
      label: "Radar",
      key: "radar",
      icon: <RadarChartOutlined />,
    },
    {
      label: "Line",
      key: "line",
      icon: <LineChartOutlined />,
    },
    {
      label: "Scatter",
      key: "scatter",
      icon: <DotChartOutlined />,
    },
    {
      label: "Table",
      key: "table",
      icon: <TableOutlined />,
    },
  ];

  const itemsAdvance = [
    {
      label: "Wordcloud",
      key: "wordcloud",
      icon: <AntCloudOutlined />,
      onClick: () => setWordCloud(true)
    },
    {
      label: "Timeline",
      key: "timeline",
      icon: <FieldTimeOutlined />,
      onClick: () => setTimeline(true)
    },
    {
      label: "Funnel",
      key: "funnel",
      icon: <FunnelPlotOutlined />,
      onClick: () => setFunnel(true)
    },
  ];

  const coloModes = [
    {
      label: (
        <div className="___mode_color">
          <span
            style={{
              backgroundColor: "#003f5c",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#2f4b7c",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#665191",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#a05195",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#d45087",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#f95d6a",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#ff7c43",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#ffa600",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "5px",
            }}
          ></span>
          Mode 1
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="___mode_color">
          <span
            style={{
              backgroundColor: "#004c6d",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#236081",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#3b7695",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#528ca9",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#68a2be",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#7fb9d3",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#96d1e9",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#aee9ff",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "5px",
            }}
          ></span>
          Mode 2
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div className="___mode_color">
          <span
            style={{
              backgroundColor: "#00876c",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#57a18b",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#8cbcac",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#bed6ce",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#f1c6c6",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#ec9c9d",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#e27076",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "1px",
            }}
          ></span>
          <span
            style={{
              backgroundColor: "#d43d51",
              display: "inline-block",
              width: "5px",
              height: "10px",
              marginRight: "5px",
            }}
          ></span>
          Mode 3
        </div>
      ),

      key: "2",
    },
  ];

  const handleDropdownColorsClick = async ({ key }) => {
    setColorModes(colorsFixed[key]);
    setColorModesIndex(key);
    setChartData({
      labels: [],
      datasets: [],
    });
    // setIsUpdateChart(true)
    handleUpdateChart(defaultChart, xAxis, yAxis, key);

    const categories = parsedDataSaved.map((item) => item[xAxis]);
    const values = parsedDataSaved.map((item) => item[yAxis]);
    // Set chart data
    setChartData({
      labels: categories,
      datasets: [
        {
          label: yAxis,
          data: values,
          backgroundColor: colorsFixed[key],
        },
      ],
    });

    const new_options = {
      scales: {
        x: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: themeToggle ? "#666666" : "#ffffff", // Color of the grid lines on the x-axis
          },
          position: "bottom",
          title: {
            display: true,
            text: xAxis,
            color: themeToggle ? "#666666" : "#ffffff",
          },
        },
        y: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: themeToggle ? "#666666" : "#ffffff", // Color of the grid lines on the x-axis
          },
          position: "left",
          title: {
            display: true,
            text: yAxis,
            color: themeToggle ? "#666666" : "#ffffff",
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };
    setOptionsChart(new_options);
    let updatedQuestions = [...questions];
    if (updatedQuestions[0]?.value?.questions[cardIndex]) {
      updatedQuestions[0].value.questions[cardIndex].ChartColour = key;
    }
    setQuestions(updatedQuestions);
  };

  const handleDropdownChartClick = async ({ key }) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      chart: { ...prevOptions.chart, type: key },
    }));
    setDefaultChart(key);
    // setIsUpdateChart(true)

    let updatedQuestions = [...questions];
    if (updatedQuestions[0]?.value?.questions[cardIndex]) {
      updatedQuestions[0].value.questions[cardIndex].ChartType = key;
    }
    setQuestions(updatedQuestions);

    handleUpdateChart(key);
  };

  const settings = {
    dots: false,
    // infinite: true,
    // autoplay: true,
    arrows: true,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // responsive: [
    //   {
    //     breakpoint: 1424, // For devices with a width of 1024px or less
    //     settings: {
    //       slidesToShow: 3, // Show 3 slides instead of 5
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1024, // For devices with a width of 1024px or less
    //     settings: {
    //       slidesToShow: 2, // Show 3 slides instead of 5
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480, // For devices with a width of 480px or less
    //     settings: {
    //       slidesToShow: 1, // Show 1 slide
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  const onChangePanel = (key) => {
    setCardSelected("");
    setActiveKey(key)
  };



  const handleColorChange = (key) => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      colors: [key?.toHexString()],
    }));
    setDefaultColor(key?.toHexString());
    setIsUpdateChart(true);
  };

  const handleUnPin = async () => {
    setIsUnpinLoading(true);
    const res = await fetchData(
      restAPIs.unPin({ Qstn_id: cardSelected?.Qstn_id })
    );
    if (res?.statusCode === 200) {
      setIsUnpinLoading(false);
      setIsPinned(false);
      setCardSelected((prevOptions) => ({ ...prevOptions, isPinned: false }));
    }
  };

  const handleUpdateChart = (key, tempXaxis, tempYaxis, colomode) => {

    if (chartRef.current) {
      setIsUpdateChartLoading(true);
      document.getElementById("chart2").style.display = "block";
      const chartContainer =
        defaultChart === "table"
          ? document.getElementById("chart2")
          : document.getElementById("chart");
      if (chartContainer) {
        html2canvas(chartContainer).then(async (canvas) => {
          document.getElementById("chart2").style.display = "none";
          const base64Image = canvas.toDataURL("image/png");
          const res = await fetchData(
            restAPIs.updateImageString({
              Qstn_id: cardSelected?.Qstn_id,
              ImageString: base64Image,
              ChartColour: colomode
                ? colomode.toString()
                : colorModessetIndex.toString(),
              ChartType: key ? key : defaultChart,
              xaxis: tempXaxis ? tempXaxis : xAxis,
              yaxis: tempYaxis ? tempYaxis : yAxis,
              ChartJSON: {
                data: chartData,
                options: optionsChart,
                parsedData: parsedDataSaved,
                xaxis: tempXaxis ? tempXaxis : xAxis,
                yaxis: tempYaxis ? tempYaxis : yAxis,
                defaultChart: key ? key : defaultChart,
              },
            })
          );
          if (res?.statusCode === 200) {
            let card = {
              Answer: cardSelected?.Answer,
              ProcessedTime: cardSelected?.ProcessedTime,
              Query: newQuery,
              Question: cardSelected?.Question,
              Status: cardSelected?.Status,
              Tag: inputTagValue,
              TriggerTime: cardSelected?.TriggerTime,
              displayText: cardSelected?.displayText,
              ChartColour: colomode ? colomode : colorModessetIndex,
              isPinned: isPinned,
              ChartType: defaultChart,
              ImageString: base64Image,
              Qstn_id: cardSelected?.Qstn_id,
              dataFound: true,
              access: cardSelected?.access,
            };
            setIsUpdateChartLoading(false);
            setIsUpdateChart(false);
            // getQuestions();
            // fetchGraphStructure(cardSelected?.Qstn_id, base64Image)

            setCardSelected(card);
            messageApi.open({
              type: "success",
              content: res?.message,
            });
          } else {
            messageApi.open({
              type: "error",
              content: res?.message,
            });
          }
        });
      }
    }
  };

  const handlePinDashboard = () => {
    setShowDashboard(false);
    getQuestions(isMultiple);
  };

  const listSearchItems = async () => {
    const payload = {
      datamart: currentDatamart?.datamartName,
      showAgentInfo: showAgentInfo,
      showTableInfo: showTableInfo,
    };
    setSearchList([]);
    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res?.statusCode === 200) {
      let tags = [];
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        tags.push({
          value: element.name,
          id: element.name,
          label: element.name,
          ...element,
        });
      }
      setSearchList(tags);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const onSelectDropDownSearchList = (data, option) => {
    setInputSeacrhValue(option.label);
    // setQuestion(option.label);
    if (option && option.label) {
      setQuestion(option.label);
    } else {
      setQuestion(data); // Fallback to value if option.label is not available
    }
  };

  const onChangeValSearch = (data, option) => {
    setInputSeacrhValue(data);
    setQuestion(data);
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [optionsChart, setOptionsChart] = useState([]);
  const [parsedDataSaved, setParsedData] = useState();
  const [keys, setKeys] = useState([]);
  const [xAxis, setXAxis] = useState();
  const [yAxis, setYAxis] = useState();

  const [isLoadingChart, setIsLoadingChart] = useState(false);

  const fetchGraphStructure = async (id, ImageString, colormodeIndex) => {
    const payload = {
      Qstn_id: id,
      question: question,
    };

    setChartData({
      labels: [],
      datasets: [],
    });
    setIsLoadingChart(true);
    const res = await fetchData(restAPIs.listGraphStructure(payload));
    if (res?.statusCode === 200) {
      setIsLoadingChart(false);
      if (
        Object.keys(res?.body || {}).length !== 0 &&
        Object.keys(res?.body.content || {}).length !== 0
      ) {
        setDataFound(true);
        setParsedDataNew(res?.body)
        const parsedData = res?.body?.content;
        setParsedData(parsedData);
        const categories = parsedData?.map((item) => item[res?.body?.xaxis]);
        const values = parsedData?.map((item) => item[res?.body?.yaxis]);
        let arry = [];
        for (let i = 0; i < Object.keys(parsedData[0]).length; i++) {
          const element = Object.keys(parsedData[0])[i];
          arry.push({ id: element, value: element, label: element });
        }

        setKeys(arry);
        setXAxis(res?.body?.xaxis);
        setYAxis(res?.body?.yaxis);

        // Set chart data
        setChartData({
          labels: categories,
          datasets: [
            {
              label: res?.body?.yaxis,
              data: values,
              backgroundColor: colorsFixed[colormodeIndex],
              borderRadius: 6, // Adjust border radius
              borderWidth: 0, // Adjust border width
              borderColor: "#FFFFFF", // Adjust border color
            },
          ],
        });

        const new_options = {
          responsive: true,
          color: "#ffffff",

          scales: {
            x: {
              grid: {
                display: false,
                color: "#ffffff99", // Color of the grid lines on the x-axis
              },
              ticks: {
                color: "#ffffff99", // Color of the grid lines on the x-axis
              },
              border: {
                display: true, // Show x-axis line
                color: "#ffffff47", // Color of x-axis line
              },
              position: "bottom",
              title: {
                display: true,
                text: res?.body?.xaxis,
                color: "#ffffff99",
              },
            },
            y: {
              grid: {
                display: false,
                color: "#ffffff47", // Color of the grid lines on the x-axis
              },
              border: {
                display: true, // Show x-axis line
                color: "#ffffff47", // Color of x-axis line
              },
              ticks: {
                color: "#ffffff47", // Color of the grid lines on the x-axis
              },
              position: "left",
              title: {
                display: true,
                text: res?.body?.yaxis,
                color: "#ffffff99",
              },
            },
          },
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        };
        setOptionsChart(new_options);
        setIsLoadingChart(false);
        if (ImageString == "") {
          setTimeout(() => {
            if (chartRef.current) {
              const chartContainer = document.getElementById("chart");
              if (chartContainer) {
                html2canvas(chartContainer).then(async (canvas) => {
                  const base64Image = canvas.toDataURL("image/png");
                  const res2 = await fetchData(
                    restAPIs.updateImageString({
                      Qstn_id: id,
                      ImageString: base64Image,
                      ChartColour: colormodeIndex.toString(),
                      ChartType: defaultChart,
                      xaxis: res?.body?.xaxis,
                      yaxis: res?.body?.yaxis,
                      ChartJSON: {
                        data: chartData,
                        options: optionsChart,
                        parsedData: parsedDataSaved,
                        xaxis: xAxis,
                        yaxis: yAxis,
                        defaultChart: defaultChart,
                      },
                    })
                  );
                  if (res2?.statusCode === 200) {
                    messageApi.open({
                      type: "success",
                      content: res2?.message,
                    });
                  } else {
                    messageApi.open({
                      type: "error",
                      content: res2?.message,
                    });
                  }
                });
              }
            }
          }, 1000);
        }
        if (simpleRef?.current) {
          const { top } = simpleRef.current.getBoundingClientRect();
          window.scrollTo({ top, behavior: "smooth" });
        }
      }
    } else {
      setDataFound(false);
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleChangeXaxis = (value) => {
    setXAxis(value);
    setChartData({
      labels: [],
      datasets: [],
    });

    const categories = parsedDataSaved.map((item) => item[value]);
    const values = parsedDataSaved.map((item) => item[yAxis]);
    // Set chart data
    setChartData({
      labels: categories,
      datasets: [
        {
          label: yAxis,
          data: values,
          backgroundColor: colorModesset,
          borderRadius: 6, // Adjust border radius
          borderWidth: 0, // Adjust border width
          borderColor: "#FFFFFF", // Adjust border color
        },
      ],
    });

    const new_options = {
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "bottom",
          title: {
            display: true,
            text: value,
          },
        },
        y: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "left",
          title: {
            display: true,
            text: yAxis,
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };
    setOptionsChart(new_options);
    handleUpdateChart(defaultChart, value, yAxis);
  };
  const handleChangeYaxis = (value) => {
    setYAxis(value);

    setChartData({
      labels: [],
      datasets: [],
    });

    const categories = parsedDataSaved.map((item) => item[xAxis]);
    const values = parsedDataSaved.map((item) => item[value]);
    // Set chart data
    setChartData({
      labels: categories,
      datasets: [
        {
          label: value,
          data: values,
          backgroundColor: colorModesset,
          borderRadius: 6, // Adjust border radius
          borderWidth: 0, // Adjust border width
          borderColor: "#FFFFFF", // Adjust border color
        },
      ],
    });

    const new_options = {
      responsive: true,
      scales: {
        x: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "bottom",
          title: {
            display: true,
            text: xAxis,
          },
        },
        y: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "left",
          title: {
            display: true,
            text: value,
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };

    setOptionsChart(new_options);
    handleUpdateChart(defaultChart, xAxis, value);
  };

  const UTCTime = (time) => {
    let date = new Date(time.replace(" ", "T") + "Z");
    return date.toLocaleString();
  };

  const onChangeSwitch = (checked) => {
    setThemeToggle((prevOptions) => checked);
    setIsUpdateChart(true);

    const new_options = {
      responsive: true,
      color: checked ? "#666666" : "#ffffff",
      scales: {
        x: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "bottom",
          title: {
            display: true,
            text: xAxis,
            color: checked ? "#666666" : "#ffffff99",
          },
        },
        y: {
          grid: {
            display: false,
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          ticks: {
            color: "#ffffff99", // Color of the grid lines on the x-axis
          },
          border: {
            display: true, // Show x-axis line
            color: "#ffffff47", // Color of x-axis line
          },
          position: "left",
          title: {
            display: true,
            text: yAxis,
            color: checked ? "#666666" : "#ffffff99",
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };
    setOptionsChart((prevOptions) => new_options);
  };

  const TableComponent = ({ data }) => {
    // Check if data is not empty
    // if (!data.length) {
    //   return <div>No data available</div>;
    // }

    // Extract column headers from the first object in the array
    if (data) {
      const headers = Object.keys(data[0]);
      return (
        <table>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, colIndex) => (
                  <td key={colIndex}>{row[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    else {
      return '';
    }


  };

  const handleHideSQL = () => {
    setSQLEnabled(false);
    setIsEdit(false);
    setIsEditButtonShow(true);
    setQuery(cardSelected?.Query);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1"); // Active tab state

  const handleTabClick = (key) => {
    if (key === "2" || key === "3") {
      setIsModalVisible(true);
    }
  };

  const handleOk = () => {
    setActiveTabKey("1");
    setIsModalVisible(false);
  };

  const handleDeleteQuestion = async (item) => {
    let payload = {
      Qstn_id: item?.Qstn_id,
    };

    setIsLoadingDelete(true);
    const res = await fetchData(restAPIs.deleteQuestion(payload));
    if (res?.statusCode === 200) {
      setChartData({
        labels: [],
        datasets: [],
      });
      setDefaultChart("area");
      setCardSelected("");
      setQuery("");
      setInputTagValue("");
      setIsPinned(false);
      setIsEdit(false);
      setSQLEnabled(false);
      setIsUpdateChart(false);
      setColorModes(colorsFixed[0]);
      setColorModesIndex(0);
      setIsLoadingDelete(false);
      messageApi.open({
        type: "success",
        content: res?.message,
      });
      getQuestions(isMultiple);
    } else {
      setIsLoadingDelete(false);
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const listActAgents = async () => {
    const res = await fetchData(restAPIs.listActiveAgents({}));
    if (res?.statusCode === 200) {
      setActiveAgents(res?.body);
    }
  };

  const handleToggleTableInfo = async (e) => {
    setShowTableInfo(e);
    const payload = {
      datamart: currentDatamart?.datamartName,
      showAgentInfo: showAgentInfo,
      showTableInfo: e,
    };
    setSearchList([]);
    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res?.statusCode === 200) {
      let tags = [];
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        tags.push({
          value: element.name,
          id: element.name,
          label: element.name,
          ...element,
        });
      }
      setSearchList(tags);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleToggleAgentInfo = async (e) => {
    setShowAgentInfo(e);
    const payload = {
      datamart: currentDatamart?.datamartName,
      showAgentInfo: e,
      showTableInfo: showTableInfo,
    };
    setSearchList([]);
    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res?.statusCode === 200) {
      let tags = [];
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        tags.push({
          value: element.name,
          id: element.name,
          label: element.name,
          ...element,
        });
      }
      setSearchList(tags);
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleQueryOnSub = (e) => {
    setQueryOnSub(e);
  };

  const handlSubmitLogicNew = () => {
    if (isMultiple) handleMultipleDataMartQuestion();
    else {
      if (showQueryOnSub) {
        handleBetterAnswer();
      } else {
        handleBetterAnswerCancel();
      }
    }
  };

  const handleMultipleDataMartQuestion = async () => {
    const payload = {
      question: question,
      username: userDetails,
    };
    const res = await fetchData(restAPIs.askMultipleSources(payload));
    if (res?.statusCode === 200) {
      setQuestion("");
      dispatch({
        type: "cardSelected",
        value: res?.body,
      });
      if (res?.body?.Status == "new") setIsEdit(true);
      setCardSelected(res?.body);
      setIsBetterLoading(false);
      setQuery(res?.body?.Query);
      setInputTagValue(res?.body?.Tag);
    } else if (res?.statusCode === 201) {
      messageApi.open({
        type: "warning",
        content: res?.message,
      });
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  };

  const handleTogglePanel = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const handleDatamartLoad = async ({ key }) => {
    setCurrentDataMart(key);
    setChartData({
      labels: [],
      datasets: [],
    });
    setCardSelected("");
    setDefaultChart("area");
    setQuery("");
    setInputTagValue("");
    setIsPinned(false);
    setIsEdit(false);
    setSQLEnabled(false);
    setIsUpdateChart(false);
    setColorModes(colorsFixed[0]);
    setColorModesIndex(0);
    setLoading(true);
    let payload = {
      dataMart: key,
    };
    const res = await fetchData(restAPIs.loadDataMart(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: "success",
        content: res?.message,
      });
      getQuestions();
    }
  };

  const handleChangeAnswer = (answer, isVoiceNeed, fontsize) => {
    if (isSpeakEnable && isVoiceNeed) {
      cancel()
      speak({ text: answer, voice: voices[2] })
    }
    // Create a new object with updated properties
    const updatedCard = { ...cardSelected, Answer: answer };
    // Update the state with the new object
    setCardSelected(updatedCard);
    setFontSize(fontsize)
  }

  const handleSpeak = () => {

    if (isSpeakEnable) {
      cancel()
    }
    else {
      speak({ text: cardSelected?.Answer, voice: voices[2] })
    }

    dispatch({
      type: "isSpeakEnable",
      value: !isSpeakEnable,
    });

  }


  const params = {
    effect: 'flip',  // Try 'cube', 'flip', or 'fade'
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 4,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  const handleSlideChange = (swiper) => {
    const currentIndex = swiper.realIndex; // Get the current slide index    
    const currentCardItem = questions[0]?.value.questions[currentIndex]; // Get the cardItem based on the index
    handleCardClick(currentCardItem, currentIndex); // Call handleCardClick with the cardItem and index
  };



  const handlers = useSwipeable({
    onSwipedUp: () => {
      const nextKey = (parseInt(activeKey, 10) + 1).toString();
      if (nextKey <= questions.length.toString()) {
        setActiveKey(nextKey);
      }
    },
    onSwipedDown: () => {
      const prevKey = (parseInt(activeKey, 10) - 1).toString();
      if (prevKey >= "1") {
        setActiveKey(prevKey);
      }
    },
    trackMouse: true, // Optional: Enables swipe detection with mouse
  });

  useEffect(() => {
    // Start the interval when the component mounts
    intervalIdRef.current = setInterval(() => {
      if (isRefreshEnabled === true)
        getQuestions(isMultipleStore);
      // refreshTable();
    }, 30000); // 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalIdRef.current);
  }, []);


  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const questionRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (questionRef.current) {
      // Check if the question exceeds 3 lines
      const isOverflowing = questionRef.current.scrollHeight > questionRef.current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [cardSelected?.Question]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <>
      <>
        <div className="__page_wrapper_">
          <div className="__graph_n_data">
            <div style={{ maxHeight: '470px', minHeight: '280px', paddingRight: '10px', }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "baseline",
                }}
              >
                <div>
                  <h2
                    style={{ marginBottom: '5px' }}
                    ref={questionRef}
                    className={`question-text ${isExpanded ? 'expanded' : 'collapsed'}`}
                  >
                    {cardSelected?.Question}

                  </h2>
                  <div style={{ display: 'flex' }}>
                    {isTruncated && (
                      <button className="__read" onClick={toggleExpanded}>
                        {isExpanded ? 'Show Less' : 'Show More'}
                      </button>
                    )}
                  </div>
                </div>

                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {cardSelected?.Status === "inprogress" && (
                    <Tooltip title='In progress'>
                      <div className="__inprogress">
                        <div className="circles">
                          <div className="circle1"></div>
                          <div className="circle2"></div>
                          <div className="circle3"></div>
                        </div>
                      </div>
                    </Tooltip>
                  )}
                  {cardSelected?.Status === "registered" && (
                    <Tooltip title='Registered'>
                      <div className="__registered"></div>
                    </Tooltip>
                  )}
                  {cardSelected?.Status === "new" && (
                    <Tooltip title='New'>
                      <div
                        className="__registered"
                        style={{ backgroundColor: "yellow" }}
                      ></div>
                    </Tooltip>
                  )}
                  {cardSelected?.Status === "failed" && (
                    <Tooltip title='Failed'>
                      <div
                        className="__registered"
                        style={{ backgroundColor: "#ab0000" }}
                      ></div>
                    </Tooltip>
                  )}
                </span>
              </div>
              {!isMultiple && (
                <div className="__edit_sec">
                  <div
                    className="__edit_btn_sec"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    {userPermissions && userPermissions?.includes('tune_ql') && <>
                      {isSQLEnabled &&
                        isEditButtonShow &&
                        (cardSelected?.Status === "registered" ||
                          cardSelected?.Status === "new" ||
                          cardSelected?.Status === "failed") && (
                          <Button
                            ghost
                            icon={
                              <FontAwesomeIcon icon={faPenToSquare} />
                            }
                            onClick={() => {
                              setIsEdit(true);
                              setIsEditButtonShow(false);
                            }}
                          >
                            {" "}
                            Tune QL
                          </Button>
                        )}
                    </>}
                    {userPermissions && userPermissions?.includes('view_ql') && cardSelected && <>
                      {
                        !isSQLEnabled ? (
                          setDataScientistValue >= 75 && (
                            <Button
                              ghost
                              icon={<ConsoleSqlOutlined />}
                              onClick={() => {
                                setSQLEnabled(true);
                                setIsEditButtonShow(true);
                              }}
                            >
                              View QL
                            </Button>
                          )
                        ) : (
                          <Button
                            ghost
                            icon={<ConsoleSqlOutlined />}
                            onClick={() => handleHideSQL()}
                          >
                            {isEdit ? "Cancel" : "Hide QL"}
                          </Button>
                        )
                      }
                    </>}
                    {cardSelected && cardSelected?.Status != "inprogress" && (
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Popconfirm
                          title={
                            cardSelected?.Status == "registered"
                              ? "Sync"
                              : "Submit"
                          }
                          description={
                            cardSelected?.Status == "registered"
                              ? "Do you want to sync with latest data?"
                              : "Are you sure want to submit this query?"
                          }
                          onConfirm={() => handleSubmitQuery()}
                          onCancel={canceling}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            ghost
                            icon={
                              <FontAwesomeIcon
                                icon={
                                  cardSelected?.Status == "registered"
                                    ? faRotateRight
                                    : faPaperPlane
                                }
                              />
                            }

                            loading={isQueryLoading}
                          >
                            {cardSelected?.Status == "registered"
                              ? isEdit
                                ? "Save & Sync"
                                : "Sync"
                              : "Confirm"}
                          </Button>
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                  {isSQLEnabled && (
                    <Tabs
                      defaultActiveKey="1"
                      className="__tabs_sql"
                      tabPosition="right"
                    >
                      <TabPane tab={"A - SQL"} key={"1"}>
                        <TextArea
                          placeholder="Enter your query"
                          value={newQuery}
                          onChange={handleOnChangeQuery}
                          type="text"
                          style={{ width: "100%" }}
                          disabled={
                            !(
                              isEdit &&
                              (cardSelected?.Status === "registered" ||
                                cardSelected?.Status === "new")
                            )
                          }
                        />
                      </TabPane>
                      <TabPane tab={"Python"} key={"2"}>
                        <div className="__prm_fet">
                          <p>
                            This feature is yet to be available in your
                            subscription.
                          </p>
                        </div>
                      </TabPane>
                      <TabPane tab={"R"} key={"3"}>
                        <div className="__prm_fet">
                          <p>
                            This feature is yet to be available in your
                            subscription.
                          </p>
                        </div>
                      </TabPane>
                    </Tabs>
                  )}
                </div>
              )}
              <div className="__voice">
                {cardSelected?.Answer && <>
                  <span style={{ fontWeight: '300', fontSize: '14px' }}>Answer </span>
                  <Button
                    ghost
                    style={{ borderRadius: '100px', padding: '0', width: '92px', height: '32px', }}
                    onClick={() => handleChangeAnswer(cardSelected?.AnswerDetails?.a11, true, '26px')}
                  >in 11words</Button>
                  <Button
                    ghost
                    style={{ borderRadius: '100px', padding: '0', width: '92px', height: '32px', }}
                    onClick={() => handleChangeAnswer(cardSelected?.AnswerDetails?.a25, true, '20px')}
                  >in 25words</Button>

                  <Button
                    ghost
                    style={{ borderRadius: '100px', padding: '0', width: '100px', height: '32px', }}
                    onClick={() => handleChangeAnswer(cardSelected?.AnswerDetails?.full, true, '16px')}
                  >Full Answer</Button>
                </>}
                {cardSelected?.Answer && <Tooltip title={isSpeakEnable ? 'Stop' : 'Speak'}>
                  <Button
                    ghost
                    icon={<FontAwesomeIcon icon={isSpeakEnable ? faVolumeHigh : faVolumeXmark} />}
                    onClick={() => handleSpeak()}
                  ></Button>
                </Tooltip>}
              </div>
              <p className="_mark_down_answer" style={{ fontSize: fontSize, transition: '0.4s', lineHeight: '1.6' }}>
                <ReactMarkdown>
                  {cardSelected &&
                    cardSelected?.Status == "new" &&
                    cardSelected?.newInfo != ""
                    ? cardSelected?.newInfo
                    : cardSelected?.Answer}
                </ReactMarkdown>
              </p>

            </div>

            <div>

              <div className="__btns_new">

                {cardSelected?.Status === "registered" && dataFound && (
                  <>

                    <Tooltip title="Blue (Reportlet)">
                      <Button
                        ghost
                        icon={<MacCommandOutlined />}

                        onClick={() => openBlueHtmlContent()}
                        loading={loadingBlue}
                      ></Button>
                    </Tooltip>
                    <Tooltip title=" Add to Story">
                      <Button
                        ghost
                        icon={<FileWordOutlined />}
                        onClick={() => handleAddPresentation(cardSelected)}

                      ></Button>
                    </Tooltip>
                  </>
                )}
                <Tooltip title=" Add to Report">
                  <Button
                    ghost
                    icon={<FontAwesomeIcon icon={faReceipt} />}
                    onClick={() => handleAddReport(cardSelected)}

                  ></Button>
                </Tooltip>


                {cardSelected &&

                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key={'1'}
                        >
                          <Popconfirm
                            placement="leftTop"
                            title="Delete Question"
                            description="Are you sure want to Delete Question"
                            onConfirm={() => handleDeleteQuestion(cardSelected)}
                            onCancel={canceling}
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ loading: isLoadingDelete }}
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                          >
                            <DeleteOutlined /> Delete
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                    onClick={(e) => e.preventDefault()}
                    trigger={["click"]}
                  >
                    <Button
                      ghost
                      icon={<MoreOutlined />}
                    ></Button>
                  </Dropdown>

                }
                {dataFound && cardSelected?.Status === "registered" ? (
                  isPinned ? (
                    <Popconfirm
                      title="Save"
                      description="Are you sure want to unpin from Dashboard"
                      onConfirm={() => handleUnPin()}
                      onCancel={canceling}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Un Pin">
                        <Button

                          ghost
                          icon={<PushpinOutlined />}
                          loading={isUnpinLoading}
                        ></Button>
                      </Tooltip>
                    </Popconfirm>
                  ) : (
                    <Tooltip title="Pin to Dashboard">
                      <Button
                        ghost
                        icon={<PushpinOutlined />}

                        onClick={() => setShowDashboard(true)}
                      ></Button>
                    </Tooltip>
                  )
                ) : (
                  ""
                )}
              </div>

              {!isMultiple && (
                <>
                  {dataFound ? (
                    <>

                      {cardSelected &&
                        cardSelected?.Status == "registered" &&
                        (
                          <div className="___chart_drops">


                            {dataFound && (
                              <>
                                {cardSelected?.Status === "registered" && (
                                  <Dropdown
                                    menu={{
                                      items,
                                      onClick: handleDropdownChartClick,
                                      // defaultSelectedKeys: [defaultChart],
                                      selectable: true,
                                    }}
                                    trigger={["click"]}
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Tooltip title="Switch Graph">
                                        <Button
                                          ghost
                                          icon={<BarChartOutlined />}

                                        ></Button>
                                      </Tooltip>
                                    </a>
                                  </Dropdown>


                                )}

                                <Dropdown
                                  overlay={
                                    <Menu>
                                      {itemsAdvance.map((mode) => (
                                        <Menu.Item
                                          key={mode.key}
                                          onClick={mode.onClick}
                                          icon={mode.icon}
                                        >
                                          {mode.label}
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  }
                                  trigger={["click"]}
                                >
                                  <Button
                                    ghost

                                  >Advance Graph</Button>
                                </Dropdown>
                              </>
                            )}

                            {dataFound &&
                              cardSelected?.Status === "registered" &&
                              defaultChart !== "table" && (
                                <Dropdown
                                  overlay={
                                    <Menu onClick={handleDropdownColorsClick}>
                                      {coloModes.map((mode) => (
                                        <Menu.Item key={mode.key}>
                                          {mode.label}
                                        </Menu.Item>
                                      ))}
                                    </Menu>
                                  }
                                  trigger={["click"]}
                                >
                                  <a onClick={(e) => e.preventDefault()}>
                                    <Tooltip title="Colors">
                                      <Button
                                        ghost

                                      >
                                        Color
                                      </Button>
                                    </Tooltip>
                                  </a>
                                </Dropdown>
                              )}
                            {defaultChart !== "table" &&
                              chartData?.labels.length > 0 && <>
                                <Button
                                  type="primary"
                                  onClick={() => setOpenLargeView(true)}
                                  ghost
                                  style={{
                                    margin: 0,
                                    color: "#fff9",
                                    borderColor: "#fff3",
                                  }}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faUpRightAndDownLeftFromCenter}
                                    />
                                  }
                                ></Button>
                                <SelectBox
                                  options={keys}
                                  value={xAxis}
                                  handleOnChange={handleChangeXaxis}
                                  label="Change X axis"
                                  width="150px"
                                />

                                <SelectBox
                                  options={keys}
                                  value={yAxis}
                                  handleOnChange={handleChangeYaxis}
                                  label="Change Y axis"
                                  width="150px"
                                />
                              </>}
                          </div>
                        )}
                      {isLoadingChart &&
                        cardSelected?.Status == "registered" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <LoadingOutlined
                            style={{
                              fontSize: "30px",
                              marginTop: "30px",
                            }}
                          />
                        </div>
                      ) : (
                        chartData?.labels.length > 0 && (
                          <>
                            <div
                              id="chart"
                              ref={chartRef}
                              style={{
                                maxHeight:
                                  defaultChart && defaultChart === "table"
                                    ? "260px"
                                    : "auto",
                              }}
                            >
                              {defaultChart &&
                                defaultChart === "radar" && (
                                  <Radar
                                    data={chartData}
                                    options={{
                                      ...optionsChart, scales: {
                                        x: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                        y: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        }
                                      }
                                    }}
                                  />
                                )}
                              {defaultChart && defaultChart === "pie" && (
                                <Pie
                                  data={chartData}
                                  options={{
                                    ...optionsChart, scales: {
                                      x: {
                                        grid: {
                                          display: false,
                                        },
                                        ticks: {
                                          color: '#ffffffc2',
                                        },
                                      },
                                      y: {
                                        grid: {
                                          display: false,
                                        },
                                        ticks: {
                                          color: '#ffffffc2',
                                        },
                                      },
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      }
                                    }
                                  }}
                                />
                              )}
                              {defaultChart &&
                                defaultChart === "line" && (
                                  <Line
                                    data={chartData}
                                    options={{
                                      ...optionsChart, scales: {
                                        x: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                        y: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        }
                                      }
                                    }}
                                  />
                                )}
                              {defaultChart && defaultChart === "bar" && (
                                <Bar
                                  data={chartData}
                                  options={{
                                    ...optionsChart, scales: {
                                      x: {
                                        grid: {
                                          display: false,
                                        },
                                        ticks: {
                                          color: '#ffffffc2',
                                        },
                                      },
                                      y: {
                                        grid: {
                                          display: false,
                                        },
                                        ticks: {
                                          color: '#ffffffc2',
                                        },
                                      },
                                    },
                                    plugins: {
                                      legend: {
                                        display: false,
                                      }
                                    }
                                  }}
                                />
                              )}
                              {defaultChart &&
                                defaultChart === "area" && (
                                  <Line
                                    data={chartData}
                                    options={{
                                      ...optionsChart, scales: {
                                        x: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                        y: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        },
                                        filler: {
                                          propagate: true,
                                        },
                                      },
                                      fill: true,
                                    }}
                                  />
                                )}
                              {defaultChart &&
                                defaultChart === "scatter" && (
                                  <Scatter
                                    data={chartData}
                                    options={{
                                      ...optionsChart, scales: {
                                        x: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                        y: {
                                          grid: {
                                            display: false,
                                          },
                                          ticks: {
                                            color: '#ffffffc2',
                                          },
                                        },
                                      },
                                      plugins: {
                                        legend: {
                                          display: false,
                                        }
                                      }
                                    }}
                                  />
                                )}

                              {defaultChart &&
                                defaultChart === "table" && (
                                  <TableComponent
                                    data={parsedDataSaved}
                                  />
                                )}
                            </div>

                          </>
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )}{" "}
                </>
              )}
            </div>

          </div>


          <div style={{ marginTop: '15px' }}>
            <div className="__search_outer">
              {isRefreshEnabled ? <Button
                ghost
                style={{
                  margin: 0,
                  height: '64px',
                }}
                icon={<FontAwesomeIcon icon={faRotateRight} />}
                onClick={() => refreshTable()}
                loading={isRefresh}
              >
                Refresh
              </Button>
                :
                <Button
                  ghost
                  style={{
                    margin: 0,
                    height: '64px',
                  }}
                  icon={<FontAwesomeIcon icon={faBackward} />}
                  onClick={() => handleResetInput()}
                >
                  Back to Recent Queries
                </Button>
              }
              <div className="__current_db">

                <>
                  <Dropdown
                    overlay={
                      <Menu>
                        {datamartList &&
                          datamartList?.map((mode) => (
                            <Menu.Item key={mode.key}>{mode.label}</Menu.Item>
                          ))}
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <a
                      onClick={(e) => e.preventDefault()}
                      style={{ cursor: "pointer" }}
                      onDoubleClick={() => navigate('/marketplace')}

                    >
                      <FontAwesomeIcon icon={faCartShopping} />
                      {datamartList.length}
                    </a>
                  </Dropdown>


                </>

              </div>
              <div className="__search_sec">
                <Button
                  ghost
                  icon={<FontAwesomeIcon icon={faSliders} />}
                  style={{ color: isPanelVisible ? "#4096ff" : "inherit" }}
                  onClick={handleTogglePanel}
                ></Button>
                <AutoComplete
                  value={question}
                  options={searchList.map((item) => ({ value: item.value }))}
                  placeholder="Type..."
                  className="__auto_com_serch __auto_com_serch2"
                  filterOption={(inputValue, option) => {
                    const lastWord = inputValue.trim().split(" ").pop();
                    return option.value
                      .toUpperCase()
                      .includes(lastWord.toUpperCase());
                  }}
                  onSelect={onSelectDropDownSearchList}
                  onClick={() => setIsPanelVisible(false)}
                  onChange={onChangeValSearch}
                  onKeyDown={handleOnkeyDown}
                >
                </AutoComplete>

                <div className="__btn_sec">
                  {question && (
                    <Button
                      ghost
                      icon={<FontAwesomeIcon icon={faXmark} />}
                      onClick={() => handleResetInput()}
                    ></Button>
                  )}
                  <Button
                    disabled={question === ""}
                    ghost
                    icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
                    onClick={() => handleSubmitSimilarQuestion()}
                    loading={loadingSimilar}
                  ></Button>
                </div>
              </div>
              <div className="__small_se_btn">
                {!listening ? <Button
                  ghost
                  style={{
                    padding: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  icon={<AudioOutlined />}
                  onClick={startListening}
                ></Button>
                  :
                  <Button
                    ghost
                    style={{
                      padding: '32px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    icon={<BorderOutlined />}
                    onClick={stopListening}
                  ></Button>
                }


                <Button
                  disabled={question === ""}
                  ghost
                  style={{ height: '64px', width: '100%', fontsize: '18px' }}
                  icon={<FontAwesomeIcon icon={faPaperPlane} />}
                  loading={isBetterLoading}
                  onClick={() => handlSubmitLogicNew()}
                >
                  Ask
                </Button>
              </div>

            </div>
            {
              <div
                className="__show_table_info"
                style={{ height: isPanelVisible ? "71px" : "0px" }}
              >
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <span style={{ marginBottom: "5px", fontSize: "13px" }}>
                    Type ahead suggestions:
                  </span>
                  <div className="___theme_changer">
                    <Switch
                      value={showTableInfo}
                      onChange={handleToggleTableInfo}
                    />{" "}
                    Data Entities
                  </div>
                  <div className="___theme_changer">
                    <Switch
                      value={showAgentInfo}
                      onChange={handleToggleAgentInfo}
                    />
                    Active Workforce
                  </div>
                </div>
                {!isMultiple && (
                  <>
                    {setDataScientistValue >= 75 && (
                      <div className="___theme_changer">
                        <Switch
                          value={showQueryOnSub}
                          onChange={handleQueryOnSub}
                        />{" "}
                        Show & tune QL
                      </div>
                    )}
                  </>
                )}
              </div>
            }
          </div>

          <div className="__sliders">
            <div {...handlers} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Tabs
                defaultActiveKey="0"
                onChange={onChangePanel}
                className="__tabs__items"
                tabPosition="left"
                activeKey={activeKey}
              >
                {questions && questions.map((item, index) => (
                  <TabPane key={index + 1} tab={<FontAwesomeIcon icon={faCircleDot} />}>
                    <div style={{ paddingLeft: "0px", width: '100%', overflow: 'hidden' }}>
                      <span>{item?.value.label}</span>
                      {isTabletOrMobile ?
                        <Swiper
                          effect={'coverflow'}
                          grabCursor={true}
                          centeredSlides={true}
                          spaceBetween={30}
                          slidesPerView={5}
                          navigation={true}
                          coverflowEffect={{
                            rotate: 50,
                            stretch: 10,
                            depth: 200,
                            modifier: 1,
                            slideShadows: false,
                          }}
                          loop={true}
                          pagination={false}
                          modules={[EffectCoverflow, Pagination, Navigation]}
                          className="mySwiper2"
                          onSlideChange={handleSlideChange}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                              coverflowEffect: {
                                rotate: 20,
                                stretch: 5,
                                depth: 100,
                              },
                            },
                            768: {
                              slidesPerView: 2,
                              coverflowEffect: {
                                rotate: 30,
                                stretch: 10,
                                depth: 150,
                              },
                            },
                            1024: {
                              slidesPerView: 2,
                              coverflowEffect: {
                                rotate: 40,
                                stretch: 15,
                                depth: 175,
                              },
                            },
                            1280: {
                              slidesPerView: 3,
                              coverflowEffect: {
                                rotate: 45,
                                stretch: 20,
                                depth: 200,
                              },
                            },
                            1440: {
                              slidesPerView: 5,
                              coverflowEffect: {
                                rotate: 50,
                                stretch: 10,
                                depth: 200,
                              },
                            },
                          }}
                        >
                          {item?.value.questions.map((cardItem, idx) => {
                            return (
                              <SwiperSlide>
                                <div
                                  key={idx} // added key for the map
                                  className={cardIndex === idx ? '__action_single_card __activeCard' : '__action_single_card'}
                                // onClick={() => handleCardClick(cardItem, idx)}

                                >
                                  <div className="__head">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "7px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {cardItem?.agentDetails ? (
                                        <>
                                          <img
                                            src={cardItem?.agentDetails?.agentlogo}
                                            alt=""
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              objectFit: "cover",
                                              borderRadius: "35px",
                                            }}
                                          />
                                          <div>
                                            <h4
                                              style={{
                                                fontSize: "13px",
                                                margin: "0",
                                                lineHeight: "1.4",
                                              }}
                                            >
                                              {cardItem?.agentDetails?.agentname}
                                            </h4>
                                            <p
                                              style={{
                                                fontSize: "12px",
                                                margin: "0",
                                              }}
                                            >
                                              {cardItem?.agentDetails?.role}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                      )}
                                    </div>

                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {cardItem?.Status === "inprogress" && (
                                        <div className="__inprogress">
                                          <div className="circles">
                                            <div className="circle1"></div>
                                            <div className="circle2"></div>
                                            <div className="circle3"></div>
                                          </div>
                                        </div>
                                      )}
                                      {cardItem?.Status === "registered" && (
                                        <div className="__registered"></div>
                                      )}
                                      {cardItem?.Status === "new" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "yellow" }}
                                        ></div>
                                      )}
                                      {cardItem?.Status === "temp" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "#61616175" }}
                                        ></div>
                                      )}

                                      {cardItem?.Status === "failed" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "#ab0000" }}
                                        ></div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="__content">
                                    {cardItem?.Status === "inprogress" &&
                                      cardItem?.approxExecCompletionTime && (
                                        <p>
                                          <FontAwesomeIcon icon={faClock} />{" "}
                                          {UTCTime(cardItem?.approxExecCompletionTime)}
                                        </p>
                                      )}
                                    <h1>{cardItem?.Question}</h1>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                        :
                        <Swiper
                          ref={swiperRef} // Assign the ref to the Swiper instance
                          centeredSlides={true}
                          spaceBetween={30}
                          slidesPerView={5}
                          navigation={{
                            nextEl: '.custom-next',
                            prevEl: '.custom-prev',
                          }}
                          loop={true}
                          modules={[Navigation]}
                          className="mySwiper2"
                          onSlideChange={handleSlideChange}
                          breakpoints={{
                            1395: {
                              slidesPerView: 4,
                            },

                            1640: {
                              slidesPerView: 5,
                            },
                          }}

                        >
                          <div className="custom-next custom-button swiper-button-next __right"><RightOutlined /></div>
                          <div className="custom-prev custom-button swiper-button-prev __left"><LeftOutlined /></div>

                          {item?.value.questions.map((cardItem, idx) => {
                            return (
                              <SwiperSlide key={idx}>
                                <div
                                  // added key for the map
                                  className={cardIndex === idx ? '__action_single_card __activeCard' : '__action_single_card'}
                                  // onClick={handleSlideChange}
                                  onClick={() => swiperRef.current?.swiper.slideToLoop(idx)}

                                >
                                  <div className="__head">
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: "7px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {cardItem?.agentDetails ? (
                                        <>
                                          <img
                                            src={cardItem?.agentDetails?.agentlogo}
                                            alt=""
                                            style={{
                                              width: "30px",
                                              height: "30px",
                                              objectFit: "cover",
                                              borderRadius: "35px",
                                            }}
                                          />
                                          <div>
                                            <h4
                                              style={{
                                                fontSize: "13px",
                                                margin: "0",
                                                lineHeight: "1.4",
                                              }}
                                            >
                                              {cardItem?.agentDetails?.agentname}
                                            </h4>
                                            <p
                                              style={{
                                                fontSize: "12px",
                                                margin: "0",
                                              }}
                                            >
                                              {cardItem?.agentDetails?.role}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        <FontAwesomeIcon icon={faCircleQuestion} />
                                      )}
                                    </div>

                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {cardItem?.Status === "inprogress" && (
                                        <div className="__inprogress">
                                          <div className="circles">
                                            <div className="circle1"></div>
                                            <div className="circle2"></div>
                                            <div className="circle3"></div>
                                          </div>
                                        </div>
                                      )}
                                      {cardItem?.Status === "registered" && (
                                        <div className="__registered"></div>
                                      )}
                                      {cardItem?.Status === "new" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "yellow" }}
                                        ></div>
                                      )}
                                      {cardItem?.Status === "temp" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "#61616175" }}
                                        ></div>
                                      )}
                                      {cardItem?.Status === "failed" && (
                                        <div
                                          className="__registered"
                                          style={{ backgroundColor: "#ab0000" }}
                                        ></div>
                                      )}
                                    </span>
                                  </div>
                                  <div className="__content">
                                    {cardItem?.Status === "inprogress" &&
                                      cardItem?.approxExecCompletionTime && (
                                        <p>
                                          <FontAwesomeIcon icon={faClock} />{" "}
                                          {UTCTime(cardItem?.approxExecCompletionTime)}
                                        </p>
                                      )}
                                    <h1>{cardItem?.Question}</h1>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      }
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>


          <div id="chart2">
            {defaultChart &&
              defaultChart === "table" && (
                <TableComponent
                  data={parsedDataSaved}
                />
              )}
          </div>
        </div>

      </>

      <>
        <LilacBlue
          open={show}
          onClose={handleCloseBlue}
          content={htmlContent}
        />

        <ImageDisplay
          open={showImg}
          onClose={() => setShowImg(false)}
          content={dataImg}
        />

        {/* <PinToDashboard
          open={showPinDashboard}
          onClose={() => handlePinDashboard()}
          questionId={cardSelected?.Qstn_id}
        /> */}

        <InsightForm
          open={showPinDashboard}
          onClose={() => handlePinDashboard()}
          dataForColumn={parsedDataSaved}
          questionId={cardSelected?.Qstn_id}
          cardSelected={cardSelected}
        />

        <AddToStory
          open={showAddtoStory}
          onClose={() => setShowAddtoStory(false)}
          questionId={cardSelected?.Qstn_id}
        />

        <AddToReport
          open={showAddtoReport}
          onClose={() => setShowAddtoReport(false)}
          questionId={cardSelected?.Qstn_id}
        />

        <GraphView
          open={isOpenLargeView}
          onClose={() => setOpenLargeView(false)}
          chartData={chartData}
          chartType={defaultChart}
          options={optionsChart}
        />

        <Warning open={isOpenWarning} onClose={() => setOpenWarning(false)} />

        <WordCloud
          open={wordcloud}
          onClose={() => setWordCloud(false)}
          description={cardSelected?.Answer}
        />

        <Timeline
          open={timeline}
          onClose={() => setTimeline(false)}
          data={parsedDataNew}
        />

        <Funnel
          open={funnel}
          onClose={() => setFunnel(false)}
          data={parsedDataNew}
        />

      </>
    </>
  );
};

export default QueryAnalyzer;
