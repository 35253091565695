// Core
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  position : relative;
  overflow: hidden;
  margin-bottom: 20px;
  label {
    display: block;
    padding: 0px;
    margin: 0px 0px 5px;
    font-size: 0.7rem;
    color: rgb(34, 34, 34);
    font-weight: 400;
  }

  input {
    height: 30px;
    border: 1.5px solid;
    border-radius: 6px;
    border-color: rgb(225, 225, 225);
    font-size: 0.8rem;
    background-color: white;
    padding: 4px 11px;
    outline:none;
    width: -webkit-fill-available;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input:disabled {
  cursor: not-allowed;
}

  .__icon {
    position: absolute;
    right: 10px;
    bottom: 9px;
    z-index: 1;
    cursor: pointer;
    svg {
      font-size: 1rem;
      color: #555;
  }
}

  ${({ error }) =>
    error &&
    css`
      label {
        color: #cb0a0a;
        font-weight: 600;
      }
      p {
        color: #cb0a0a;
        font-weight: 400;
        margin: 0;
        font-size: 14px;
        margin-left: 20px;
        margin-top:2px;
        display: flex;
    }
    input {
      border-color: #cb0a0a !important;
    }
      }
    `}
`;
