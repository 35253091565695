// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Upload, notification, Switch, Checkbox, Tabs, message, Tooltip, Input, Popconfirm } from 'antd';
import { PlusOutlined, CopyOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { faClose, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectBox from 'components/commonComponents/SelectBox';
import ModelCreate from '../ModelCreate';
import { useMediaQuery } from 'react-responsive';
import DeleteModel from '../DeleteModel';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const { TabPane } = Tabs;

const ModelTransform = (props) => {
  const { onClose, open, title, description, dataSource, datamartList } = props;
  const { userDetails, setDataScientistValue, userPermissions, domainName } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();

  const [modelNames, setModelNames] = useState([])
  const [configData, setConfigData] = useState({});
  const [configDataFull, setConfigDataFull] = useState({});
  const [activeKey, setActiveKey] = useState(null);

  const [modelSelected, setModelSelected] = useState('');

  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false)
  const [dataSourceSelected, setDataSource] = useState('')
  const [dataTablesList, setTablesList] = useState([])
  const [dataTableSelected, setTableSelected] = useState('')
  const [featuresList, setFeaturesList] = useState([])
  const [featuresSelected, setFeaturesSelected] = useState([])
  const [modelCreateShow, setModelCreateShow] = useState(false)
  const [modelDeleteShow, setModelDeleteShow] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 784px)' });
  const [copy, setCopy] = useState(false)
  const [toggle, setToggle] = useState(false)

  const [modelNameToPass, setModelNameToPass] = useState('')

  const dispatch = useDispatch();

  useEffect(() => {
    fetchModelNames();
  }, [])

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const { cluster, modelResult, createNewCheck, } = data;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setDataSource('')
      setTablesList([])
      setTableSelected([])
      setModelSelected('')
      setActiveKey(null)
      setToggle(false)
      onClose();
    }
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };


  const handleOnChangeDataSource = async (value) => {
    setDataSource(value);
    setTablesList([]);
    setTableSelected('')
    const payload = {
      "datamart": value,
      "listTablesOnly": true
    }

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      let items = []
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        items.push({ label: element.name, id: element.name })
      }
      setTablesList(items)
    }
  }


  const onChangeCheckBox = (e) => {
    setToggle(e.target.checked)
  }

  const handleUpload = async () => {

    setLoading(true)

    const newConfig = configData[modelSelected]?.map(item => ({
      [item.key]: data[item.key] || '',
      attributes: item.attributes?.reduce((acc, attr) => {
        acc[attr.key] = data[attr.key] || '';
        return acc;
      }, {}) || {}
    })) || [];


    let payload = {
      "model": modelSelected,
      "datamart": dataSourceSelected,
      "tablename": dataTableSelected,
      "resultTableName": modelResult,
      "newViewFlag": toggle,
      "newViewName": createNewCheck,
      "config_data": newConfig
    }

    console.log("new", payload);

    const res = await fetchData(restAPIs.transformModel(payload));
    if (res.statusCode === 200) {
      setLoading(false)
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      setData({})
      setToggle(false)
      setDataSource('')
      setTablesList([])
      setTableSelected([])
      setModelSelected('')
      setActiveKey(null)
      datamartList();
      onClose();
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
      setLoading(false)
    }
  }

  const handleOnChangeTable = async (value) => {
    setFeaturesList([])
    setTableSelected(value);
    const payload = {
      "datamart": dataSourceSelected,
      "listColumnsOnly": true,
      "tablename": value
    }

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      let items = []
      for (let i = 0; i < res?.body.length; i++) {
        const element = res?.body[i];
        items.push({ label: element.name, id: element.name })
      }
      setFeaturesList(items)
    }
  }

  const fetchModelNames = async () => {
    setActiveKey(null)
    const payload = {
      "domain" : domainName
    }
    const res = await fetchData(restAPIs.getModelNames(payload));
    let temparry = []
    for (let i = 0; i < res?.body.length; i++) {
      temparry.push(res?.body[i].modelName)
    }
    setModelNames(temparry)
  }


  const fetchConfigData = async (modelName) => {
    const payload = { modelName };
    const res = await fetchData(restAPIs.getModelConfig(payload));
    if (res.statusCode === 200) {
      setConfigDataFull(res.body)
      setConfigData((prevConfigData) => ({
        ...prevConfigData,
        [modelName]: res.body.config_json,
      }));
    }
  };

  const handleTabChange = (key) => {
    setActiveKey(key)
    setModelSelected(key);
    if (!configData[key]) {
      fetchConfigData(key);
    }
  };

  const renderDynamicFields = (config) => {

    return config.map((item, index) => (
      <div key={index} className='__dynamic_form_field'>
        <InputField
          onChange={handleOnChange(item.key)}
          value={data[item.key] || ''}
          type="text"
          placeholder={item.label}
          label={<div className='__title_tip'>{item.label}<Tooltip title={item.info}><InfoCircleOutlined style={{ cursor: 'pointer' }} /></Tooltip></div>}
          error={error[item.key]}
          style={{ marginBottom: '0px' }}
        />
        {item.attributes &&
          item.attributes.map((attr, idx) => (
            <div key={idx} style={{ marginTop: '0px', marginLeft: '25px' }}>
              <InputField
                onChange={handleOnChange(attr.key)}
                value={data[attr.key] || ''}
                type="text"
                placeholder={attr.info}
                label={attr.label}
                error={error[attr.key]}
              />
            </div>
          ))}
      </div>
    ));
  };

  const handleCopy = () => {
    let userObj = {
      modelName: activeKey,
      configData: configDataFull,
      isCopy: true
    }
    dispatch({
      type: "activeModelSet",
      value: userObj,
    });
    setModelNameToPass(activeKey)
    setCopy(true)
    setModelCreateShow(true)
  }
  const handleAddClick = () => {
    let userObj = {
      modelName: '',
      configData: '',
      isCopy: false
    }
    dispatch({
      type: "activeModelSet",
      value: null,
    });
    setModelNameToPass('')
    setCopy(false)
    setModelCreateShow(true)
  }

  const handleModelDelete = async (key) => {
    setModelDeleteShow(true)
  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced __model_transfrom'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Model Run</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <Tabs className='__tabs' tabPosition={isTabletOrMobile ? 'top' : 'left'} onChange={handleTabChange} activeKey={activeKey}>
            {modelNames?.map((name, index) => (
              <TabPane tab={
                <div className='___mod_del' style={{ display: 'flex', justifyContent: 'space-between' }}>{name} {name === activeKey &&
                  <div>
                    <DeleteOutlined style={{ color: 'red' }} onClick={() => handleModelDelete(name)} />
                  </div>
                }
                </div>
              } key={name}>
                <div className='____common_model_class_desc'>
                  <div className='__com_twoLine'>
                    <SelectBox
                      options={dataSource}
                      value={dataSourceSelected}
                      handleOnChange={handleOnChangeDataSource}
                      label='Source'
                    />

                    <SelectBox
                      options={dataTablesList}
                      value={dataTableSelected}
                      handleOnChange={handleOnChangeTable}
                      label='Source Table'
                    />
                  </div>
                  {configData[name] ? renderDynamicFields(configData[name]) : <p>Loading configuration...</p>}
                  <div style={{ marginTop: '20px' }}>
                    <InputField
                      onChange={handleOnChange("modelResult")}
                      value={modelResult}
                      type="text"
                      placeholder={'Model Result'}
                      label={'Model Result'}
                      error={error.modelResult}
                    />
                  </div>

                  <p className="__check-bx" style={{ fontSize: '14px' }}><Checkbox onChange={onChangeCheckBox} /><span>Create a new view with the model result</span></p>
                  {toggle &&
                    <InputField
                      onChange={handleOnChange("createNewCheck")}
                      value={createNewCheck}
                      type="text"
                      placeholder={''}
                      error={error.createNewCheck}
                    />
                  }

                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleUpload}>Submit</Button>
                  </div>

                </div>
              </TabPane>
            ))}
          </Tabs>
          {userPermissions && userPermissions?.includes("create_model") && <>
            {setDataScientistValue === 100 && <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <button type="primary" className="__extra_btn_cls_plus" style={{ borderRadius: '100px' }} onClick={() => handleAddClick()}><PlusOutlined /></button>
              {activeKey && <Tooltip title={'Copy'}><Button type="primary" style={{ borderColor: '#fff3', color: '#fff3' }} ghost onClick={() => handleCopy()} icon={<CopyOutlined />}></Button></Tooltip>}
            </div>}
          </>}
        </div>

        <ModelCreate
          open={modelCreateShow}
          onClose={() => setModelCreateShow(false)}
          getModels={fetchModelNames}
          isCopy={copy}
          getModelDataConfig={configDataFull}
          modelName={modelNameToPass}
        />

        <DeleteModel
          open={modelDeleteShow}
          onClose={() => { setModelDeleteShow(false); }}
          getModels={fetchModelNames}
          modelName={activeKey}
        />
      </Modal>
    </>
  );
};

export default ModelTransform;

ModelTransform.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  dataSource: PropTypes.any,
  datamartList: PropTypes.func,
};

