import { useState, useCallback } from 'react';
import { useSpeechRecognition as useBaseSpeechRecognition } from 'react-speech-kit';

const useSpeechRecognition = (onResult) => {

    const { listen,listening, stop } = useBaseSpeechRecognition({
        onResult: (result) => {
            onResult(result);
        },
        onError: (error) => {
            console.error(`Error: ${error.message}`);
        }
    });

    const startListening = useCallback(() => {
        // setListening(true);
        listen();
    }, [listen]);

    const stopListening = useCallback(() => {
        // setListening(false);
        stop();
    }, [stop]);

    return {
        listening,
        startListening,
        stopListening
    };
};

export default useSpeechRecognition;
