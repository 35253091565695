
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Tree, message, notification } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SelectBox from 'components/commonComponents/SelectBox';
import IframeView from '../IframeView';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const ContributionModel = (props) => {
  const { onClose, open } = props;
  const { datamartName } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  const [sourceTables, setSourceTables] = useState([])
  const [sourceTableSelected, setSourceTableSelected] = useState('')
  const [sourceColumns, setSourceColumns] = useState([])
  const [sourceColumnsSelected, setColumnsSelected] = useState('')
  const [contributionsSingle, setCountributionSingle] = useState([])
  const [contributionsSingleSelected, setCountributionSingleSelected] = useState([])
  const [contributionsMultiple, setCountributionMultiple] = useState([])
  const [contributionsMultipleSelected, setCountributionMultipleSelected] = useState([])
  const [iframeView, setIframeView] = useState(false)

  

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      dispatch({
        type: 'datamartName',
        value: null
      });
      setSourceTables([])
      setSourceTableSelected('')
      setCountributionMultiple([])
      setCountributionMultipleSelected('')
      setCountributionSingle([])
      setCountributionSingleSelected('')
      onClose();
    }
  };

  useEffect(() => {
    if (datamartName)
      fetchTables();
  }, [datamartName])


  const fetchTables = async () => {
    const payload = {
      datamart: datamartName,
      listTablesOnly: true
    };

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name }));
      setSourceTables(items);
    } else {
      openNotificationWithIcon('Error', res?.message || 'Failed to fetch tables.');
    }
  };

  const handleOnChangeSourceTable = async (value) => {
    setSourceTableSelected(value)
    setCountributionMultiple([])
    setCountributionMultipleSelected('')
    setCountributionSingle([])
    setCountributionSingleSelected('')
    const payload = {
      datamart: datamartName,
      listTablesOnly: true,
      tablename: value,
      listColumnsOnly: true,
      listNumericalColumnsOnly: true

    };

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name }));
      setCountributionSingle(items);
    } else {
      openNotificationWithIcon('Error', res?.message || 'Failed to fetch tables.');
    }
  }


  const handleOnChangeColumn = async (value) => {
    setColumnsSelected(value)
    setCountributionMultiple([])
    setCountributionMultipleSelected('')
    setCountributionSingle([])
    setCountributionSingleSelected('')
    const payload = {
      datamart: datamartName,
      listTablesOnly: true,
      tablename: sourceTableSelected,
      listColumnsOnly: true,
      listNumericalColumnsOnly: true
    };

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name }));
      setCountributionSingle(items);
    } else {
      openNotificationWithIcon('Error', res?.message || 'Failed to fetch tables.');
    }
  }


  const handleOnChangeSingleContribute = (value) => {
    setCountributionSingleSelected(value)
    setCountributionMultiple([])
    setCountributionMultiple(contributionsSingle.filter(item => item.label !== value))
    setCountributionMultipleSelected(null)
  }

  const handleOnChangeMultipleContribute = (value) => {
    setCountributionMultipleSelected(value)
  }


  const handleSubmit = async () => {
    setLoading(true)
    let payload = {
      "datasource": "",
      "tablename": sourceTableSelected,
      "targetColumn": contributionsSingleSelected,
      "contributionColumns": contributionsMultipleSelected
    }
    const res = await fetchData(restAPIs.evalModel(payload));
    setLoading(false)
    if (res?.statusCode === 200) {
      dispatch({
        type: 'urlGenerated',
        value: res?.body
      });
      setIframeView(true)

    } else {
      openNotificationWithIcon('Error', res?.message || 'Failed to fetch tables.');
    }
    
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __access_model'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Contribution </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='____common_model_class_desc' style={{ color: '#fff' }}>
            <p style={{ color: '#fff' }}>Source : {datamartName}</p>
            <div style={{ marginBottom: '15px' }}>
              <SelectBox
                options={sourceTables}
                value={sourceTableSelected}
                handleOnChange={(value) => handleOnChangeSourceTable(value)}
                label='Source Table'
              />
            </div>


            { <div style={{ marginBottom: '15px' }}>
              <SelectBox
                options={contributionsSingle}
                value={contributionsSingleSelected}
                handleOnChange={(value) => handleOnChangeSingleContribute(value)}
                label='Target Column '
              />
            </div>}
            { <div style={{ marginBottom: '15px' }}>
              <SelectBox
                options={contributionsMultiple}
                value={contributionsMultipleSelected}
                handleOnChange={(value) => handleOnChangeMultipleContribute(value)}
                label='Contribution Columns '
                multiple
              />
            </div>}


            <div style={{ display: 'flex', gap: '20px', justifyContent: 'end' }}>
              <Button type="primary" className="__extra_btn_cls" onClick={()=>handleSubmit()} loading={loading}>Submit</Button>
            </div>
          </div>
        </div>
      </Modal>

<IframeView
open={iframeView}
onClose={()=>setIframeView(false)}
/>
    </>
  );
};

export default ContributionModel;

ContributionModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
