
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Tree, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const AccessModel = (props) => {
  const { onClose, open } = props;
  const { datamartName } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();
  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeysState, setCheckedKeysState] = useState([]);
  const [halfCheckedKeysState, setHalfCheckedKeysState] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [loading, setLoading] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setTreeData([])
      dispatch({
        type: 'datamartName',
        value: null
      });
      onClose();
    }
  };


  const transformDataToTreeNodes = (data) => {
    return data.users.map((user) => ({
      title: user.username,
      key: user.username,
      children: user.tables.map((table) => ({
        title: table.tablename,
        key: `${user.username}-${table.tablename}`,
        children: table.columns.map((column) => ({
          title: column.columnname,
          key: `${user.username}-${table.tablename}-${column.columnname}`,
        })),
      })),
    }));
  };

  const getInitialCheckedAndHalfCheckedKeys = (data) => {
    const checkedKeys = [];
    const halfCheckedKeys = [];

    data.users.forEach((user) => {
      let userChecked = user.selected;
      let userHalfChecked = false;

      user.tables.forEach((table) => {
        const tableKey = `${user.username}-${table.tablename}`;
        let tableChecked = table.selected;
        let tableHalfChecked = false;

        table.columns.forEach((column) => {
          const columnKey = `${user.username}-${table.tablename}-${column.columnname}`;
          if (column.selected) {
            checkedKeys.push(columnKey);
          }
          tableChecked = tableChecked && column.selected;
          tableHalfChecked = tableHalfChecked || column.selected;
        });

        if (tableChecked) {
          checkedKeys.push(tableKey);
        } else if (tableHalfChecked) {
          halfCheckedKeys.push(tableKey);
        }

        userChecked = userChecked && tableChecked;
        userHalfChecked = userHalfChecked || tableHalfChecked;
      });

      if (userChecked) {
        checkedKeys.push(user.username);
      } else if (userHalfChecked) {
        halfCheckedKeys.push(user.username);
      }
    });

    return { checkedKeys, halfCheckedKeys };
  };


  const fetchAndTransformData = async () => {
    setLoading(true)
    try {
      const res = await fetchData(restAPIs.fetchDatasourceAccess({ dataSource: datamartName }));
      const data = {
        users: res.body?.users
      }
      const transformedData = transformDataToTreeNodes(data); // Assuming your API response is an object with a 'data' property
      setTreeData(transformedData);
      const { checkedKeys, halfCheckedKeys } = getInitialCheckedAndHalfCheckedKeys(data);
      setCheckedKeysState(checkedKeys);
      setHalfCheckedKeysState(halfCheckedKeys);
      setLoading(false)
    } catch (error) {
      // Handle error
      console.error('Error fetching or transforming data:', error);
    }
  };

  useEffect(() => {
    if(datamartName)
    fetchAndTransformData();
  }, [datamartName]); // Fetch data on component mount

  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeys, { halfCheckedKeys }) => {
    setCheckedKeysState(checkedKeys);
    setHalfCheckedKeysState(halfCheckedKeys);
  };

  const onSelect = (selectedKeys) => {
    setSelectedKeys(selectedKeys);
  };


  const handleSubmit = async () => {
    setLoadingButton(true)
    let payload = {
      "dataSource": datamartName,
      "users": {}
    }
    // First, ensure that all tables and columns are included if their parent is selected
    treeData.forEach((user) => {
      if (checkedKeysState.includes(user.key)) {
        payload.users[user.key] = {};
        user.children.forEach((table) => {
          if (checkedKeysState.includes(table.key)) {
            payload.users[user.key][table.title] = table.children.map(column => column.title);
          }
        });
      }
    });

    // Next, add individual selected columns to their respective tables if not already added
    checkedKeysState.forEach((key) => {
      const keyParts = key.split('-');

      // If it's a user-level key
      if (keyParts.length === 1) {
        if (!payload.users[key]) {
          payload.users[key] = {};
        }
      }

      // If it's a table-level key
      if (keyParts.length === 2) {
        const [username, tableName] = keyParts;
        if (!payload.users[username]) {
          payload.users[username] = {};
        }
        if (!payload.users[username][tableName]) {
          payload.users[username][tableName] = [];
        }
      }

      // If it's a column-level key
      if (keyParts.length === 3) {
        const [username, tableName, columnName] = keyParts;
        if (!payload.users[username]) {
          payload.users[username] = {};
        }
        if (!payload.users[username][tableName]) {
          payload.users[username][tableName] = [];
        }
        if (!payload.users[username][tableName].includes(columnName)) {
          payload.users[username][tableName].push(columnName);
        }
      }
    });

    const res = await fetchData(restAPIs.manageUserAccess(payload));
    if (res?.statusCode === 200) {
      setLoadingButton(false);
      handleClose();
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __access_model'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Manage Access </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>

          {loading ? <div style={{ textAlign: 'center', height: '100%', width: '100%', fontSize: '33px', color: '#fff' }}>
            <LoadingOutlined />

          </div> : <div className='__model_tree'>
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeysState}
              halfCheckedKeys={halfCheckedKeysState}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
              treeData={treeData}
            />
          </div>}

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" loading={loadingButton} onClick={() => handleSubmit()}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccessModel;

AccessModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
