// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faChevronDown, faChevronUp, faInfo, faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import UserIcon from 'assets/images/man.png'
import UserIcon1 from 'assets/images/man1.png'
import UserIcon2 from 'assets/images/man2.png'
import UserIcon3 from 'assets/images/man3.png'

import AI from 'assets/images/ai2.png'

import { Button, Popconfirm, Progress, Slider, Switch, Tooltip, message, notification } from 'antd';

import SliderSlick from 'react-slick';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import UpdateKey from 'components/modals/UpdateKey';
import DarkMaterModal from 'components/modals/DarkMaterModal';

import CustomButton from 'components/commonComponents/Button';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const openNotificationWithIcon = (title, message, type) => {
    notification[type]({
        message: title,
        description: message,
    });
};


const Settings = () => {
    const { setDataScientistValue, userSettingsShape, domainName, userDetails } = useSelector((store) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const [businessLead, setBusinessLead] = useState(50)
    const [dataScientist, setDataScientist] = useState(setDataScientistValue)
    const [dataAdministrator, setDataAdministrator] = useState(75)
    const [analyst, setDataAnalyst] = useState(0)
    const [avatar, setAvatar] = useState(UserIcon)
    const [sliderRef, setSliderRef] = useState('')
    const [sliderIndex, setSliderIndex] = useState(0)

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const { key } = data;
    const [toggle, setToggle] = useState(false)

    const [darkmater, setDarkmater] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch({
            type: "headerName",
            value: '',
          });
    },[])

    const [sliderValues, setSliderValues] = useState(
        userSettingsShape?.reduce((acc, item) => {
            acc[item.roleId] = parseInt(item.threshold ? item.threshold : item.defaultValue); 
            return acc;
        }, {})
    );



    const onChangeBusinessLead = (newValue) => {
        setBusinessLead(newValue);
    };

    const onChangeDataScientist = (newValue) => {
        setDataScientist(newValue);
        dispatch({
            type: "setDataScientistValue",
            value: newValue,
        });
    };

    const onChangeDataAdministrator = (newValue) => {
        setDataAdministrator(newValue);
    };

    const onChangeDataAnalyst = (newValue) => {
        setDataAnalyst(newValue);
    };

    const beforeChange = (prev, next) => {
        setSliderIndex(next);
    };


    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        beforeChange: beforeChange
    };

    const onAvatarClick = (image) => {
        setAvatar(image)
    }


    const slickPrev = () => {
        sliderRef.slickPrev();
    }

    const slickNext = () => {
        sliderRef.slickNext();
    }


    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null,
        });
        setData({
            ...data,
            [key]: value,
        });
    };


    const getSHA256Hash = async (input) => {
        const textAsBuffer = new TextEncoder().encode(input);
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray
            .map((item) => item.toString(16).padStart(2, "0"))
            .join("");
        return hash;
    };

    const onChangeSwitch = (checked) => {
        setToggle(prevOptions => (checked));
    }

    const handleSliderChange = (value, roleId) => {
        setSliderValues(prevState => ({
            ...prevState,
            [roleId]: value
        }));
    };

    const handleShapeSubmit = async () => {
        const payload = {
            username: userDetails,
            roles: userSettingsShape.map(item => ({
                [item.roleId]: sliderValues[item.roleId]
            }))
        };

        const res = await fetchData(restAPIs.updateUserRoles(payload));
        if (res?.statusCode === 200) {

            messageApi.open({
                type: "success",
                content: res?.message,
            });

            signOut();
            dispatch({
                type: "userDetails",
                value: "",
            });
            localStorage.setItem("token", "");
            localStorage.setItem("userDetails", "");
            localStorage.setItem("userGroups", "");
            localStorage.setItem("userPermissions", "");
            localStorage.setItem("userSettingsShape", "");
            dispatch({
                type: "userPermissions",
                value: null,
            });

            dispatch({
                type: "userSettingsShape",
                value: null,
            });

            dispatch({
                type: "domainName",
                value: null,
            });
            localStorage.setItem("domainName", "");
            navigate("/login");
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }

    }

    const cancel = (e) => {
        console.log(e);
    };

    return (
        <>
            {contextHolder}
            <div className='__top_st_hedr'>

                <Button type="primary" className="__extra_btn_cls" style={{ margin: 0, marginBottom: '3px' }} onClick={() => setToggle(true)} icon={<FontAwesomeIcon icon={faKey} />}></Button>
                <Button type="primary" className="__extra_btn_cls __dark_meter" style={{ margin: 0, marginBottom: '3px' }} onClick={() => setDarkmater(true)} ><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 1024 1024" version="1.1"><path d="M602.24 246.72a17.28 17.28 0 0 0-11.84-16.32l-42.88-14.4A90.56 90.56 0 0 1 490.24 160l-14.4-42.88a17.28 17.28 0 0 0-32 0L428.8 160a90.56 90.56 0 0 1-57.28 57.28l-42.88 14.4a17.28 17.28 0 0 0 0 32l42.88 14.4a90.56 90.56 0 0 1 57.28 57.28l14.4 42.88a17.28 17.28 0 0 0 32 0l14.4-42.88a90.56 90.56 0 0 1 57.28-57.28l42.88-14.4a17.28 17.28 0 0 0 12.48-16.96z m301.12 221.76l-48.32-16a101.44 101.44 0 0 1-64-64l-16-48.32a19.2 19.2 0 0 0-36.8 0l-16 48.32a101.44 101.44 0 0 1-64 64l-48.32 16a19.2 19.2 0 0 0 0 36.8l48.32 16a101.44 101.44 0 0 1 64 64l16 48.32a19.2 19.2 0 0 0 36.8 0l16-48.32a101.44 101.44 0 0 1 64-64l48.32-16a19.2 19.2 0 0 0 0-36.8z m-376.64 195.52l-64-20.8a131.84 131.84 0 0 1-83.52-83.52l-20.8-64a25.28 25.28 0 0 0-47.68 0l-20.8 64a131.84 131.84 0 0 1-82.24 83.52l-64 20.8a25.28 25.28 0 0 0 0 47.68l64 20.8a131.84 131.84 0 0 1 83.52 83.84l20.8 64a25.28 25.28 0 0 0 47.68 0l20.8-64a131.84 131.84 0 0 1 83.52-83.52l64-20.8a25.28 25.28 0 0 0 0-47.68z"></path></svg>{domainName?.darkmaterDomain}</Button>

            </div>
            <div className='__settings'>
                <div className='__user_change_sec common_card'>
                    <h1>Transferring your Consciousness</h1>
                    <img src={avatar} alt="" />
                    <h2>Assimilating knowledge and expertise <span style={{ color: 'orange' }}>10%</span></h2>
                    <h2>Uploading...</h2>
                    <Progress percent={10} steps={10} size={[8, 16]} strokeColor={'#98DB33'} />
                </div>
                <div className='__shape_user_sec'>
                    <img src={AI} alt="" />
                    <span style={{ display: 'flex', alignItems: 'center', gap: '10px', zIndex: 1 }}>
                        <h1>Shape Your Self</h1>
                        <Tooltip title={dataScientist == 100 ? 'Able to view and modify SQL query, Able to register Model Run' : dataScientist == 75 ? 'Able to view and modify SQL query' : ''}>
                            <Button ghost icon={<FontAwesomeIcon icon={faInfo} />} style={{ borderColor: '#ffffff1f' }}></Button>

                        </Tooltip>
                    </span>

                    <div className="__ranges">
                        {userSettingsShape && userSettingsShape.map((item, index) => (
                            <div className="__single_item" key={index}>
                                <div className="__head">
                                    <h1>{item.roleName}</h1>
                                    <span>{sliderValues[item.roleId]}%</span>
                                </div>
                                <Slider
                                    defaultValue={sliderValues[item.roleId]}
                                    step={25}
                                    onChange={(value) => handleSliderChange(value, item.roleId)}
                                />
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>

                        <Popconfirm
                            title="Warning"
                            description={
                                <p>
                                    You are about to change the configurations. <br />
                                    This action will cause LiLaQ to restart, <br />
                                    which may interrupt ongoing processes. <br />
                                    Are you sure you want to proceed?
                                </p>
                            }
                            onConfirm={handleShapeSubmit}
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                className="__extra_btn_cls"
                                style={{
                                    border: 0,
                                    color: "#fff",
                                    marginBottom: "20px",
                                    width: "150px",
                                }}
                            >
                                Shape
                            </Button>
                        </Popconfirm>


                    </div>
                </div>
                <div className='__choose_avatar_sec common_card'>
                    <h1>Avatars</h1>
                    <button onClick={() => slickPrev()} disabled={sliderIndex === 0}><FontAwesomeIcon icon={faChevronUp} /></button>
                    <div style={{ display: 'block', overflow: 'hidden', width: '100%', maxHeight: '70vh', padding: '10px' }}>
                        <SliderSlick {...settings} ref={c => setSliderRef(c)}>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon)}>
                                <img src={UserIcon} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon1)}>
                                <img src={UserIcon1} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon2)}>
                                <img src={UserIcon2} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon3)}>
                                <img src={UserIcon3} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon)}>
                                <img src={UserIcon} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon1)}>
                                <img src={UserIcon1} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon2)}>
                                <img src={UserIcon2} alt="" />
                            </div>
                            <div className='__single_avatar' onClick={() => onAvatarClick(UserIcon3)}>
                                <img src={UserIcon3} alt="" />
                            </div>
                        </SliderSlick>
                    </div>
                    <button style={{ marginBottom: '20px' }} onClick={() => slickNext()} disabled={sliderIndex === (9 / 3)}><FontAwesomeIcon icon={faChevronDown} /></button>
                </div>
            </div>
            <div className='__update_modification_btn'>
                <button>Update Modification</button>
            </div>
            <UpdateKey
                open={toggle}
                onClose={() => setToggle(false)}
            />

            <DarkMaterModal
                open={darkmater}
                onClose={() => setDarkmater(false)}
            />

        </>
    );
};

export default Settings;
