// Core
import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import * as ReactDOMClient from 'react-dom/client';


// Others
import RootReducer from './reducers/rootReducer';
import App from './App';
import { thunk } from 'redux-thunk';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


const logger = createLogger();

const store = createStore(RootReducer, applyMiddleware(thunk, logger));
const rootElement = document.getElementById('root')
const root = ReactDOMClient.createRoot(rootElement)

const reduxApp = (
  <Provider store={store}>
    <DndProvider backend={HTML5Backend}>
      <App />
    </DndProvider>
  </Provider>
);
root.render(reduxApp);
