// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Styles
import './styles.scss';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
// icons

const Subscription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubscribe = async (plan) =>{
        dispatch({
            type: "subscriptionSelected",
            value: plan,
          });
        navigate('/payment')
    }

    return (
        <>
            <div className='__susbscription_page'>
                <div className='__inner_wrap'>
                <div className='__top_head'>
                <button className="__logo">LiLaQ-Ai</button>
                </div>
                <h3 className='__top_head_h1'>Select Your Subscription Plan</h3>
                <div className='__subs_page'>
                    <div className="__subBlock" >
                        {/* <h3>Monthly</h3> */}
                        <h1>$ 199.00<span>/month</span></h1>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />LiLAC Hosted</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Pilot-playground</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Own data marketplace </p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Max size 1 to 11 GB</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Min Subscription : 1 month</p>
                        <Button type="primary" className="__extra_btn_cls" style={{width:'100%'}} onClick={()=>handleSubscribe('basic')}>Subscribe</Button>
                    </div>

                    <div className="__subBlock" >
                        {/* <h3>Yearly</h3> */}
                        <h1>$ 1199.00<span>/qtr</span></h1>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Self Hosted</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Own AWS</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />No Max data Cap </p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Min Subscription : 6 months</p>
                        <Button type="primary" className="__extra_btn_cls" style={{width:'100%'}} onClick={()=>handleSubscribe('premium')}>Subscribe</Button>
                    </div>

                    <div className="__subBlock" >
                        {/* <h3>Yearly</h3> */}
                        <h1>$ 1199.00<span>/qtr</span></h1>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Self Hosted</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Own AWS</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />No Max data Cap </p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />+ Data Engg./Ai Experts Team at $25k/mo </p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Min Subscription : 3 months</p>
                        <Button type="primary" className="__extra_btn_cls" style={{width:'100%'}} onClick={()=>handleSubscribe('enterprise')}>Subscribe</Button>
                    </div>

                    <div className="__subBlock" >
                        {/* <h3>Yearly</h3> */}
                        <h1>Talk to Sales</h1>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Dedicated Data Engg./Ai Experts Team</p>
                        <p><CheckCircleOutlined style={{ color: '#18B943', margin: '0 10px' }} />Min Subscription : 6+ months</p>
                        <Button type="primary" className="__extra_btn_cls" style={{width:'100%'}} onClick={()=>handleSubscribe('basic')}>Subscribe</Button>
                    </div>
                </div>

                <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
                </div>
            </div>
        </>
    );
};

export default Subscription;