import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts';
import './styles.scss';

const Timeline = (props) => {
  const { onClose, open, data } = props;

  const timelineData = data && data.content?.map((item) => ({
    x: item.Activity_Details,
    y: new Date(item.Activity_Date).getTime(),  // Convert date to timestamp
  }));

  const options = {
    chart: {
      type: 'rangeBar',
    },
    xaxis: {
      type: 'datetime',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    title: {
      text: 'Activity Timeline',
      align: 'center'
    }
  };

  const series = [{
    data: timelineData
  }];

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };




  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="__common_model_class __lilac_blue_2222 __msm"
        footer={null}
        closable={false}
      >
        <div className="__common_model_content">
          <div className="____common_model_head">
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <div className="wordcloud">

            <Chart
              options={options}
              series={series}
              type="rangeBar"
              height="400"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

Timeline.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.string,
};

export default Timeline;
