import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chart from 'react-apexcharts';
import './styles.scss';

const Funnel = (props) => {
  const { onClose, open, data } = props;

  const funnelData = data && data.content?.map(item => ({
    x: item.Product_Family_Name,
    y: parseFloat(item.Total_Number_of_Prescriptions)  // Convert string to float
}));

// Configuration for the funnel chart
const options = {
    chart: {
        type: 'funnel',
    },
    xaxis: {
        categories: funnelData && funnelData.map(item => item.x),
    },
    plotOptions: {
        funnel: {
            curve: {
                enabled: true
            }
        }
    },
    title: {
        text: 'Total Number of Prescriptions by Product Family',
        align: 'center'
    }
};

const series = [{
    name: 'Total Number of Prescriptions',
    data: funnelData && funnelData.map(item => item.y),
}];

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };




  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="__common_model_class __lilac_blue_2222 __msm"
        footer={null}
        closable={false}
      >
        <div className="__common_model_content">
          <div className="____common_model_head">
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <div className="wordcloud">

          <Chart
                options={options}
                series={series}
                type="funnel"
                height="400"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

Funnel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.any,
};

export default Funnel;
