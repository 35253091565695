const initialState = {
  userDetails: null,
  domainName:{},
  questionsSelected:[],
  subscriptionSelected:null,
  cardSelected:null,
  similarQuestionTableItem:{},
  dataMartItem:{},
  activeDataMart:{},
  activeModelSet:null,
  datamartName:null,
  agentData:null,
  questionQuickView:null,
  setDataScientistValue:100,
  greyQuestionsRefresh:false,
  urlGenerated:null,
  lilBotUserData:null,
  systemIntegration:null,
  shadeData:null,
  userPermissions:null,
  userSettingsShape:null,
  storyBookView:null,
  isSpeakEnable:true,
  headerName:'',
  teamData:null,
  isMultipleStore:true,
  appData:null,

};
export default initialState;
