
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Tree, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';


const PathTree = (props) => {
  const { onClose, open, basePath, setPath } = props;
  // const { datamartName } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();

  const [treeData, setTreeData] = useState([]);
  //     [
  //     {
  //         "key": "s3://lilac-test1/1lakh_sample_records/",
  //         "title": "1lakh_sample_records/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/ACF/",
  //         "title": "ACF/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Apache_2k_logs/",
  //         "title": "Apache_2k_logs/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/AzureDevOpsLog1/",
  //         "title": "AzureDevOpsLog1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/HCP360_SAMPLEDATA/",
  //         "title": "HCP360_SAMPLEDATA/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/LLQT1/",
  //         "title": "LLQT1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/LLQU1/",
  //         "title": "LLQU1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Linux_2k_logs/",
  //         "title": "Linux_2k_logs/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/LocalTest/",
  //         "title": "LocalTest/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/My_Test_set/",
  //         "title": "My_Test_set/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/OMOP_DATASET/",
  //         "title": "OMOP_DATASET/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Sample_csv/",
  //         "title": "Sample_csv/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/ServiceNowSamples_v1/",
  //         "title": "ServiceNowSamples_v1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Survey_2023/",
  //         "title": "Survey_2023/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Vendor_Data/",
  //         "title": "Vendor_Data/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/Windows_2k_logs/",
  //         "title": "Windows_2k_logs/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/a/",
  //         "title": "a/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/apache_log11/",
  //         "title": "apache_log11/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/apache_log2/",
  //         "title": "apache_log2/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/application_incident_logs/",
  //         "title": "application_incident_logs/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/athena_results/",
  //         "title": "athena_results/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/banking_1/",
  //         "title": "banking_1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/breaktest/",
  //         "title": "breaktest/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/cloudops_data/",
  //         "title": "cloudops_data/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/df_master/",
  //         "title": "df_master/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dfmaster_test/",
  //         "title": "dfmaster_test/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/diabetes/",
  //         "title": "diabetes/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt1/",
  //         "title": "dt1/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt3/",
  //         "title": "dt3/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt4/",
  //         "title": "dt4/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt5/",
  //         "title": "dt5/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt6/",
  //         "title": "dt6/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt7/",
  //         "title": "dt7/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/dt9/",
  //         "title": "dt9/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/hcp/",
  //         "title": "hcp/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/hcp2/",
  //         "title": "hcp2/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/hcpfaker/",
  //         "title": "hcpfaker/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/hr_data/",
  //         "title": "hr_data/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/human_resource_management/",
  //         "title": "human_resource_management/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/iceberg/",
  //         "title": "iceberg/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/incident_logs/",
  //         "title": "incident_logs/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/kivor-appOps/",
  //         "title": "kivor-appOps/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/manifest/",
  //         "title": "manifest/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/merged_dfmaster/",
  //         "title": "merged_dfmaster/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/merged_dfmaster_test/",
  //         "title": "merged_dfmaster_test/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/newTest/",
  //         "title": "newTest/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/omop_csv_with_meta/",
  //         "title": "omop_csv_with_meta/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/pok/",
  //         "title": "pok/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/poklocal/",
  //         "title": "poklocal/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/sample_360_merged/",
  //         "title": "sample_360_merged/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/sample_datasets/",
  //         "title": "sample_datasets/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/sample_invoices/",
  //         "title": "sample_invoices/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/sc2/",
  //         "title": "sc2/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/snowplow-analytics-data/",
  //         "title": "snowplow-analytics-data/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/survey_test/",
  //         "title": "survey_test/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/survey_test2/",
  //         "title": "survey_test2/",
  //         "isLeaf": false
  //     },
  //     {
  //         "key": "s3://lilac-test1/test_final_draft/",
  //         "title": "test_final_draft/",
  //         "isLeaf": false
  //     }
  // ]
  //);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeysState, setCheckedKeysState] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);


  const getTreeData = async () => {
    try {
      const res = await fetchData(restAPIs.browse(basePath));
      setTreeData(res.body.map((item) => ({
        title: item.prefix,
        key: item.key,
        isLeaf: false,
      })));
    } catch (error) {
      console.error("Error fetching tree data:", error);
    }
  }

  useEffect(() => { getTreeData() }, [basePath]);


  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onLoadData = async (node) => {
    if (node.children) {
      return; // If children are already loaded, don't make API call
    }

    setLoadingKeys((prevLoadingKeys) => [...prevLoadingKeys, node.key]);

    try {
      const res = await fetchData(restAPIs.browse(basePath + '/' + node.title));
      const childNodes = res.body.map((item) => ({
        title: item.prefix,
        key: item.key,
        isLeaf: false,
      }));

      setTreeData((prevTreeData) =>
        updateTreeData(prevTreeData, node.key, childNodes)
      );
    } catch (error) {
      console.error("Error fetching child nodes:", error);
    } finally {
      setLoadingKeys((prevLoadingKeys) =>
        prevLoadingKeys.filter((key) => key !== node.key)
      );
    }
  };

  const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return { ...node, children };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

  const onSelect = (selectedKeys, info) => {
    setSelectedKey(info.node.key);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeysState(checkedKeysValue);
  };

  const handleChoose = () => {
    if (selectedKey) {
      console.log("Selected Key:", selectedKey);
      setPath(selectedKey);
      onClose();
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };




  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __access_model'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Choose Path </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div style={{ height: '50ch', overflowY: 'auto' }}>
            <Tree

              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={true}
              onCheck={onCheck}
              checkedKeys={checkedKeysState}
              loadData={onLoadData}
              onSelect={onSelect}
              treeData={treeData}
              showLine={{ showLeafIcon: false }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" disabled={!selectedKey} onClick={() => handleChoose()}>Choose</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PathTree;

PathTree.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  basePath: PropTypes.string,
  setPath: PropTypes.func
};
