import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Switch, Tooltip, Upload, message } from 'antd';
import { InboxOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'components/commonComponents/TextArea';
import './styles.scss';
import { LILAC_SERVER_URL } from 'config/constants';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const ModelCreate = (props) => {
  const { onClose, open, getModels, isCopy, getModelDataConfig, modelName } = props;
  const { activeModelSet } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [jsonInput, setJsonInput] = useState(`{
    "config_json": [
      {
        "label": "display_name",
        "key": "key_used_to_hold_the_value",
        "info": "hint",
        "isColumn":false,
        "attributes": [
          {
            "label": "display_name",
            "key": "key_used_to_hold_the_value",
            "info": "hint"
          }
        ]
      }
    ]
  }`);
  const [errorInput, setErrorInput] = useState('');
  const [isInputmodel, setIsInputModel] = useState(true)
  const dispatch = useDispatch();

  useEffect(() => {
    
    if (activeModelSet?.isCopy) {
      setJsonInput(JSON.stringify(activeModelSet?.configData))
      setData({ modelName: activeModelSet?.modelName })
      renderDynamicFields()
    }
  }, [activeModelSet])

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      setData({});
      setJsonInput(`{
        "config_json": [
          {
            "label": "display_name",
            "key": "key_used_to_hold_the_value",
            "info": "hint",
            "isColumn":false,
            "attributes": [
              {
                "label": "display_name",
                "key": "key_used_to_hold_the_value",
                "info": "hint"
              }
            ]
          }
        ]
      }`);
      setFile(null);
      setIsInputModel(true)
      dispatch({
        type: "activeModelSet",
        value: null,
      });
      onClose();
    }
  };

  const handleUpload = async () => {
    if (!data.modelName || !file) {
      messageApi.open({
        type: 'error',
        content: 'Model Name and File are required',
      });
      return;
    }
    setLoading(true);
    let formData = new FormData();
    formData.append('modelName', data.modelName);
    formData.append('description', data.description);
    formData.append('file', file);
    formData.append('config_data', jsonInput);

    const res = await fetchData(restAPIs.registerModel(formData));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      setData({});
      setJsonInput(`{
        "config_json": [
          {
            "label": "display_name",
            "key": "key_used_to_hold_the_value",
            "info": "hint",
            "isColumn":false,
            "attributes": [
              {
                "label": "display_name",
                "key": "key_used_to_hold_the_value",
                "info": "hint"
              }
            ]
          }
        ]
      }`);
      getModels();
      onClose();
      setFile(null);
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
      setLoading(false);
    }
  };

  const uploadProps = {
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    fileList: file ? [file] : null,
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setJsonInput(input);
    try {
      JSON.parse(input);
      renderDynamicFields()
      setErrorInput(''); // Clear error if JSON is valid
    } catch (e) {
      setErrorInput('Invalid JSON');
    }
  };

  const renderDynamicFields = () => {
    let config = JSON.parse(jsonInput)?.config_json
    return config.map((item, index) => (
      <div key={index} className='__dynamic_form_field_new'>
        <InputField
          onChange={handleOnChange(item.key)}
          value={data[item.key] || ''}
          type="text"
          placeholder={item.label}
          label={<div className='__title_tip'>{item.label}<Tooltip title={item.info}><InfoCircleOutlined style={{ cursor: 'pointer' }} /></Tooltip></div>}
          error={error[item.key]}
          style={{ marginBottom: '0px' }}
        />
        {item.attributes &&
          item.attributes.map((attr, idx) => (
            <div key={idx} style={{ marginTop: '0px', marginLeft: '25px' }}>
              <InputField
                onChange={handleOnChange(attr.key)}
                value={data[attr.key] || ''}
                type="text"
                placeholder={attr.info}
                label={attr.label}
                error={error[attr.key]}
              />
            </div>
          ))}
      </div>
    ));
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(`${LILAC_SERVER_URL}/downloadModelFile`, {
        method: "POST",
        body: JSON.stringify({ "model": modelName }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
      const contentDisposition = response.headers.get("Content-Disposition");
      let filename = `${modelName}`; // Default filename
  
      if (contentDisposition) {
        const matches = /filename="?([^"]+)"?/.exec(contentDisposition);
        if (matches && matches[1]) {
          filename = matches[1];
        }
      }
  
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      alert("oh no!");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced __model_transfrom'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Register your model</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='____common_model_class_desc' style={{ maxHeight: '70vh' }}>
            <div style={{ marginTop: '20px' }}>
              <InputField
                onChange={handleOnChange("modelName")}
                value={data.modelName}
                type="text"
                placeholder={'Model Name'}
                label={'Model Name'}
                error={error.modelName}
              />
            </div>
            <div className='__com_run_twoLine' style={{ alignItems: 'center' }}>
              <TextArea
                label="Description"
                placeholder="Description"
                value={data.description}
                onChange={handleOnChange('description')}
              />
              <div style={{ marginBottom: '20px', display: 'grid', gridTemplateColumns: '1fr 33px', gap: '10px', alignItems: 'baseline' }}>
                <Upload {...uploadProps} maxCount={1} multiple={false}>
                  <Button ghost type="primary" className="__extra_btn_cls" style={{ width: 'max-content', borderColor:'#fff5' }} icon={<InboxOutlined />}>Select Notebook File to upload</Button>
                </Upload>
               {isCopy && <Button ghost type="primary" className="__extra_btn_cls" style={{ width: '32px', margin: 0, borderColor:'#fff5' }} icon={<DownloadOutlined />} onClick={()=>handleDownload()}></Button>}
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h4 style={{ margin: '0', marginBottom: '7px', fontWeight: '500' }}>Model Input Parameters</h4>
                <Tooltip title={'Change View'}><Switch size='small' checked={isInputmodel} onChange={() => setIsInputModel(!isInputmodel)} /></Tooltip>
              </div>
              {!isInputmodel ? <textarea
                className='___json_input'
                value={jsonInput}
                onChange={handleInputChange}
                placeholder="Enter JSON here"
              />
                :
                renderDynamicFields()
              }
              {errorInput && <div style={{ color: 'red' }}>{errorInput}</div>}
            </div>
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleUpload}>Register</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModelCreate;

ModelCreate.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  getModels: PropTypes.func,
  isCopy: PropTypes.bool,
  getModelDataConfig: PropTypes.any,
  modelName: PropTypes.any
};

