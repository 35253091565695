import {  BASE_URL,GREY_SERVER_URL,LILAC_SERVER_URL, LILBOT_URL, ORANGE_SERVER_URL } from 'config/constants';

export const restAPIs = {
  userListing:(page)=>({
    method: 'get',
    endpoint: `https://reqres.in/api/users?page=${page}`
  }),

  login: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/login`
  }),

  
  signup: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/signup`
  }),


  subscribe: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/subscribe`
  }),

  awsInsertion: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/configureAws`
  }),

  installApp: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/installApp`
  }),

  uploadMeta: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/registerData`
  }),

  listDataMart:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listDataMart`
  }),

  loadDataMart:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/loadDataMart`
  }),
  
  submitQuestion: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/getAnswer`
  }),

  iframeLoader: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/getMoreDetails`
  }),

  listQuestion:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listQuestion`
  }),

  submitQuestionSimilar: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/checkSimilarQuestions`
  }),

  generatePpt: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/generatePpt`
  }),

  getQuestionQuery: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/getQuestionQuery`
  }),

  submitQuestionQuery: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/submitQuestionQuery`
  }),

  uploadTemplate: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/uploadTemplate`
  }),

  listPptTemplates: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/listPptTemplates`
  }),

  listTags: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listTags`
  }),

  getCurrentDataMart: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/getCurrentDataMart`
  }),

  generateERD: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/generateErd`
  }),

  generateGraph: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/generateGraph`
  }),

  generateSchema: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/generateSchema`
  }),

  getReport: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchReport`
  }),

  addReport: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/addReport`
  }),

  addToStory: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/addToStory`
  }),

  listStory: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listStory`
  }),
  fetchStory: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchStory`
  }),
  updateStory: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateStory`
  }),

  generateMultipleGraphs: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/generateMultipleGraphs`
  }),

  listReport: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listReport`
  }),

  fetchJson: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchJson`
  }),

  fetchOptions: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchOptions`
  }),

  updateChart: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateChart`
  }),

  updateImageString: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateImageString`
  }),
  listUsers: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/listUsers`
  }),

  unPin: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/unpin`
  }),
  
  downloadJson: () => ({
    method: 'post',
    endpoint: `${LILAC_SERVER_URL}/download_dfjson`
  }),

  uploadJson: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/create_sqlite`
  }),

  listSearchStructure: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/fetchDbStructure`
  }),

  listGraphStructure: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchGraphData`
  }),

  transformModel: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/transformModel`
  }),

  registerModel: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/registerModel`
  }),

  getModelNames: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/getModelNames`
  }),

  getModelConfig: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/getModelConfig`
  }),

  downloadModelFile: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/downloadModelFile`
  }),

  deleteModel: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/deleteModel`
  }),

  fetchDatasourceAccess: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchDatasourceAccess`
  }),

  manageUserAccess: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/manageUserAccess`
  }),


  listAgents: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listAgents`
  }),

  createAgents: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/createAgents`
  }),

  editAgents: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/editAgents`
  }),

  deleteAgents: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/deleteAgents`
  }),
  
  listUserDatamart: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listUserDatamart`
  }),

  
  listUserQuestions: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listUserQuestions`
  }),

  deleteQuestion: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/deleteQuestion`
  }),
  
  listGreyQuestion: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listGreyQuestion`
  }),
  
  updateSecretKey: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateSecretKey`
  }),

  fetchDreamMode: (body) => ({
    method: 'post',
    body,
    endpoint: `${GREY_SERVER_URL}/fetchDreamMode`
  }),

  updateDreamMode: (body) => ({
    method: 'post',
    body,
    endpoint: `${GREY_SERVER_URL}/updateDreamMode`
  }),

  submitForCollaborative: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/submitForCollaborativeAnswer`
  }),
  getCollaborativeAnswers: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/showCollaborativeAnswers`
  }),
  evalModel : (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/evalModel`
  }),
  listActiveAgents: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listActiveAgents`
  }),

  getCollabRunLogs: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/getCollabRunLogs`
  }),

  listDarkMater: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/listDarkMater `
  }),

  resetGreyShades: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/resetGreyShades `
  }),

  
  queryDocument:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/queryDocument`
  }),

  
  loadDocument:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/loadDocument`
  }),


  lilbot:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/lilbotDM`
  }),

  createLilbotMember:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/createLilbotMember`
  }),

  listLilbotMembers:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listLilbotMembers`
  }),

  deleteLilbotMember:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/deleteLilbotMember`
  }),

  editLilbotMember:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/editLilbotMember`
  }),

  updateDataMart:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateDataMart`
  }),

  deleteDataMart:(body)=>({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/deleteDataMart`
  }),

  updateDarkMater:(body)=>({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/updateDarkMater`
  }),

  getDomain:(body)=>({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/showDarkMaterDetails`
  }),

  asktoLilaq:(body)=>({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/registerDataAgents`
  }),

  importDarkMater:(body)=>({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/importDarkMater`
  }),

  askMultipleSources: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/askMultipleSources`
  }),

  listQuestionForMultiple: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listQuestionForMultiple`
  }),


  updateDarkMaterRoles: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/updateDarkMaterRoles`
  }),

  registerSystemIntegration: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/registerSystemIntegration`
  }),

  listSystemIntegration: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listSystemIntegration`
  }),

  fetchUserPermissions: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/fetchUserPermissions`
  }),

  listRoles: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/listRoles`
  }),

  fetchRolePermissions: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/fetchRolePermissions`
  }),

  editRolePermissons: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/editRolePermissons`
  }),

  createRolePermissions: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/createRolePermissions`
  }),
  deleteRolePermissions: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/deleteRolePermissions`
  }),

  updateUserRoles: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/updateUserRoles`
  }),

  
  fetchStoryPreview: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchStoryPreview`
  }),
 
  processAnswer: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/processAnswer`
  }),

  
  listReportBooks: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listReportBooks`
  }),

  listReportFormTemplates: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listReportFormTemplates`
  }),

  addToReportBook: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/addToReportBook`
  }),

  listReportBookItems: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listReportBookItems`
  }),

  generateSingleReportForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/generateSingleReportForm`
  }),

  downloadSingleReportForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/downloadSingleReportForm`
  }),

  listReportBundleTemplates: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listReportBundleTemplates`
  }),

  createShadeTeam: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/createShadeTeam`
  }),

  listShadeTeam: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listShadeTeam`
  }),

  generateSampleDatasets: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/generateSampleDatasets`
  }),

  listApps: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/listApps`
  }),

  registerApp: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/registerApp`
  }),
  browse: (body) => ({
    method: 'post',
    body,
    endpoint: `${LILAC_SERVER_URL}/${body}`
  }),

  viewAppResponses: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/viewAppResponses`
  }),

  addToMyInsights: (body) => ({ 
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/addToMyInsights`
  }),

  fetchMyInsights: (body) => ({ 
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchMyInsights`
  }),

  fetchInsightTimelines: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchInsightTimelines`
  }),

  fetchLilbotTypeAheads: (body) => ({
    method: 'post',
    body,
    endpoint: `${ORANGE_SERVER_URL}/fetchLilbotTypeAheads`
  }),

  


};


