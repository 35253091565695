// // Core
// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

// // Styles
// import './styles.scss';
// import { Button, Modal, Upload, notification, Switch, Checkbox, Tabs, message } from 'antd';
// import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
// import InputField from 'components/commonComponents/InputField';
// import { useDispatch, useSelector } from 'react-redux';
// import { restAPIs } from 'utils/restAPIs';
// import { fetchData } from 'utils/fetch';

// import { faClose, faRemove } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SelectBox from 'components/commonComponents/SelectBox';

// const openNotificationWithIcon = (title, message) => {
//   notification["error"]({
//     message: title,
//     description: message,
//   });
// };

// const { TabPane } = Tabs;

// const ThreeSixtyView = (props) => {
//   const { onClose, open, title, description, dataSource, datamartList } = props;
//   const { userDetails } = useSelector((store) => store.commonData);
//   const [messageApi, contextHolder] = message.useMessage();
//   const [data, setData] = useState({});
//   const [error, setError] = useState({});
 

//   useEffect(() => {
//     console.log("datasor", dataSource);
//   }, [dataSource])

//   const handleOnChange2 = (key) => (value) => {
//     setError({
//       ...error,
//       [key]: null,
//     });
//     setData({
//       ...data,
//       [key]: value,
//     });
//   };

//   const { modelResult } = data;

//   const [componentSets, setComponentSets] = useState([{
//     dataSourceSelected: '',
//     dataTablesList: [],
//     dataTableSelected: []
//   }]);

//   const handleOnChange = (key, index) => (value) => {
//     const newComponentSets = [...componentSets];
//     newComponentSets[index][key] = value;
//     setComponentSets(newComponentSets);
//   };

//   const handleOnChangeDataSource = async (index, value) => {
//     const newComponentSets = [...componentSets];
//     newComponentSets[index].dataSourceSelected = value;
//     newComponentSets[index].dataTablesList = [];
//     newComponentSets[index].dataTableSelected = [];
//     setComponentSets(newComponentSets);

//     const payload = {
//       "datamart": value,
//       "listTablesOnly": true
//     };

//     const res = await fetchData(restAPIs.listSearchStructure(payload));
//     if (res.statusCode === 200) {
//       let items = res.body.map(element => ({ label: element.name, id: element.name }));
//       newComponentSets[index].dataTablesList = items;
//       setComponentSets(newComponentSets);
//     }
//   };


//   const handleClose = (event, reason) => {
//     if (reason === "backdropClick") {
//       console.log(reason);
//     } else {
//       setData({})
//       onClose();
//     }
//   };




//   const handleAddComponentSet = () => {
//     setComponentSets([...componentSets, { dataSourceSelected: '', dataTablesList: [], dataTableSelected: [] }]);
//   };


//   return (
//     <>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         className='__common_model_class __height_reduced __model_transfrom'
//         footer={null}
//         closable={false}
//       >
//         {contextHolder}
//         <div className='__common_model_content'>
//           <div className='____common_model_head'>
//             <h3 style={{ marginBottom: '10px' }}>360 View</h3>
//             <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
//           </div>
//           <div className='____common_model_class_desc'>
//                 {/* <div className='__com_twoLine'>
//                   <SelectBox
//                     options={dataSource}
//                     value={dataSourceSelected}
//                     handleOnChange={handleOnChangeDataSource}
//                     label='Source'
//                   />

//                   <SelectBox
//                     options={dataTablesList}
//                     value={dataTableSelected}
//                     handleOnChange={handleOnChangeTable}
//                     label='Source Table'
//                     multiple
//                   />
//                 </div> */}


//             {componentSets.map((set, index) => (
//               <div key={index} className='__com_twoLine'>
//                 <SelectBox
//                   options={dataSource}
//                   value={set.dataSourceSelected}
//                   handleOnChange={(value) => handleOnChangeDataSource(index, value)}
//                   label='Source'
//                 />
//                 <SelectBox
//                   options={set.dataTablesList}
//                   value={set.dataTableSelected}
//                   handleOnChange={(value) => handleOnChange('dataTableSelected', index)(value)}
//                   label='Source Table'
//                   multiple
//                 />
//               </div>
//             ))}
//             <Button onClick={handleAddComponentSet} type="primary" className="__extra_btn_cls_plus" style={{ borderRadius: '100px' }}><PlusOutlined /></Button>


//                 <div style={{ marginTop: '20px' }} className='__com_twoLine'>
//                   <div style={{flex:1}}>
//                   <InputField
//                     onChange={handleOnChange2("modelResult")}
//                     value={modelResult}
//                     type="text"
//                     placeholder={'Enter new 360 name'}
//                     label={'View Name'}
//                     error={error.modelResult}
//                   />
//                   </div>
//                   <Button ghost style={{borderColor:'#ffffff5e', marginBottom: '20px'}} className="__extra_btn_cls" >Choose Existing</Button>
//                 </div>


//                 <div style={{ display: 'flex',gap:'20px' , justifyContent: 'end' }}>
//                 <Button style={{borderColor:'#ffffff5e'}} ghost className="__extra_btn_cls">Visual Linker</Button>
//                   <Button type="primary" className="__extra_btn_cls">Validate & Register</Button>
//                 </div>

//               </div>
//           <div>
//             <button type="primary" className="__extra_btn_cls_plus" style={{ borderRadius: '100px' }}><PlusOutlined /></button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default ThreeSixtyView;

// ThreeSixtyView.propTypes = {
//   onClose: PropTypes.func,
//   open: PropTypes.bool,
//   dataSource: PropTypes.any,
//   datamartList: PropTypes.func,
// };


// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, notification, Tabs, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import SelectBox from 'components/commonComponents/SelectBox';

const { TabPane } = Tabs;

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const ThreeSixtyView = (props) => {
  const { onClose, open, dataSource, datamartList } = props;
  const { userDetails } = useSelector((store) => store.commonData);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [componentSets, setComponentSets] = useState([
    { dataSourceSelected: '', dataTablesList: [], dataTableSelected: [] }
  ]);

  const handleOnChange2 = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleOnChangeDataSource = async (index, value) => {
    const newComponentSets = [...componentSets];
    newComponentSets[index].dataSourceSelected = value;
    newComponentSets[index].dataTablesList = [];
    newComponentSets[index].dataTableSelected = [];
    setComponentSets(newComponentSets);

    const payload = {
      datamart: value,
      listTablesOnly: true
    };

    const res = await fetchData(restAPIs.listSearchStructure(payload));
    if (res.statusCode === 200) {
      const items = res.body.map(element => ({ label: element.name, id: element.name }));
      newComponentSets[index].dataTablesList = items;
      setComponentSets(newComponentSets);
    } else {
      openNotificationWithIcon('Error', res?.message || 'Failed to fetch tables.');
    }
  };

  const handleOnChangeTable = (index, value) => {
    const newComponentSets = [...componentSets];
    newComponentSets[index].dataTableSelected = value;
    setComponentSets(newComponentSets);
  };

  const handleAddComponentSet = () => {
    setComponentSets([...componentSets, { dataSourceSelected: '', dataTablesList: [], dataTableSelected: [] }]);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({});
      setComponentSets([{ dataSourceSelected: '', dataTablesList: [], dataTableSelected: [] }]);
      onClose();
    }
  };

  const { modelResult } = data;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced __model_transfrom'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Data View</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='____common_model_class_desc'>
            {componentSets.map((set, index) => (
              <div key={index} className='__com_twoLine' style={{marginBottom:'15px'}}>
                <SelectBox
                  options={dataSource}
                  value={set.dataSourceSelected}
                  handleOnChange={(value) => handleOnChangeDataSource(index, value)}
                  label='Source'
                />
                <SelectBox
                  options={set.dataTablesList}
                  value={set.dataTableSelected}
                  handleOnChange={(value) => handleOnChangeTable(index, value)}
                  label='Source Table'
                  multiple
                />
              </div>
            ))}
            <Button onClick={handleAddComponentSet} type="primary" className="__extra_btn_cls_plus" style={{ borderRadius: '100px' }}>
              <PlusOutlined />
            </Button>
            <div style={{ marginTop: '20px' }} className='__com_twoLine'>
              <div style={{ flex: 1 }}>
                <InputField
                  onChange={handleOnChange2("modelResult")}
                  value={modelResult}
                  type="text"
                  placeholder={'Enter new view name'}
                  label={'View Name'}
                  error={error.modelResult}
                />
              </div>
              <Button ghost style={{ borderColor: '#ffffff5e', marginBottom: '20px' }} className="__extra_btn_cls">Choose Existing</Button>
            </div>
            <div style={{ display: 'flex', gap: '20px', justifyContent: 'end' }}>
              <Button style={{ borderColor: '#ffffff5e' }} ghost className="__extra_btn_cls">Visual Linker</Button>
              <Button type="primary" className="__extra_btn_cls">Validate & Register</Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ThreeSixtyView;

ThreeSixtyView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  dataSource: PropTypes.array,
  datamartList: PropTypes.func,
};

