// Core
import React, { useEffect, useRef, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faArrowUpRightFromSquare, faCircleQuestion, faCubesStacked, faFilePowerpoint, faMicrophone, faPaperPlane, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaretRightOutlined } from '@ant-design/icons';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import UserIcon from 'assets/images/man.png'
import { useNavigate } from 'react-router-dom';

import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import LilacBlue from 'components/modals/LilacBlue';
import { Button, notification } from 'antd';
import TimeAgo from 'react-timeago'


const openNotificationWithIcon = (title, message) => {
    notification["error"]({
        message: title,
        description: message,
    });
};

const Chat = () => {

    const { questionsSelected } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [question, setQuestion] = useState('');
    const [questions, setQuestions] = useState([])
    const [loadingBlue, setLoadingBlue] = useState(false)
    const [answer, setAnswer] = useState('')
    const [htmlContent, setHtmlContent] = useState('')
    const [show, setShow] = useState(false)

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        vertical: true,
        verticalSwiping: true,

    };

    const Card = ({ item }) => {
        const [, drag] = useDrag({
            type: 'CARD',
            item: [item],
        });

        return (
            <div ref={drag} className="__single_card" onClick={() => handleCardClick(item)}>
                <div className='__head'>
                    <FontAwesomeIcon icon={faCircleQuestion} />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z" fill="white" />
                    </svg> */}
                   {item?.Status === 'inprogress' ? <div className='__inprogress'>
                        {/* <span>In Progress</span> */}
                        <div className="circles">
                            <div className="circle1"></div>
                            <div className="circle2"></div>
                            <div className="circle3"></div>
                        </div>
                    </div> : <div className='__registered'></div>}
                </div>
                <div className='__content'>
                    <h1>{item.displayText}</h1>
                     {item.ProcessedTime && <p><TimeAgo date={item.ProcessedTime} /></p>}
                    {/* <p>{item?.Question} </p> */}
                </div>
            </div>
        );
    };

    

    const handleDrop = (action, item) => {
        // Handle the dropped item based on the action, e.g., navigate to a different route
        dispatch({
            type: 'questionsSelected',
            value: questionsSelected?.concat(item)
        });

        if (action === 'presentation') {
            navigate('/presentation');
        } else if (action === 'actions') {
            navigate('/actions');
        } else {
            // Handle other actions accordingly
        }
    };

    const DroppableArea = ({ action, svg }) => {
        const [, drop] = useDrop({
            accept: 'CARD',
            drop: (item) => handleDrop(action, item),
        });

        return (
            <button ref={drop} onClick={() => navigate(`/${action}`)} className="__droppable_area_button">
                {svg}
            </button>
        );
    };

    const handleSubmitQuestion = async () => {
        setLoadingBlue(true)
        let payload = {
            question: question
        }
        const res = await fetchData(restAPIs.submitQuestion(payload));
        if (res.statusCode === 200) {
            setLoadingBlue(false)
            setAnswer(res?.message)
        }
        else {
            setLoadingBlue(false)
            openNotificationWithIcon('No Data', res?.message);
        }
    }

    const handleOnChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleOpenBlue = async () => {
        setLoadingBlue(true)
        let payload = {
            question: question
        }
        const res = await fetchData(restAPIs.iframeLoader(payload));

        // if (res.statusCode === 200) {
        setLoadingBlue(false)
        setHtmlContent(res)
        setShow(true)
        // }

    }

    const handleClose = () => {
        setShow(false)
    }


    const getQuestions = async () => {
        const payload = {
            "pageNo": 1,
            "pageSize": 10
        }
        const res = await fetchData(restAPIs.listQuestion(payload));
        if (res?.statusCode === 200)
            setQuestions(res?.body)
    }

    useEffect(() => {
        getQuestions();
        // setQuestions([
        //     { displayText: "Did you know that the youngest patient in the dataframe is 0 years old?", ProcessedTime : new Date() },
        //     { displayText: "Did you know that in the given dataset, the genders and their frequencies of unique patient IDs are as follows:", ProcessedTime : new Date() },
        //     { displayText: "Did you know that the ages and their frequencies of unique patient IDs in the dataframe are as follows", ProcessedTime : new Date() },
        //     { displayText: "Did you know that the name of the provider with the highest number of claims from the dataset is Smith?", ProcessedTime : new Date() },
        //     { displayText: "Did you know that in the given dataframe, there is only 1 patient who claimed more than 1500 times?", ProcessedTime : new Date() },
        //     { displayText: "Did you know that there are 218 unique patients for psoriasis in the dataset?", ProcessedTime : new Date() }
        // ])

    }, [])


    const handleCardClick = (item) => {
        dispatch({
            type: 'cardSelected',
            value: item
        });
        navigate('/card-detail')
    }
    return (
        <>
            <div className='__dashboard'>
                <div className='__talking_person_sec'>
                    <img src={UserIcon} alt="" style={{ opacity: answer ? .2 : 1 }} />
                    {answer &&
                    <div className='__result'>
                        <p>{answer && answer}</p>
                        <Button type="primary" className="__extra_btn_cls" icon={<FontAwesomeIcon icon={faArrowRight} fontSize={13} />} onClick={() => handleOpenBlue()} loading={loadingBlue}>Lilac Blue</Button>
                    </div>}
                    <div className='__type_sec'>
                        <input type="text" value={question} placeholder='Type & Enter' onChange={(e) => handleOnChange(e)} />
                        <Button icon={<FontAwesomeIcon icon={faPaperPlane} />} onClick={() => handleSubmitQuestion()} loading={loadingBlue}></Button>
                    </div>
                    <LilacBlue
                        open={show}
                        onClose={handleClose}
                        content={htmlContent}
                    />

                </div>

                <div className='__bottom_menus'>
                    <DroppableArea action="presentation" svg={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M22 12.999V20C22 20.5523 21.5523 21 21 21H13V12.999H22ZM11 12.999V21H3C2.44772 21 2 20.5523 2 20V12.999H11ZM11 3V10.999H2V4C2 3.44772 2.44772 3 3 3H11ZM21 3C21.5523 3 22 3.44772 22 4V10.999H13V3H21Z" fill="white" />
                    </svg>} />
                    <DroppableArea action="actions" svg={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M5 4H15V8H19V20H5V4ZM3.9985 2C3.44749 2 3 2.44405 3 2.9918V21.0082C3 21.5447 3.44476 22 3.9934 22H20.0066C20.5551 22 21 21.5489 21 20.9925L20.9997 7L16 2H3.9985ZM10.4999 7.5C10.4999 9.07749 10.0442 10.9373 9.27493 12.6534C8.50287 14.3757 7.46143 15.8502 6.37524 16.7191L7.55464 18.3321C10.4821 16.3804 13.7233 15.0421 16.8585 15.49L17.3162 13.5513C14.6435 12.6604 12.4999 9.98994 12.4999 7.5H10.4999ZM11.0999 13.4716C11.3673 12.8752 11.6042 12.2563 11.8037 11.6285C12.2753 12.3531 12.8553 13.0182 13.5101 13.5953C12.5283 13.7711 11.5665 14.0596 10.6352 14.4276C10.7999 14.1143 10.9551 13.7948 11.0999 13.4716Z" fill="white" />
                    </svg>} />
                </div>
 
                {questions.length > 0 && <div className='__right_sec'>
                    <Slider {...settings}>
                        {questions.map((item, idx) => {
                            return (<div><Card key={idx} item={item} /></div>
                            )
                        })}
                    </Slider>
                </div>}
            </div>
        </>
    );
};

export default Chat;
