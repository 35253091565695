// Core
import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";

// Others
import InputField from "components/commonComponents/InputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Button, notification } from "antd";

import AI from 'assets/images/man.png'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { fetchAuthSession, signIn, signOut, confirmSignIn } from 'aws-amplify/auth';
import { restAPIs } from "utils/restAPIs";
import { fetchData } from "utils/fetch";

// Function to show notifications with Ant Design's notification component
const openNotificationWithIcon = (type, title, message) => {
  switch (type) {
    case 'success':
      notification.success({
        message: title,
        description: message,
      });
      break;
    case 'error':
      notification.error({
        message: title,
        description: message,
      });
      break;
    default:
      notification.info({
        message: title,
        description: message,
      });
      break;
  }
};

const Login = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [userGroups, setUserGroups] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);
  const [darkmaterDomain, setDarkamter] = useState('')



  useEffect(() => {
    const fetchUserGroup = async () => {
      try {
        setLoading(true);
        const session = await fetchAuthSession();
      } catch (error) {
        console.error('Error fetching user groups:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserGroup();
    getDomain();
  }, []);

  // Function to handle input changes
  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  // Function to handle login action
  const handleLoginIn = async () => {
    if (darkmaterDomain == '') return;

    const { username, password } = data;
    const errors = {
      username: !username,
      password: !password,
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    let payload = {
      "username": username,
      "password": password
    };

    try {
      const user = await signIn({ username, password });
      console.log('User signed in:', user);
      if (user && user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setShowNewPassword(true);
      } else {
        const session = await fetchAuthSession();
        const group = session['tokens']['accessToken']['payload']['cognito:groups'];
        setUserGroups(group);
        localStorage.setItem('userGroups', JSON.stringify(group));
        localStorage.setItem("userDetails", JSON.stringify(username));
        dispatch({
          type: "userDetails",
          value: username,
        });
        openNotificationWithIcon('success', 'Success', 'User Signed in');
        localStorage.setItem("token", '123456');
        navigate('/query-analyzer'); // Navigate to home page on successful login
      }
    } catch (error) {
      openNotificationWithIcon('error', 'Error', error.message ? error.message : 'Unauthorized access');
      console.error('Error logging in:', error);
    } finally {
      setLoading(false);
    }
  }

  // Function to handle new password submission
  const handleNewPasswordSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const loggedUser = await confirmSignIn({ challengeResponse: newPassword });
      setNewPassword('');
      console.log('Password changed successfully:', loggedUser);
      setShowNewPassword(false);
      setData({ username: '', password: '' });
      openNotificationWithIcon('success', 'Success', 'Password changed successfully. Please log in again with your new password.');
      await signOut();
      navigate('/login'); // Navigate to login page after password change
    } catch (error) {
      console.error('Error changing password:', error);
      openNotificationWithIcon('error', 'Error', error.message);
    } finally {
      setLoading(false);
    }
  };

  // Handle Enter key for form submission
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      showNewPassword ? handleNewPasswordSubmit(event) : handleLoginIn();
    }
  }

  // Toggle password visibility
  const iconClick = () => {
    setVisibility((state) => !state);
  }

  const { username, password } = data;

  const getDomain = async () => {
    const res = await fetchData(restAPIs.getDomain());
    if (res?.statusCode === 200) {
      localStorage.setItem("domainName", JSON.stringify(res?.body));
      setDarkamter(res?.body?.darkmaterDomain)
      dispatch({
        type: "domainName",
        value: res?.body,
      });

    }
  }

  return (
    <div className="login-page">
      <div className="login-page---form">
        <button className="__logo">
          🦄LiLaQ-Ai
          <span>{darkmaterDomain}</span>
        </button>
        <label className="__h4_head">Welcome to LiLaQ</label>
        <label className="__p_head">Please enter your details to login</label>
        {!showNewPassword ? (
          <>
            <InputField
              onChange={handleOnChange('username')}
              value={username}
              type="text"
              placeholder="Username"
              label="Username"
              error={error.username}
              onKeyDown={handleKeyDown}
            />
            <InputField
              onChange={handleOnChange('password')}
              value={password}
              placeholder="Password"
              type={visible ? 'text' : 'password'}
              label="Password"
              error={error.password}
              onKeyDown={handleKeyDown}
              icon={visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
              onIconClick={iconClick}
            />
            <Button
              type="primary"
              className="__extra_btn_cls"
              onClick={handleLoginIn}
              loading={loading}
              disabled={loading}
            >
              Login
            </Button>
          </>
        ) : (
          <form onSubmit={handleNewPasswordSubmit}>
            <Input.Password
              type={visible ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              required
            />
            <p>
              <Button
                type="primary"
                className="__extra_btn_cls"
                htmlType="submit"
                loading={loading}
                disabled={loading}
              >
                Change Password
              </Button>
            </p>
          </form>
        )}

        <label className="__dnt-accnt">Don't have an account? <a href="/signup">Signup Now</a></label>
      </div>
      <div className="__img-sec-lgn">
        <img src={AI} alt="AI Image" />
      </div>

      <p className='__copyright'>Copyright © LiLaQ-Ai, 2024</p>
    </div>
  );
};

export default Login;
