// Core
import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";

// Others
import InputField from "components/commonComponents/InputField";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, notification } from "antd";

import AI from 'assets/images/man.png'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";


const openNotificationWithIcon = (message) => {
    notification["error"]({
        message: "Error",
        description: message,
    });
};

const SignUp = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [visible, setVisibility] = useState(false)

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null,
        });
        setData({
            ...data,
            [key]: value,
        });
    };


    const handleLoginIn = async () => {

        const errors = {
            username: !username,
            email: !email,
            password: !password,
        };
        setError(errors);
        const isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setLoading(true);

        let payload = {
            "username": username,
            "email": email,
            "password": password
        }

        const res = await fetchData(restAPIs.signup(payload));
        if (res.statusCode === 200) {
            setLoading(false);
            dispatch({
                type: "userDetails",
                value: username,
            });
            localStorage.setItem("userDetails", JSON.stringify(username));
            navigate("/susbcribe");
        } else {
            setLoading(false);
            openNotificationWithIcon(res?.message);
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleLoginIn()
        }
    }

    const iconClick = () => {
        setVisibility((state) => !state)
    }

    const { username, email, password } = data;

    const onChangeCheckBox = (e) => {
        console.log(`checked = ${e.target.checked}`);
    }

    return (
        <div className="login-page">
            <div className="login-page---form">
                <button className="__logo">LiLaQ-Ai</button>
                <label className="__h4_head">Create your account</label>
                <label className="__p_head">Please enter your details to signup</label>
                <InputField
                    onChange={handleOnChange("username")}
                    value={username}
                    type="text"
                    placeholder={'Username'}
                    label={'Username'}
                    error={error.username}
                    onKeyDown={handleKeyDown}
                />
                <InputField
                    onChange={handleOnChange("email")}
                    value={email}
                    type="text"
                    placeholder={'Email'}
                    label={'Email'}
                    error={error.email}
                    onKeyDown={handleKeyDown}
                />
                <InputField
                    onChange={handleOnChange("password")}
                    value={password}
                    placeholder={'Password'}
                    type={visible ? "text" : "password"}
                    label={'Password'}
                    error={error.password}
                    onKeyDown={handleKeyDown}
                    icon={visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                    onIconClick={() => iconClick()}
                />
                <Button type="primary" className="__extra_btn_cls" onClick={() => handleLoginIn()}>Sign Up</Button>

                <p className="__check-bx"><Checkbox onChange={onChangeCheckBox} /><span>By creating an account with us, you agree to the <a href="#">Terms & Conditions</a> and <a href="#">our Privacy Policy</a></span></p>
                <label className="__dnt-accnt">Already have an account  ?<a className="" href="/login"> Login</a> </label>
            </div>
            <div className="__img-sec-lgn">
                <img src={AI} alt="" />
            </div>
            <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
        </div>
    );
};

export default SignUp;
