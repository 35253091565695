// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { STORY_URL } from 'config/constants';

const IframeViewBook = (props) => {
  const { onClose, open } = props;
  const { storyBookView } = useSelector((store) => store.commonData);
  const [urlGenerated, setUrlGenerated] = useState('')
  const dispatch = useDispatch();
  
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      
      dispatch({
        type: 'storyBookView',
        value: null
    });
      onClose();
      
    }
  };

  useEffect(() => {
    if(storyBookView)
    generateUrl();
}, [storyBookView])

  

  const generateUrl = async () => {
    let payload = 
    {
      "reportBookName": storyBookView.reportBookName,
      "Qstn_id": storyBookView.Qstn_id,
      "formTemplateFilename": storyBookView.formTemplateFilename,
      "formTemplateName": storyBookView.formTemplateName
    }
    const res = await fetchData(restAPIs.generateSingleReportForm(payload));
    if (res.statusCode === 200) {
      console.log("res", res);
      setUrlGenerated(res?.body)
      
    }
};



  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __lilac_blue_2'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className="iframe-container2">
            <iframe
              src={urlGenerated+'/'}
              frameBorder="0"
              title={storyBookView?.reportBookName}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IframeViewBook;

IframeViewBook.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
