// Core
import React from "react";
import { Navigate, Outlet } from 'react-router-dom';

// Others
import { isAuthenticated } from 'utils/helpers'

const ProtectedRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}
export default ProtectedRoute;