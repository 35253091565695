
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Button, Modal, Upload, notification, Switch, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'components/commonComponents/TextArea';

const openNotificationWithIcon = (title, message) => {
  notification.error({
    message: title,
    description: message,
  });
};

const getBase64 = (file) =>

  new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      return reject(new Error('Provided parameter is not a Blob or File.'));
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const generateUID = () => {
  return 'rc-upload-' + Date.now() + '-' + Math.floor(Math.random() * 1000);
};

const base64ToFileObject = (base64, filename) => {
  const arr = base64.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const fileExtension = filename.split('.').pop();
  const filenameWithoutExtension = filename.replace(`.${fileExtension}`, '');
  const newFilename = `${filenameWithoutExtension}.${fileExtension}`;

  const file = new File([u8arr], newFilename, { type: mime });

  return {
    uid: generateUID(),
    lastModified: file.lastModified,
    lastModifiedDate: new Date(file.lastModified),
    name: file.name,
    size: file.size,
    type: file.type,
    webkitRelativePath: file.webkitRelativePath || "",
    file: file
  };
};

const ShadeAddEditModel = (props) => {
  const { onClose, open, teamName } = props;
  const { userDetails, shadeData  } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (shadeData?.isEdit) {
      setData(shadeData?.dataSet);
      if (shadeData?.dataSet?.agentlogo) {
        const fileObj = base64ToFileObject(shadeData?.dataSet?.agentlogo, 'logo.jpg');
        console.log("file", fileObj);
        
        setFileList([fileObj.file]);
      }
    }
  }, [shadeData]);

  const handleUpload = async () => {
    const errors = {
      role: !data.role,
      goal: !data.goal,
      backstory: !data.backstory,
      description: !data.description,
      agentname: !data.agentname,
      status: !data.status
    };

    setError(errors);

    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;

    const payload = {
      agentlogo: fileList.length > 0 && await getBase64(fileList[0]),
      role: data.role,
      goal: data.goal,
      backstory: data.backstory,
      description: data.description,
      agentname: data.agentname,
      status: data.status,
      teamName:teamName
    };

    if (shadeData?.isEdit) payload.agentId = shadeData?.dataSet?.agentId;

    setLoading(true);


    const res = shadeData?.isEdit
      ? await fetchData(restAPIs.editAgents(payload))
      : await fetchData(restAPIs.createAgents(payload));

    setLoading(false);
    if (res?.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: res.message,
      });
      shadeData?.getAgents(teamName);
      handleClose();
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }

  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({});
      setFileList([])
      dispatch({
        type: "shadeData",
        value: null,
      });
      onClose();
    }
  };

  const onChangeSwitch = (checked) => {
    setData({
      ...data,
      status: checked ? 'active' : 'inactive',
    });
  };

  const uploadProps = {
    onRemove: (file) => {
      setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced __agent'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>{shadeData?.isEdit ? 'Edit' : 'Add'} Shades of grey </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc __agent_delm'>
            <InputField
              onChange={handleOnChange("agentname")}
              value={data.agentname}
              type="text"
              placeholder={'Name'}
              label={'Name'}
              error={error.agentname}
            />
            <InputField
              onChange={handleOnChange("role")}
              value={data.role}
              type="text"
              placeholder={'Role'}
              label={'Role'}
              error={error.role}
            />
            <TextArea
              onChange={handleOnChange("goal")}
              value={data.goal}
              type="text"
              placeholder={'Goal'}
              label={'Goal'}
              error={error.goal}
            />
            <TextArea
              onChange={handleOnChange("backstory")}
              value={data.backstory}
              type="text"
              placeholder={'Backstory'}
              label={'Backstory'}
              error={error.backstory}
            />
            <TextArea
              label="Description"
              placeholder="Description"
              value={data.description}
              onChange={handleOnChange('description')}
            />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <label htmlFor="logo" style={{ marginBottom: '10px' }}>Logo</label>
              <Upload {...uploadProps} maxCount={1} multiple={false} accept="image/*" >
                <Button className="__extra_btn_cls" style={{ margin: '0' }} icon={<InboxOutlined />} >Upload</Button>
              </Upload>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <label htmlFor="Status" style={{ marginBottom: '10px' }}>Status</label>
              <Switch
                checkedChildren={'Active'}
                unCheckedChildren={'Inactive'}
                onChange={onChangeSwitch}
                checked={data.status === 'active'}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff', marginTop: '10px' }} className="__extra_btn_cls" loading={loading} onClick={handleUpload}>{shadeData?.isEdit ? 'Update' : 'Add'}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShadeAddEditModel;

ShadeAddEditModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  teamName:PropTypes.any
};
