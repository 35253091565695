import React, { useCallback, useState } from "react";
import { LILAC_SERVER_URL } from "config/constants";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  Position,
} from "reactflow";
import "reactflow/dist/style.css";

import Button from "components/commonComponents/Button";
import { color } from "highcharts";
// const blah = [
//   {
//     id: "1",
//     position: { x: 0, y: 300 },
//     data: { label: "Multi channel activity" },
//     style: { width: 200, height: 140 },
//     draggable: false,
//   },
//   {
//     id: "1a",
//     parentId: "1",
//     position: { x: 5, y: 340 },
//     data: { label: "NPI_ID" },
//     style: { width: 190, height: 40 },
//     type: "input",
//     sourcePosition: Position.Right,
//     draggable: false,
//   }]

// const initialNodes = [
//   {
//     id: "1",
//     position: { x: 100, y: 100 },
//     data: { label: "abservation_period" },
//     style: { width: 200, height: 290 },
//     draggable: "true",
//   },
//   {
//     id: "1a",
//     parentId: "1",
//     position: { x: 105, y: 130 },
//     data: { label: "observation_period_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//     sourcePosition: Position.Right,
//     type:"input"

//   },
//   {
//     id: "1b",
//     parentId: "1",
//     position: { x: 105, y: 180 },
//     data: { label: "person_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "1c",
//     parentId: "1",
//     position: { x: 105, y: 230 },
//     data: { label: "observation_period_start_date" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "1d",
//     parentId: "1",
//     position: { x: 105, y: 280 },
//     data: { label: "observation_period_end_date" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "1e",
//     parentId: "1",
//     position: { x: 105, y: 330 },
//     data: { label: "period_type_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2",
//     position: { x: 400, y: 100 },
//     data: { label: "location" },
//     style: { width: 200, height: 440 },
//     draggable: "true",
//   },
//   {
//     id: "2a",
//     parentId: "2",
//     position: { x: 405, y: 130 },
//     data: { label: "location_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2b",
//     parentId: "2",
//     position: { x: 405, y: 180 },
//     data: { label: "address_1" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2c",
//     parentId: "2",
//     position: { x: 405, y: 230 },
//     data: { label: "address_2" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2d",
//     parentId: "2",
//     position: { x: 405, y: 280 },
//     data: { label: "city" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2e",
//     parentId: "2",
//     position: { x: 405, y: 330 },
//     data: { label: "state" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2f",
//     parentId: "2",
//     position: { x: 405, y: 380 },
//     data: { label: "zip" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2g",
//     parentId: "2",
//     position: { x: 405, y: 430 },
//     data: { label: "county" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "2h",
//     parentId: "2",
//     position: { x: 405, y: 480 },
//     data: { label: "location_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "3",
//     position: { x: 700, y: 100 },
//     data: { label: "domain" },
//     style: { width: 200, height: 200 },
//     draggable: "true",
//   },
//   {
//     id: "3a",
//     parentId: "3",
//     position: { x: 705, y: 130 },
//     data: { label: "domain_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "3b",
//     parentId: "3",
//     position: { x: 705, y: 180 },
//     data: { label: "domain_name" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "3c",
//     parentId: "3",
//     position: { x: 705, y: 230 },
//     data: { label: "domain_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "4",
//     position: { x: 1000, y: 100 },
//     data: { label: "vocabulary" },
//     style: { width: 200, height: 290 },
//     draggable: "true",
//   },
//   {
//     id: "4a",
//     parentId: "4",
//     position: { x: 1005, y: 130 },
//     data: { label: "vocabulary_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "4b",
//     parentId: "4",
//     position: { x: 1005, y: 180 },
//     data: { label: "vocabulary_name" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "4c",
//     parentId: "4",
//     position: { x: 1005, y: 230 },
//     data: { label: "vocabulary_reference" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "4d",
//     parentId: "4",
//     position: { x: 1005, y: 280 },
//     data: { label: "vocabulary_version" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "4e",
//     parentId: "4",
//     position: { x: 1005, y: 330 },
//     data: { label: "vocabulary_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5",
//     position: { x: 1300, y: 100 },
//     data: { label: "death" },
//     style: { width: 200, height: 390 },
//     draggable: "true",
//   },
//   {
//     id: "5a",
//     parentId: "5",
//     position: { x: 1305, y: 130 },
//     data: { label: "person_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5b",
//     parentId: "5",
//     position: { x: 1305, y: 180 },
//     data: { label: "death_date" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5c",
//     parentId: "5",
//     position: { x: 1305, y: 230 },
//     data: { label: "death_datetime" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5d",
//     parentId: "5",
//     position: { x: 1305, y: 280 },
//     data: { label: "death_type_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5e",
//     parentId: "5",
//     position: { x: 1305, y: 330 },
//     data: { label: "cause_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5f",
//     parentId: "5",
//     position: { x: 1305, y: 380 },
//     data: { label: "cause_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "5g",
//     parentId: "5",
//     position: { x: 1305, y: 430 },
//     data: { label: "cause_source_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6",
//     position: { x: 1600, y: 100 },
//     data: { label: "payer_plan_period" },
//     style: { width: 200, height: 890 },
//     draggable: "true",
//   },
//   {
//     id: "6a",
//     parentId: "6",
//     position: { x: 1605, y: 130 },
//     data: { label: "payer_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6b",
//     parentId: "6",
//     position: { x: 1605, y: 180 },
//     data: { label: "payer_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6c",
//     parentId: "6",
//     position: { x: 1605, y: 230 },
//     data: { label: "payer_source_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6d",
//     parentId: "6",
//     position: { x: 1605, y: 280 },
//     data: { label: "plan_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6e",
//     parentId: "6",
//     position: { x: 1605, y: 330 },
//     data: { label: "plan_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6f",
//     parentId: "6",
//     position: { x: 1605, y: 380 },
//     data: { label: "plan_source_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6g",
//     parentId: "6",
//     position: { x: 1605, y: 430 },
//     data: { label: "sponsor_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6h",
//     parentId: "6",
//     position: { x: 1605, y: 480 },
//     data: { label: "sponsor_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6i",
//     parentId: "6",
//     position: { x: 1605, y: 530 },
//     data: { label: "sponsor_source_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6j",
//     parentId: "6",
//     position: { x: 1605, y: 580 },
//     data: { label: "family_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6k",
//     parentId: "6",
//     position: { x: 1605, y: 630 },
//     data: { label: "stop_reason_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6l",
//     parentId: "6",
//     position: { x: 1605, y: 680 },
//     data: { label: "stop_reason_source_value" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6m",
//     parentId: "6",
//     position: { x: 1605, y: 730 },
//     data: { label: "stop_reason_source_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6n",
//     parentId: "6",
//     position: { x: 1605, y: 780 },
//     data: { label: "payer_plan_period_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6o",
//     parentId: "6",
//     position: { x: 1605, y: 830 },
//     data: { label: "person_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6p",
//     parentId: "6",
//     position: { x: 1605, y: 880 },
//     data: { label: "payer_plan_period_start_date" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "6q",
//     parentId: "6",
//     position: { x: 1605, y: 930 },
//     data: { label: "payer_plan_period_end_date" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7",
//     position: { x: 1900, y: 100 },
//     data: { label: "relationship" },
//     style: { width: 200, height: 340 },
//     draggable: "true",
//   },
//   {
//     id: "7a",
//     parentId: "7",
//     position: { x: 1905, y: 130 },
//     data: { label: "relationship_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7b",
//     parentId: "7",
//     position: { x: 1905, y: 180 },
//     data: { label: "relationship_name" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7c",
//     parentId: "7",
//     position: { x: 1905, y: 230 },
//     data: { label: "is_hierarchical" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7d",
//     parentId: "7",
//     position: { x: 1905, y: 280 },
//     data: { label: "defines_ancestry" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7e",
//     parentId: "7",
//     position: { x: 1905, y: 330 },
//     data: { label: "reverse_relationship_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "7f",
//     parentId: "7",
//     position: { x: 1905, y: 380 },
//     data: { label: "relationship_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "8",
//     position: { x: 2200, y: 100 },
//     data: { label: "concept_class" },
//     style: { width: 200, height: 200 },
//     draggable: "true",
//   },
//   {
//     id: "8a",
//     parentId: "8",
//     position: { x: 2205, y: 130 },
//     data: { label: "concept_class_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "8b",
//     parentId: "8",
//     position: { x: 2205, y: 180 },
//     data: { label: "concept_class_name" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
//   {
//     id: "8c",
//     parentId: "8",
//     position: { x: 2205, y: 230 },
//     data: { label: "concept_class_concept_id" },
//     style: { width: 190, height: 40 },
//     draggable: "true",
//   },
// ];
// const initialEdges = [
//   { id: "death-5a-1b", source: "5a", target: "1b" },
//   { id: "payer_plan_period-6o-1b", source: "6o", target: "1b" },
// ];

const SchemaDiagram = (props) => {
  const { schemaJson } = props;
  const [json, setJson] = useState(schemaJson);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    schemaJson.initialNodes
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    schemaJson.initialEdges
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  // console.log(schemaJson);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDownload = async () => {
    fetch(`${LILAC_SERVER_URL}/download_dfjson`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "relation.json";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("oh no!"));
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      fetch(`${LILAC_SERVER_URL}/create_sqlite`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      alert("Please select a file");
    }
  };

  return (
    <div style={{ width: "100%", position: "relative", height: "80vh" }}>
      <div style={{ height: "100px", display: "flex", flexWrap: "wrap" }}>
        {/* <Button style={{color:"#fffff"}}onClick={() => handleDownload()}>Download</Button> */}
        <button
          style={{
            height: "35px",
            padding: "8px 16px",
            margin: "0 10px",
            backgroundColor: "#533ba8",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleDownload()}
        >
          Download
        </button>
        <input type="file" onChange={handleFileChange} />
        {selectedFile && (
          <div>
            <h3>Selected File:</h3>
            <p>Name: {selectedFile.name}</p>
          </div>
        )}
        {/* <Button onClick={() => handleUpload()}>Upload</Button> */}
        <button
          style={{
            height: "35px",

            padding: "8px 16px",
            margin: "0 10px",
            backgroundColor: "#533ba8",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleUpload()}
        >
          Upload
        </button>
      </div>
      <ReactFlow
        nodes={schemaJson.initialNodes}
        edges={schemaJson.initialEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        fitView
        connectionLineType="straight"
        onConnect={onConnect}
        attributionPosition="bottom-left"
      >
        <Controls />
        {/* <MiniMap /> */}
        <Background />
      </ReactFlow>
      <br />
    </div>
  );
};
export default SchemaDiagram;
