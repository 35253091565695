// // Core
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

// // Styles
// import './styles.scss';

// import { CheckCircleOutlined } from '@ant-design/icons';
// import { Button } from 'antd';
// import { useDispatch } from 'react-redux';
// import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// // icons

// const stripePromise = loadStripe('pk_test_51OogWQSCM0J1pAtzVqaOA29BWtdbuaV8srXrewDtTHQOdPTDbFNKuis9nkPI31IwnHD6uvgXDjYv0DIShPA2Mouf00NmRUquSD');



// const clientSecret = 'cs_test_a1doNFlyt6ZTGjEdmCE5Fh89LRxicqU1HozNFm0cDzgi5y1QLDg8Oi3XnC_secret_fid2cGd2ZndsdXFsamtQa2x0cGBrYHZ2QGtkZ2lgYSc'

// const Payment = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const handlePaymentSuccess = () => {
//         const localDetails = localStorage.getItem("userDetails");
//         dispatch({
//             type: "userDetails",
//             value: JSON.parse(localDetails),
//         });
//         localStorage.setItem("token", '123456');
//         navigate('/home')
//     }

//     return (
//         <>
//             <div className='__susbscription_page'>
//                 <div className='__inner_wrap'>
//                     <div className='__top_head'>
//                         <button className="__logo">LiLaQ-Ai</button>
//                     </div>
//                     <h3 className='__top_head_h1'>Payment</h3>
//                     <div className='__subs_page'>
//                         <EmbeddedCheckoutProvider
//                             stripe={stripePromise}
//                             options={{
//                                 clientSecret,
//                                 onComplete: handlePaymentSuccess,
//                             }}
//                         >
//                             <div style={{ maxWidth: '420px' }}>
//                                 <EmbeddedCheckout />
//                             </div>
//                         </EmbeddedCheckoutProvider>
//                     </div>

//                     <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Payment;


import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const stripePromise = loadStripe('pk_test_51OogWQSCM0J1pAtzVqaOA29BWtdbuaV8srXrewDtTHQOdPTDbFNKuis9nkPI31IwnHD6uvgXDjYv0DIShPA2Mouf00NmRUquSD');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message);
    } else {
      setError(null);
      // Send paymentMethod.id to your server to process the payment
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}
      {error && <div>{error}</div>}
    </form>
  );
};

const Payment = () => {
  const { userDetails, subscriptionSelected } = useSelector((store) => store.commonData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading]  = useState(false)

  const handlePaymentSuccess = async () => {
    setLoading(true);
    let payload = {
      "username": userDetails,
      "count": 1,
      "plan": subscriptionSelected
    }

    const res = await fetchData(restAPIs.subscribe(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      navigate('/aws-key')
    } else {
      setLoading(false);
      openNotificationWithIcon(res?.message);
    }

  }
  return (

    <>
      <div className='__susbscription_page'>
        <div className='__inner_wrap'>
          <div className='__top_head'>
            <button className="__logo">LiLaQ-Ai</button>
          </div>
          <h3 className='__top_head_h1'>Payment</h3>
          <div className='__subs_page'>
            <div>
              <Elements stripe={stripePromise}>
                <CheckoutForm />
              </Elements>
              <Button type="primary" className="__extra_btn_cls" loading={loading} style={{ width: '200px', margin: '0 auto', marginTop: '30px' }} onClick={() => handlePaymentSuccess()}>Submit</Button>
            </div>
          </div>

          <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
        </div>
      </div>
    </>
  );
};

export default Payment;


