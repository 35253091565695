// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const InfoModal = (props) => {
  const { onClose, open, content } = props;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content' style={{color:'#fff'}}>
          <div className='____common_model_head'>
            <h3 style={{marginBottom:'10px'}}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose}/></button>
          </div>
          <div className='__contnet'>
            <h2 style={{margin:'10px 0', fontSize:'22px', marginTop:'40px'}}>{content?.title}</h2>
            <p style={{fontWeight:'300'}}>{content?.description}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;

InfoModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  content: PropTypes.any,
};
