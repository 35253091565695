// Core
import React, { useEffect, useRef, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faAdd, faArrowLeft, faFileArrowDown, faArrowUpRightFromSquare, faCircleQuestion, faCubesStacked, faFilePowerpoint, faMicrophone, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseCircleOutlined, InboxOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';

import UserIcon from 'assets/images/man.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Switch, Tooltip, Upload, message } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { LILAC_SERVER_URL, ORANGE_SERVER_URL, STORY_URL } from 'config/constants';
import SelectBox from 'components/commonComponents/SelectBox';
import TimeAgo from 'react-timeago'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tabs } from 'antd';
import PresentationStoryDrag from 'components/modals/PresentationStoryDrag';
import IframeViewBook from 'components/modals/IframeViewBook';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';
import PresentationFullView from 'components/modals/PresentationFullView';
const { TabPane } = Tabs;


const Presentation = () => {
    // const { questionsSelected } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [file, setFile] = useState([]);
    const [template, setTemplate] = useState('')
    const [templateList, setTemplateList] = useState([])
    const [storyList, setStoryList] = useState([])
    const [storySelected, setStorySelected] = useState('')
    const [questionsSelected, setQuestionSelected] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [previewContent, setPreviewContent] = useState('');
    const [loadingPreview, setLoadingPreview] = useState()
    const [activeKey, setActiveKey] = useState('1');

    const [isStoryMode, setIsStoryMode] = useState(false)

    const [isBookMode, setIsBookMode] = useState(false)
    const [bookSelected, setBookSelected] = useState('')
    const [bookList, setBookList] = useState([])
    const [bookListSelected, setBookListSelected] = useState([])
    const [openBookView, setOpenBookView] = useState(false)

    const [singleStorySelected, setSingleStorySelected] = useState('')

    const [isDownloading, setIsDownloading] = useState('')
    const [loadingBookDownload, setLoadingBookDownload] = useState(false)

    const [isOpenLargeView, setOpenLargeView] = useState(false);


    const contentRef = useRef();


    const handleChangeTab = (e) => {
        setActiveKey(e)
    }


    const HTMLPreview = ({ content }) => {
        return (
            <div style={{ width: '100%', height: '600px' }}>
                <iframe
                    src={content}
                    title="content"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
        );
    };

    const HTMLPreview2 = ({ content }) => {
        return (
            <div style={{ width: '100%', height: '600px' }}>
                <iframe
                    src={content}
                    title="content"
                    style={{ width: '100%', height: '100%', border: 'none' }}
                />
            </div>
        );
    };
    useEffect(() => {
        listTemplates();
        listStories();
        getListReportBooks();

    }, [])


    // const handleCreatePPT = async () => {
    //     setLoading(true)
    //     let payload = {
    //         cards: questionsSelected,
    //         template: template,
    //         storyName: storySelected
    //     }
    //     fetch(`${LILAC_SERVER_URL}/generatePpt`, {
    //         method: "POST",
    //         body: JSON.stringify(payload),
    //         headers: {
    //             "Content-type": "application/json; charset=UTF-8"
    //         }
    //     })
    //         .then((resp) => resp.blob())
    //         .then((blob) => {
    //             const url = window.URL.createObjectURL(blob);
    //             const a = document.createElement("a");
    //             a.style.display = "none";
    //             a.href = url;
    //             a.download = `${storySelected}.pptx`;
    //             document.body.appendChild(a);
    //             a.click();
    //             window.URL.revokeObjectURL(url);

    //         })
    //         .catch(() => alert("oh no!"));
    //     setLoading(false)
    // }

    const handleCreatePPT = async () => {
        setLoading(true);
        let payload = {
            cards: questionsSelected,
            template: template,
            storyName: storySelected
        };

        fetch(`${LILAC_SERVER_URL}/generatePpt`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `${storySelected}.pptx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error creating PPT:", error);
                alert("Failed to create PPT. Please try again.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const removeCard = (item_se) => {
        let new_array = questionsSelected.filter(item => item !== item_se);
        dispatch({
            type: 'questionsSelected',
            value: new_array
        });
        setQuestionSelected(new_array);
    }

    const uploadProps = {
        onRemove: (file) => {
            setFile('');
        },
        beforeUpload: (file) => {
            setFile(file);
            return false;
        },
        file,
    };

    const handleOnChangeTemplate = (value) => {
        setTemplate(value);
    };

    const listTemplates = async () => {
        let payload = {}
        const res = await fetchData(restAPIs.listPptTemplates(payload));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element.templateName, label: element.templateName, ...element })
            }
            setTemplateList(pptm)
        }
    }

    const handleSubmitTemplate = async () => {
        let formd = new FormData();
        formd.append('file', file);
        const res = await fetchData(restAPIs.uploadTemplate(formd));
        if (res.statusCode === 200) {
            listTemplates();
        }
    }

    const onDragEnd = (result) => {
        // Reorder logic here
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const updatedItems = Array.from(questionsSelected);
        const [removedItem] = updatedItems.splice(startIndex, 1);
        updatedItems.splice(endIndex, 0, removedItem);
        dispatch({
            type: 'questionsSelected',
            value: updatedItems
        });
        setQuestionSelected(updatedItems);
    };

    const listStories = async () => {
        let payload = {}
        const res = await fetchData(restAPIs.listStory(payload));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element, label: element })
            }
            setStoryList(pptm)
            if (res?.body.length > 0) {
                handleOnChangeStory(res?.body[0])
            }
        }
    }



    const handleOnChangeStory = async (value) => {
        setStorySelected(value);
        setLoadingPreview(true);
        setPreviewContent('');

        let payload = {
            "storyName": value
        }
        const res = await fetchData(restAPIs.fetchStoryPreview(payload));

        if (res?.statusCode === 200) {
            setPreviewContent(res?.body); // Assuming the HTML content is in the 'body' field

        }

        const res2 = await fetchData(restAPIs.fetchStory(payload));
        if (res2.statusCode === 200) {
            setQuestionSelected(res2?.body)
        }
        setLoadingPreview(false);
    };

    const onChangeSwitch = (e) => {
        setIsStoryMode(!isStoryMode)
    }

    const onChangeSwitchBook = (e) => {
        setIsBookMode(!isBookMode)
    }

    const handleUpdateOrder = async () => {
        let payload = {
            "updatedOrder": questionsSelected
        }
        const res = await fetchData(restAPIs.updateStory(payload));
        if (res.statusCode === 200) {
            messageApi.open({
                type: 'success',
                content: res?.message,
            });
        }

    };

    const handleOnChangeReport = async (value) => {
        setBookSelected(value)
        let payload = {
            reportBookName: value
        }
        const res = await fetchData(restAPIs.listReportBookItems(payload));
        if (res.statusCode === 200) {
            setBookListSelected(res?.body)
        }
    }

    const getListReportBooks = async () => {
        let payload = {}
        const res = await fetchData(restAPIs.listReportBooks(payload));
        if (res.statusCode === 200) {
            const items = res.body.map(element => ({ label: element, id: element }));
            setBookList(items)

            if (res.body.length > 0) {
                handleOnChangeReport(res.body[0])
            }
        }
    };

    const handleEyeBook = (item) => {
        setSingleStorySelected(item)

        dispatch({
            type: 'storyBookView',
            value: item
        });

        setOpenBookView(true)
    }

    const downloadStory = async (item, i) => {

        setIsDownloading(i)

        let payload = {
            "reportBookName": item.reportBookName,
            "Qstn_id": item.Qstn_id,
            "formTemplateFilename": item.formTemplateFilename,
            "formTemplateName": item.formTemplateName
        }

        const res = item?.formTemplateFileExt === 'html' ? await fetchData(restAPIs.generateSingleReportForm(payload)) : await fetchData(restAPIs.downloadSingleReportForm(payload));
        console.log("rse", res);


        if (item?.formTemplateFileExt === 'html') {
            generatePDF(res?.body)
        }
        else {
            if (res.statusCode === 200) {
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = res?.body;
                a.download = `${res?.body}`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(res?.body);
                setIsDownloading('')
            }
        }
    }

    const generatePDF = async (url) => {
        try {
            // Fetch the content from the URL
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const htmlContent = await response.text();

            // Create a temporary container for HTML content
            const container = document.createElement('div');
            container.innerHTML = htmlContent;

            // Apply padding and width styling
            container.style.display = 'flex';
            container.style.padding = '45px';
            container.style.width = '200mm';
            container.style.overflow = 'auto';    // Set width to 90%
            container.style.margin = 'auto';  // Center the container
            container.style.whiteSpace = 'pre-line';
            container.style.boxSizing = 'border-box'; // Include padding in the width

            document.body.appendChild(container);

            // Convert HTML to canvas
            const canvas = await html2canvas(container, { scale: 1 });
            const imgData = canvas.toDataURL('image/png');

            console.log("container.style.padding = '40px';", canvas);


            // Create PDF using jsPDF
            const pdf = new jsPDF();
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 295; // A4 height in mm
            const imgHeight = canvas.height * imgWidth / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // Add image to PDF, handle page breaks
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('report.pdf');

            // Clean up
            setIsDownloading('')
            document.body.removeChild(container);

        } catch (error) {
            console.error('Error generating PDF:', error);
            alert('An error occurred while generating the PDF. Please try again.');
        }
    };


    const handleCreateReportBookDownload = () => {
        setLoadingBookDownload(true);
        let payload = {
            reportBookName: bookSelected
        };

        fetch(`${ORANGE_SERVER_URL}/downloadReportBook`, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `${bookSelected}.zip`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Error creating zip:", error);
                alert("Failed to create zip. Please try again.");
            })
            .finally(() => {
                setLoadingBookDownload(false);
            });
    }


    return (
        <>
            <div className='__presentation'>
                <Tabs className='__tabs2' style={{ fontSize: '30px' }} activeKey={activeKey} tabPosition={'top'} onChange={(e) => handleChangeTab(e)}>
                    <TabPane tab={'Presentations'} key='1'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='__template_sec' style={{ width: '100%', marginBottom: '30px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: '#fff', gap: '50px', width: '100%' }}>
                                    <div style={{ flex: 5 }}>
                                        <SelectBox
                                            options={storyList}
                                            value={storySelected}
                                            handleOnChange={handleOnChangeStory}
                                            label='Choose an Insight story'
                                            showSearch={true}
                                        />
                                    </div>
                                    <div style={{ flex: 3, display: 'flex', gap: '15px', alignItems: 'end', justifyContent: 'end' }}>
                                        <span style={{
                                            display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '20px', marginBottom: '14px'
                                        }}>
                                            <label htmlFor="" style={{ fontSize: '11px' }}>Manage Story</label>
                                            <Switch
                                                onChange={onChangeSwitch}
                                                checked={isStoryMode}
                                                className='__default'
                                            />
                                        </span>
                                        <div style={{ width: '300px' }}>
                                            <SelectBox
                                                options={templateList}
                                                value={template}
                                                handleOnChange={handleOnChangeTemplate}
                                                label='Choose template'
                                            />
                                        </div>
                                        <Tooltip title='Download as PPT'><Button type="primary" className="__extra_btn_cls" loading={loading} onClick={() => handleCreatePPT()} style={{ marginTop: '25px', height: '45px' }} icon={<FontAwesomeIcon icon={faFileArrowDown} />}></Button></Tooltip>
                                    </div>
                                </div>

                                {isStoryMode ? <>
                                    <div style={{ marginTop: '30px' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable" direction="horizontal">
                                                    {(provided) => (
                                                        <div className="__presentation_card_list" ref={provided.innerRef} {...provided.droppableProps}>
                                                            {questionsSelected && questionsSelected.map((item, idx) => (
                                                                <Draggable key={idx} draggableId={idx.toString()} index={idx}>
                                                                    {(provided) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="__grid_item">
                                                                            <div className="__presentation_single_card">
                                                                                <div className="__head">
                                                                                    <Tooltip title={item.Question}><FontAwesomeIcon icon={faCircleQuestion} /></Tooltip>
                                                                                    <CloseCircleOutlined onClick={() => removeCard(item)} style={{ cursor: 'pointer' }} />
                                                                                </div>
                                                                                <div className="__content">
                                                                                    <h4>{item.Question}</h4>
                                                                                    {item.ProcessedTime && <p><TimeAgo date={item.ProcessedTime} /></p>}
                                                                                </div>
                                                                                <div className="__footer"></div>
                                                                            </div>
                                                                            <button className="__add_icon">+</button>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                                            <Button className="__extra_btn_cls" style={{ marginTop: '20px', marginBottom: '0px', borderColor: '#ffffff63' }} onClick={() => handleUpdateOrder()}>Update Order</Button>
                                        </div>
                                    </div>

                                </>
                                    :
                                    <>
                                        {previewContent && (

                                            <div className="__story_preview" style={{ width: '99%', marginTop: '20px', color: 'white' }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '-1%', marginBottom: '10px' }}>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => setOpenLargeView(true)}
                                                        ghost
                                                        style={{
                                                            margin: 0,
                                                            color: "#fff9",
                                                            borderColor: "#fff3",
                                                        }}
                                                        icon={
                                                            <FontAwesomeIcon
                                                                icon={faUpRightAndDownLeftFromCenter}
                                                            />
                                                        }
                                                    ></Button>
                                                </div>

                                                <div className="__preview_content" style={{ border: '1px solid #ccc', padding: '10px', maxHeight: '700px', width: '99%', overflowY: 'hidden', background: '#fff', borderRadius: ' 10px' }}>
                                                    <HTMLPreview content={previewContent} />
                                                </div>
                                            </div>
                                        )}
                                    </>}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={'Report Books'} key='2'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='__template_sec' style={{ width: '100%', marginBottom: '30px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', color: '#fff', gap: '50px', width: '100%' }}>
                                    <div style={{ flex: 5 }}>
                                        <SelectBox
                                            options={bookList}
                                            value={bookSelected}
                                            handleOnChange={handleOnChangeReport}
                                            label='Choose a report'
                                            showSearch={true}
                                        />
                                    </div>

                                    <div style={{ flex: 3, display: 'flex', gap: '15px', alignItems: 'end', justifyContent: 'end' }}>
                                        <span style={{ display: 'flex', gap: '20px' }}>
                                            <label htmlFor="" style={{ fontSize: '11px' }}>Manage Reports</label>
                                            <Switch
                                                onChange={onChangeSwitchBook}
                                                checked={isBookMode}
                                                className='__default'
                                            />
                                        </span>

                                    </div>
                                    <Tooltip title='Download'><Button type="primary" className="__extra_btn_cls" loading={loadingBookDownload} onClick={() => handleCreateReportBookDownload()} style={{ marginTop: '25px', height: '45px' }} icon={<FontAwesomeIcon icon={faFileArrowDown} />}></Button></Tooltip>
                                </div>

                                {isBookMode ? <>
                                    <div style={{ marginTop: '30px' }}>
                                        <div style={{ width: '100%', display: 'flex' }}>
                                            <div className="__presentation_card_list_books">
                                                {bookListSelected && bookListSelected.map((item, idx) => (
                                                    <div className="__presentation_single_card">
                                                        <div className="__head">
                                                            <Tooltip title={item.Question}><FontAwesomeIcon icon={faCircleQuestion} /></Tooltip>
                                                            {/* <CloseCircleOutlined onClick={() => removeCard(item)} style={{ cursor: 'pointer' }} /> */}
                                                        </div>
                                                        <div className="__content">
                                                            <h4>{item.Question}</h4>
                                                            <p>{item?.formTemplateName}</p>
                                                            <p className='__ext'>{item?.formTemplateFileExt}</p>
                                                        </div>
                                                        <div className="__footer">
                                                            {item?.previewFlag && <EyeOutlined style={{ cursor: 'pointer', fontSize: '22px' }} onClick={() => handleEyeBook(item)} />}
                                                            {/* <DownloadOutlined style={{ cursor: 'pointer', fontSize: '22px' }}  /> */}
                                                            <Button
                                                                loading={idx === isDownloading}
                                                                ghost
                                                                icon={
                                                                    <DownloadOutlined style={{ cursor: 'pointer', fontSize: '22px' }} />
                                                                }
                                                                onClick={() => downloadStory(item, idx)}
                                                            ></Button>
                                                        </div>
                                                    </div>

                                                ))}
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                    :
                                    <>

                                        {/* {previewContent && (
                                            <div className="__story_preview" style={{ width: '99%', marginTop: '20px', color: 'white' }}>
                                                <div className="__preview_content" style={{ border: '1px solid #ccc', padding: '10px', maxHeight: '700px', width: '100%', overflowY: 'hidden', background: '#fff', borderRadius: ' 10px' }}>
                                                    <HTMLPreview2 content={'urlSelected'} />
                                                </div>
                                            </div>
                                        )} */}
                                    </>}
                            </div>
                        </div>

                    </TabPane>
                </Tabs>
                {contextHolder}
                <IframeViewBook
                    open={openBookView}
                    onClose={() => setOpenBookView(false)}
                />

                <div ref={contentRef} style={{ display: 'none' }} ></div>

                <PresentationFullView
                    onClose={() => setOpenLargeView(false)}
                    url={previewContent}
                    open={isOpenLargeView}
                />
            </div>
        </>
    );
};

export default Presentation;
