import React, { useEffect, useRef, useState } from 'react';
import './styles.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { message, Tooltip, Slider as Sliding } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useSwipeable } from "react-swipeable";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import dayjs from 'dayjs';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);

const MyInsight = () => {
  const { userDetails, } = useSelector(
    (store) => store.commonData
  );
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [isEmpty, setIsEmpty] = useState(false)
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cardIndex, setCardIndex] = useState(0);
  const sliderRef = useRef(null); // For referencing the slider instance
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1394px)' });
  const chartRef = useRef(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [cardSelected, setCardSelected] = useState(null);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const questionRef = useRef(null);
  const [sliderValues, setSliderValues] = useState([]);
  const [marks, setMarks] = useState({});

  const [legendColumn, setLegendColumn] = useState('');
  const [legendValues, setLegendValues] = useState([]);

  const settings = {
    dots: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCardIndex(newIndex); // Update index on slide change
    },
    appendDots: dots => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '77vh',
          position: 'absolute',
          left: '-25%',
          top: '0%',
          transform: 'translateX(25%)'
        }}
      >
        <ul style={{ margin: 0, marginRight: '40px' }}>
          {dots.map((dot, idx) => (
            <li
              key={idx}
              className={idx === cardIndex ? 'slick-active' : ''}
              onClick={() => handleDotClick(idx)} // Change slide on dot click
              {...swipeHandlers}
            >
              {dot}
            </li>
          ))}
        </ul>
      </div>
    ),
    customPaging: i => (
      <div className="__custom_dot" />
    ),
    afterChange: (currentIndex) => {
      // Use the currentIndex to get data related to the slide
      const selectedCard = questions[currentIndex]; // Assuming questions array contains slide data
      handleSlideChange(selectedCard, currentIndex);
    }
  };


  const swipeHandlers = useSwipeable({
    onSwipedUp: (eventData) => {
      if (cardIndex < questions.length - 1) {
        handleSlideChange(questions[cardIndex + 1], cardIndex + 1);
      }
    },
    onSwipedDown: (eventData) => {
      if (cardIndex > 0) {
        handleSlideChange(questions[cardIndex - 1], cardIndex - 1);
      }
    },
    trackMouse: true, // Track mouse swipes too
  });

  // Function to handle dot clicks and update slider
  const handleDotClick = (index) => {
    setCardIndex(index); // Update cardIndex
    sliderRef.current.slickGoTo(index); // Change slide in the slider
  };

  // Function to handle slide change on swipe
  const handleSlideChange = (card, currentIndex) => {
    let direction = currentIndex > cardIndex ? 'next' : 'prev';
    if (direction === 'next' && cardIndex < questions.length - 1) {
      sliderRef.current.slickNext(); // Go to the next slide
    } else if (direction === 'prev' && cardIndex > 0) {
      sliderRef.current.slickPrev(); // Go to the previous slide
    }


    setCardIndex(currentIndex);
    setCardSelected(card);
    setLabels(card?.chartAttributes);
    // fetchGraph(card);
    setSliderValue(0)
    fetchTimeline(card?.insightId);
  };

  const colors = [
    {
      backgroundColor: 'rgba(54, 162, 235, 0.2)', // Light Blue
      borderColor: 'rgba(54, 162, 235, 1)',
      pointBackgroundColor: 'rgba(54, 162, 235, 1)',
    },
    {
      backgroundColor: 'rgba(255, 99, 132, 0.2)', // Light Red
      borderColor: 'rgba(255, 99, 132, 1)',
      pointBackgroundColor: 'rgba(255, 99, 132, 1)',
    },
    {
      backgroundColor: 'rgba(75, 192, 192, 0.2)', // Light Green
      borderColor: 'rgba(75, 192, 192, 1)',
      pointBackgroundColor: 'rgba(75, 192, 192, 1)',
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.2)', // Light Yellow
      borderColor: 'rgba(255, 206, 86, 1)',
      pointBackgroundColor: 'rgba(255, 206, 86, 1)',
    },
    // Add more colors if you have more datasets
  ];

  const getQuestions = async () => {
    const payload = {
      pageNo: 1,
      pageSize: 10,
      triggerTimeFlag: true,
      username: 'gokul',
    };
    setLoading(true);
    const res = await fetchData(restAPIs.fetchMyInsights(payload));
    if (res?.statusCode === 200) {
      setLoading(false);
      setQuestions(res?.body);
      setIsEmpty(res?.body?.length === 0 ? true : false)
      if (res?.body?.length > 0) {
        setCardSelected(res?.body[0]);
        setLabels(res?.body[0]?.chartAttributes);
        setLegendValues(res?.body[0]?.legenedValues);
        setLegendColumn(res?.body[0]?.legendColumn);
        // fetchGraph(res?.body[0]);
        fetchTimeline(res?.body[0]?.insightId, res?.body[0]?.legendColumn, res?.body[0]?.legenedValues);
      }
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  };



  useEffect(() => {
    getQuestions();
  }, []);

  const fetchGraph = async (card, legendColumn, legendValues) => {

    const payload = {
      "Qstn_id": card?.Qstn_id,
      "versionId": card?.versionId,
    }
    const res = await fetchData(restAPIs.listGraphStructure(payload));
    if (res?.statusCode === 200) {

      console.log("legendValues", legendValues);
      console.log("legendColumn", legendColumn);
      

      const getColumns = res?.body?.content?.filter((item) => legendValues.includes(item[legendColumn]));

      console.log("getColumns", getColumns);


      let newData = getColumns.map(item => {
        const { [legendColumn]: label, ...rest } = item; // Destructure to separate the label and the rest of the fields
        return {
          label,
          data: Object.values(rest).map(value => parseInt(value)) // Convert the remaining values to an array of integers
        };
      });
      setDatasets(newData)
    }
  }

  const fetchTimeline = async (id, legendColumn, legendValues) => {
    const payload = {
      "insightId": id,
    }
    const res = await fetchData(restAPIs.fetchInsightTimelines(payload));
    if (res?.statusCode === 200) {
      setSliderValues(res?.body);
      const marks = createSliderMarks(res?.body);
      setMarks(marks);
      if(res?.body?.length > 0)
      fetchGraph(res?.body[0], legendColumn, legendValues);
    }
  }


  const createSliderMarks = (data) => {
    const totalDataPoints = data.length;
    return data.reduce((marks, item, index) => {
      const percentage = (index / (totalDataPoints - 1)) * 100; // Position in percentage
      marks[percentage] = dayjs(item.createdTs).format('MMM DD, YYYY'); // Format the date
      return marks;
    }, {});
  };

  const handleSliderChange = (value) => {
    // Find the corresponding record in data based on the slider value
    const totalDataPoints = sliderValues.length;
    const index = Math.round((value / 100) * (totalDataPoints - 1)); // Calculate the index based on the value
    const selectedRecord = sliderValues[index];
    // You can then send this `selectedRecord` where needed (e.g., API call)
    setSliderValue(value); // Update slider value in state
    fetchGraph2(selectedRecord);
  };


  
  const fetchGraph2 = async (card) => {

    const payload = {
      "Qstn_id": card?.Qstn_id,
      "versionId": card?.versionId,
    }
    const res = await fetchData(restAPIs.listGraphStructure(payload));
    if (res?.statusCode === 200) {

      console.log("legendValues", legendValues);
      console.log("legendColumn", legendColumn);
      

      const getColumns = res?.body?.content?.filter((item) => legendValues.includes(item[legendColumn]));

      console.log("getColumns", getColumns);


      let newData = getColumns.map(item => {
        const { [legendColumn]: label, ...rest } = item; // Destructure to separate the label and the rest of the fields
        return {
          label,
          data: Object.values(rest).map(value => parseInt(value)) // Convert the remaining values to an array of integers
        };
      });
      setDatasets(newData)
    }
  }




  const formatTooltipValue = (value) => {
    const totalDataPoints = sliderValues.length;
    const index = Math.round((value / 100) * (totalDataPoints - 1)); // Calculate the index based on the value
    const selectedRecord = sliderValues[index];
    return selectedRecord
      ? dayjs(selectedRecord.createdTs).format('MMM DD, YYYY HH:mm') // Return date with time
      : dayjs().format('MMM DD, YYYY HH:mm'); // Fallback to current date and time
  };


  useEffect(() => {
    dispatch({
      type: "headerName",
      value: 'My Insights',
    });
  }, [])

  const handleLegendClick = (index) => {
    const ci = chartRef.current;
    const meta = ci.getDatasetMeta(0);
    console.log("meta", meta);
    const item = meta.data[+index];
    item.hidden = !item.hidden;
    ci.update();
  };


  useEffect(() => {
    if (questionRef.current) {
      // Check if the question exceeds 3 lines
      const isOverflowing = questionRef.current.scrollHeight > questionRef.current.clientHeight;
      setIsTruncated(isOverflowing);
    }
  }, [cardSelected?.Question]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <>
      {contextHolder}

      <div className='__insight_container'>
        <div className='__insight_content'>
          {loading ? (
            <div className='__insight_loader'>
              <LoadingOutlined />
            </div>
          ) : isEmpty ? (
            <div className='__insight_loader'>
              No Insights Available
            </div>
          ) : (
            <div className='__insight_full_sec'>
              <div className='__insight_cards' {...swipeHandlers}>
                <Slider {...settings} ref={sliderRef}>
                  {questions.map((cardItem, idx) => {
                    return (
                      <div
                        // added key for the map
                        key={idx}
                        className={cardIndex === idx ? '__action_single_card __activeCard' : '__action_single_card'}
                      >
                        <h1>hai</h1>
                      </div>
                    )
                  })}
                </Slider>
              </div>
              <div className='__insight_chart' style={{ width: '100%' }}>
                <h4 style={{ width: '100%', textAlign: 'center' }}>{cardSelected?.insightName}</h4>
                <div className='__chart_n_tbl'>
                  <div className='__chart'>
                    <Radar
                      ref={chartRef}
                      data={{
                        labels: labels,
                        datasets: datasets.map((dataset, index) => ({
                          label: dataset.label,
                          data: dataset.data.slice(0, labels.length), // Slice dataset to match labels
                          backgroundColor: colors[index % colors.length].backgroundColor,
                          borderColor: colors[index % colors.length].borderColor,
                          pointBackgroundColor: colors[index % colors.length].pointBackgroundColor,
                          borderWidth: 2,
                          fill: true,
                        })),
                      }}
                      options={{
                        responsive: true,
                        scales: {
                          r: {
                            angleLines: {
                              color: 'rgba(255, 255, 255, 0.1)',
                            },
                            grid: {
                              color: 'rgba(255, 255, 255, 0.2)',
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                        },
                      }}
                    />
                  </div>
                  <div className="__table">
                    <p className={`__answeerrr answer-text ${isExpanded ? 'expanded' : 'collapsed'}`} ref={questionRef} >{cardSelected?.Answer}</p>
                    <div style={{ display: 'flex' }}>
                      {isTruncated && (
                        <button className="__read" onClick={toggleExpanded}>
                          {isExpanded ? 'Show Less' : 'Show More'}
                        </button>
                      )}
                    </div>
                    <table>
                      <thead>
                        <tr>
                          {datasets.map((dataset, idx) => (
                            <th key={idx} style={{ backgroundColor: colors[idx % colors.length].backgroundColor }} onClick={() => handleLegendClick(idx)}>{dataset.label}</th>
                          ))}
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {labels.map((label, rowIndex) => (
                          <tr key={rowIndex}>
                            {datasets.map((dataset, colIndex) => (
                              <td key={colIndex}>{dataset.data[rowIndex]}</td>
                            ))}
                            <td style={{ paddingRight: '2rem' }}>{label}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='____sliding'>
                  <Sliding
                    marks={marks}
                    min={0}
                    max={100}
                    defaultValue={0}
                    tooltip={{ formatter: formatTooltipValue }}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    step={null} // Disable steps between marks
                  />
                </div>
              </div>
            </div>
          )}
        </div>

      </div>

    </>
  );
};

export default MyInsight;
