export const BASE_URL = 'https://108f-103-175-89-118.ngrok-free.app/'
export const IFRAME_URL = 'https://108f-103-175-89-118.ngrok-free.app/interactive'

// export const LILAC_SERVER_URL = 'http://srv550235.hstgr.cloud:6001'
// export const ORANGE_SERVER_URL = 'http://srv550235.hstgr.cloud:9001'
// export const GREY_SERVER_URL = 'https://grey.alpha.lilaq.ai'

export const LILAC_SERVER_URL = 'https://server.alpha.lilaq.ai'
export const ORANGE_SERVER_URL = 'https://orange.alpha.lilaq.ai'
export const GREY_SERVER_URL = 'https://grey.alpha.lilaq.ai'

export const LILBOT_URL = 'http://98.70.73.24:8065/hooks/b9wn7fpzabre7mjtnjb9oy43by'


export const STORY_URL =  'http://54.236.24.66:3003/'
