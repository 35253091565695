import {
  USER_DETAILS,
  QUESTIONS_SELECTED,
  SUBSCRIPTION_SELECTED,
  CARD_SELECTED,
  SIMILAR_QUESTION_TBLSL,
  DATAMART,
  ACTIVE_DATAMART,
  ACTIVE_MODELSET,
  DATAMART_NAME,
  AGENT_DATA,
  QUESTION_QUICKVIEW,
  SET_DATA_SCIENTIST_VALUE,
  GREY_QUESTION_REFRESH,
  URL_GENERATED,
  GERY_QUESTION_VIEW,
  DOMAIN_NAME,
  LILBOT_USER_DATA,
  SYSTEM_INTEGRATION,
  SHADE_DATA,
  USER_PERMISSIONS,
  USER_SETTINGS_SHAPE,
  STORY_BOOK_VIEW,
  IS_SPEAK_ENABLE,
  HEADER_NAME,
  TEAM_DATA,
  ISMULTIPLE,
  APP_DATA

} from 'config/actionTypes';
import initialState from './initialState';

const commonData = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.value
      };

    case DOMAIN_NAME:
      return {
        ...state,
        domainName: action.value
      }


    case QUESTIONS_SELECTED:
      return {
        ...state,
        questionsSelected: action.value
      };

    case SUBSCRIPTION_SELECTED:
      return {
        ...state,
        subscriptionSelected: action.value
      }
    case CARD_SELECTED:
      return {
        ...state,
        cardSelected: action.value
      }
    case SIMILAR_QUESTION_TBLSL:
      return {
        ...state,
        similarQuestionTableItem: action.value
      }
    case DATAMART:
      return {
        ...state,
        dataMartItem: action.value
      }
    case ACTIVE_DATAMART:
      return {
        ...state,
        activeDataMart: action.value
      }
    case ACTIVE_MODELSET:
      return {
        ...state,
        activeModelSet: action.value
      }
    case DATAMART_NAME:
      return {
        ...state,
        datamartName: action.value
      }

    case AGENT_DATA:
      return {
        ...state,
        agentData: action.value
      }

    case QUESTION_QUICKVIEW:
      return {
        ...state,
        questionQuickView: action.value
      }

    case SET_DATA_SCIENTIST_VALUE:
      return {
        ...state,
        setDataScientistValue: action.value
      }
    case GREY_QUESTION_REFRESH:
      return {
        ...state,
        greyQuestionsRefresh: action.value
      }

    case URL_GENERATED:
      return {
        ...state,
        urlGenerated: action.value
      }

    case GERY_QUESTION_VIEW:
      return {
        ...state,
        questionGreyQuickView: action.value
      }

    case LILBOT_USER_DATA:
      return {
        ...state,
        lilBotUserData: action.value
      }

    case SYSTEM_INTEGRATION:
      return {
        ...state,
        systemIntegration: action.value
      }

    case SHADE_DATA:
      return {
        ...state,
        shadeData: action.value
      }

    case USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.value
      }

    case USER_SETTINGS_SHAPE:
      return {
        ...state,
        userSettingsShape: action.value
      }

    case STORY_BOOK_VIEW:
      return {
        ...state,
        storyBookView: action.value
      }
    case IS_SPEAK_ENABLE:
      return {
        ...state,
        isSpeakEnable: action.value
      }
    case HEADER_NAME:
      return {
        ...state,
        headerName: action.value
      }

    case TEAM_DATA:
      return {
        ...state,
        teamData: action.value
      }

    case ISMULTIPLE:
      return {
        ...state,
        isMultipleStore:action.value
      }

    case APP_DATA:
      return {
        ...state,
        appData: action.value
      }


    default:
      return state;
  }
};
export default commonData;
