import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload, notification } from 'antd';
import './styles.scss';
import InputField from 'components/commonComponents/InputField';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const UploadMeta = () => {
  const { userDetails } = useSelector((store) => store.commonData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [file, setFile] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false)

  const handleUpload = async () => {
    setLoading(true);
    let formd = new FormData();
    formd.append('file', file);
    formd.append('username', userDetails);
    formd.append('datasetName', name);
    const res = await fetchData(restAPIs.uploadMeta(formd));
    if (res.statusCode === 200) {
      setLoading(false);
      localStorage.setItem("token", '123456');
      navigate('/query-analyzer')
    } else {
      setLoading(false);
      openNotificationWithIcon(res?.message);
    }


  };

  const props = {

    onRemove: (file) => {
      setFile('');
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    file,
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const { name } = data;

  return (
    <>
      <div className='__susbscription_page'>
        <div className='__inner_wrap'>
          <div className='__top_head'>
            <button className="__logo">LiLaQ-Ai</button>
          </div>
          <h3 className='__top_head_h1'>Upload Your Data</h3>
          <div className='__subs_page'>
            <div>
              <InputField
                onChange={handleOnChange("name")}
                value={name}
                type="text"
                placeholder={'Name'}
                label={'Name'}
                error={error.name}
              />
              <div style={{ fontSize: '12px', marginBottom: '10px' }}>File</div>
              <div>

                <Upload {...props} maxCount={1} multiple={false}>
                  <Button className="__extra_btn_cls_upload" icon={<InboxOutlined />}>Select File to upload</Button>
                </Upload>
                <Button type="primary" className="__extra_btn_cls" loading={loading} icon={<UploadOutlined />} onClick={handleUpload}>Upload</Button>
              </div>
            </div>
          </div>
          <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
        </div>
      </div>
    </>
  );
};

export default UploadMeta;
