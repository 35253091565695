// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

const UpdateKey = (props) => {
  const { onClose, open, title, description, questionId } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { key } = data;
  const [visible, setVisibility] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {

    const errors = {
      key: !key,
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    const payload = {
      key: btoa(key)
    }

    const res = await fetchData(restAPIs.updateSecretKey(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }
  const iconClick = () => {
    setVisibility((state) => !state)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Configuration Key</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("key")}
              value={key}
              placeholder={'Enter your key'}
              type={visible ? "text": "password"}
              label='System configuration key'
              error={error.key}
              marginBottom={'0px'}
              icon={visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
             onIconClick={() => iconClick()}
            />
            <Button className='__extra_btn_cls' style={{ border: 0, color: '#fff', marginTop:'10px' }} loading={loading} onClick={() => handleSubmit()} >Update</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateKey;

UpdateKey.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
