// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faClose, faDatabase, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Styles
import './styles.scss';
import { Modal, Tooltip, Button, Popover, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { FileWordOutlined, InfoOutlined, ZoomInOutlined } from '@ant-design/icons';
import AddToStory from '../AddToStory';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  Filler
} from 'chart.js';
import { Line, Bar, Pie, Radar, Scatter } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  TooltipChart,
  Legend,
  Filler
);


const QuickView = (props) => {
  const { onClose, open, title, description, Image, Qstn_id, Question, chartJSON } = props;

  const { cardSelected } = useSelector((store) => store.commonData);
  const [showAddtoStory, setShowAddtoStory] = useState(false)
  const [openPop, setOpen] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [themeToggle, setThemeToggle] = useState(false)
  const [openPopDatamart, setOpenDatamart] = useState(false);

  // const xAxis = "Date"
  // const yAxis = "Script_Count"

  const defaultChart2 = 'bar'
  const chartData2 = {
    "labels": [
      "2023-12-16",
      "2023-05-17",
      "2023-08-11",
      "2023-10-04",
      "2023-01-30",
      "2023-10-18",
      "2023-03-26",
      "2023-05-07",
      "2023-01-04",
      "2023-04-28",
      "2023-06-19",
      "2023-10-23",
      "2023-08-03",
      "2023-07-19",
      "2023-03-26",
      "2023-07-21",
      "2023-01-16",
      "2023-03-06",
      "2023-11-07",
      "2023-06-09",
      "2023-07-23",
      "2023-09-30",
      "2023-01-07",
      "2023-11-12",
      "2023-10-31",
      "2023-08-19",
      "2023-07-01",
      "2023-09-08",
      "2023-11-13",
      "2023-05-01",
      "2023-08-11",
      "2023-05-31",
      "2023-05-06",
      "2023-04-30",
      "2023-07-17",
      "2023-02-21",
      "2023-02-08",
      "2023-12-21",
      "2023-02-06",
      "2023-01-10",
      "2023-02-09",
      "2023-09-19",
      "2023-03-16",
      "2023-11-05",
      "2023-10-16",
      "2023-03-19",
      "2023-06-30",
      "2023-07-05",
      "2023-03-13",
      "2023-09-10",
      "2023-10-10",
      "2023-11-10",
      "2023-07-29",
      "2023-08-10",
      "2023-12-30",
      "2023-11-11",
      "2023-11-06",
      "2023-02-11",
      "2023-05-16",
      "2023-09-26",
      "2023-03-10",
      "2023-04-06",
      "2023-04-05",
      "2023-05-08",
      "2023-05-01",
      "2023-01-06",
      "2023-06-03",
      "2023-07-14",
      "2023-09-01",
      "2023-10-17",
      "2023-12-21",
      "2023-05-19",
      "2023-06-19",
      "2023-03-13",
      "2023-01-28",
      "2023-11-01",
      "2023-04-27",
      "2023-06-01",
      "2023-01-09",
      "2023-12-19",
      "2023-12-02",
      "2023-12-07",
      "2023-12-23",
      "2023-11-09",
      "2023-04-16",
      "2023-09-30",
      "2023-12-27",
      "2023-11-18",
      "2023-07-26",
      "2023-08-21",
      "2023-06-17",
      "2023-11-22",
      "2023-09-11",
      "2023-10-23",
      "2023-05-14",
      "2023-08-20",
      "2023-10-09",
      "2023-03-08",
      "2023-03-07",
      "2023-08-25",
      "2023-01-18",
      "2023-09-03",
      "2023-07-16",
      "2023-11-06",
      "2023-09-22",
      "2023-03-23",
      "2023-01-15",
      "2023-04-09",
      "2023-12-27",
      "2023-12-15",
      "2023-05-04",
      "2023-12-13",
      "2023-05-10",
      "2023-08-27",
      "2023-05-22",
      "2023-12-15",
      "2023-04-01",
      "2023-03-30",
      "2023-05-31",
      "2023-06-10",
      "2023-04-13",
      "2023-09-15",
      "2023-12-08",
      "2023-12-12",
      "2023-05-27",
      "2023-01-13",
      "2023-07-03",
      "2023-02-22",
      "2023-07-18",
      "2023-08-21",
      "2023-10-30",
      "2023-03-18",
      "2023-02-19",
      "2023-09-01",
      "2023-12-23",
      "2023-05-11",
      "2023-03-02",
      "2023-01-04",
      "2023-05-14",
      "2023-08-05",
      "2023-10-13",
      "2023-11-20",
      "2023-01-14",
      "2023-11-13",
      "2023-05-26",
      "2023-11-17",
      "2023-12-06",
      "2023-09-01",
      "2023-11-02",
      "2023-08-11",
      "2023-06-08",
      "2023-01-01",
      "2023-07-02",
      "2023-03-12",
      "2023-04-22",
      "2023-02-18",
      "2023-01-20",
      "2023-02-16",
      "2023-04-03",
      "2023-02-28",
      "2023-09-19",
      "2023-10-09",
      "2023-11-15",
      "2023-08-15",
      "2023-06-04",
      "2023-02-28",
      "2023-11-22",
      "2023-01-05",
      "2023-07-30",
      "2023-06-16",
      "2023-06-14",
      "2023-08-31",
      "2023-10-21",
      "2023-02-12",
      "2023-09-20",
      "2023-09-19",
      "2023-07-09",
      "2023-08-17",
      "2023-02-14",
      "2023-07-02",
      "2023-08-24",
      "2023-09-09",
      "2023-01-29",
      "2023-05-15",
      "2023-12-13",
      "2023-10-31",
      "2023-12-29",
      "2023-06-18",
      "2023-01-25",
      "2023-06-15",
      "2023-11-05",
      "2023-03-03",
      "2023-07-03",
      "2023-07-08",
      "2023-04-13",
      "2023-05-18",
      "2023-02-16",
      "2023-04-06",
      "2023-11-19",
      "2023-06-24",
      "2023-02-09",
      "2023-02-13",
      "2023-06-24",
      "2023-05-13",
      "2023-02-01",
      "2023-07-28",
      "2023-08-04",
      "2023-01-03",
      "2023-04-26",
      "2023-01-15",
      "2023-05-09",
      "2023-09-24",
      "2023-10-14",
      "2023-02-19",
      "2023-11-03",
      "2023-05-30",
      "2023-10-11",
      "2023-07-29",
      "2023-02-07",
      "2023-04-10",
      "2023-12-03",
      "2023-03-05",
      "2023-04-21",
      "2023-11-22",
      "2023-08-16",
      "2023-05-03",
      "2023-06-17",
      "2023-01-08",
      "2023-07-08",
      "2023-02-04",
      "2023-07-30",
      "2023-11-03",
      "2023-04-12",
      "2023-10-18",
      "2023-05-22",
      "2023-07-09",
      "2023-01-14",
      "2023-12-18",
      "2023-12-05",
      "2023-01-09",
      "2023-05-04",
      "2023-12-06",
      "2023-11-29",
      "2023-02-06",
      "2023-11-06",
      "2023-08-11",
      "2023-02-23",
      "2023-01-25",
      "2023-03-15",
      "2023-01-11",
      "2023-01-02",
      "2023-05-13",
      "2023-03-01",
      "2023-01-28",
      "2023-01-12",
      "2023-02-05",
      "2023-12-09",
      "2023-07-19",
      "2023-01-02",
      "2023-03-03",
      "2023-07-26",
      "2023-10-01",
      "2023-01-25",
      "2023-04-26",
      "2023-03-12",
      "2023-03-06",
      "2023-09-28",
      "2023-05-24",
      "2023-01-31",
      "2023-12-10",
      "2023-09-21",
      "2023-12-15",
      "2023-12-08",
      "2023-12-24",
      "2023-08-26",
      "2023-01-15",
      "2023-10-25",
      "2023-03-13",
      "2023-09-17",
      "2023-05-02",
      "2023-01-13",
      "2023-02-09",
      "2023-10-17",
      "2023-07-11",
      "2023-01-22",
      "2023-01-18",
      "2023-03-19",
      "2023-06-09",
      "2023-08-06",
      "2023-01-12",
      "2023-10-15",
      "2023-09-05",
      "2023-12-26",
      "2023-07-21",
      "2023-03-28",
      "2023-01-17",
      "2023-04-06",
      "2023-11-17",
      "2023-04-22",
      "2023-01-28",
      "2023-05-16",
      "2023-01-19",
      "2023-08-12",
      "2023-05-22",
      "2023-12-10",
      "2023-12-11",
      "2023-11-04",
      "2023-02-27",
      "2023-08-29",
      "2023-11-23",
      "2023-04-28",
      "2023-06-07",
      "2023-07-20",
      "2023-02-01",
      "2023-02-18",
      "2023-07-06",
      "2023-09-22",
      "2023-10-16",
      "2023-04-09",
      "2023-12-31",
      "2023-09-03",
      "2023-10-14",
      "2023-04-29",
      "2023-07-28",
      "2023-04-23",
      "2023-03-15",
      "2023-05-04",
      "2023-05-28",
      "2023-10-15",
      "2023-11-25",
      "2023-12-05",
      "2023-06-27",
      "2023-11-12",
      "2023-08-31",
      "2023-10-07",
      "2023-06-18",
      "2023-03-04",
      "2023-05-19",
      "2023-06-25",
      "2023-08-30",
      "2023-03-12",
      "2023-09-28",
      "2023-10-28",
      "2023-01-30",
      "2023-06-12",
      "2023-07-21",
      "2023-03-30",
      "2023-12-07",
      "2023-04-01",
      "2023-11-18",
      "2023-12-18",
      "2023-11-11",
      "2023-10-22",
      "2023-08-06",
      "2023-06-14",
      "2023-07-04",
      "2023-11-12",
      "2023-08-06",
      "2023-10-02",
      "2023-07-15",
      "2023-02-22",
      "2023-01-31",
      "2023-11-21",
      "2023-04-10",
      "2023-12-17",
      "2023-10-05",
      "2023-03-19",
      "2023-05-06",
      "2023-05-20",
      "2023-01-02",
      "2023-09-27",
      "2023-10-26",
      "2023-03-17",
      "2023-03-11",
      "2023-06-22",
      "2023-10-06",
      "2023-12-24",
      "2023-08-05",
      "2023-01-15",
      "2023-06-02",
      "2023-04-05",
      "2023-10-25",
      "2023-02-20",
      "2023-11-18",
      "2023-08-28",
      "2023-05-09",
      "2023-04-04",
      "2023-08-22",
      "2023-08-03",
      "2023-12-16",
      "2023-08-26",
      "2023-09-02",
      "2023-10-10",
      "2023-10-17",
      "2023-07-20",
      "2023-08-31",
      "2023-04-11",
      "2023-12-14",
      "2023-06-13",
      "2023-06-03",
      "2023-07-27",
      "2023-01-26",
      "2023-09-10",
      "2023-09-18",
      "2023-07-12",
      "2023-01-16",
      "2023-03-01",
      "2023-05-25",
      "2023-09-17",
      "2023-04-05",
      "2023-11-01",
      "2023-09-11",
      "2023-06-07",
      "2023-03-18",
      "2023-09-15",
      "2023-02-16",
      "2023-06-02",
      "2023-12-14",
      "2023-05-11",
      "2023-04-05",
      "2023-04-01",
      "2023-11-22",
      "2023-01-08",
      "2023-04-03",
      "2023-10-12",
      "2023-06-19",
      "2023-08-19",
      "2023-06-20",
      "2023-10-04",
      "2023-08-30",
      "2023-11-09",
      "2023-11-11",
      "2023-03-07",
      "2023-06-30",
      "2023-02-15",
      "2023-12-03",
      "2023-02-17",
      "2023-09-16",
      "2023-07-14",
      "2023-12-26",
      "2023-11-26",
      "2023-07-16",
      "2023-11-02",
      "2023-09-22",
      "2023-04-17",
      "2023-08-02",
      "2023-04-29",
      "2023-02-10",
      "2023-03-17",
      "2023-03-03",
      "2023-04-16",
      "2023-11-05",
      "2023-07-04",
      "2023-08-09",
      "2023-09-25",
      "2023-11-16",
      "2023-10-02",
      "2023-06-04",
      "2023-07-12",
      "2023-01-27",
      "2023-05-27",
      "2023-04-25",
      "2023-04-14",
      "2023-09-04",
      "2023-08-25",
      "2023-11-12",
      "2023-06-06",
      "2023-12-27",
      "2023-04-30",
      "2023-02-20",
      "2023-10-27",
      "2023-04-30",
      "2023-10-13",
      "2023-12-06",
      "2023-06-30",
      "2023-11-08",
      "2023-02-14",
      "2023-07-25",
      "2023-01-11",
      "2023-03-06",
      "2023-09-29",
      "2023-11-08",
      "2023-07-07",
      "2023-02-27",
      "2023-08-21",
      "2023-09-14",
      "2023-11-24",
      "2023-07-11",
      "2023-02-13",
      "2023-09-30",
      "2023-09-28",
      "2023-08-20",
      "2023-10-29",
      "2023-05-02",
      "2023-02-01",
      "2023-08-04",
      "2023-10-07",
      "2023-04-23",
      "2023-02-12",
      "2023-01-26",
      "2023-04-19",
      "2023-05-23",
      "2023-05-10",
      "2023-10-04",
      "2023-11-30",
      "2023-03-20",
      "2023-02-28",
      "2023-10-24",
      "2023-12-13",
      "2023-03-03",
      "2023-01-22",
      "2023-09-25",
      "2023-06-15",
      "2023-02-14",
      "2023-11-09",
      "2023-06-22",
      "2023-06-16",
      "2023-09-07",
      "2023-11-02",
      "2023-10-12",
      "2023-02-13",
      "2023-04-27",
      "2023-08-16",
      "2023-12-16",
      "2023-02-10",
      "2023-11-14",
      "2023-06-12",
      "2023-09-14",
      "2023-01-22",
      "2023-02-09",
      "2023-05-29",
      "2023-11-13",
      "2023-12-20",
      "2023-02-15",
      "2023-06-23",
      "2023-06-20",
      "2023-11-21",
      "2023-05-01",
      "2023-12-02",
      "2023-11-26",
      "2023-06-15",
      "2023-05-21",
      "2023-03-04",
      "2023-07-08",
      "2023-09-27",
      "2023-08-19",
      "2023-10-10",
      "2023-01-29",
      "2023-04-11",
      "2023-05-21",
      "2023-05-29",
      "2023-02-07",
      "2023-03-22",
      "2023-11-07",
      "2023-06-10",
      "2023-06-26",
      "2023-06-21",
      "2023-12-12",
      "2023-09-02",
      "2023-10-26",
      "2023-07-11",
      "2023-05-05",
      "2023-02-17",
      "2023-11-23",
      "2023-12-26",
      "2023-10-06",
      "2023-01-09",
      "2023-12-31",
      "2023-10-16",
      "2023-02-13",
      "2023-07-04",
      "2023-05-21",
      "2023-01-27",
      "2023-12-27",
      "2023-04-20",
      "2023-04-26",
      "2023-08-13",
      "2023-04-18",
      "2023-12-30",
      "2023-11-20",
      "2023-11-02",
      "2023-05-08",
      "2023-10-23",
      "2023-05-23",
      "2023-02-02",
      "2023-12-16",
      "2023-02-18",
      "2023-12-18",
      "2023-02-08",
      "2023-03-30",
      "2023-11-14",
      "2023-05-06",
      "2023-12-23",
      "2023-07-26",
      "2023-09-26",
      "2023-10-28",
      "2023-12-19",
      "2023-05-26",
      "2023-03-25",
      "2023-04-24",
      "2023-04-25",
      "2023-07-23",
      "2023-09-19",
      "2023-10-15",
      "2023-01-30",
      "2023-09-29",
      "2023-05-21",
      "2023-12-25",
      "2023-12-18",
      "2023-12-11",
      "2023-03-16",
      "2023-11-28",
      "2023-07-19",
      "2023-12-21",
      "2023-11-04",
      "2023-10-26",
      "2023-11-16",
      "2023-09-30",
      "2023-09-04",
      "2023-12-14",
      "2023-09-25",
      "2023-12-24",
      "2023-06-29",
      "2023-02-20",
      "2023-04-07",
      "2023-10-23",
      "2023-12-02",
      "2023-11-30",
      "2023-02-10",
      "2023-03-27",
      "2023-02-06",
      "2023-06-08",
      "2023-03-29",
      "2023-10-08",
      "2023-03-11",
      "2023-09-04",
      "2023-10-11",
      "2023-01-06",
      "2023-06-15",
      "2023-03-05",
      "2023-03-23",
      "2023-04-14",
      "2023-04-22",
      "2023-12-04",
      "2023-03-29",
      "2023-02-22",
      "2023-08-25",
      "2023-05-03",
      "2023-08-09",
      "2023-03-30",
      "2023-12-31",
      "2023-04-01",
      "2023-06-17",
      "2023-11-27",
      "2023-02-16",
      "2023-03-13",
      "2023-03-17",
      "2023-04-11",
      "2023-02-15",
      "2023-08-13",
      "2023-02-02",
      "2023-03-01",
      "2023-08-17",
      "2023-09-09",
      "2023-07-10",
      "2023-07-27",
      "2023-11-18",
      "2023-06-25",
      "2023-07-19",
      "2023-12-04",
      "2023-07-14",
      "2023-03-20",
      "2023-08-21",
      "2023-10-19",
      "2023-04-02",
      "2023-05-26",
      "2023-06-08",
      "2023-11-04",
      "2023-11-28",
      "2023-09-11",
      "2023-08-07",
      "2023-05-10",
      "2023-10-03",
      "2023-01-12",
      "2023-06-05",
      "2023-11-01",
      "2023-07-13",
      "2023-07-04",
      "2023-09-13",
      "2023-08-14",
      "2023-08-07",
      "2023-04-25",
      "2023-08-10",
      "2023-02-07",
      "2023-11-14",
      "2023-07-13",
      "2023-08-08",
      "2023-11-14",
      "2023-12-24",
      "2023-01-23",
      "2023-06-25",
      "2023-03-20",
      "2023-07-03",
      "2023-04-21",
      "2023-08-04",
      "2023-01-08",
      "2023-10-05",
      "2023-12-03",
      "2023-11-29",
      "2023-04-04",
      "2023-02-26",
      "2023-09-06",
      "2023-05-17",
      "2023-12-07",
      "2023-09-21",
      "2023-01-02",
      "2023-11-07",
      "2023-08-28",
      "2023-03-01",
      "2023-04-21",
      "2023-08-05",
      "2023-05-20",
      "2023-09-28",
      "2023-04-03",
      "2023-07-01",
      "2023-10-03",
      "2023-08-15",
      "2023-06-16",
      "2023-09-11",
      "2023-09-09",
      "2023-05-06",
      "2023-09-27",
      "2023-01-11",
      "2023-11-03",
      "2023-12-29",
      "2023-03-16",
      "2023-01-17",
      "2023-03-26",
      "2023-08-23",
      "2023-01-19",
      "2023-03-27",
      "2023-04-08",
      "2023-10-23",
      "2023-02-05",
      "2023-08-01",
      "2023-07-24",
      "2023-03-14",
      "2023-07-03",
      "2023-07-21",
      "2023-06-21",
      "2023-05-25",
      "2023-11-20",
      "2023-03-09",
      "2023-01-08",
      "2023-04-10",
      "2023-04-24",
      "2023-06-14",
      "2023-06-27",
      "2023-04-17",
      "2023-08-28",
      "2023-05-07",
      "2023-04-07",
      "2023-09-08",
      "2023-07-06",
      "2023-07-11",
      "2023-05-30",
      "2023-07-12",
      "2023-03-09",
      "2023-04-09",
      "2023-08-30",
      "2023-02-26",
      "2023-08-12",
      "2023-02-04",
      "2023-01-04",
      "2023-11-28",
      "2023-07-11",
      "2023-08-14",
      "2023-08-18",
      "2023-08-17",
      "2023-12-20",
      "2023-01-11",
      "2023-06-26",
      "2023-02-13",
      "2023-07-17",
      "2023-06-07",
      "2023-04-28",
      "2023-10-15",
      "2023-10-25",
      "2023-08-19",
      "2023-02-25",
      "2023-03-12",
      "2023-09-22",
      "2023-12-09",
      "2023-01-13",
      "2023-05-03",
      "2023-02-20",
      "2023-02-19",
      "2023-02-26",
      "2023-09-29",
      "2023-06-06",
      "2023-01-06",
      "2023-11-19",
      "2023-03-18",
      "2023-09-02",
      "2023-11-02",
      "2023-05-31",
      "2023-11-23",
      "2023-01-17",
      "2023-05-04",
      "2023-03-01",
      "2023-10-26",
      "2023-04-06",
      "2023-03-24",
      "2023-09-12",
      "2023-06-29",
      "2023-04-08",
      "2023-02-08",
      "2023-11-11",
      "2023-02-15",
      "2023-01-01",
      "2023-01-15",
      "2023-08-02",
      "2023-03-31",
      "2023-11-27",
      "2023-10-05",
      "2023-07-27",
      "2023-07-18",
      "2023-12-16",
      "2023-10-09",
      "2023-12-12",
      "2023-03-08",
      "2023-03-02",
      "2023-11-13",
      "2023-03-31",
      "2023-10-10",
      "2023-03-09",
      "2023-10-18",
      "2023-04-16",
      "2023-04-08",
      "2023-12-11",
      "2023-10-25",
      "2023-02-14",
      "2023-04-30",
      "2023-03-25",
      "2023-11-30",
      "2023-07-02",
      "2023-04-15",
      "2023-06-30",
      "2023-06-12",
      "2023-01-12",
      "2023-01-21",
      "2023-10-21",
      "2023-09-03",
      "2023-11-15",
      "2023-12-20",
      "2023-02-24",
      "2023-02-18",
      "2023-04-24",
      "2023-03-29",
      "2023-04-11",
      "2023-10-19",
      "2023-11-25",
      "2023-07-31",
      "2023-08-15",
      "2023-07-22",
      "2023-01-04",
      "2023-07-22",
      "2023-08-09",
      "2023-02-02",
      "2023-05-31",
      "2023-08-27",
      "2023-12-27",
      "2023-05-29",
      "2023-11-28",
      "2023-12-23",
      "2023-10-07",
      "2023-08-09",
      "2023-06-01",
      "2023-06-15",
      "2023-01-29",
      "2023-02-27",
      "2023-01-06",
      "2023-09-24",
      "2023-06-18",
      "2023-06-27",
      "2023-06-08",
      "2023-06-23",
      "2023-10-21",
      "2023-05-12",
      "2023-05-07",
      "2023-10-09",
      "2023-03-12",
      "2023-03-10",
      "2023-01-22",
      "2023-01-24",
      "2023-06-18",
      "2023-04-08",
      "2023-01-10",
      "2023-09-01",
      "2023-12-07",
      "2023-11-05",
      "2023-05-01",
      "2023-03-28",
      "2023-12-01",
      "2023-12-01",
      "2023-11-15",
      "2023-08-01",
      "2023-06-05",
      "2023-05-11",
      "2023-01-13",
      "2023-06-10",
      "2023-06-23",
      "2023-05-25",
      "2023-10-13",
      "2023-12-30",
      "2023-03-28",
      "2023-10-27",
      "2023-04-16",
      "2023-05-02",
      "2023-02-09",
      "2023-05-28",
      "2023-02-03",
      "2023-09-29",
      "2023-09-17",
      "2023-08-26",
      "2023-05-12",
      "2023-05-24",
      "2023-07-10",
      "2023-06-20",
      "2023-06-17",
      "2023-08-24",
      "2023-09-12",
      "2023-11-19",
      "2023-06-02",
      "2023-11-17",
      "2023-11-08",
      "2023-01-01",
      "2023-09-08",
      "2023-03-07",
      "2023-06-09",
      "2023-12-10",
      "2023-06-23",
      "2023-10-29",
      "2023-12-09",
      "2023-12-28",
      "2023-06-19",
      "2023-10-27",
      "2023-09-02",
      "2023-06-02",
      "2023-04-23",
      "2023-08-12",
      "2023-10-01",
      "2023-10-16",
      "2023-03-11",
      "2023-08-29",
      "2023-12-09",
      "2023-06-09",
      "2023-10-31",
      "2023-12-05",
      "2023-08-26",
      "2023-06-24",
      "2023-09-22",
      "2023-11-17",
      "2023-10-05",
      "2023-12-22",
      "2023-03-29",
      "2023-03-06",
      "2023-07-29",
      "2023-06-18",
      "2023-09-12",
      "2023-10-21",
      "2023-07-27",
      "2023-10-29",
      "2023-09-26",
      "2023-07-22",
      "2023-06-28",
      "2023-04-12",
      "2023-06-08",
      "2023-07-04",
      "2023-06-26",
      "2023-04-04",
      "2023-09-23",
      "2023-06-05",
      "2023-09-15",
      "2023-03-29",
      "2023-09-05",
      "2023-09-05",
      "2023-09-21",
      "2023-04-09",
      "2023-08-23",
      "2023-06-01",
      "2023-08-07",
      "2023-05-14",
      "2023-12-01",
      "2023-08-24",
      "2023-08-11",
      "2023-09-28",
      "2023-06-03",
      "2023-09-14"
    ],
    "datasets": [
      {
        "label": "Script_Count",
        "data": [
          "6499",
          "5914",
          "4486",
          "3690",
          "6247",
          "4023",
          "8914",
          "5294",
          "4938",
          "4507",
          "7172",
          "5116",
          "10543",
          "6740",
          "7218",
          "5168",
          "6561",
          "8021",
          "6185",
          "6623",
          "4229",
          "4883",
          "6642",
          "6241",
          "8206",
          "5892",
          "5864",
          "7098",
          "7941",
          "6482",
          "5990",
          "5203",
          "9132",
          "3302",
          "5579",
          "6334",
          "5764",
          "7669",
          "8013",
          "5527",
          "8040",
          "7040",
          "5148",
          "6083",
          "5736",
          "5318",
          "4445",
          "5100",
          "3355",
          "6430",
          "6202",
          "5564",
          "6285",
          "7334",
          "5928",
          "6986",
          "7768",
          "5383",
          "6802",
          "6837",
          "5635",
          "7176",
          "3984",
          "6316",
          "8106",
          "7390",
          "4571",
          "6219",
          "4691",
          "3285",
          "6527",
          "7418",
          "6448",
          "7259",
          "7455",
          "6615",
          "6616",
          "7857",
          "7364",
          "3698",
          "5206",
          "4821",
          "6717",
          "7626",
          "6933",
          "3888",
          "7261",
          "7627",
          "7053",
          "5411",
          "6130",
          "8248",
          "7114",
          "6700",
          "6487",
          "6830",
          "5327",
          "4311",
          "6126",
          "4918",
          "6507",
          "6515",
          "7468",
          "3816",
          "5389",
          "7229",
          "6111",
          "4371",
          "5158",
          "4645",
          "3684",
          "3327",
          "4542",
          "4222",
          "6119",
          "6187",
          "5726",
          "5946",
          "5240",
          "7004",
          "4213",
          "4217",
          "4847",
          "5992",
          "5090",
          "5724",
          "5131",
          "5225",
          "5735",
          "4987",
          "1739",
          "3948",
          "5413",
          "3686",
          "8052",
          "6348",
          "3844",
          "6108",
          "5492",
          "5232",
          "6051",
          "7745",
          "6213",
          "4676",
          "7202",
          "6088",
          "3740",
          "5145",
          "6923",
          "5222",
          "5220",
          "4252",
          "5432",
          "8556",
          "6410",
          "5694",
          "5318",
          "6194",
          "5713",
          "6255",
          "6675",
          "4919",
          "5132",
          "4885",
          "5308",
          "4966",
          "4930",
          "5894",
          "6424",
          "5284",
          "8572",
          "4328",
          "7838",
          "6897",
          "5517",
          "5068",
          "5681",
          "4736",
          "4850",
          "7063",
          "8790",
          "6780",
          "5090",
          "7454",
          "6223",
          "3006",
          "5813",
          "5942",
          "5204",
          "6168",
          "3301",
          "6179",
          "5377",
          "5641",
          "5873",
          "6807",
          "4132",
          "8075",
          "6292",
          "7833",
          "6314",
          "4034",
          "4625",
          "7011",
          "5791",
          "9793",
          "3322",
          "5998",
          "4870",
          "4568",
          "6823",
          "7334",
          "5569",
          "5633",
          "6480",
          "5616",
          "5408",
          "6378",
          "4154",
          "5823",
          "6070",
          "5038",
          "6149",
          "4494",
          "6024",
          "6369",
          "6805",
          "3319",
          "5800",
          "7451",
          "4906",
          "6738",
          "3687",
          "8437",
          "6139",
          "6407",
          "5135",
          "6849",
          "6447",
          "6662",
          "4648",
          "4273",
          "4266",
          "4858",
          "6003",
          "4590",
          "4162",
          "4184",
          "5649",
          "5603",
          "5485",
          "4759",
          "5779",
          "8928",
          "7073",
          "6359",
          "5377",
          "6341",
          "5794",
          "4353",
          "5432",
          "5154",
          "3393",
          "5691",
          "8467",
          "4656",
          "5065",
          "7947",
          "4298",
          "5553",
          "4795",
          "5110",
          "6641",
          "3952",
          "6993",
          "5730",
          "7609",
          "3776",
          "5727",
          "2522",
          "5578",
          "5005",
          "4226",
          "3869",
          "4618",
          "4250",
          "3994",
          "6428",
          "4626",
          "3059",
          "3778",
          "6941",
          "6934",
          "6001",
          "5660",
          "6283",
          "4825",
          "4220",
          "4350",
          "5168",
          "4168",
          "3532",
          "3345",
          "5021",
          "3086",
          "5037",
          "3519",
          "3583",
          "7721",
          "4348",
          "7426",
          "7517",
          "3673",
          "3080",
          "4634",
          "7858",
          "6536",
          "4357",
          "4598",
          "4319",
          "9157",
          "3738",
          "6402",
          "8820",
          "6282",
          "5739",
          "9183",
          "4203",
          "3843",
          "8111",
          "7026",
          "4157",
          "5568",
          "5338",
          "6679",
          "4781",
          "4776",
          "7010",
          "5406",
          "7123",
          "3764",
          "6294",
          "6343",
          "3869",
          "6263",
          "5741",
          "6844",
          "5726",
          "3657",
          "4511",
          "6380",
          "5438",
          "6470",
          "3155",
          "4706",
          "3914",
          "5039",
          "4195",
          "4834",
          "4814",
          "9578",
          "5910",
          "6433",
          "4807",
          "8416",
          "4857",
          "6594",
          "5631",
          "5837",
          "4889",
          "6025",
          "5340",
          "5492",
          "5747",
          "4335",
          "5683",
          "4576",
          "5424",
          "4395",
          "8453",
          "6601",
          "5129",
          "3799",
          "4020",
          "6628",
          "5297",
          "5030",
          "6906",
          "5145",
          "5510",
          "6714",
          "5719",
          "7119",
          "4420",
          "5762",
          "7871",
          "5424",
          "4916",
          "5871",
          "8134",
          "4638",
          "7841",
          "6347",
          "6627",
          "5581",
          "5197",
          "6438",
          "6957",
          "7767",
          "5043",
          "6596",
          "5494",
          "5852",
          "7654",
          "4286",
          "4097",
          "4396",
          "4737",
          "4114",
          "3753",
          "6442",
          "3562",
          "4998",
          "6222",
          "7599",
          "4623",
          "3721",
          "5956",
          "6191",
          "6531",
          "4633",
          "7379",
          "6951",
          "5678",
          "7028",
          "6647",
          "3130",
          "6175",
          "4430",
          "5062",
          "7391",
          "5835",
          "7433",
          "5480",
          "5560",
          "3691",
          "4598",
          "4281",
          "4397",
          "3934",
          "3106",
          "3279",
          "6303",
          "6267",
          "5667",
          "5614",
          "7895",
          "4631",
          "4151",
          "4886",
          "5677",
          "6125",
          "5319",
          "5286",
          "2615",
          "5394",
          "5861",
          "2599",
          "5190",
          "3595",
          "3149",
          "3220",
          "6074",
          "5484",
          "4546",
          "5095",
          "6422",
          "6257",
          "4155",
          "4726",
          "4931",
          "7526",
          "4243",
          "5580",
          "5672",
          "1935",
          "6944",
          "4456",
          "5830",
          "3283",
          "5791",
          "6270",
          "6241",
          "3097",
          "3946",
          "3278",
          "6159",
          "5685",
          "6374",
          "3898",
          "3544",
          "3055",
          "3017",
          "6767",
          "4445",
          "5250",
          "5845",
          "6427",
          "5638",
          "9283",
          "5763",
          "7470",
          "5080",
          "7984",
          "4239",
          "3948",
          "6249",
          "5180",
          "5091",
          "4745",
          "5900",
          "6026",
          "6212",
          "3653",
          "4461",
          "5967",
          "3875",
          "6924",
          "6295",
          "5206",
          "6487",
          "6621",
          "5745",
          "4046",
          "6801",
          "5939",
          "5015",
          "5471",
          "4390",
          "5760",
          "7728",
          "4505",
          "4268",
          "5274",
          "6341",
          "6303",
          "6223",
          "6787",
          "4788",
          "4302",
          "5277",
          "4552",
          "6852",
          "8411",
          "4309",
          "6573",
          "4823",
          "2797",
          "3103",
          "4745",
          "4438",
          "11177",
          "7291",
          "5348",
          "4263",
          "6041",
          "4463",
          "6428",
          "5473",
          "8411",
          "3644",
          "5663",
          "5543",
          "5040",
          "5831",
          "3968",
          "3395",
          "7504",
          "5757",
          "3648",
          "4485",
          "6995",
          "3954",
          "4781",
          "5102",
          "4778",
          "5754",
          "5945",
          "5850",
          "5299",
          "3427",
          "5620",
          "5643",
          "6696",
          "5535",
          "5948",
          "5027",
          "6464",
          "3354",
          "6059",
          "5709",
          "8312",
          "8159",
          "7086",
          "7627",
          "5709",
          "5893",
          "4615",
          "7212",
          "6457",
          "5659",
          "4881",
          "5031",
          "4370",
          "4176",
          "3058",
          "6570",
          "5597",
          "4437",
          "5135",
          "5348",
          "5996",
          "3867",
          "4570",
          "6416",
          "5123",
          "7461",
          "1872",
          "7857",
          "5199",
          "3690",
          "4738",
          "6634",
          "6324",
          "3385",
          "4828",
          "5355",
          "5160",
          "4630",
          "4609",
          "4827",
          "6617",
          "6277",
          "6523",
          "6094",
          "6872",
          "2646",
          "7677",
          "5585",
          "6044",
          "4975",
          "6112",
          "4660",
          "7745",
          "5776",
          "7083",
          "4822",
          "4044",
          "3706",
          "6260",
          "6559",
          "6136",
          "5135",
          "5212",
          "6063",
          "6597",
          "7390",
          "5854",
          "5426",
          "6609",
          "6783",
          "5067",
          "6161",
          "6009",
          "5363",
          "4421",
          "7666",
          "8409",
          "3754",
          "7505",
          "5846",
          "7905",
          "4624",
          "3641",
          "5750",
          "3671",
          "5809",
          "6764",
          "8846",
          "6230",
          "7239",
          "6766",
          "4128",
          "5529",
          "4538",
          "3179",
          "3672",
          "6842",
          "5385",
          "5597",
          "3462",
          "6897",
          "5313",
          "4288",
          "6180",
          "6723",
          "2614",
          "6288",
          "3963",
          "3304",
          "4899",
          "5981",
          "4290",
          "5534",
          "5704",
          "5724",
          "8343",
          "6343",
          "4602",
          "5705",
          "4776",
          "5787",
          "5494",
          "3711",
          "6619",
          "6955",
          "6934",
          "6456",
          "4780",
          "7069",
          "6113",
          "4228",
          "4741",
          "4302",
          "5286",
          "5171",
          "7527",
          "5982",
          "5018",
          "2662",
          "7454",
          "4471",
          "4454",
          "5979",
          "5125",
          "3767",
          "6503",
          "5942",
          "4813",
          "3485",
          "6809",
          "5090",
          "4157",
          "4475",
          "6163",
          "5450",
          "4222",
          "4328",
          "8900",
          "8170",
          "7380",
          "5399",
          "6702",
          "5058",
          "5472",
          "5594",
          "4896",
          "5435",
          "7226",
          "4749",
          "8728",
          "6685",
          "6266",
          "4975",
          "6971",
          "4034",
          "4250",
          "5453",
          "6016",
          "7152",
          "6665",
          "4539",
          "8159",
          "4834",
          "6178",
          "7563",
          "4938",
          "4683",
          "4153",
          "5843",
          "3417",
          "4302",
          "2902",
          "4012",
          "3923",
          "5236",
          "4001",
          "6104",
          "6647",
          "10311",
          "3155",
          "3783",
          "6706",
          "2287",
          "4713",
          "4360",
          "4803",
          "4074",
          "5686",
          "5566",
          "4245",
          "5300",
          "3442",
          "4061",
          "3987",
          "4100",
          "3466",
          "4335",
          "4830",
          "5662",
          "6466",
          "3728",
          "3397",
          "5578",
          "5156",
          "5042",
          "4783",
          "5811",
          "5118",
          "4689",
          "5882",
          "5430",
          "3908",
          "6741",
          "7561",
          "5754",
          "5415",
          "4447",
          "3976",
          "5031",
          "4048",
          "3755",
          "5015",
          "3220",
          "5389",
          "5692",
          "5088",
          "5508",
          "6357",
          "5888",
          "4716",
          "4958",
          "3223",
          "5229",
          "4683",
          "5933",
          "4970",
          "3177",
          "2961",
          "5635",
          "3975",
          "4684",
          "5204",
          "3880",
          "4915",
          "6373",
          "5089",
          "4041",
          "4669",
          "5154",
          "2529",
          "6265",
          "4330",
          "7522",
          "6009",
          "4466",
          "4884",
          "5134",
          "4580",
          "5747",
          "5502",
          "5558",
          "5114",
          "6367",
          "3877",
          "5364",
          "6784",
          "5818",
          "4657",
          "4111",
          "6014",
          "4862",
          "5489",
          "6326",
          "3599",
          "5059",
          "8164",
          "4798",
          "5251",
          "4475",
          "3326",
          "4938",
          "4283",
          "6138",
          "5208",
          "6266",
          "4658",
          "5664",
          "5434",
          "5844",
          "7391",
          "4397",
          "4960",
          "5727",
          "5163",
          "7576",
          "5761",
          "5689",
          "2472",
          "5259",
          "3929",
          "4928",
          "7053",
          "5908",
          "3195",
          "4054",
          "4344",
          "5199",
          "5331",
          "3847",
          "5890",
          "3357",
          "5988",
          "8064",
          "4826",
          "5588",
          "5273",
          "5100",
          "6982",
          "3191",
          "6111",
          "5259",
          "3360",
          "4507",
          "4795",
          "6158",
          "5262",
          "8707",
          "2970",
          "6071",
          "5389",
          "6607",
          "5518",
          "6794",
          "5666",
          "7511",
          "5888",
          "5272",
          "4930",
          "7379",
          "4681",
          "5512",
          "4691",
          "6443",
          "6031",
          "8534",
          "4514",
          "5114",
          "7845",
          "5765",
          "4995",
          "5786",
          "5149",
          "6471",
          "4847",
          "4690",
          "4933",
          "6820",
          "9173",
          "7073",
          "5710",
          "5651",
          "4812",
          "4985",
          "7595",
          "7013",
          "3126",
          "4166",
          "3656",
          "4799",
          "4806",
          "6763",
          "4271",
          "4978",
          "6128",
          "4796",
          "6654",
          "6625",
          "3981"
        ],
        "backgroundColor": [
          "#003f5c",
          "#2f4b7c",
          "#665191",
          "#a05195",
          "#d45087",
          "#f95d6a",
          "#ff7c43",
          "#ffa600"
        ]
      }
    ]
  }
  const options2 = {
    "responsive": true,
    "color": "#ffffff",
    "scales": {
      "x": {
        "grid": {
          "color": "#ffffff"
        },
        "ticks": {
          "color": "#ffffff"
        },
        "position": "bottom",
        "title": {
          "display": true,
          "text": "Date",
          "color": "#ffffff"
        }
      },
      "y": {
        "grid": {
          "color": "#ffffff"
        },
        "ticks": {
          "color": "#ffffff"
        },
        "position": "left",
        "title": {
          "display": true,
          "text": "Script_Count",
          "color": "#ffffff"
        }
      }
    },
    "plugins": {
      "legend": {
        "position": "bottom"
      }
    }
  }

  const parsedDataSaved2 = [
    {
      "Category": "Product Potency",
      "Date": "2023-12-16",
      "Script_Count": "6499"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-17",
      "Script_Count": "5914"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-11",
      "Script_Count": "4486"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-04",
      "Script_Count": "3690"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-30",
      "Script_Count": "6247"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-18",
      "Script_Count": "4023"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-26",
      "Script_Count": "8914"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-07",
      "Script_Count": "5294"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-04",
      "Script_Count": "4938"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-28",
      "Script_Count": "4507"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-19",
      "Script_Count": "7172"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-23",
      "Script_Count": "5116"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-03",
      "Script_Count": "10543"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-19",
      "Script_Count": "6740"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-26",
      "Script_Count": "7218"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-21",
      "Script_Count": "5168"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-16",
      "Script_Count": "6561"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-06",
      "Script_Count": "8021"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-07",
      "Script_Count": "6185"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-09",
      "Script_Count": "6623"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-23",
      "Script_Count": "4229"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-30",
      "Script_Count": "4883"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-07",
      "Script_Count": "6642"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-12",
      "Script_Count": "6241"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-31",
      "Script_Count": "8206"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-19",
      "Script_Count": "5892"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-01",
      "Script_Count": "5864"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-08",
      "Script_Count": "7098"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-13",
      "Script_Count": "7941"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-01",
      "Script_Count": "6482"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-11",
      "Script_Count": "5990"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-31",
      "Script_Count": "5203"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-06",
      "Script_Count": "9132"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-30",
      "Script_Count": "3302"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-17",
      "Script_Count": "5579"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-21",
      "Script_Count": "6334"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-08",
      "Script_Count": "5764"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-21",
      "Script_Count": "7669"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-06",
      "Script_Count": "8013"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-10",
      "Script_Count": "5527"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-09",
      "Script_Count": "8040"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-19",
      "Script_Count": "7040"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-16",
      "Script_Count": "5148"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-05",
      "Script_Count": "6083"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-16",
      "Script_Count": "5736"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-19",
      "Script_Count": "5318"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-30",
      "Script_Count": "4445"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-05",
      "Script_Count": "5100"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-13",
      "Script_Count": "3355"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-10",
      "Script_Count": "6430"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-10",
      "Script_Count": "6202"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-10",
      "Script_Count": "5564"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-29",
      "Script_Count": "6285"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-10",
      "Script_Count": "7334"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-30",
      "Script_Count": "5928"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-11",
      "Script_Count": "6986"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-06",
      "Script_Count": "7768"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-11",
      "Script_Count": "5383"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-16",
      "Script_Count": "6802"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-26",
      "Script_Count": "6837"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-10",
      "Script_Count": "5635"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-06",
      "Script_Count": "7176"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-05",
      "Script_Count": "3984"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-08",
      "Script_Count": "6316"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-01",
      "Script_Count": "8106"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-06",
      "Script_Count": "7390"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-03",
      "Script_Count": "4571"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-14",
      "Script_Count": "6219"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-01",
      "Script_Count": "4691"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-17",
      "Script_Count": "3285"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-21",
      "Script_Count": "6527"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-19",
      "Script_Count": "7418"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-19",
      "Script_Count": "6448"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-13",
      "Script_Count": "7259"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-28",
      "Script_Count": "7455"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-01",
      "Script_Count": "6615"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-27",
      "Script_Count": "6616"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-01",
      "Script_Count": "7857"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-09",
      "Script_Count": "7364"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-19",
      "Script_Count": "3698"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-02",
      "Script_Count": "5206"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-07",
      "Script_Count": "4821"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-23",
      "Script_Count": "6717"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-09",
      "Script_Count": "7626"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-16",
      "Script_Count": "6933"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-30",
      "Script_Count": "3888"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-27",
      "Script_Count": "7261"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-18",
      "Script_Count": "7627"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-26",
      "Script_Count": "7053"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-21",
      "Script_Count": "5411"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-17",
      "Script_Count": "6130"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-22",
      "Script_Count": "8248"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-11",
      "Script_Count": "7114"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-23",
      "Script_Count": "6700"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-14",
      "Script_Count": "6487"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-20",
      "Script_Count": "6830"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-09",
      "Script_Count": "5327"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-08",
      "Script_Count": "4311"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-07",
      "Script_Count": "6126"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-25",
      "Script_Count": "4918"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-18",
      "Script_Count": "6507"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-03",
      "Script_Count": "6515"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-16",
      "Script_Count": "7468"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-06",
      "Script_Count": "3816"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-22",
      "Script_Count": "5389"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-23",
      "Script_Count": "7229"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-15",
      "Script_Count": "6111"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-09",
      "Script_Count": "4371"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-27",
      "Script_Count": "5158"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-15",
      "Script_Count": "4645"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-04",
      "Script_Count": "3684"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-13",
      "Script_Count": "3327"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-10",
      "Script_Count": "4542"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-27",
      "Script_Count": "4222"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-22",
      "Script_Count": "6119"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-15",
      "Script_Count": "6187"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-01",
      "Script_Count": "5726"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-30",
      "Script_Count": "5946"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-31",
      "Script_Count": "5240"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-10",
      "Script_Count": "7004"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-13",
      "Script_Count": "4213"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-15",
      "Script_Count": "4217"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-08",
      "Script_Count": "4847"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-12",
      "Script_Count": "5992"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-27",
      "Script_Count": "5090"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-13",
      "Script_Count": "5724"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-03",
      "Script_Count": "5131"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-22",
      "Script_Count": "5225"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-18",
      "Script_Count": "5735"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-21",
      "Script_Count": "4987"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-30",
      "Script_Count": "1739"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-18",
      "Script_Count": "3948"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-19",
      "Script_Count": "5413"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-01",
      "Script_Count": "3686"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-23",
      "Script_Count": "8052"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-11",
      "Script_Count": "6348"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-02",
      "Script_Count": "3844"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-04",
      "Script_Count": "6108"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-14",
      "Script_Count": "5492"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-05",
      "Script_Count": "5232"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-13",
      "Script_Count": "6051"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-20",
      "Script_Count": "7745"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-14",
      "Script_Count": "6213"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-13",
      "Script_Count": "4676"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-26",
      "Script_Count": "7202"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-17",
      "Script_Count": "6088"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-06",
      "Script_Count": "3740"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-01",
      "Script_Count": "5145"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-02",
      "Script_Count": "6923"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-11",
      "Script_Count": "5222"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-08",
      "Script_Count": "5220"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-01",
      "Script_Count": "4252"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-02",
      "Script_Count": "5432"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-12",
      "Script_Count": "8556"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-22",
      "Script_Count": "6410"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-18",
      "Script_Count": "5694"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-20",
      "Script_Count": "5318"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-16",
      "Script_Count": "6194"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-03",
      "Script_Count": "5713"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-28",
      "Script_Count": "6255"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-19",
      "Script_Count": "6675"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-09",
      "Script_Count": "4919"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-15",
      "Script_Count": "5132"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-15",
      "Script_Count": "4885"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-04",
      "Script_Count": "5308"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-28",
      "Script_Count": "4966"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-22",
      "Script_Count": "4930"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-05",
      "Script_Count": "5894"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-30",
      "Script_Count": "6424"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-16",
      "Script_Count": "5284"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-14",
      "Script_Count": "8572"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-31",
      "Script_Count": "4328"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-21",
      "Script_Count": "7838"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-12",
      "Script_Count": "6897"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-20",
      "Script_Count": "5517"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-19",
      "Script_Count": "5068"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-09",
      "Script_Count": "5681"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-17",
      "Script_Count": "4736"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-14",
      "Script_Count": "4850"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-02",
      "Script_Count": "7063"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-24",
      "Script_Count": "8790"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-09",
      "Script_Count": "6780"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-29",
      "Script_Count": "5090"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-15",
      "Script_Count": "7454"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-13",
      "Script_Count": "6223"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-31",
      "Script_Count": "3006"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-29",
      "Script_Count": "5813"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-18",
      "Script_Count": "5942"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-25",
      "Script_Count": "5204"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-15",
      "Script_Count": "6168"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-05",
      "Script_Count": "3301"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-03",
      "Script_Count": "6179"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-03",
      "Script_Count": "5377"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-08",
      "Script_Count": "5641"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-13",
      "Script_Count": "5873"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-18",
      "Script_Count": "6807"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-16",
      "Script_Count": "4132"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-06",
      "Script_Count": "8075"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-19",
      "Script_Count": "6292"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-24",
      "Script_Count": "7833"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-09",
      "Script_Count": "6314"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-13",
      "Script_Count": "4034"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-24",
      "Script_Count": "4625"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-13",
      "Script_Count": "7011"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-01",
      "Script_Count": "5791"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-28",
      "Script_Count": "9793"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-04",
      "Script_Count": "3322"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-03",
      "Script_Count": "5998"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-26",
      "Script_Count": "4870"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-15",
      "Script_Count": "4568"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-09",
      "Script_Count": "6823"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-24",
      "Script_Count": "7334"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-14",
      "Script_Count": "5569"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-19",
      "Script_Count": "5633"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-03",
      "Script_Count": "6480"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-30",
      "Script_Count": "5616"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-11",
      "Script_Count": "5408"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-29",
      "Script_Count": "6378"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-07",
      "Script_Count": "4154"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-10",
      "Script_Count": "5823"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-03",
      "Script_Count": "6070"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-05",
      "Script_Count": "5038"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-21",
      "Script_Count": "6149"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-22",
      "Script_Count": "4494"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-16",
      "Script_Count": "6024"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-03",
      "Script_Count": "6369"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-17",
      "Script_Count": "6805"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-08",
      "Script_Count": "3319"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-08",
      "Script_Count": "5800"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-04",
      "Script_Count": "7451"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-30",
      "Script_Count": "4906"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-03",
      "Script_Count": "6738"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-12",
      "Script_Count": "3687"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-18",
      "Script_Count": "8437"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-22",
      "Script_Count": "6139"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-09",
      "Script_Count": "6407"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-14",
      "Script_Count": "5135"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-18",
      "Script_Count": "6849"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-05",
      "Script_Count": "6447"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-09",
      "Script_Count": "6662"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-04",
      "Script_Count": "4648"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-06",
      "Script_Count": "4273"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-29",
      "Script_Count": "4266"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-06",
      "Script_Count": "4858"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-06",
      "Script_Count": "6003"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-11",
      "Script_Count": "4590"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-23",
      "Script_Count": "4162"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-25",
      "Script_Count": "4184"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-15",
      "Script_Count": "5649"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-11",
      "Script_Count": "5603"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-02",
      "Script_Count": "5485"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-13",
      "Script_Count": "4759"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-01",
      "Script_Count": "5779"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-28",
      "Script_Count": "8928"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-12",
      "Script_Count": "7073"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-05",
      "Script_Count": "6359"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-09",
      "Script_Count": "5377"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-19",
      "Script_Count": "6341"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-02",
      "Script_Count": "5794"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-03",
      "Script_Count": "4353"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-26",
      "Script_Count": "5432"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-01",
      "Script_Count": "5154"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-25",
      "Script_Count": "3393"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-26",
      "Script_Count": "5691"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-12",
      "Script_Count": "8467"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-06",
      "Script_Count": "4656"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-28",
      "Script_Count": "5065"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-24",
      "Script_Count": "7947"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-31",
      "Script_Count": "4298"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-10",
      "Script_Count": "5553"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-21",
      "Script_Count": "4795"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-15",
      "Script_Count": "5110"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-08",
      "Script_Count": "6641"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-24",
      "Script_Count": "3952"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-26",
      "Script_Count": "6993"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-15",
      "Script_Count": "5730"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-25",
      "Script_Count": "7609"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-13",
      "Script_Count": "3776"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-17",
      "Script_Count": "5727"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-02",
      "Script_Count": "2522"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-13",
      "Script_Count": "5578"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-09",
      "Script_Count": "5005"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-17",
      "Script_Count": "4226"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-11",
      "Script_Count": "3869"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-22",
      "Script_Count": "4618"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-18",
      "Script_Count": "4250"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-19",
      "Script_Count": "3994"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-09",
      "Script_Count": "6428"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-06",
      "Script_Count": "4626"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-12",
      "Script_Count": "3059"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-15",
      "Script_Count": "3778"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-05",
      "Script_Count": "6941"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-26",
      "Script_Count": "6934"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-21",
      "Script_Count": "6001"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-28",
      "Script_Count": "5660"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-17",
      "Script_Count": "6283"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-06",
      "Script_Count": "4825"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-17",
      "Script_Count": "4220"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-22",
      "Script_Count": "4350"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-28",
      "Script_Count": "5168"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-16",
      "Script_Count": "4168"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-19",
      "Script_Count": "3532"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-12",
      "Script_Count": "3345"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-22",
      "Script_Count": "5021"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-10",
      "Script_Count": "3086"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-11",
      "Script_Count": "5037"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-04",
      "Script_Count": "3519"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-27",
      "Script_Count": "3583"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-29",
      "Script_Count": "7721"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-23",
      "Script_Count": "4348"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-28",
      "Script_Count": "7426"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-07",
      "Script_Count": "7517"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-20",
      "Script_Count": "3673"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-01",
      "Script_Count": "3080"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-18",
      "Script_Count": "4634"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-06",
      "Script_Count": "7858"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-22",
      "Script_Count": "6536"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-16",
      "Script_Count": "4357"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-09",
      "Script_Count": "4598"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-31",
      "Script_Count": "4319"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-03",
      "Script_Count": "9157"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-14",
      "Script_Count": "3738"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-29",
      "Script_Count": "6402"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-28",
      "Script_Count": "8820"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-23",
      "Script_Count": "6282"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-15",
      "Script_Count": "5739"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-04",
      "Script_Count": "9183"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-28",
      "Script_Count": "4203"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-15",
      "Script_Count": "3843"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-25",
      "Script_Count": "8111"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-05",
      "Script_Count": "7026"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-27",
      "Script_Count": "4157"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-12",
      "Script_Count": "5568"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-31",
      "Script_Count": "5338"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-07",
      "Script_Count": "6679"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-18",
      "Script_Count": "4781"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-04",
      "Script_Count": "4776"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-19",
      "Script_Count": "7010"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-25",
      "Script_Count": "5406"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-30",
      "Script_Count": "7123"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-12",
      "Script_Count": "3764"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-28",
      "Script_Count": "6294"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-28",
      "Script_Count": "6343"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-30",
      "Script_Count": "3869"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-12",
      "Script_Count": "6263"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-21",
      "Script_Count": "5741"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-30",
      "Script_Count": "6844"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-07",
      "Script_Count": "5726"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-01",
      "Script_Count": "3657"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-18",
      "Script_Count": "4511"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-18",
      "Script_Count": "6380"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-11",
      "Script_Count": "5438"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-22",
      "Script_Count": "6470"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-06",
      "Script_Count": "3155"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-14",
      "Script_Count": "4706"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-04",
      "Script_Count": "3914"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-12",
      "Script_Count": "5039"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-06",
      "Script_Count": "4195"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-02",
      "Script_Count": "4834"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-15",
      "Script_Count": "4814"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-22",
      "Script_Count": "9578"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-31",
      "Script_Count": "5910"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-21",
      "Script_Count": "6433"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-10",
      "Script_Count": "4807"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-17",
      "Script_Count": "8416"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-05",
      "Script_Count": "4857"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-19",
      "Script_Count": "6594"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-06",
      "Script_Count": "5631"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-20",
      "Script_Count": "5837"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-02",
      "Script_Count": "4889"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-27",
      "Script_Count": "6025"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-26",
      "Script_Count": "5340"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-17",
      "Script_Count": "5492"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-11",
      "Script_Count": "5747"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-22",
      "Script_Count": "4335"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-06",
      "Script_Count": "5683"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-24",
      "Script_Count": "4576"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-05",
      "Script_Count": "5424"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-15",
      "Script_Count": "4395"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-02",
      "Script_Count": "8453"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-05",
      "Script_Count": "6601"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-25",
      "Script_Count": "5129"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-20",
      "Script_Count": "3799"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-18",
      "Script_Count": "4020"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-28",
      "Script_Count": "6628"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-09",
      "Script_Count": "5297"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-04",
      "Script_Count": "5030"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-22",
      "Script_Count": "6906"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-03",
      "Script_Count": "5145"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-16",
      "Script_Count": "5510"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-26",
      "Script_Count": "6714"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-02",
      "Script_Count": "5719"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-10",
      "Script_Count": "7119"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-17",
      "Script_Count": "4420"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-20",
      "Script_Count": "5762"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-31",
      "Script_Count": "7871"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-11",
      "Script_Count": "5424"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-14",
      "Script_Count": "4916"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-13",
      "Script_Count": "5871"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-03",
      "Script_Count": "8134"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-27",
      "Script_Count": "4638"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-26",
      "Script_Count": "7841"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-10",
      "Script_Count": "6347"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-18",
      "Script_Count": "6627"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-12",
      "Script_Count": "5581"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-16",
      "Script_Count": "5197"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-01",
      "Script_Count": "6438"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-25",
      "Script_Count": "6957"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-17",
      "Script_Count": "7767"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-05",
      "Script_Count": "5043"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-01",
      "Script_Count": "6596"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-11",
      "Script_Count": "5494"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-07",
      "Script_Count": "5852"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-18",
      "Script_Count": "7654"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-15",
      "Script_Count": "4286"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-16",
      "Script_Count": "4097"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-02",
      "Script_Count": "4396"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-14",
      "Script_Count": "4737"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-11",
      "Script_Count": "4114"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-05",
      "Script_Count": "3753"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-01",
      "Script_Count": "6442"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-22",
      "Script_Count": "3562"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-08",
      "Script_Count": "4998"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-03",
      "Script_Count": "6222"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-12",
      "Script_Count": "7599"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-19",
      "Script_Count": "4623"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-19",
      "Script_Count": "3721"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-20",
      "Script_Count": "5956"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-04",
      "Script_Count": "6191"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-30",
      "Script_Count": "6531"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-09",
      "Script_Count": "4633"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-11",
      "Script_Count": "7379"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-07",
      "Script_Count": "6951"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-30",
      "Script_Count": "5678"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-15",
      "Script_Count": "7028"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-03",
      "Script_Count": "6647"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-17",
      "Script_Count": "3130"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-16",
      "Script_Count": "6175"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-14",
      "Script_Count": "4430"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-26",
      "Script_Count": "5062"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-26",
      "Script_Count": "7391"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-16",
      "Script_Count": "5835"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-02",
      "Script_Count": "7433"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-22",
      "Script_Count": "5480"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-17",
      "Script_Count": "5560"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-02",
      "Script_Count": "3691"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-29",
      "Script_Count": "4598"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-10",
      "Script_Count": "4281"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-17",
      "Script_Count": "4397"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-03",
      "Script_Count": "3934"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-16",
      "Script_Count": "3106"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-05",
      "Script_Count": "3279"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-04",
      "Script_Count": "6303"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-09",
      "Script_Count": "6267"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-25",
      "Script_Count": "5667"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-16",
      "Script_Count": "5614"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-02",
      "Script_Count": "7895"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-04",
      "Script_Count": "4631"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-12",
      "Script_Count": "4151"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-27",
      "Script_Count": "4886"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-27",
      "Script_Count": "5677"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-25",
      "Script_Count": "6125"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-14",
      "Script_Count": "5319"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-04",
      "Script_Count": "5286"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-25",
      "Script_Count": "2615"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-12",
      "Script_Count": "5394"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-06",
      "Script_Count": "5861"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-27",
      "Script_Count": "2599"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-30",
      "Script_Count": "5190"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-20",
      "Script_Count": "3595"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-27",
      "Script_Count": "3149"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-30",
      "Script_Count": "3220"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-13",
      "Script_Count": "6074"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-06",
      "Script_Count": "5484"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-30",
      "Script_Count": "4546"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-08",
      "Script_Count": "5095"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-14",
      "Script_Count": "6422"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-25",
      "Script_Count": "6257"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-11",
      "Script_Count": "4155"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-06",
      "Script_Count": "4726"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-29",
      "Script_Count": "4931"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-08",
      "Script_Count": "7526"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-07",
      "Script_Count": "4243"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-27",
      "Script_Count": "5580"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-21",
      "Script_Count": "5672"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-14",
      "Script_Count": "1935"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-24",
      "Script_Count": "6944"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-11",
      "Script_Count": "4456"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-13",
      "Script_Count": "5830"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-30",
      "Script_Count": "3283"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-28",
      "Script_Count": "5791"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-20",
      "Script_Count": "6270"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-29",
      "Script_Count": "6241"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-02",
      "Script_Count": "3097"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-01",
      "Script_Count": "3946"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-04",
      "Script_Count": "3278"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-07",
      "Script_Count": "6159"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-23",
      "Script_Count": "5685"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-12",
      "Script_Count": "6374"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-26",
      "Script_Count": "3898"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-19",
      "Script_Count": "3544"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-23",
      "Script_Count": "3055"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-10",
      "Script_Count": "3017"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-04",
      "Script_Count": "6767"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-30",
      "Script_Count": "4445"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-20",
      "Script_Count": "5250"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-28",
      "Script_Count": "5845"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-24",
      "Script_Count": "6427"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-13",
      "Script_Count": "5638"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-03",
      "Script_Count": "9283"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-22",
      "Script_Count": "5763"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-25",
      "Script_Count": "7470"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-15",
      "Script_Count": "5080"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-14",
      "Script_Count": "7984"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-09",
      "Script_Count": "4239"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-22",
      "Script_Count": "3948"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-16",
      "Script_Count": "6249"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-07",
      "Script_Count": "5180"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-02",
      "Script_Count": "5091"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-12",
      "Script_Count": "4745"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-13",
      "Script_Count": "5900"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-27",
      "Script_Count": "6026"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-16",
      "Script_Count": "6212"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-16",
      "Script_Count": "3653"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-10",
      "Script_Count": "4461"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-14",
      "Script_Count": "5967"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-12",
      "Script_Count": "3875"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-14",
      "Script_Count": "6924"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-22",
      "Script_Count": "6295"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-09",
      "Script_Count": "5206"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-29",
      "Script_Count": "6487"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-13",
      "Script_Count": "6621"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-20",
      "Script_Count": "5745"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-15",
      "Script_Count": "4046"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-23",
      "Script_Count": "6801"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-20",
      "Script_Count": "5939"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-21",
      "Script_Count": "5015"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-01",
      "Script_Count": "5471"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-02",
      "Script_Count": "4390"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-26",
      "Script_Count": "5760"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-15",
      "Script_Count": "7728"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-21",
      "Script_Count": "4505"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-04",
      "Script_Count": "4268"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-08",
      "Script_Count": "5274"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-27",
      "Script_Count": "6341"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-19",
      "Script_Count": "6303"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-10",
      "Script_Count": "6223"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-29",
      "Script_Count": "6787"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-11",
      "Script_Count": "4788"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-21",
      "Script_Count": "4302"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-29",
      "Script_Count": "5277"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-07",
      "Script_Count": "4552"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-22",
      "Script_Count": "6852"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-07",
      "Script_Count": "8411"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-10",
      "Script_Count": "4309"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-26",
      "Script_Count": "6573"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-21",
      "Script_Count": "4823"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-12",
      "Script_Count": "2797"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-02",
      "Script_Count": "3103"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-26",
      "Script_Count": "4745"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-11",
      "Script_Count": "4438"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-05",
      "Script_Count": "11177"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-17",
      "Script_Count": "7291"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-23",
      "Script_Count": "5348"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-26",
      "Script_Count": "4263"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-06",
      "Script_Count": "6041"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-09",
      "Script_Count": "4463"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-31",
      "Script_Count": "6428"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-16",
      "Script_Count": "5473"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-13",
      "Script_Count": "8411"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-04",
      "Script_Count": "3644"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-21",
      "Script_Count": "5663"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-27",
      "Script_Count": "5543"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-27",
      "Script_Count": "5040"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-20",
      "Script_Count": "5831"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-26",
      "Script_Count": "3968"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-13",
      "Script_Count": "3395"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-18",
      "Script_Count": "7504"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-30",
      "Script_Count": "5757"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-20",
      "Script_Count": "3648"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-02",
      "Script_Count": "4485"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-08",
      "Script_Count": "6995"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-23",
      "Script_Count": "3954"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-23",
      "Script_Count": "4781"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-02",
      "Script_Count": "5102"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-16",
      "Script_Count": "4778"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-18",
      "Script_Count": "5754"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-18",
      "Script_Count": "5945"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-08",
      "Script_Count": "5850"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-30",
      "Script_Count": "5299"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-14",
      "Script_Count": "3427"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-06",
      "Script_Count": "5620"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-23",
      "Script_Count": "5643"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-26",
      "Script_Count": "6696"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-26",
      "Script_Count": "5535"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-28",
      "Script_Count": "5948"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-19",
      "Script_Count": "5027"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-26",
      "Script_Count": "6464"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-25",
      "Script_Count": "3354"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-24",
      "Script_Count": "6059"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-25",
      "Script_Count": "5709"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-23",
      "Script_Count": "8312"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-19",
      "Script_Count": "8159"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-15",
      "Script_Count": "7086"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-30",
      "Script_Count": "7627"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-29",
      "Script_Count": "5709"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-21",
      "Script_Count": "5893"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-25",
      "Script_Count": "4615"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-18",
      "Script_Count": "7212"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-11",
      "Script_Count": "6457"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-16",
      "Script_Count": "5659"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-28",
      "Script_Count": "4881"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-19",
      "Script_Count": "5031"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-21",
      "Script_Count": "4370"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-04",
      "Script_Count": "4176"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-26",
      "Script_Count": "3058"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-16",
      "Script_Count": "6570"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-30",
      "Script_Count": "5597"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-04",
      "Script_Count": "4437"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-14",
      "Script_Count": "5135"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-25",
      "Script_Count": "5348"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-24",
      "Script_Count": "5996"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-29",
      "Script_Count": "3867"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-20",
      "Script_Count": "4570"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-07",
      "Script_Count": "6416"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-23",
      "Script_Count": "5123"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-02",
      "Script_Count": "7461"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-30",
      "Script_Count": "1872"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-10",
      "Script_Count": "7857"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-27",
      "Script_Count": "5199"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-06",
      "Script_Count": "3690"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-08",
      "Script_Count": "4738"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-29",
      "Script_Count": "6634"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-08",
      "Script_Count": "6324"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-11",
      "Script_Count": "3385"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-04",
      "Script_Count": "4828"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-11",
      "Script_Count": "5355"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-06",
      "Script_Count": "5160"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-15",
      "Script_Count": "4630"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-05",
      "Script_Count": "4609"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-23",
      "Script_Count": "4827"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-14",
      "Script_Count": "6617"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-22",
      "Script_Count": "6277"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-04",
      "Script_Count": "6523"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-29",
      "Script_Count": "6094"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-22",
      "Script_Count": "6872"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-25",
      "Script_Count": "2646"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-03",
      "Script_Count": "7677"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-09",
      "Script_Count": "5585"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-30",
      "Script_Count": "6044"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-31",
      "Script_Count": "4975"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-01",
      "Script_Count": "6112"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-17",
      "Script_Count": "4660"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-27",
      "Script_Count": "7745"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-16",
      "Script_Count": "5776"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-13",
      "Script_Count": "7083"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-17",
      "Script_Count": "4822"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-11",
      "Script_Count": "4044"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-15",
      "Script_Count": "3706"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-13",
      "Script_Count": "6260"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-02",
      "Script_Count": "6559"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-01",
      "Script_Count": "6136"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-17",
      "Script_Count": "5135"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-09",
      "Script_Count": "5212"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-10",
      "Script_Count": "6063"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-27",
      "Script_Count": "6597"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-18",
      "Script_Count": "7390"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-25",
      "Script_Count": "5854"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-19",
      "Script_Count": "5426"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-04",
      "Script_Count": "6609"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-14",
      "Script_Count": "6783"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-20",
      "Script_Count": "5067"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-21",
      "Script_Count": "6161"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-19",
      "Script_Count": "6009"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-02",
      "Script_Count": "5363"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-26",
      "Script_Count": "4421"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-08",
      "Script_Count": "7666"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-04",
      "Script_Count": "8409"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-28",
      "Script_Count": "3754"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-11",
      "Script_Count": "7505"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-07",
      "Script_Count": "5846"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-10",
      "Script_Count": "7905"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-03",
      "Script_Count": "4624"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-12",
      "Script_Count": "3641"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-05",
      "Script_Count": "5750"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-01",
      "Script_Count": "3671"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-13",
      "Script_Count": "5809"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-04",
      "Script_Count": "6764"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-13",
      "Script_Count": "8846"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-14",
      "Script_Count": "6230"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-07",
      "Script_Count": "7239"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-25",
      "Script_Count": "6766"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-10",
      "Script_Count": "4128"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-07",
      "Script_Count": "5529"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-14",
      "Script_Count": "4538"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-13",
      "Script_Count": "3179"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-08",
      "Script_Count": "3672"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-14",
      "Script_Count": "6842"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-24",
      "Script_Count": "5385"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-23",
      "Script_Count": "5597"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-25",
      "Script_Count": "3462"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-20",
      "Script_Count": "6897"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-03",
      "Script_Count": "5313"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-21",
      "Script_Count": "4288"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-04",
      "Script_Count": "6180"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-08",
      "Script_Count": "6723"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-05",
      "Script_Count": "2614"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-03",
      "Script_Count": "6288"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-29",
      "Script_Count": "3963"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-04",
      "Script_Count": "3304"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-26",
      "Script_Count": "4899"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-06",
      "Script_Count": "5981"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-17",
      "Script_Count": "4290"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-07",
      "Script_Count": "5534"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-21",
      "Script_Count": "5704"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-02",
      "Script_Count": "5724"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-07",
      "Script_Count": "8343"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-28",
      "Script_Count": "6343"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-01",
      "Script_Count": "4602"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-21",
      "Script_Count": "5705"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-05",
      "Script_Count": "4776"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-20",
      "Script_Count": "5787"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-28",
      "Script_Count": "5494"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-03",
      "Script_Count": "3711"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-07-01",
      "Script_Count": "6619"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-03",
      "Script_Count": "6955"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-15",
      "Script_Count": "6934"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-16",
      "Script_Count": "6456"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-11",
      "Script_Count": "4780"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-09",
      "Script_Count": "7069"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-06",
      "Script_Count": "6113"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-27",
      "Script_Count": "4228"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-11",
      "Script_Count": "4741"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-03",
      "Script_Count": "4302"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-29",
      "Script_Count": "5286"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-16",
      "Script_Count": "5171"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-17",
      "Script_Count": "7527"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-26",
      "Script_Count": "5982"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-23",
      "Script_Count": "5018"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-19",
      "Script_Count": "2662"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-27",
      "Script_Count": "7454"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-08",
      "Script_Count": "4471"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-23",
      "Script_Count": "4454"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-05",
      "Script_Count": "5979"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-01",
      "Script_Count": "5125"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-24",
      "Script_Count": "3767"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-14",
      "Script_Count": "6503"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-03",
      "Script_Count": "5942"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-21",
      "Script_Count": "4813"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-21",
      "Script_Count": "3485"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-25",
      "Script_Count": "6809"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-20",
      "Script_Count": "5090"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-09",
      "Script_Count": "4157"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-08",
      "Script_Count": "4475"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-10",
      "Script_Count": "6163"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-24",
      "Script_Count": "5450"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-14",
      "Script_Count": "4222"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-27",
      "Script_Count": "4328"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-17",
      "Script_Count": "8900"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-28",
      "Script_Count": "8170"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-07",
      "Script_Count": "7380"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-07",
      "Script_Count": "5399"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-08",
      "Script_Count": "6702"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-06",
      "Script_Count": "5058"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-11",
      "Script_Count": "5472"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-05-30",
      "Script_Count": "5594"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-12",
      "Script_Count": "4896"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-09",
      "Script_Count": "5435"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-09",
      "Script_Count": "7226"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-30",
      "Script_Count": "4749"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-26",
      "Script_Count": "8728"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-12",
      "Script_Count": "6685"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-02-04",
      "Script_Count": "6266"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-04",
      "Script_Count": "4975"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-28",
      "Script_Count": "6971"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-11",
      "Script_Count": "4034"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-14",
      "Script_Count": "4250"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-18",
      "Script_Count": "5453"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-17",
      "Script_Count": "6016"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-20",
      "Script_Count": "7152"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-11",
      "Script_Count": "6665"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-26",
      "Script_Count": "4539"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-13",
      "Script_Count": "8159"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-17",
      "Script_Count": "4834"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-07",
      "Script_Count": "6178"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-28",
      "Script_Count": "7563"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-15",
      "Script_Count": "4938"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-25",
      "Script_Count": "4683"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-19",
      "Script_Count": "4153"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-25",
      "Script_Count": "5843"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-12",
      "Script_Count": "3417"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-22",
      "Script_Count": "4302"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-09",
      "Script_Count": "2902"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-13",
      "Script_Count": "4012"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-03",
      "Script_Count": "3923"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-20",
      "Script_Count": "5236"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-19",
      "Script_Count": "4001"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-26",
      "Script_Count": "6104"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-29",
      "Script_Count": "6647"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-06",
      "Script_Count": "10311"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-06",
      "Script_Count": "3155"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-19",
      "Script_Count": "3783"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-18",
      "Script_Count": "6706"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-02",
      "Script_Count": "2287"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-02",
      "Script_Count": "4713"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-31",
      "Script_Count": "4360"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-23",
      "Script_Count": "4803"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-17",
      "Script_Count": "4074"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-04",
      "Script_Count": "5686"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-01",
      "Script_Count": "5566"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-26",
      "Script_Count": "4245"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-06",
      "Script_Count": "5300"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-24",
      "Script_Count": "3442"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-12",
      "Script_Count": "4061"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-29",
      "Script_Count": "3987"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-08",
      "Script_Count": "4100"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-02-08",
      "Script_Count": "3466"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-11",
      "Script_Count": "4335"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-15",
      "Script_Count": "4830"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-01",
      "Script_Count": "5662"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-15",
      "Script_Count": "6466"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-02",
      "Script_Count": "3728"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-03-31",
      "Script_Count": "3397"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-27",
      "Script_Count": "5578"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-05",
      "Script_Count": "5156"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-27",
      "Script_Count": "5042"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-18",
      "Script_Count": "4783"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-16",
      "Script_Count": "5811"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-09",
      "Script_Count": "5118"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-12",
      "Script_Count": "4689"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-08",
      "Script_Count": "5882"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-02",
      "Script_Count": "5430"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-13",
      "Script_Count": "3908"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-31",
      "Script_Count": "6741"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-10",
      "Script_Count": "7561"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-09",
      "Script_Count": "5754"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-18",
      "Script_Count": "5415"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-16",
      "Script_Count": "4447"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-08",
      "Script_Count": "3976"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-11",
      "Script_Count": "5031"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-25",
      "Script_Count": "4048"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-14",
      "Script_Count": "3755"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-04-30",
      "Script_Count": "5015"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-25",
      "Script_Count": "3220"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-30",
      "Script_Count": "5389"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-02",
      "Script_Count": "5692"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-15",
      "Script_Count": "5088"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-30",
      "Script_Count": "5508"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-12",
      "Script_Count": "6357"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-12",
      "Script_Count": "5888"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-21",
      "Script_Count": "4716"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-21",
      "Script_Count": "4958"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-03",
      "Script_Count": "3223"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-11-15",
      "Script_Count": "5229"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-20",
      "Script_Count": "4683"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-24",
      "Script_Count": "5933"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-18",
      "Script_Count": "4970"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-04-24",
      "Script_Count": "3177"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-29",
      "Script_Count": "2961"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-11",
      "Script_Count": "5635"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-19",
      "Script_Count": "3975"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-25",
      "Script_Count": "4684"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-31",
      "Script_Count": "5204"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-15",
      "Script_Count": "3880"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-22",
      "Script_Count": "4915"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-04",
      "Script_Count": "6373"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-22",
      "Script_Count": "5089"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-09",
      "Script_Count": "4041"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-02-02",
      "Script_Count": "4669"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-31",
      "Script_Count": "5154"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-08-27",
      "Script_Count": "2529"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-12-27",
      "Script_Count": "6265"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-29",
      "Script_Count": "4330"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-28",
      "Script_Count": "7522"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-23",
      "Script_Count": "6009"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-07",
      "Script_Count": "4466"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-09",
      "Script_Count": "4884"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-01",
      "Script_Count": "5134"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-15",
      "Script_Count": "4580"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-01-29",
      "Script_Count": "5747"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-27",
      "Script_Count": "5502"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-01-06",
      "Script_Count": "5558"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-24",
      "Script_Count": "5114"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-18",
      "Script_Count": "6367"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-27",
      "Script_Count": "3877"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-08",
      "Script_Count": "5364"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-23",
      "Script_Count": "6784"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-10-21",
      "Script_Count": "5818"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-12",
      "Script_Count": "4657"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-07",
      "Script_Count": "4111"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-09",
      "Script_Count": "6014"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-12",
      "Script_Count": "4862"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-03-10",
      "Script_Count": "5489"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-22",
      "Script_Count": "6326"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-01-24",
      "Script_Count": "3599"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-18",
      "Script_Count": "5059"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-08",
      "Script_Count": "8164"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-10",
      "Script_Count": "4798"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-01",
      "Script_Count": "5251"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-07",
      "Script_Count": "4475"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-05",
      "Script_Count": "3326"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-05-01",
      "Script_Count": "4938"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-28",
      "Script_Count": "4283"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-01",
      "Script_Count": "6138"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-01",
      "Script_Count": "5208"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-15",
      "Script_Count": "6266"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-01",
      "Script_Count": "4658"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-05",
      "Script_Count": "5664"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-11",
      "Script_Count": "5434"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-01-13",
      "Script_Count": "5844"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-10",
      "Script_Count": "7391"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-23",
      "Script_Count": "4397"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-25",
      "Script_Count": "4960"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-13",
      "Script_Count": "5727"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-30",
      "Script_Count": "5163"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-28",
      "Script_Count": "7576"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-10-27",
      "Script_Count": "5761"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-16",
      "Script_Count": "5689"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-02",
      "Script_Count": "2472"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-02-09",
      "Script_Count": "5259"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-05-28",
      "Script_Count": "3929"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-02-03",
      "Script_Count": "4928"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-29",
      "Script_Count": "7053"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-17",
      "Script_Count": "5908"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-26",
      "Script_Count": "3195"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-12",
      "Script_Count": "4054"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-05-24",
      "Script_Count": "4344"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-10",
      "Script_Count": "5199"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-20",
      "Script_Count": "5331"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-17",
      "Script_Count": "3847"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-24",
      "Script_Count": "5890"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-12",
      "Script_Count": "3357"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-11-19",
      "Script_Count": "5988"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-02",
      "Script_Count": "8064"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-11-17",
      "Script_Count": "4826"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-11-08",
      "Script_Count": "5588"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-01-01",
      "Script_Count": "5273"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-08",
      "Script_Count": "5100"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-07",
      "Script_Count": "6982"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-09",
      "Script_Count": "3191"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-10",
      "Script_Count": "6111"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-06-23",
      "Script_Count": "5259"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-29",
      "Script_Count": "3360"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-12-09",
      "Script_Count": "4507"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-12-28",
      "Script_Count": "4795"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-19",
      "Script_Count": "6158"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-27",
      "Script_Count": "5262"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-02",
      "Script_Count": "8707"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-02",
      "Script_Count": "2970"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-04-23",
      "Script_Count": "6071"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-08-12",
      "Script_Count": "5389"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-01",
      "Script_Count": "6607"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-16",
      "Script_Count": "5518"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-03-11",
      "Script_Count": "6794"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-08-29",
      "Script_Count": "5666"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-12-09",
      "Script_Count": "7511"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-09",
      "Script_Count": "5888"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-10-31",
      "Script_Count": "5272"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-05",
      "Script_Count": "4930"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-26",
      "Script_Count": "7379"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-24",
      "Script_Count": "4681"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-22",
      "Script_Count": "5512"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-11-17",
      "Script_Count": "4691"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-10-05",
      "Script_Count": "6443"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-22",
      "Script_Count": "6031"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-03-29",
      "Script_Count": "8534"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-06",
      "Script_Count": "4514"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-07-29",
      "Script_Count": "5114"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-18",
      "Script_Count": "7845"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-12",
      "Script_Count": "5765"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-21",
      "Script_Count": "4995"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-07-27",
      "Script_Count": "5786"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-10-29",
      "Script_Count": "5149"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-26",
      "Script_Count": "6471"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-07-22",
      "Script_Count": "4847"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-06-28",
      "Script_Count": "4690"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-12",
      "Script_Count": "4933"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-06-08",
      "Script_Count": "6820"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-07-04",
      "Script_Count": "9173"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-26",
      "Script_Count": "7073"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-04-04",
      "Script_Count": "5710"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-23",
      "Script_Count": "5651"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-05",
      "Script_Count": "4812"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-15",
      "Script_Count": "4985"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-03-29",
      "Script_Count": "7595"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-09-05",
      "Script_Count": "7013"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-09-05",
      "Script_Count": "3126"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-09-21",
      "Script_Count": "4166"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-04-09",
      "Script_Count": "3656"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-23",
      "Script_Count": "4799"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-06-01",
      "Script_Count": "4806"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-07",
      "Script_Count": "6763"
    },
    {
      "Category": "Product Formula",
      "Date": "2023-05-14",
      "Script_Count": "4271"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-12-01",
      "Script_Count": "4978"
    },
    {
      "Category": "Product Potency",
      "Date": "2023-08-24",
      "Script_Count": "6128"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-08-11",
      "Script_Count": "4796"
    },
    {
      "Category": "Product Efficacy",
      "Date": "2023-09-28",
      "Script_Count": "6654"
    },
    {
      "Category": "Product Side Effects",
      "Date": "2023-06-03",
      "Script_Count": "6625"
    },
    {
      "Category": "Product Pipeline",
      "Date": "2023-09-14",
      "Script_Count": "3981"
    }
  ]

  const [optionsChart, setOptionsChart] = useState()
  const [chartData, setChartData] = useState()
  const [parsedDataSaved, setParsedData] = useState()
  const [xAxis, setxAxis] = useState('')
  const [yAxis, setyAxis] = useState('')
  const [defaultChart, setDefaultChart] = useState('')


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleAddPresentation = () => {
    setShowAddtoStory(true)
  }

  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOpenChangeDatamart = (newOpen) => {
    setOpenDatamart(newOpen);
  };

  // const TableComponent = ({ data }) => {
  //   if (!data || data.length === 0) {
  //     return <div>No data available</div>;
  //   }
  
  //   // Flatten the data
  //   const flattenedData = [];
  //   const keys = Object.keys(data[0]);
  //   const length = Object.values(data[0][keys[0]]).length;
  
  //   for (let i = 0; i < length; i++) {
  //     const row = {};
  //     keys.forEach(key => {
  //       row[key] = data[0][key][i];
  //     });
  //     flattenedData.push(row);
  //   }
  
  //   // Extract column headers from the first object in the flattened array
  //   const headers = Object.keys(flattenedData[0]);
  //   console.log("flattenedData", flattenedData);
  
  //   return (
  //     <table>
  //       <thead>
  //         <tr>
  //           {headers.map((header, index) => (
  //             <th key={index}>{header}</th>
  //           ))}
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {flattenedData.map((row, rowIndex) => (
  //           <tr key={rowIndex}>
  //             {headers.map((header, colIndex) => (
  //               <td key={colIndex}>{row[header]}</td>
  //             ))}
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   );
  // };

  const TableComponent = ({ data }) => {
    // Check if data is not empty
    // if (!data.length) {
    //   return <div>No data available</div>;
    // }

    // Extract column headers from the first object in the array
    const headers = Object.keys(data[0]);

    return (
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td key={colIndex}>{row[header]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const onChangeSwitch = (checked) => {
    setThemeToggle(prevOptions => (checked));

    const new_options = {
      responsive: true,
      color: checked ? '#666666' : '#ffffff',
      scales: {
        x: {
          grid: {
            color: checked ? '#666666' : '#ffffff', // Color of the grid lines on the x-axis
          },
          ticks: {
            color: checked ? '#666666' : '#ffffff', // Color of the grid lines on the x-axis
          },
          position: 'bottom',
          title: {
            display: true,
            text: xAxis,
            color: checked ? '#666666' : '#ffffff',
          }
        },
        y: {
          grid: {
            color: checked ? '#666666' : '#ffffff', // Color of the grid lines on the x-axis
          },
          ticks: {
            color: checked ? '#666666' : '#ffffff', // Color of the grid lines on the x-axis
          },
          position: 'left',
          title: {
            display: true,
            text: yAxis,
            color: checked ? '#666666' : '#ffffff',
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',

        },
      }
    }
    setOptionsChart(prevOptions => (new_options));

  };

  useEffect(()=>{


      setOptionsChart({...cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.options,scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      } })
      setChartData(cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.data)
      setParsedData(cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.parsedData)
      setxAxis(cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.xAxis)
      setyAxis(cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.yAxis)
      setDefaultChart(cardSelected?.ChartJSON && JSON.parse(cardSelected?.ChartJSON)?.defaultChart)
  
  },[cardSelected?.ChartJSON])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class_quick'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='____common_model_class_desc'>
            <div className='__hd_top'>
              <h1>{title}</h1>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Tooltip title={'Datamart'}>
                  <Popover
                    content={cardSelected?.Datamart}
                    title="Datamart"
                    trigger="click"
                    open={openPopDatamart}
                    overlayClassName='__pop_over'
                    onOpenChange={handleOpenChangeDatamart}
                  >
                    <Button ghost icon={<FontAwesomeIcon icon={faDatabase} />} style={{ borderColor: '#ffffff1f' }} ></Button>
                  </Popover>

                </Tooltip>
                <Tooltip title="Question">
                  <Popover
                    content={Question}
                    title="Question"
                    trigger="click"
                    open={openPop}
                    overlayClassName='__pop_over'
                    onOpenChange={handleOpenChange}
                  >
                    <Button ghost icon={<InfoOutlined />} style={{ borderColor: '#ffffff1f' }} ></Button>
                  </Popover>
                </Tooltip>


                <Tooltip title=" Add to Story">
                  <Button ghost icon={<FileWordOutlined />} onClick={() => handleAddPresentation()} style={{ borderColor: '#ffffff1f' }} ></Button>
                </Tooltip>

                <Tooltip title=" Large View">
                  <Button ghost icon={<ZoomInOutlined />} onClick={() => setImageView(true)} style={{ borderColor: '#ffffff1f' }} ></Button>
                </Tooltip>

              </div>
            </div>
            <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: description }}></p>
            {/* <img src={Image} alt="" onClick={() => setImageView(true)} /> */}
            {/* <div className='___theme_changer' style={{ justifyContent: 'flex-start', padding: '0', margin: '10px 0' }}>
              Change graph background
              <Switch checkedChildren={<FontAwesomeIcon icon={faMoon} />}
                unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
                onChange={onChangeSwitch} />
            </div> */}

            <div id="chart3" style={{ background: themeToggle ? '#fff9' : 'transparent', color: themeToggle ? '#000' : '#fff', maxHeight: (defaultChart && defaultChart === 'table') ? '260px' : 'auto', minHeight: (defaultChart && defaultChart === 'table') ? '20px' : '50vh', justifyContent: (defaultChart && defaultChart === 'table') ? 'flex-start' : 'inherit'  }}>
              {defaultChart && defaultChart === 'radar' && <Radar data={chartData} options={optionsChart} />}
              {defaultChart && defaultChart === 'pie' && <Pie data={chartData} options={optionsChart} />}
              {defaultChart && defaultChart === 'line' && <Line data={chartData} options={optionsChart} />}
              {defaultChart && defaultChart === 'bar' && <Bar data={chartData} options={optionsChart} />}
              {defaultChart && defaultChart === 'area' && <Line data={chartData} options={{
                ...optionsChart,
                plugins: {
                  filler: {
                    propagate: true
                  },
                },
                fill: true
              }} />}
              {defaultChart && defaultChart === 'scatter' && <Scatter data={chartData} options={optionsChart} />}
              {defaultChart && defaultChart === 'table' && <TableComponent data={parsedDataSaved} />}
            </div>



            <Modal
              open={imageView}
              onClose={() => setImageView(false)}
              className='__common_model_class __lilac_blue __msm'
              footer={null}
              closable={false}
            >
              <div className='__common_model_content'>
                <div className='____common_model_head'>
                  <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
                  <button onClick={() => setImageView(false)}><FontAwesomeIcon icon={faClose} /></button>
                </div>
                {/* <img src={Image} style={{ width: '100%', objectFit: 'contain', borderRadius:'20px' }} /> */}
                {/* <div className='___theme_changer' style={{ justifyContent: 'flex-start', padding: '0', margin: '10px 0', color:"#fff" }}>
              Change graph background
              <Switch checkedChildren={<FontAwesomeIcon icon={faMoon} />}
                unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
                onChange={onChangeSwitch} />
            </div> */}
                <div id="chart" style={{ background: themeToggle ? '#fff9' : 'transparent', color: themeToggle ? '#000' : '#fff', maxHeight: (defaultChart && defaultChart === 'table') ? '260px' : 'auto', minHeight: (defaultChart && defaultChart === 'table') ? '20px' : '60vh'}}>
                  {defaultChart && defaultChart === 'radar' && <Radar data={chartData} options={optionsChart} />}
                  {defaultChart && defaultChart === 'pie' && <Pie data={chartData} options={optionsChart} />}
                  {defaultChart && defaultChart === 'line' && <Line data={chartData} options={optionsChart} />}
                  {defaultChart && defaultChart === 'bar' && <Bar data={chartData} options={optionsChart} />}
                  {defaultChart && defaultChart === 'area' && <Line data={chartData} options={{
                    ...optionsChart,
                    plugins: {
                      filler: {
                        propagate: true
                      },
                    },
                    fill: true
                  }} />}
                  {defaultChart && defaultChart === 'scatter' && <Scatter data={chartData} options={optionsChart} />}

                  {defaultChart && defaultChart === 'table' && <TableComponent data={parsedDataSaved} />}
                </div>
              </div>
            </Modal>
          </div>
        </div>

        <AddToStory
          open={showAddtoStory}
          onClose={() => setShowAddtoStory(false)}
          questionId={Qstn_id}
        />
      </Modal>
    </>
  );
};

export default QuickView;

QuickView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  Image: PropTypes.string,
  description: PropTypes.string,
  Qstn_id: PropTypes.string,
  Question: PropTypes.string,
  chartJSON:PropTypes.any

};
