
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Button, Modal, Select, Input, notification, message, Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const { Option } = Select;

const AddEditRole = (props) => {
  const { onClose, open, roleId, listRoles } = props;
  const { userDetails } = useSelector((store) => store.commonData);
  const [role, setRole] = useState('');
  const [permissions, setPermissions] = useState({
    25: [],
    50: [],
    75: [],
    100: [],
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [sliderValue, setSliderValue] = useState(0)

  useEffect(() => {
    if (roleId) {
      getRolePermission()
    }
  }, [roleId]);

  const getRolePermission = async () => {
    const payload = {
      "roleId": roleId
    }
    const res = await fetchData(restAPIs.fetchRolePermissions(payload));
    if (res?.statusCode === 200) {
      setRole(res?.body.roleName);
      const newPermissions = res?.body.permissions.reduce((acc, item) => {
        acc[item.threshold] = item.permissions;
        return acc;
      }, { 25: [], 50: [], 75: [], 100: [] });
      setPermissions(newPermissions);
      setSliderValue(res?.body?.defaultValue)
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }
  }

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handlePermissionsChange = (percentage, value) => {
    const newPermissions = { ...permissions, [percentage]: value };

    const higherPercentages = [100, 75, 50, 25].filter(p => p > percentage);

    higherPercentages.forEach(p => {
      newPermissions[p] = Array.from(new Set([...newPermissions[p], ...value]));
    });

    setPermissions(newPermissions);
  };

  const handleClose = () => {
    setRole('');
    setPermissions({ 25: [], 50: [], 75: [], 100: [] });
    setSliderValue(0)
    onClose();
  };

  const handleSubmit = async () => {
    const converted = {
      "permissions": Object.keys(permissions)
        .map(key => ({
          "permissions": permissions[key],
          "threshold": key
        }))
        .sort((a, b) => parseInt(a.threshold) - parseInt(b.threshold))
    };

    const payload = {
      roleName: role,
      permissions: converted?.permissions,
      defaultValue: sliderValue
    };
    if (roleId)
      payload.roleId = roleId
    const res = roleId ? await fetchData(restAPIs.editRolePermissons(payload)) : await fetchData(restAPIs.createRolePermissions(payload));

    if (res?.statusCode === 200) {
      listRoles();
      handleClose();
    } else {
      messageApi.open({
        type: "error",
        content: res?.message,
      });
    }

  };

  const handleSliderChange = (e) => {
    setSliderValue(e)
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height22_reduced'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>{roleId ? 'Update Role' : 'Add Role'}</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc __roles'>
            <div>
              <Input
                placeholder="Role"
                value={role}
                onChange={handleRoleChange}
                style={{ marginBottom: 16 }}
              />
              {[25, 50, 75, 100].map((percentage) => (
                <div key={percentage} className='__single_percent'>
                  <span className='__per'>{percentage}%</span>
                  <Select
                    mode="multiple"
                    style={{ width: '100%', marginLeft: 8 }}
                    placeholder="Select permissions"
                    value={permissions[percentage]}
                    onChange={(value) => handlePermissionsChange(percentage, value)}
                  >
                    <Option value="view_ql">View QL</Option>
                    <Option value="tune_ql">Tune QL</Option>
                    <Option value="create_model">Create Model</Option>
                    <Option value="register_data">Register Data</Option>
                    <Option value="access_data_garage">Access Data Garage</Option>
                  </Select>
                </div>
              ))}

              <div className="__ranges">
                <div className="__single_item">
                  <div className="__head">
                    <h1>{'Default Value'}</h1>
                    <span>{sliderValue}%</span>
                  </div>
                  <Slider
                    defaultValue={sliderValue}
                    value={sliderValue}
                    step={25}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff', marginTop: '10px' }} className="__extra_btn_cls" loading={loading} onClick={handleSubmit}>Submit</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

AddEditRole.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  roleId: PropTypes.any,
  listRoles: PropTypes.func

};

export default AddEditRole;
