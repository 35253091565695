
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Table, Tree, message } from 'antd';
import { faClose, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const { Column } = Table;

const DarkMaterModal = (props) => {
  const { onClose, open } = props;
  const { questionQuickView } = useSelector((store) => store.commonData);
  const dispatch = useDispatch();
  const [paginationData, setPaginationData] = useState({
    page: 1,
    totalItems: 0
  });
  const { totalItems, page } = paginationData;
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(true);
  const [loadingReset, setLoadingReset] = useState(true);
  const [listAgents, setListAgents] = useState([])

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      dispatch({
        type: 'questionQuickView',
        value: null
      });
      onClose();
    }
  };
  useEffect(() => {
    getAgents();
  }, []);

  const getAgents = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.listDarkMater());
    if (res?.statusCode === 200) {
      setLoading(false)
      setListAgents(res?.body)
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  const config = {
    pagination: {
      total: totalItems,
      // current: page,
      defaultCurrent: 1,
      defaultPageSize: 8,
      showSizeChanger: false,
      // onChange: handlePageChange
    }
  }

  const resetShades = async () => {
    setLoadingReset(true)
    const res = await fetchData(restAPIs.resetGreyShades());
    if (res?.statusCode === 200) {
      setLoadingReset(false)
      // getAgents();
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
    }
    else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __qustinview_model __shs'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Darkmater </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          {contextHolder}
          <div className='__table_sec'>
            <div style={{ display: 'flex', justifyContent: 'end', marginBottom: '20px', gap: '20px' }}>

              <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" onClick={() => resetShades()}>Reset Shades</Button>
            </div>
            <Table {...config} rowKey={(record, index) => index} dataSource={listAgents} loading={loading} className="__market_table">
              <Column
                title={'Name'}
                dataIndex="agentname"
                key="agentname"
                className='___col'
              />
              <Column
                title={'Role'}
                dataIndex="role"
                key="role"
                className='___col'
              />
              <Column
                title='Goal'
                dataIndex="goal"
                key="goal"
                className='___col'
              />

              <Column
                title={'Backstory'}
                dataIndex="backstory"
                key="backstory"
                className='___col'
              />

              <Column
                title='Description'
                dataIndex="description"
                key="description"
                className='___col'
              />

            </Table>
          </div>

        </div>
      </Modal>
    </>
  );
};

export default DarkMaterModal;

DarkMaterModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
