import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Button, Select, Upload, notification } from 'antd';
import './styles.scss';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';

const openNotificationWithIcon = (title, message) => {
    notification["error"]({
        message: title,
        description: message,
    });
};

const InstallApp = () => {
    const { userDetails } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)
    const [userList, setUsers] = useState([])
    const { email } = data;

    const handleInstall = async () => {
        setLoading(true);
        let payload = {
            "username": userDetails,
            "emails": userList
        }
        const res = await fetchData(restAPIs.installApp(payload));
        if (res.statusCode === 200) {
            setLoading(false);
            navigate('/upload-meta')
        } else {
            setLoading(false);
            openNotificationWithIcon(res?.message);
        }
    }
    const handleAdd = () => {
        const errors = {
            email: !email,
        };
        setError(errors);
        const isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setUsers([...userList, email]);
        setData({ ...data, email: '' }); // Clear email input after adding
    };

    const handleRemove = (emailToRemove) => {
        const updatedUserList = userList.filter((email) => email !== emailToRemove);
        setUsers(updatedUserList);
    };


    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null,
        });
        setData({
            ...data,
            [key]: value,
        });
    };


    return (
        <>
            <div className='__susbscription_page'>
                <div className='__inner_wrap'>
                    <div className='__top_head'>
                        <button className="__logo">LiLaQ-Ai</button>
                    </div>
                    <h3 className='__top_head_h1'>Add Your Team</h3>
                    <div className='__subs_page'>
                        <div>
                            <div>
                                <InputField
                                    onChange={handleOnChange("email")}
                                    value={email}
                                    type="email"
                                    placeholder={'Email'}
                                    label={'Email'}
                                    error={error.email}
                                />
                                <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>Add</Button>
                            </div>
                            <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleInstall}>Install App</Button>
                        </div>
                        <div className='__userList_set'>
                            {
                                userList && userList?.map((item, idx) => {
                                    return (
                                        <div key={idx} className='__user_single'>
                                            <h3>{item}</h3>
                                            <CloseCircleFilled style={{ cursor: 'pointer' }} onClick={() => handleRemove(item)} />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
                </div>
            </div>
        </>
    );
};

export default InstallApp;
