// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const AskLilaq = (props) => {
  const { userDetails } = useSelector((store) => store.commonData);
  const { onClose, open } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { text } = data;
  const [messageApi, contextHolder] = message.useMessage();


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError({})
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {

    const errors = {
      text: !text,
      
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    const payload = {
      input_text: text,
      username: userDetails
    }

    const res = await fetchData(restAPIs.asktoLilaq(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      handleClose();
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Ask LilaQ to Register Data</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("text")}
              value={text}
              placeholder={'Enter your text'}
              label='Text'
              error={error.text}
            />
            <Button className='__extra_btn_cls' style={{ border: 0, color: '#fff', marginTop:'10px' }} disabled={!text} loading={loading} onClick={() => handleSubmit()} >Update</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AskLilaq;

AskLilaq.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
