// Core
import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleFilled } from '@ant-design/icons';

// Styles
import './styles.scss';
import { Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const Warning = (props) => {
  const { onClose, open } = props;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
       <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __access_model'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Warning </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>

          <div className='__desc_wring'>
            <InfoCircleFilled  style={{fontSize:'32px', color:'red'}}/>
            <div>
            <h3>Access Denied</h3>
            <p>Sorry, you don't have access to view this details.!</p>
            </div>
          </div>

          

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" onClick={() => handleClose()}>Close</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Warning;

Warning.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
