// Core
import React, { useEffect, useState } from 'react';

// Styles
import './styles.scss';

// assets
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LilacBlue from 'components/modals/LilacBlue';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { AutoComplete, Button } from 'antd';
import TextArea from 'components/commonComponents/TextArea';


const CardDetail = () => {
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const { cardSelected } = useSelector((store) => store.commonData);
    const [loadingBlue, setLoadingBlue] = useState(false)
    const [htmlContent, setHtmlContent] = useState('')
    const [newQuery, setQuery] = useState('')
    const [isQueryLoading, setIsQueryLoading] = useState(false)
    const dispatch = useDispatch();

    const [inputTagValue, setInputTagValue] = useState('');
    const [tagList, setTagList] = useState([])

    const handleClose = () => {
        setShow(false)
    }

    const openBlueHtmlContent = async () => {
        setLoadingBlue(true)
        let payload = {
            question: cardSelected?.Question
        }
        const res = await fetchData(restAPIs.iframeLoader(payload));

        // if (res.statusCode === 200) {
        setLoadingBlue(false)
        setHtmlContent(res)
        setShow(true)
        // }
    }
    useEffect(() => {
        setQuery(cardSelected?.Query)
        setInputTagValue(cardSelected?.Tag)
        listAllTags();
    }, [cardSelected])

    const handleSubmitQuery = async () => {
        setIsQueryLoading(true)
        const payload = {
            question: cardSelected?.Question,
            query: newQuery,
            Tag: inputTagValue
        }
        const res = await fetchData(restAPIs.submitQuestionQuery(payload));
        if (res?.statusCode === 200) {
            setIsQueryLoading(false)
            // setQuery(res?.body)
            dispatch({
                type: 'cardSelected',
                value: res?.body
            });
        }
    }
    const handleOnChangeQuery = (e) => {
        setQuery(e);
    };


    const onSelectDropDownTag = (data, option) => {
        setInputTagValue(option.label);
    };

    const onChangeValTag = (data, option) => {
        setInputTagValue(data);
    };


    const listAllTags = async () => {
        const res = await fetchData(restAPIs.listTags());
        if (res?.statusCode === 200) {
            let tags = []
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                tags.push({ value: element.Tag, id: element.Tag, label: element.Tag })
            }
            setTagList(tags);
        }
    }


    return (
        <>
            <div className='__card_detail'>
                <div className='__common_header_top'>
                    <div style={{ maxWidth: '70%' }}>
                        <h1>{cardSelected && cardSelected?.displayText}</h1>
                    </div>
                    <div className='__btns_'>
                        <button onClick={() => navigate('/home')}><FontAwesomeIcon icon={faArrowLeft} /></button>
                    </div>
                </div>
                <p style={{ paddingRight: '100px', fontSize: '20px', lineHeight: '1.7' }}>{cardSelected && cardSelected?.Question}</p>
                <p style={{ paddingRight: '100px', lineHeight: '1.7' }}>{cardSelected && cardSelected?.Answer}</p>

                <>
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <AutoComplete
                            value={inputTagValue}
                            options={tagList}
                            placeholder={'Enter tag'}
                            autoFocus={true}
                            className="__auto_com_serch"
                            filterOption={(inputValue, option) =>
                                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                            onSelect={onSelectDropDownTag}
                            onChange={onChangeValTag}
                        />
                    </div>
                    <div style={{ width: '70%' }} className='__text_ar_query'>
                        <TextArea
                            placeholder="Enter your query"
                            value={newQuery}
                            onChange={handleOnChangeQuery}
                            type="text"
                            style={{ width: '100%' }}
                            disabled={cardSelected?.Status !== 'registered'}
                        />
                        {cardSelected?.Status === 'registered' && <Button className="__extra_btn_cls__submit" onClick={() => { handleSubmitQuery() }} loading={isQueryLoading}>Submit</Button>}
                    </div>


                    {cardSelected?.Status === 'registered' &&
                        <div className='__goto'>
                            <Button type="primary" className="__extra_btn_cls" loading={loadingBlue} icon={<FontAwesomeIcon icon={faArrowRight} fontSize={13} />} onClick={() => openBlueHtmlContent()}>Go to Lilac Blue</Button>
                        </div>}

                </>
            </div>

            <LilacBlue
                open={show}
                onClose={handleClose}
                content={htmlContent}
            />
        </>
    );
};

export default CardDetail;
