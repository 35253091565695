// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LilacBlue = (props) => {
  const { onClose, open, content, title, description } = props;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __lilac_blue'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{marginBottom:'10px'}}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose}/></button>
          </div>
          <div dangerouslySetInnerHTML={{ __html : content}}></div>
        </div>
      </Modal>
    </>
  );
};

export default LilacBlue;

LilacBlue.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
