// Core
import React, { useEffect, useState } from "react";

// Styles
import "./styles.scss";

// Assets
import { faAdd, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    SendOutlined,
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import {
    message,
    Table,
    Button,
    Popconfirm,
    Tabs,
    Switch,
    Tooltip,
    Input,
    Upload,
    notification,
    Collapse,
    Select,
    Dropdown,
    Menu,
} from "antd";
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { useDispatch, useSelector } from "react-redux";
import InputField from "components/commonComponents/InputField";
import { LILAC_SERVER_URL } from "config/constants";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import AgentAddEditModel from "components/modals/AgentAddEditModel";
import ShadeAddEditModel from "components/modals/ShadeAddEditModel";
import AddEditRole from "components/modals/AddEditRole";
import SelectBox from "components/commonComponents/SelectBox";
import AddTeam from "components/modals/AddTeam";

const { Column } = Table;
const { TabPane } = Tabs;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

const openNotificationWithIcon = (title, message) => {
    notification["error"]({
        message: title,
        description: message,
    });
};

const ConfigDarkMater = () => {
    const [loading, setLoading] = useState(true);
    const [loadingShades, setLoadingShades] = useState(false);
    const { userDetails, domainName } = useSelector((store) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeKey, setActiveKey] = useState("1");

    // State to manage multiple sets of agent and role data
    const [agents, setAgents] = useState([
        { agentname: "", role: "", goal: "", backstory: "", description: "" },
    ]);
    const [roles, setRoles] = useState([{ roleDm: "" }]);
    const [domain, setDomain] = useState(
        domainName ? domainName.darkmaterDomain : ""
    );
    const [error, setError] = useState({});
    const [file, setFile] = useState(null);

    const [showRole, setShowRole] = useState(false);

    const [listAgents, setListAgents] = useState([]);
    const [showModelAgent, setShowModalAgent] = useState(false);
    const [paginationData, setPaginationData] = useState({
        page: 1,
        totalItems: 0,
    });
    const { totalItems, page } = paginationData;


    const [loadingRoles, setLoadingRoles] = useState(true);
    const [listRoles, setListRoles] = useState([]);
    const [paginationDataRole, setPaginationDataRole] = useState({
        pageRole: 1,
        totalItemsRole: 0,
    });
    const { totalItemsRole, pageRole } = paginationDataRole;
    const [roleIdSelected, setRoleIdSelected] = useState('')

    const [teamsList, setTeamsList] = useState([])
    const [teamSelected, setTeamSelected] = useState('')
    const [showTeam, setShowTeam] = useState(false)


    useEffect(() => {
        listTeams();
        getRoles();
    }, []);

    const getAgents = async (teamName) => {
        setLoadingShades(true);
        const res = await fetchData(restAPIs.listAgents({ teamName: teamName }));
        if (res?.statusCode === 200) {
            setLoadingShades(false);
            setListAgents(res?.body);
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }
    };

    const validateFields = () => {
        let isValid = true;
        const newError = {};

        if (!domain.trim()) {
            newError.domain = "Domain is required";
            isValid = false;
        }

        agents.forEach((agent, index) => {
            if (!agent.agentname.trim()) {
                newError[`agentname_${index}`] = "Agent name is required";
                isValid = false;
            }
            if (!agent.role.trim()) {
                newError[`role_${index}`] = "Role is required";
                isValid = false;
            }
            if (!agent.goal.trim()) {
                newError[`goal_${index}`] = "Goal is required";
                isValid = false;
            }
            if (!agent.backstory.trim()) {
                newError[`backstory_${index}`] = "Backstory is required";
                isValid = false;
            }
            if (!agent.description.trim()) {
                newError[`description_${index}`] = "Description is required";
                isValid = false;
            }
        });

        roles.forEach((role, index) => {
            if (!role.roleDm.trim()) {
                newError[`roleDm_${index}`] = "Role is required";
                isValid = false;
            }
        });

        setError(newError);
        return isValid;
    };

    const handleSubmit = async () => {
        if (!validateFields()) {
            messageApi.open({
                type: "error",
                content: "Please fill in all required fields.",
            });
            return;
        }

        const darkmater_mapping = {
            [domain]: agents.map((agent) => ({
                ...agent,
                status: "active",
            })),
        };

        const payload = {
            domain,
            darkmater_mapping,
            roles: roles.map((role) => role.roleDm),
        };

        const res = await fetchData(restAPIs.updateDarkMater(payload));
        if (res?.statusCode === 200) {
            setDomain("");
            setAgents([
                { agentname: "", role: "", goal: "", backstory: "", description: "" },
            ]);
            setRoles([{ roleDm: "" }]);
            messageApi.open({
                type: "success",
                content: res?.message,
            });

            signOut();
            dispatch({
                type: "userDetails",
                value: "",
            });
            localStorage.setItem("token", "");
            localStorage.setItem("userDetails", "");
            localStorage.setItem("userGroups", "");

            dispatch({
                type: "domainName",
                value: null,
            });
            localStorage.setItem("domainName", "");
            navigate("/login");
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }
    };

    const handleChangeTab = (e) => {
        setActiveKey(e);
    };

    const handleExport = () => {
        fetch(`${LILAC_SERVER_URL}/exportDarkMater`, {
            method: "POST",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((resp) => resp.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = `license.darkmater`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert("oh no!"));
    };

    const uploadProps = {
        onRemove: () => {
            setFile(null);
        },
        beforeUpload: (file) => {
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isLt5M) {
                openNotificationWithIcon("Error", "File must be smaller than 5MB!");
                return Upload.LIST_IGNORE;
            }
            setFile(file);
            return false;
        },
        file,
    };

    const handleImport = async () => {
        const errors = {
            file: !file,
        };
        setError(errors);
        const isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;

        setLoading(true);
        let formd = new FormData();
        formd.append("file", file);
        const res = await fetchData(restAPIs.importDarkMater(formd));
        if (res?.statusCode === 200) {
            setLoading(false);
            signOut();
            dispatch({
                type: "userDetails",
                value: "",
            });
            localStorage.setItem("token", "");
            localStorage.setItem("userDetails", "");
            localStorage.setItem("userGroups", "");
            localStorage.setItem("userPermissions", "");
            localStorage.setItem("userSettingsShape", "");
            dispatch({
                type: "userPermissions",
                value: null,
            });

            dispatch({
                type: "userSettingsShape",
                value: null,
            });

            dispatch({
                type: "domainName",
                value: null,
            });
            localStorage.setItem("domainName", "");
            navigate("/login");
        } else {
            setLoading(false);
            openNotificationWithIcon(res?.message);
        }
    };

    const cancel = (e) => {
        console.log(e);
    };

    const config = {
        pagination: {
            total: totalItems,
            // current: page,
            defaultCurrent: 1,
            defaultPageSize: 8,
            showSizeChanger: false,
            // onChange: handlePageChange
        },
    };

    const handleEdit = (data) => {
        dispatch({
            type: "shadeData",
            value: {
                dataSet: data,
                isEdit: true,
                getAgents,
            },
        });
        setShowModalAgent(true);
    };

    const handleAddAgent = () => {
        if (teamSelected) {
            dispatch({
                type: "shadeData",
                value: { getAgents },
            });
            setShowModalAgent(true);
        }
        else {
            messageApi.open({
                type: 'error',
                content: 'Please select a team',
            });
        }
       
        
    };

    const handleDelete = async (record) => {
        const payload = {
            role: record?.role,
            agentId: record?.agentId,
        };
        const res = await fetchData(restAPIs.deleteAgents(payload));
        if (res?.statusCode === 200) {
            getAgents(teamSelected);
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }
    };

    const handleUpdateDomain = async () => {
        const payload = {
            domain,
        };

        const res = await fetchData(restAPIs.updateDarkMater(payload));
        if (res?.statusCode === 200) {
            setDomain("");
            setAgents([
                { agentname: "", role: "", goal: "", backstory: "", description: "" },
            ]);
            setRoles([{ roleDm: "" }]);
            messageApi.open({
                type: "success",
                content: res?.message,
            });

            signOut();
            dispatch({
                type: "userDetails",
                value: "",
            });
            localStorage.setItem("token", "");
            localStorage.setItem("userDetails", "");
            localStorage.setItem("userGroups", "");
            localStorage.setItem("userPermissions", "");
            localStorage.setItem("userSettingsShape", "");
            dispatch({
                type: "userPermissions",
                value: null,
            });

            dispatch({
                type: "userSettingsShape",
                value: null,
            });

            dispatch({
                type: "domainName",
                value: null,
            });
            localStorage.setItem("domainName", "");
            navigate("/login");
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }
    };



    const getRoles = async () => {
        setLoadingRoles(true);
        const res = await fetchData(restAPIs.listRoles());
        if (res?.statusCode === 200) {
            setLoadingRoles(false);
            setListRoles(res?.body);
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }
    };

    const configRoles = {
        pagination: {
            total: totalItemsRole,
            // current: page,
            defaultCurrent: 1,
            defaultPageSize: 8,
            showSizeChanger: false,
            // onChange: handlePageChange
        },
    };


    const handleAddRole = () => {
        setRoleIdSelected(null)
        setShowRole(true);
    };


    const handleEditRole = (roleId) => {
        setRoleIdSelected(roleId)
        setShowRole(true);
    };


    const handleDeleteRole = async (record) => {
        console.log("rsc", record);
        const payload = {
            roleId: record?.roleId
        };
        const res = await fetchData(restAPIs.deleteRolePermissions(payload));
        if (res?.statusCode === 200) {
            getRoles();
        } else {
            messageApi.open({
                type: "error",
                content: res?.message,
            });
        }

    };

    const listTeams = async () => {
        const res = await fetchData(restAPIs.listShadeTeam({}));
        let pptm = []
        if (res.statusCode === 200) {
            for (let i = 0; i < res?.body.length; i++) {
                const element = res?.body[i];
                pptm.push({ id: element.teamName, label: element.teamName, ...element })
            }
            if (res?.body.length > 0) {
                setTeamSelected(res?.body[0].teamName)
                getAgents(res?.body[0].teamName);
            }
            setTeamsList(pptm)
        }
    }

    const handleAddTeam = () => {
        dispatch({
            type: "teamData",
            value: { listTeams }
        });
        setShowTeam(true);
        
    }

    const items = [
        {
            label: "Add Shade",
            key: "bar",
            icon: <PlusCircleOutlined />,
            onClick: handleAddAgent,
        },
        {
            label: "Add Team",
            key: "bar2",
            icon: <PlusCircleOutlined />,
            onClick: handleAddTeam,
        },
    ];


    const handleOnChangeTeam = (value) => {
        setTeamSelected(value);
        getAgents(value);
    };

    useEffect(() => {
        dispatch({
            type: "headerName",
            value: 'Configure Dark Mater',
        });
    }, [])

    return (
        <>
            {contextHolder}
            <div className="___logSes_wrap">


                <div className="___loginSession_sec_new __configure_">
                    <Tabs
                        className="__tabs2"
                        tabPosition={"top"}
                        activeKey={activeKey}
                        onChange={handleChangeTab}
                    >
                        <TabPane tab={"Update Darkmater"} key="1">
                            <span>Darkmater Name</span>
                            <Input
                                onChange={(e) => setDomain(e.target.value)}
                                value={domain}
                                type="text"
                                placeholder={"Domain Name"}
                                label={"Domain Name"}
                                className="__input_domain"
                                status={error.domain ? "error" : ""}
                            />

                            <Popconfirm
                                title="Warning"
                                description={
                                    <p>
                                        You are about to change the configurations. <br />
                                        This action will cause LiLaQ to restart, <br />
                                        which may interrupt ongoing processes. <br />
                                        Are you sure you want to proceed?
                                    </p>
                                }
                                onConfirm={handleUpdateDomain}
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="__extra_btn_cls"
                                    disabled={!domain}
                                    style={{ border: 0, color: "#fff", marginBottom: "20px" }}
                                >
                                    Update
                                </Button>
                            </Popconfirm>

                            <div className="__upload_exp" style={{ marginTop: "30px" }}>
                                <div className="uplods" style={{ color: "#fff" }}>
                                    <div style={{ fontSize: "12px", marginBottom: "10px" }}>
                                        Import Darkmater
                                    </div>
                                    <Upload
                                        {...uploadProps}
                                        maxCount={1}
                                        multiple={false}
                                        accept=".darkmater"
                                    >
                                        <Button className="__extra_btn_cls_upload">
                                            Select File to upload
                                        </Button>
                                    </Upload>
                                    <Popconfirm
                                        title="Warning"
                                        description={
                                            <p>
                                                You are about to change the configurations. <br />
                                                This action will cause LiLaQ to restart, <br />
                                                which may interrupt ongoing processes. <br />
                                                Are you sure you want to proceed?
                                            </p>
                                        }
                                        onConfirm={handleImport}
                                        onCancel={cancel}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            className="__extra_btn_cls"
                                            style={{ border: 0, color: "#fff", marginBottom: "20px" }}
                                        >
                                            Update
                                        </Button>
                                    </Popconfirm>
                                </div>
                                <Button
                                    className="__extra_btn_cls"
                                    style={{
                                        border: 0,
                                        color: "#fff",
                                        marginBottom: "20px",
                                        width: "180px",
                                    }}
                                    onClick={() => handleExport()}
                                >
                                    Export Current Darkmater
                                </Button>
                            </div>
                        </TabPane>

                        <TabPane tab={"Manage Shades"} key="2">

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', gap: '20px' }}>
                                {/* <Button type="primary" ghost style={{ color: '#fff', borderColor: '#fff3', marginTop: '0' }} className="__extra_btn_cls" icon={<PlusCircleOutlined />} onClick={() => handleAddAgent()}>Add</Button> */}
                                <div style={{ width: '300px' }}>
                                    <SelectBox
                                        options={teamsList}
                                        value={teamSelected}
                                        handleOnChange={handleOnChangeTeam}
                                        placeholder='Select team'
                                        showSearch={true}
                                    />
                                </div>
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            {items.map((mode) => (
                                                <Menu.Item
                                                    key={mode.key}
                                                    onClick={mode.onClick}
                                                    icon={mode.icon}
                                                    disabled={mode.disabled}
                                                >
                                                    {mode.label}
                                                </Menu.Item>
                                            ))}
                                        </Menu>
                                    }
                                    trigger={["click"]}
                                    placement={'bottomRight'}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Button
                                            ghost
                                            icon={<FontAwesomeIcon icon={faAdd} />}
                                            style={{ borderColor: "#ffffff1f", height: '45px' }}
                                        >
                                            Add
                                        </Button>
                                    </a>
                                </Dropdown>
                            </div>
                            <Table
                                {...config}
                                rowKey={(record, index) => index}
                                dataSource={listAgents}
                                loading={loadingShades}
                                className="__market_table"
                            >
                                <Column
                                    title={"Logo"}
                                    dataIndex="agentlogo"
                                    key="agentlogo"
                                    className="___col"
                                    render={(agentlogo) => (
                                        <img
                                            src={agentlogo}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "40px",
                                                objectFit: "cover",
                                            }}
                                        />
                                    )}
                                />
                                <Column
                                    title={"Name"}
                                    dataIndex="agentname"
                                    key="agentname"
                                    className="___col"
                                />
                                <Column
                                    title={"Role"}
                                    dataIndex="role"
                                    key="role"
                                    className="___col"
                                />
                                <Column
                                    title={"Backstory"}
                                    dataIndex="backstory"
                                    key="backstory"
                                    className="___col"
                                    render={(text, record) => (
                                        <Tooltip title={record?.backstory}>
                                            {" "}
                                            <span className="__double_line">{record?.backstory}</span>
                                        </Tooltip>
                                    )}
                                />
                                <Column
                                    title={"Description"}
                                    dataIndex="description"
                                    key="description"
                                    className="___col"
                                    render={(text, record) => (
                                        <Tooltip title={record?.description}>
                                            {" "}
                                            <span className="__double_line">
                                                {record?.description}
                                            </span>
                                        </Tooltip>
                                    )}
                                />

                                <Column
                                    title={"Goal"}
                                    dataIndex="goal"
                                    key="goal"
                                    className="___col"
                                    render={(text, record) => (
                                        <Tooltip title={record?.goal}>
                                            {" "}
                                            <span className="__double_line">{record?.goal}</span>
                                        </Tooltip>
                                    )}
                                />

                                <Column
                                    title="Actions"
                                    key="action"
                                    render={(text, record) => (
                                        <div className="table-action">
                                            <EditOutlined
                                                onClick={() => handleEdit(record)}
                                                style={{ fontSize: "18px", color: "#03A9F4" }}
                                            />
                                            <Popconfirm
                                                title={`Are you sure to delete: ${record?.role}?`}
                                                onConfirm={() => handleDelete(record)}
                                                okText="Delete"
                                                cancelText="No"
                                                placement="left"
                                            >
                                                <DeleteOutlined
                                                    style={{ fontSize: "18px", color: "#F44336" }}
                                                />
                                            </Popconfirm>
                                        </div>
                                    )}
                                />
                            </Table>
                        </TabPane>

                        <TabPane tab={"Manage Roles"} key="3">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginBottom: "20px",
                                    gap: "20px",
                                }}
                            >
                                <Button
                                    type="primary"
                                    ghost
                                    style={{
                                        color: "#fff",
                                        borderColor: "#fff3",
                                        marginTop: "0",
                                    }}
                                    className="__extra_btn_cls"
                                    icon={<PlusCircleOutlined />}
                                    onClick={() => handleAddRole()}
                                >
                                    Add
                                </Button>
                            </div>

                            <Table
                                {...configRoles}
                                rowKey={(record, index) => index}
                                dataSource={listRoles}
                                loading={loadingRoles}
                                className="__market_table"
                            >
                                <Column
                                    title={"Role Name"}
                                    dataIndex="roleName"
                                    key="roleName"
                                    className="___col"
                                />

                                <Column
                                    title="Actions"
                                    key="action"
                                    render={(text, record) => (
                                        <div className="table-action">
                                            <EditOutlined
                                                onClick={() => handleEditRole(record)}
                                                style={{ fontSize: "18px", color: "#03A9F4" }}
                                            />
                                            <Popconfirm
                                                title={`Are you sure to delete: ${record?.roleName}?`}
                                                onConfirm={() => handleDeleteRole(record)}
                                                okText="Delete"
                                                cancelText="No"
                                                placement="left"
                                            >
                                                <DeleteOutlined
                                                    style={{ fontSize: "18px", color: "#F44336" }}
                                                />
                                            </Popconfirm>
                                        </div>
                                    )}
                                />
                            </Table>
                        </TabPane>
                    </Tabs>
                </div>
            </div>

            <ShadeAddEditModel
                open={showModelAgent}
                onClose={() => setShowModalAgent(false)}
                teamName={teamSelected}
            />
            <AddTeam
                open={showTeam}
                onClose={() => setShowTeam(false)}
            />
            <AddEditRole open={showRole} onClose={() => setShowRole(false)} roleId={roleIdSelected} listRoles={getRoles} />
        </>
    );
};

export default ConfigDarkMater;
