const awsconfig = {
  Auth: {
    Cognito: {
      userPoolClientId: '55d549vhufej36k1bc9obe4opd',
      userPoolId: 'us-east-1_krhNcGqi6',
      region: 'us-east-1',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH'
    }
  }
}

export default awsconfig;
