// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Upload, notification, Switch } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { faClose, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectBox from 'components/commonComponents/SelectBox';

import AWS from 'assets/images/storage/amazon-s3.svg'
import Local from 'assets/images/storage/file.svg'
import GoogleDrive from 'assets/images/storage/google-drive.svg'
import CloudStore from 'assets/images/storage/google-cloud.svg'
import Asure from 'assets/images/storage/azure-storage-blob.svg'
import PathTree from '../PathTree';



const openNotificationWithIcon = (title, message) => {
  notification["error"]({
    message: title,
    description: message,
  });
};

const UploadDataMart = (props) => {
  const { onClose, open, title, description } = props;
  const { userDetails, dataMartItem } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [file, setFile] = useState([]);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('aws')
  const [toggle, setToggle] = useState(true)
  const [openTree, setOpenTree] = useState(false)
  const [path, setPath] = useState('')

  const { datamartList } = dataMartItem

  const handleUpload = async () => {
    if (type === 'local') {
      const errors = {
        name: !name,
        file: !file
      };
      setError(errors);
      const isFormValid = Object.values(errors).every((item) => !item);
      if (!isFormValid) return;

      setLoading(true);
      let formd = new FormData();
      formd.append('file', file);
      formd.append('username', userDetails);
      formd.append('datasetName', name);
      formd.append('mode', 'local');
      formd.append("relation", toggle ? "True" : "False");
      formd.append('type', 'raw data');

      const formDataObject = {};
      for (let key of formd.keys()) {
        formDataObject[key] = formd.get(key);
      }

      // Log the plain object to the console
      const res = await fetchData(restAPIs.uploadMeta(formd));
      if (res.statusCode === 200) {
        setLoading(false);
        datamartList();
        onClose();
      } else {
        setLoading(false);
        openNotificationWithIcon(res?.message);
      }
    }
    if (type === 'aws') {
      const errors = {
        name: !name,
        path: !path
      };
      setError(errors);
      const isFormValid = Object.values(errors).every((item) => !item);
      if (!isFormValid) return;

      setLoading(true);
      let formd = new FormData();
      formd.append('awspath', path);
      formd.append('username', userDetails);
      formd.append('datasetName', name);
      formd.append('mode', 's3');
      formd.append("relation", toggle ? true : false);

      const formDataObject = {};
      for (let key of formd.keys()) {
        formDataObject[key] = formd.get(key);
      }

      // Log the plain object to the console
      const res = await fetchData(restAPIs.uploadMeta(formd));
      if (res.statusCode === 200) {
        setLoading(false);
        datamartList();
        onClose();
      } else {
        setLoading(false);
        openNotificationWithIcon(res?.message);
      }
    }

  };

  const uploadProps = {

    onRemove: (file) => {
      setFile('');
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    file,
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });

    if (key === 'path') {
      setPath(value)
    }
  };

  const { name } = data;

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setFile('');
      setData({})
      setPath('')
      onClose();
    }
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const typeList = [
    {
      id: 'aws',
      label: 'AWS S3',
      icon: <img src={AWS} alt="AWS" style={{ width: '18px' }} />
    },
    {
      id: 'azure',
      label: 'Azure',
      icon: <img src={Asure} alt="Azure" style={{ width: '18px' }} />
    },
    {
      id: 'cloud',
      label: 'Google Cloud',
      icon: <img src={CloudStore} alt="Google Cloud" style={{ width: '18px' }} />
    },
    {
      id: 'google',
      label: 'Google Drive',
      icon: <img src={GoogleDrive} alt="Google Drive" style={{ width: '18px' }} />
    },
    {
      id: 'local',
      label: 'Local',
      icon: <img src={Local} alt="Local" style={{ width: '18px' }} />
    }
  ]

  const handleOnChangeType = (value) => {
    setType(value);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Register Your Data</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc'>

            <SelectBox
              options={typeList}
              value={type}
              handleOnChange={handleOnChangeType}
              label='Choose type'
            />

            <div style={{ marginTop: '20px' }}>
              <InputField
                onChange={handleOnChange("name")}
                value={name}
                type="text"
                placeholder={'Dataset Name'}
                label={'Dataset Name'}
                error={error.name}
              />
            </div>

            {type !== 'local' && <div style={{ marginTop: '20px', display: 'grid', gridTemplateColumns: '1fr 100px', gap: '10px', alignItems: 'center' }}>
              <InputField
                onChange={handleOnChange("path")}
                value={path}
                type="text"
                placeholder={'Enter the path'}
                label={'Path'}
                error={error.path}
              />

              <Button ghost className="__extra_btn_cls" style={{ width: '100px', marginTop: '0' }} onClick={() => setOpenTree(true)}>Choose</Button>
            </div>}


            {type === 'local' && <><div style={{ fontSize: '12px', marginBottom: '10px' }}>File</div>
              <Upload {...uploadProps} maxCount={1} multiple={false}>
                <Button className="__extra_btn_cls_upload" icon={<InboxOutlined />}>Select File to upload</Button>
              </Upload>
            </>
            }<div
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "10px" }}>Relations:</span>
              <Switch
                onChange={handleToggle}
                checkedChildren="On"
                unCheckedChildren="Off"
                defaultChecked={true}
              />
            </div>
            <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={handleUpload}>Register</Button>

          </div>
        </div>
        <PathTree open={openTree} onClose={() => setOpenTree(false)} basePath='browseS3' setPath={setPath} />
      </Modal>
    </>
  );
};

export default UploadDataMart;

UploadDataMart.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};
