
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, Tree, message } from 'antd';
import { faClose, faDatabase } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const QuestionView = (props) => {
  const { onClose, open } = props;
  const { questionQuickView } = useSelector((store) => store.commonData);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      dispatch({
        type: 'questionQuickView',
        value: null
      });
      onClose();
    }
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __qustinview_model'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Quick View </h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>

          <span className='__current_db'><a><button className='__button'><FontAwesomeIcon icon={faDatabase} /></button> {questionQuickView?.Datamart}</a></span>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'baseline' }}>
            
            <h2>{questionQuickView?.Question}</h2>
            <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {questionQuickView?.Status === 'inprogress' && (
                <div className='__inprogress'>
                  <div className="circles">
                    <div className="circle1"></div>
                    <div className="circle2"></div>
                    <div className="circle3"></div>
                  </div>
                </div>
              )}
              {questionQuickView?.Status === 'registered' && <div className='__registered'></div>}
              {questionQuickView?.Status === 'new' && <div className='__registered' style={{ backgroundColor: 'yellow' }}></div>}
            </span>
          </div>
          <div className='_max_vie'>
            <p>{questionQuickView?.Answer}</p>
            <p>{questionQuickView?.SQLQuery}</p>
          </div>


          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" onClick={() => handleClose()}>Close</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default QuestionView;

QuestionView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
