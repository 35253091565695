// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, Button, Checkbox, DatePicker, Modal, Switch, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { useSelector } from 'react-redux';
import TextArea from 'components/commonComponents/TextArea';

const AddEditApp = (props) => {
  const { userDetails, appData} = useSelector((store) => store.commonData);
  const { onClose, open,  listapps } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { appName, appDesc, appDetails, isActive, validFrom, validTo } = data;
  const [checked, setChecked] = useState(true)
  const [messageApi, contextHolder] = message.useMessage();


  useEffect(() => {
    if (appData?.isEdit || appData?.isClone) {
      setData(appData?.data)
    }
  }, [appData])

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError({})
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {
    const errors = {
      appName: !appName,
      appDesc: !appDesc,
      appDetails: !appDetails,
      validFrom: !validFrom,
      validTo: !validTo
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);
    const payload = {
      appName, 
      appDesc,
      appDetails,
      isActive,
      validFrom,
      validTo
    }
    if(appData?.isEdit) {
      payload.appId = appData?.data?.appId
    }
    const res = await fetchData(restAPIs.registerApp(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      listapps();
      handleClose();
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content' style={{ color: '#fff' }}>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Add App</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("appName")}
              value={appName}
              placeholder={'Enter app name'}
              label='App Name'
              error={error.appName}
              disabled={appData?.isEdit}
            />

            <TextArea
              onChange={handleOnChange("appDesc")}
              value={appDesc}
              type="text"
              placeholder={'Enter the description'}
              label={'App Description'}
              error={error.appDesc}
              disabled={appData?.isEdit}
            />

            <TextArea
              onChange={handleOnChange("appDetails")}
              value={appDetails}
              type="text"
              placeholder={'Enter the details of the app'}
              label={'What does this app do?'}
              error={error.appDetails}
              disabled={appData?.isEdit}
              
            />

            {/* New Switch for isActive */}
            <div className='field'>
              <label>Active Status</label>
              <Switch 
                checked={isActive} 
                onChange={(checked) => handleOnChange('isActive')(checked)} 
              />
            </div>

            {/* DatePicker for validFrom */}
            <div className='field'>
              <label>Valid From</label>
              <DatePicker
              showTime
                value={validFrom}
                onChange={(date) => handleOnChange('validFrom')(date)}
              />
              {error.validFrom && <span className="error">Valid From is required</span>}
            </div>

            {/* DatePicker for validTo */}
            <div className='field'>
              <label>Valid To</label>
              <DatePicker
                showTime
                value={validTo}
                onChange={(date) => handleOnChange('validTo')(date)}
              />
              {error.validTo && <span className="error">Valid To is required</span>}
            </div>

            <Button className='__extra_btn_cls' style={{ border: '1px solid #fff2', color: '#fff', marginTop: '10px' }} loading={loading} onClick={() => handleSubmit()} >Create</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditApp;

AddEditApp.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  listapps: PropTypes.func
};
