// Core
import React, { useEffect, useRef, useState } from 'react';

// Styles
import './styles.scss';

// Assets
import { faArrowLeft, faClose, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserOutlined } from '@ant-design/icons';
import { message, Button, Avatar } from 'antd';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';


const Typing = ({ text, delay = 6, onComplete }) => {
    const to = useRef();
    const [charIndex, setCharIndex] = useState(1);
    const chatDisplayRef = useRef(null);

    useEffect(() => {
        if (charIndex < text?.length) {
            to.current = setTimeout(() => setCharIndex(charIndex + 1), delay);
            if (typeof onComplete === "function") {
                onComplete();
            }
        } else {
            // Call onComplete when typing animation completes
            if (typeof onComplete === "function") {
                onComplete();
            }
        }
        return () => clearTimeout(to.current); // cleanup on unmount
    }, [charIndex]);


    const htmlContent = { __html: text?.substr(0, charIndex) };
    return <span dangerouslySetInnerHTML={htmlContent} ref={chatDisplayRef} />;
};



const FileChat = () => {
    const { userDetails } = useSelector((store) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [text, setText] = useState("");
    const [isInitial, setIsInitial] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const chatDisplayRef = useRef(null);
    const [msgHt, setMsgHt] = useState("");
    const [isTextareaFocused, setIsTextareaFocused] = useState(false);
    const textareaRef = useRef(null);
    const { documentName } = useParams();

    const scrollToBottom = () => {
        if (chatDisplayRef.current) {
            chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
        }
    };


    const handleOnKeyDown = (e) => {
        if (e.keyCode === 13 && isTextareaFocused) {
            e.preventDefault(); // Prevent default behavior of adding a new line
            if (text?.length > 0) {
                submitText();
            }
        }
    };


    useEffect(() => {
        var messageBody = document.querySelector("#containerBody");
        setMsgHt(messageBody);
        messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }, [msgHt]);

    useEffect(() => {
        // Scroll to the bottom of the chat display
        if (chatDisplayRef.current) {
            chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
        }
    }, [messageList]);

    const handleOnchange = (e) => {
        setText(e.target.value);
    };

    const handleTextareaFocus = () => {
        setIsTextareaFocused(true);
    };

    const handleTextareaBlur = () => {
        setIsTextareaFocused(false);
    };

    const submitText = async () => {
        setIsInitial(true);
        let msgListTemp = messageList;
        msgListTemp.push({
            type: "user",
            text: text,
        });

        setIsLoading(true);
        setText("");
        const res = await fetchData(restAPIs.queryDocument({ query: text, document: documentName }));
        msgListTemp.push({
            type: "response",
            question: text,
            text: res?.body,
            typingComplete: false,
        });
        setIsLoading(false);
        setMessageList(msgListTemp);
    };




    return (
        <>
            {contextHolder}
            <div className='___logSes_wrap'>
                <div className='__common_header_top'>
                    <div style={{ maxWidth: '70%' }}>
                        <h1>Talk to your document</h1>
                    </div>
                    <div className='__btns_' style={{ paddingRight: '20px' }}>
                        <button onClick={() => navigate(-1)}><FontAwesomeIcon icon={faClose} /></button>
                    </div>
                </div>

                <div className="___chat_container_wrapper">
                    <div className="__chat_display">
                        <div className="__container_head">
                            <div className="__left">
                                <span>{documentName}</span>

                            </div>
                        </div>
                        <div
                            className="__container_body"
                            id="containerBody"
                            ref={chatDisplayRef}
                        >
                            {isInitial ? (
                                <>
                                    {messageList?.map((item, index) => {
                                        return (
                                            <>
                                                {item?.type === "user" ? (
                                                    <div className="___use_resp_set" key={index} style={{ justifyContent: 'end', flexDirection: 'row-reverse' }}>
                                                        <div className="___user">
                                                            <Avatar
                                                                style={{ backgroundColor: "#87d068" }}
                                                                icon={<UserOutlined />}
                                                            />
                                                        </div>
                                                        <div className="___response" style={{ textAlign: 'right' }}>
                                                            <h2>You</h2>
                                                            <p>{item?.text}</p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="___use_resp_set" key={index}>
                                                        <div className="___user">
                                                            <Avatar
                                                                style={{ background: '#6200e9', fontWeight: 'bold' }}
                                                                size={33}
                                                            >L</Avatar>
                                                        </div>
                                                        <div className="___response">
                                                            <h2>LiLaQ</h2>
                                                            {
                                                                <>
                                                                    <p>
                                                                        <Typing
                                                                            text={item?.text}
                                                                            onComplete={() => scrollToBottom()}
                                                                        />
                                                                    </p>

                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                    {isLoading && (
                                        <>
                                            <p
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    gap: "11px",
                                                    marginLeft: "55px",
                                                }}
                                                key={1}
                                            >
                                                Generating Answer...
                                            </p>
                                        </>
                                    )}
                                </>
                            ) : ('')}
                        </div>
                    </div>

                    <div className="___send_form">
                        <input
                            className="___text_area"
                            placeholder="Ask me anything.!"
                            onChange={handleOnchange}
                            value={text}
                            onKeyDown={handleOnKeyDown}
                            onFocus={handleTextareaFocus}
                            onBlur={handleTextareaBlur}
                            ref={textareaRef}
                        />
                        {
                            <div className="__right_btn_actions">
                                <div className="__askbtn">
                                    <Button
                                        loading={isLoading}
                                        onClick={() => submitText()}
                                        disabled={!text.length || isLoading}
                                        icon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    >
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    );
};

export default FileChat;

