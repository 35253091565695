
// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { Button, Modal, message } from 'antd';
import InputField from 'components/commonComponents/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LilBotAddEditModel = (props) => {
  const { onClose, open } = props;
  const { userDetails, lilBotUserData } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (lilBotUserData?.isEdit) {
      setData(lilBotUserData?.dataSet);
    }
  }, [lilBotUserData]);

  const handleUpload = async () => {
    const errors = {
      fname: !data.fname,
      lname: !data.lname,
      email: !data.email,
      userId: !data.userId
    };

    setError(errors);

    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;

    const payload = {
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      userId: data.userId
    };

    if (lilBotUserData?.isEdit) payload.memberId = lilBotUserData?.dataSet?.memberId;

    setLoading(true);


    const res = lilBotUserData?.isEdit
      ? await fetchData(restAPIs.editLilbotMember(payload))
      : await fetchData(restAPIs.createLilbotMember(payload));

    setLoading(false);
    if (res?.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: res.message,
      });
      lilBotUserData?.lilbotMembers();
      lilBotUserData?.listActAgentsLilBotInput();
      handleClose();
    } else {
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }

  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({});
      dispatch({
        type: "lilBotUserData",
        value: null,
      });
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __height_reduced __agent'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>{lilBotUserData?.isEdit ? 'Edit' : 'Add'} LilBot Workforce</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='.____common_model_class_desc __agent_delm'>
          <InputField
              onChange={handleOnChange("userId")}
              value={data.userId}
              type="number"
              placeholder={'User Id'}
              label={'User Id'}
              error={error.userId}
            />
            <div></div>
            <InputField
              onChange={handleOnChange("fname")}
              value={data.fname}
              type="text"
              placeholder={'First Name'}
              label={'First Name'}
              error={error.fname}
            />
            <InputField
              onChange={handleOnChange("lname")}
              value={data.lname}
              type="text"
              placeholder={'Last Name'}
              label={'Lats Name'}
              error={error.lname}
            />
            <InputField
              onChange={handleOnChange("email")}
              value={data.email}
              type="email"
              placeholder={'Email'}
              label={'Email'}
              error={error.email}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff', marginTop: '10px' }} className="__extra_btn_cls" loading={loading} onClick={handleUpload}>{lilBotUserData?.isEdit ? 'Update' : 'Add'}</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LilBotAddEditModel;

LilBotAddEditModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
