// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Input, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteOutlined } from '@ant-design/icons';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
const DeleteModel = (props) => {
  const { onClose, open, modelName, getModels } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [inputValueDelete, setInputValueDelete] = useState('')
  const [loading, setLoading] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const confirmDelete = async () => {
    if (inputValueDelete === modelName) {
      setLoading(true)
      let payload = { "model": modelName };
      const res = await fetchData(restAPIs.deleteModel(payload));
      setLoading(false)
      getModels();
      setInputValueDelete('');
      onClose();
    } else {
      messageApi.open({
        type: 'error',
        content: 'Input value does not match the required value',
      });
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class ___deleteModel'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Delete</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='__model_description'>
            <DeleteOutlined style={{ color: 'red', fontSize: '35px' }} />
            <p>You are about to delete <b>'{modelName}'</b>. This action cannot be reverted.</p>
            <p style={{ fontWeight: '300', fontSize: '14px' }}>To confirm, please type <strong>{modelName}</strong> exactly</p>
            <Input
              placeholder={`Type '${modelName}' to delete`}
              value={inputValueDelete}
              onChange={(e) => setInputValueDelete(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" style={{ color: '#fff' }} className="__extra_btn_cls" loading={loading} disabled={inputValueDelete !== modelName} onClick={confirmDelete}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteModel;

DeleteModel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  modelName: PropTypes.string,
  getModels: PropTypes.func
};
