// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { AutoComplete, DatePicker, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

import { Form, Input, Radio, TimePicker, Select, Button, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
const { Option } = Select;

const InsightForm = (props) => {
  const { userDetails } = useSelector(
    (store) => store.commonData
  );
  const { onClose, open, cardSelected, questionId, dataForColumn } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [dataForValues, setDataForValues] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const onFinish = async (values) => {

    let payload = {
      "Qstn_id": cardSelected?.Qstn_id,
      "insightName": values.insightName,
      "legendColumn": values.legendColumn,
      "legendValues": values.legendValues,
      "chartAttributes": values.chartAttributes,
      "minute": values.minute ? values.minute : '*',
      "hour": values.hour ? values.hour : '*',
      "day": values.day ? values.day : '*',
      "month": values.month ? values.month : '*',
      "dayOfWeek": values.dayOfWeek ? values.dayOfWeek : '*',
      "year": values.year ? values.year : '*',
      "username": userDetails,
      "Question": cardSelected?.Question,
      "Answer": cardSelected?.Answer,
      "versionId": "",
      "versionLabel": ""
    }

    const res = await fetchData(restAPIs.addToMyInsights(payload));
    if (res?.statusCode === 200) {
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      handleClose();
    }

  };

  const handleChangeLegend = (value) => {
    setDataForValues((prev) => dataForColumn.map((item) => item[value]));
  }

  const formatTwoDigits = (value) => value.toString().padStart(2, '0');

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___anana' style={{ color: '#fff' }}>

            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item label="Insight Name" name="insightName" rules={[{ required: true, message: 'Please enter insight name' }]}>
                <Input placeholder="Enter insight name" />
              </Form.Item>

              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                <Form.Item label="Day of week" name="dayOfWeek" style={{ fontSize: '10px', marginBottom: '0' }}>
                  <Select placeholder="Every week" allowClear>
                    <Option value="sun">Sunday</Option>
                    <Option value="mon">Monday</Option>
                    <Option value="tue">Tuesday</Option>
                    <Option value="wed">Wednesday</Option>
                    <Option value="thu">Thursday</Option>
                    <Option value="fri">Friday</Option>
                    <Option value="sat">Saturday</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="Month" name="month" style={{ fontSize: '10px', marginBottom: '0' }}>
                  <Select placeholder="Every month" allowClear>
                    {[...Array(12)].map((_, i) => (
                      <Option key={i + 1} value={i + 1}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Day of month" name="day" style={{ fontSize: '10px', marginBottom: '0' }}>
                  <Select placeholder="Every day" allowClear>
                    {[...Array(31)].map((_, i) => (
                      <Option key={i + 1} value={i + 1}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Year"
                  name="year"
                  style={{ fontSize: '10px', marginBottom: '0' }}
                  rules={[
                    {
                      validator: (_, value) => {
                        const currentYear = dayjs().year();
                        if (value && value < currentYear) {
                          return Promise.reject(new Error(`Year cannot be less than ${currentYear}`));
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                >
                  <Input
                    placeholder="Every Year"
                    type='number'
                    min={dayjs().year()}  // dynamically setting the min to the current year
                  />
                </Form.Item>

                <Form.Item label="Hour" name="hour" style={{ fontSize: '10px', }}>
                  <Select placeholder="Every hour" allowClear>
                    {[...Array(24)].map((_, i) => (
                      <Option key={i} value={i}>
                        {formatTwoDigits(i)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item label="Minute" name="minute" style={{ fontSize: '10px', }}>
                  <Select placeholder="Every minute" allowClear>
                    {[...Array(60)].map((_, i) => (
                      <Option key={i} value={i}>
                        {formatTwoDigits(i)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>


              </div>

              {/* Legend - Required Field */}
              <Form.Item
                label="Legend"
                name="legendColumn"
                rules={[{ required: true, message: 'Please select a legend column!' }]}
              >
                <Select placeholder="Select a column from the list" onChange={handleChangeLegend}>
                  {dataForColumn && Object.keys(dataForColumn[0]).map((column, key) => (
                    <Option key={key} value={column}>
                      {column}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Legend Values - Required Field */}
              <Form.Item
                label="Legend Values"
                name="legendValues"
                rules={[{ required: true, message: 'Please select legend values!' }]}
              >
                <Select mode="multiple" placeholder="Column values">
                  {dataForValues && dataForValues.map((column, index) => (
                    <Option key={index} value={column}>
                      {column}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Chart Attributes - Required Field */}
              <Form.Item
                label="Chart Attributes"
                name="chartAttributes"
                rules={[{ required: true, message: 'Please select chart attributes!' }]}
              >
                <Select mode="multiple" placeholder="Chart attributes">
                  {dataForColumn && Object.keys(dataForColumn[0]).map((column) => (
                    <Option key={column} value={column}>
                      {column}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Submit Button */}
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginTop: '20px' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>

          </div>
        </div>
      </Modal>
    </>
  );
};

export default InsightForm;

InsightForm.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  cardSelected: PropTypes.any,
  questionId: PropTypes.string,
  dataForColumn: PropTypes.any,
};
