// Core
import React, { useEffect, useRef, useState } from "react";

// Styles
import "./styles.scss";

// assets
import {
  faAdd,
  faClone,
  faCloudUpload,
  faComment,
  faCopy,
  faDatabase,
  faDiagramProject,
  faEye,
  faIndent,
  faInfo,
  faInfoCircle,
  faPencil,
  faRightLeft,
  faRotateRight,
  faShapes,
  faStreetView,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DatabaseOutlined, EyeOutlined, MoreOutlined, SettingOutlined, InfoCircleOutlined, TableOutlined } from "@ant-design/icons";

import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import UploadDataMart from "components/modals/UploadDataMart";
import {
  Button,
  Dropdown,
  Menu,
  Checkbox,
  Table,
  Tabs,
  Tooltip,
  message,
  Popconfirm,
  Switch,
  Radio,
} from "antd";
import { useDispatch } from "react-redux";
import ImageDisplay from "components/modals/ImageDisplay";
import ModelTransform from "components/modals/ModelTransform";
import ThreeSixtyView from "components/modals/ThreeSixtyView";
import AccessModel from "components/modals/AccessModel";
import ContributionModel from "components/modals/ContributionModel";
import TalkFileUpload from "components/modals/TalkFileUpload";
import { useNavigate } from "react-router-dom";
import AskLilaq from "components/modals/AskLilaq";
import SystemIntegrationModal from "components/modals/SystemIntegrationModal";
import DeleteDataMart from "components/modals/DeleteDataMart";
import { useSelector } from "react-redux";
import GenerateSystemData from "components/modals/GenerateSystemData";
import AddEditApp from "components/modals/AddEditApp";
import DeleteApp from "components/modals/DeleteApp";
import LilacBlue from "components/modals/LilacBlue";
import AppViewHtmlContent from "components/modals/AppViewHtmlContent";
import InfoModal from "components/modals/InfoModal";

const RadioGroup = Radio.Group;
const { Column } = Table;
const { TabPane } = Tabs;

const DataMarketPlace = () => {
  const { userPermissions } = useSelector((store) => store.commonData);
  const [showMeta, setShowMeta] = useState(false);
  const [listOfDataMart, setListDataMart] = useState([]);
  const [listOfDataMartDocument, setListDataMartDocument] = useState([]);
  const [loading, setLoading] = useState("");
  const [loadingERD, setLoadingERD] = useState("");
  const [loadingAccess, setLoadingAccess] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedValuesDocument, setCheckedValuesDocument] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paginationData, setPaginationData] = useState({
    page: 1,
    totalItems: 0,
  });
  const { totalItems, page } = paginationData;
  const config = {
    pagination: {
      total: totalItems,
      default: 1,
      pageSize: 8,
      showSizeChanger: false,
    },
  };
  const [show, setShow] = useState(false);
  const [showModel, setAccessModelShow] = useState(false);
  const [accessData, setAccessData] = useState("");
  const [showModelTransform, setModelTransform] = useState(false);
  const [showModelContribution, setContributionModel] = useState(false);
  const [taklFileUpload, setTalkFileUpload] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState("");
  const [selectionMode, setSelectionMode] = useState("");
  const [askLilaq, setAskLilaq] = useState(false);

  const [isLoadingIntegration, setListLoadingIntegration] = useState(false);
  const [integrationList, setIntegrationList] = useState([]);
  const [integrationOpen, setIntegrationOpen] = useState(false);

  const [actvtiveDatamart, setActiveDatamart] = useState("");
  const [deleteDatamartShow, setDeleteDatamartShow] = useState(false);

  const [generateSystemData, setGenerateSystemData] = useState(false);
  const intervalIdRef = useRef(null);

  const [listLoadingApps, setListLoadingApps] = useState(false);
  const [listApps, setListApps] = useState([]);

  const [registerApp, setRegisterApp] = useState(false);
  const [showImgApp, setShowImgApp] = useState(false);
  const [dataImgApp, setDataImgApp] = useState("");
  const [deleteApp, setDeleteApp] = useState(false);
  const [activeApp, setActiveApp] = useState("");

  const [showHtmlContent, setShowHtmlContent] = useState(false);
  const [htmlContent, setHtmlContent] = useState("");

  const [infoContent, setInfoContent] = useState("");
  const [showInfo, setShowInfo] = useState(false);



  const datamartList = async () => {
    setListLoading(true);
    const res = await fetchData(restAPIs.listDataMart());
    if (res.statusCode === 200) {
      let items = [];
      let items2 = [];
      let checked = [];
      let checkedDocu = [];
      for (let i = 0; i < res?.body?.items.length; i++) {
        const element = res?.body.items[i];
        if (element.type === "document") {
          items2.push({
            label: element.datamartName,
            value: element.datamartName,
            id: element.datamartName,
            ...element,
          });
          if (element.isActive) checkedDocu.push(element.datamartName);
        } else {
          items.push({
            label: element.datamartName,
            value: element.datamartName,
            id: element.datamartName,
            ...element,
          });
          if (element.isActive) checked.push(element.datamartName);
        }
      }
      setListLoading(false);
      setSelectionMode(res?.body?.isMultiple ? "multiple" : "single");
      dispatch({
        type: "isMultipleStore",
        value: res?.body?.isMultiple,
      });
      setListDataMart(items);
      setListDataMartDocument(items2);
      setCheckedValues(checked);
      setCheckedValuesDocument(checkedDocu);
    }

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    // Start a new interval
    intervalIdRef.current = setInterval(() => {
      datamartList();
    }, 30000);
  };

  const dataIntegration = async () => {
    setListLoadingIntegration(true);
    const res = await fetchData(restAPIs.listSystemIntegration());
    console.log("res", res);
    if (res.statusCode === 200) {
      setListLoadingIntegration(false);
      setIntegrationList(res?.body);
    }
  };

  useEffect(() => {
    datamartList();
    dataIntegration();
    listapps();
  }, []);

  const handleLoadData = async (key, data) => {
    setLoading(key);
    let payload = {
      dataMart: data,
    };
    const res = await fetchData(restAPIs.loadDataMart(payload));
    if (res.statusCode === 200) {
      setLoading("");
      messageApi.open({
        type: "success",
        content: res?.message,
      });
      datamartList();
    }
  };

  const handleAdd = () => {
    setShowMeta(true);
    dispatch({
      type: "dataMartItem",
      value: { datamartList },
    });
  };

  const handleCloseMeta = () => {
    setShowMeta(false);
  };

  const [dataImg, setDataImg] = useState("");
  const [showImg, setShowImg] = useState(false);
  const [dataMartIsLoading, setdataMartIsLoading] = useState(false);

  const handleERD = async (key, datamartName) => {
    setLoadingERD(key);
    const res = await fetchData(
      restAPIs.generateSchema({ datamart: datamartName })
    );
    if (res?.statusCode === 200) {
      setDataImg(res?.body);
      setLoadingERD("");
      setShowImg(true);
    }
    setLoadingERD("");
  };

  const handleDropdownClick = (e) => {
    setModelTransform(true);
  };

  const [threeSixty, setThreeSixty] = useState(false);

  const handleThreeSixty = () => {
    setThreeSixty(true);
  };

  const handleFileUpload = () => {
    dispatch({
      type: "dataMartItem",
      value: { datamartList },
    });
    setTalkFileUpload(true);
  };

  const handleSystemIntegration = () => {
    dispatch({
      type: "systemIntegration",
      value: { dataIntegration },
    });
    setIntegrationOpen(true);
  };


  const handleAddApp = (data) => {

    dispatch({
      type: "appData",
      value: {
        isEdit: false,
        data: null,
        isClone: false
      }
    });

    setRegisterApp(true);
  }

  const handleEditApp = (data) => {

    console.log("called");

    dispatch({
      type: "appData",
      value: {
        isEdit: true,
        data: data,
        isClone: false
      }
    });

    setRegisterApp(true);
  }

  const handleCloneApp = (data) => {
    dispatch({
      type: "appData",
      value: {
        isEdit: false,
        data: data,
        isClone: true
      }
    });

    setRegisterApp(true);
  }

  const handleDeleteApp = async (app) => {
    setActiveApp(app?.appName);
    setDeleteApp(true);
  }



  const items = [
    {
      label: "Raw Data Source",
      key: "bar",
      icon: <DatabaseOutlined />,
      onClick: handleAdd,
    },
    // {
    //   label: "Data View",
    //   key: "360",
    //   icon: <FontAwesomeIcon icon={faStreetView} />,
    //   onClick: handleThreeSixty,
    // },
    {
      label: "Model Run",
      key: "model",
      icon: <FontAwesomeIcon icon={faRightLeft} />,
      onClick: handleDropdownClick,
    },
    {
      label: "Multimodal", //'Document',
      key: "document",
      icon: <FontAwesomeIcon icon={faCloudUpload} />,
      onClick: handleFileUpload,
    },
    {
      label: "System Integration",
      key: "system",
      icon: <FontAwesomeIcon icon={faIndent} />,
      onClick: handleSystemIntegration,
    },
    {
      label: "Ask LilaQ to Register Data",
      key: "document",
      icon: <FontAwesomeIcon icon={faAdd} />,
      onClick: () => setAskLilaq(true),
    },
    {
      label: "Generate Sample Datasets ",
      key: "360",
      icon: <FontAwesomeIcon icon={faStreetView} />,
      onClick: () => setGenerateSystemData(true),
    },
    {
      label: "Register App ",
      key: "app",
      icon: <FontAwesomeIcon icon={faShapes} />,
      onClick: handleAddApp,
    },

  ];

  const items2 = [
    {
      label: "Raw Data Source",
      key: "bar",
      icon: <DatabaseOutlined />,
      onClick: handleAdd,
    },
  ];

  const handleAccess = async (key, datamartName) => {
    setAccessData(datamartName);
    dispatch({
      type: "datamartName",
      value: datamartName,
    });
    setAccessModelShow(true);
  };

  const handleDataMartype = async (key, datamartName) => {
    setAccessData(datamartName);
    dispatch({
      type: "datamartName",
      value: datamartName,
    });
    setContributionModel(true);
  };
  const handleCheckboxChange = (checkedValues) => {
    setCheckedValues(checkedValues);
  };

  const handleRadioChange = (e) => {
    setCheckedValues([e.target.value]);
  };

  const handleCheckboxChangeDocument = (checkedValues) => {
    setCheckedValuesDocument(checkedValues);
  };

  const handleRadioChangeDocument = (e) => {
    setCheckedValuesDocument([e.target.value]);
  };

  const handleUpdateDatamart = async () => {
    const datamarts = [...listOfDataMart, ...listOfDataMartDocument].map(
      (name) => ({
        datamartName: name.datamartName,
        active: false,
      })
    );

    const convertedArray = [...checkedValues, ...checkedValuesDocument].map(
      (name) => ({
        datamartName: name,
        active: true,
      })
    );

    const updatedArray = datamarts.map((item1) => {
      const match = convertedArray.find(
        (item2) => item2.datamartName === item1.datamartName
      );
      if (match) {
        return { ...item1, active: match.active };
      }
      return item1;
    });
    setdataMartIsLoading(true);
    const res = await fetchData(
      restAPIs.updateDataMart({ dataMart: updatedArray })
    );
    if (res?.statusCode === 200) {
      setdataMartIsLoading(false);
      messageApi.open({
        type: "success",
        content: res?.message,
      });
    }
  };

  const handleDelete = async (i, datamartName) => {
    setActiveDatamart(datamartName);
    setDeleteDatamartShow(true);
  };

  const cancel = (e) => {
    console.log(e);
  };


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const listapps = async () => {
    setListLoadingApps(true);
    const res = await fetchData(restAPIs.listApps());
    if (res.statusCode === 200) {
      setListLoadingApps(false);
      setListApps(res?.body);
    }
  }
  const handleViewAppLink = (record) => {
    setDataImgApp(record.appUrl);
    setShowImgApp(true);
  }


  useEffect(() => {
    dispatch({
      type: "headerName",
      value: 'Data Garage',
    });
  }, [])


  useEffect(() => {
    // Start the interval when the component mounts
    intervalIdRef.current = setInterval(() => {
      datamartList();
    }, 30000); // 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalIdRef.current);
  }, []);

  const handleCopy = (record) => {
    navigator.clipboard.writeText(record.appUrl);
    messageApi.open({
      type: "success",
      content: "Link copied to clipboard",
    });
  }

  const handleTableContent = async(record) => {  
    let payload = {
      appId: record?.appId,
    };
    const res = await fetchData(restAPIs.viewAppResponses(payload));
    setHtmlContent(res);
    setShowHtmlContent(true);
  }


  return (
    <>
      <div className="__data_charts">
        <div className="__add_btn">
          {userPermissions && userPermissions?.includes("register_data") && (
            <Dropdown
              overlay={
                <Menu>
                  {items.map((mode) => (
                    <Menu.Item
                      key={mode.key}
                      onClick={mode.onClick}
                      icon={mode.icon}
                      disabled={mode.disabled}
                    >
                      {mode.label}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Button
                  ghost
                  icon={<FontAwesomeIcon icon={faAdd} />}
                  style={{ borderColor: "#ffffff1f" }}
                >
                  Register New
                </Button>
              </a>
            </Dropdown>
          )}
          <span className="__switch">
            Multiple Select{" "}
            <Switch
              checked={selectionMode === "multiple"}
              onChange={() =>
                setSelectionMode(
                  selectionMode === "multiple" ? "single" : "multiple"
                )
              }
            />
          </span>
          {selectionMode === "multiple" && (
            <Button
              type="primary"
              className="__extra_btn_cls"
              style={{
                margin: 0,
                height: "35px",
                border: "1px solid #fff2",
                color: "#fff",
              }}
              onClick={() => handleUpdateDatamart()}
              disabled={
                checkedValues.length === 0 &&
                checkedValuesDocument.length === 0
              }
              loading={dataMartIsLoading}
            >
              Update
            </Button>
          )}
          <Button
            type="primary"
            ghost
            style={{ margin: 0, marginRight: "10px", border: 0 }}
            icon={<FontAwesomeIcon icon={faRotateRight} />}
            onClick={() => datamartList()}
            loading={listLoading}
          >
            Refresh
          </Button>
        </div>

        <div>

          <Tabs className="__tabs2" tabPosition={"top"} defaultActiveKey="1">
            <TabPane tab={"Datasource"} key="1">
              {selectionMode === "multiple" ? (
                <Checkbox.Group
                  onChange={handleCheckboxChange}
                  value={checkedValues}
                >
                  {listOfDataMart &&
                    listOfDataMart.map((option, i) => (
                      <Checkbox
                        key={option.datamartName}
                        value={option.datamartName}
                        style={{ position: "relative" }}
                        checked={option?.checked}
                      >
                        <div className="__check_bx_one">
                          <button className="__button">
                            <FontAwesomeIcon icon={faDatabase} />
                          </button>
                          <span className="__text">
                            <Tooltip title={option.datamartName}>
                              {" "}
                              <p>
                                {option.datamartName}{" "}
                                {option?.isActive ||
                                  (option?.checked && <a>Active</a>)}
                              </p>
                            </Tooltip>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {option?.type !== "document" && (
                                <>
                                  <Button
                                    loading={i === loadingERD}
                                    ghost
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faDiagramProject}
                                      />
                                    }
                                    className="__orange_color __erd"
                                    onClick={() =>
                                      handleERD(i, option.datamartName)
                                    }
                                  ></Button>
                                  <Button
                                    loading={i === loadingAccess}
                                    ghost
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    className="__yellow_color"
                                    onClick={() =>
                                      handleAccess(i, option.datamartName)
                                    }
                                  ></Button>
                                  {option?.type === "modelrun" && (
                                    <Button
                                      loading={i === loadingAccess}
                                      ghost
                                      icon={<FontAwesomeIcon icon={faEye} />}
                                      className="__blue_color"
                                      onClick={() =>
                                        handleDataMartype(
                                          i,
                                          option.datamartName
                                        )
                                      }
                                    ></Button>
                                  )}
                                  {option.status.toString().toLowerCase() ===
                                    "inprogress" && (
                                      <Button disabled className="__orange_color">
                                        In Progress
                                      </Button>
                                    )}
                                </>
                              )}
                              {option?.type === "document" && (
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faComment} />}
                                  className="__blue_color_dark"
                                  onClick={() =>
                                    navigate(
                                      "/chat-document/" + option?.datamartName
                                    )
                                  }
                                >
                                  Talk
                                </Button>
                              )}



                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key={'1'}
                                    >
                                      <a
                                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                                        onClick={() =>
                                          handleDelete(i, option.datamartName)
                                        }
                                      ><FontAwesomeIcon icon={faTrash} /> Delete</a>
                                    </Menu.Item>
                                  </Menu>
                                }
                                onClick={(e) => e.preventDefault()}
                                trigger={["click"]}
                              >
                                <Button
                                  ghost
                                  icon={<MoreOutlined />}
                                ></Button>
                              </Dropdown>

                            </div>
                          </span>
                        </div>
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              ) : (
                <Radio.Group
                  onChange={handleRadioChange}
                  value={checkedValues[0]}
                >
                  {listOfDataMart &&
                    listOfDataMart.map((option, i) => (
                      <Radio.Button
                        key={option.datamartName}
                        value={option.datamartName}
                        style={{ position: "relative" }}
                        checked={option?.checked}
                      >
                        <div className="__check_bx_one">
                          <button className="__button">
                            <FontAwesomeIcon icon={faDatabase} />
                          </button>
                          <span className="__text">
                            <Tooltip title={option.datamartName}>
                              {" "}
                              <p>
                                {option.datamartName}{" "}
                                {option?.isActive && <a>Active</a>}
                              </p>
                            </Tooltip>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {option?.type !== "document" && (
                                <>
                                  <Button
                                    loading={i === loadingERD}
                                    ghost
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faDiagramProject}
                                      />
                                    }
                                    className="__orange_color"
                                    onClick={() =>
                                      handleERD(i, option.datamartName)
                                    }
                                  ></Button>
                                  <Button
                                    loading={i === loadingAccess}
                                    ghost
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    className="__yellow_color"
                                    onClick={() =>
                                      handleAccess(i, option.datamartName)
                                    }
                                  ></Button>
                                  {option?.type === "modelrun" && (
                                    <Button
                                      loading={i === loadingAccess}
                                      ghost
                                      icon={<FontAwesomeIcon icon={faEye} />}
                                      className="__blue_color"
                                      onClick={() =>
                                        handleDataMartype(
                                          i,
                                          option.datamartName
                                        )
                                      }
                                    ></Button>
                                  )}
                                  {option.status.toString().toLowerCase() ===
                                    "registered" && (
                                      <Button
                                        loading={i === loading}
                                        onClick={() =>
                                          handleLoadData(i, option.datamartName)
                                        }
                                      >
                                        Load
                                      </Button>
                                    )}
                                  {option.status.toString().toLowerCase() ===
                                    "inprogress" && (
                                      <Button disabled className="__orange_color">
                                        In Progress
                                      </Button>
                                    )}
                                </>
                              )}
                              {option?.type === "document" && (
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faComment} />}
                                  className="__blue_color_dark"
                                  onClick={() =>
                                    navigate(
                                      "/chat-document/" + option?.datamartName
                                    )
                                  }
                                >
                                  Talk
                                </Button>
                              )}

                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key={'2'}
                                    >
                                      <a
                                        style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                                        onClick={() =>
                                          handleDelete(i, option.datamartName)
                                        }
                                      ><FontAwesomeIcon icon={faTrash} /> Delete</a>
                                    </Menu.Item>
                                  </Menu>
                                }
                                onClick={(e) => e.preventDefault()}
                                trigger={["click"]}
                              >
                                <Button
                                  ghost
                                  style={{ padding: "0px" }}
                                  icon={<MoreOutlined />}
                                ></Button>
                              </Dropdown>
                            </div>
                          </span>
                        </div>
                      </Radio.Button>
                    ))}
                  <div></div>
                  <div></div>
                </Radio.Group>
              )}
            </TabPane>

            <TabPane tab={"Multimodal"} key="2">
              {selectionMode === "multiple" ? (
                <Checkbox.Group
                  onChange={handleCheckboxChangeDocument}
                  value={checkedValuesDocument}
                >
                  {listOfDataMartDocument &&
                    listOfDataMartDocument.map((option, i) => (
                      <Checkbox
                        key={option.datamartName}
                        value={option.datamartName}
                        style={{ position: "relative" }}
                        checked={option?.checked}
                      >
                        <div className="__check_bx_one">
                          <button className="__button">
                            <FontAwesomeIcon icon={faDatabase} />
                          </button>
                          <span className="__text">
                            <Tooltip title={option.datamartName}>
                              {" "}
                              <p>
                                {option.datamartName}{" "}
                                {option?.isActive && <a>Active</a>}
                              </p>
                            </Tooltip>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {option?.type !== "document" && (
                                <>
                                  <Button
                                    loading={i === loadingERD}
                                    ghost
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faDiagramProject}
                                      />
                                    }
                                    className="__orange_color"
                                    onClick={() =>
                                      handleERD(i, option.datamartName)
                                    }
                                  ></Button>
                                  <Button
                                    loading={i === loadingAccess}
                                    ghost
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    className="__yellow_color"
                                    onClick={() =>
                                      handleAccess(i, option.datamartName)
                                    }
                                  ></Button>
                                  {option?.type === "modelrun" && (
                                    <Button
                                      loading={i === loadingAccess}
                                      ghost
                                      icon={<FontAwesomeIcon icon={faEye} />}
                                      className="__blue_color"
                                      onClick={() =>
                                        handleDataMartype(
                                          i,
                                          option.datamartName
                                        )
                                      }
                                    ></Button>
                                  )}
                                  {option.status.toString().toLowerCase() ===
                                    "inprogress" && (
                                      <Button disabled className="__orange_color">
                                        In Progress
                                      </Button>
                                    )}
                                </>
                              )}
                              {option?.type === "document" && (
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faComment} />}
                                  className="__blue_color_dark"
                                  onClick={() =>
                                    navigate(
                                      "/chat-document/" + option?.datamartName
                                    )
                                  }
                                >
                                  Talk
                                </Button>
                              )}
                              <Popconfirm
                                title="Delete"
                                description="Are you sure want to delete?"
                                onConfirm={() =>
                                  handleDelete(i, option.datamartName)
                                }
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                loading={i === loadingDelete}
                              >
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faTrash} />}
                                  className="__red_color"
                                ></Button>
                              </Popconfirm>
                            </div>
                          </span>
                        </div>
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              ) : (
                <Radio.Group
                  onChange={handleRadioChangeDocument}
                  value={checkedValuesDocument[0]}
                >
                  {listOfDataMartDocument &&
                    listOfDataMartDocument.map((option, i) => (
                      <Radio.Button
                        key={option.datamartName}
                        value={option.datamartName}
                        style={{ position: "relative" }}
                        checked={option?.checked}
                      >
                        <div className="__check_bx_one">
                          <button className="__button">
                            <FontAwesomeIcon icon={faDatabase} />
                          </button>
                          <span className="__text">
                            <Tooltip title={option.datamartName}>
                              {" "}
                              <p>
                                {option.datamartName}{" "}
                                {option?.isActive ||
                                  (option?.checked && <a>Active</a>)}
                              </p>
                            </Tooltip>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              {option?.type !== "document" && (
                                <>
                                  <Button
                                    loading={i === loadingERD}
                                    ghost
                                    icon={
                                      <FontAwesomeIcon
                                        icon={faDiagramProject}
                                      />
                                    }
                                    className="__orange_color __erd"
                                    onClick={() =>
                                      handleERD(i, option.datamartName)
                                    }
                                  ></Button>
                                  <Button
                                    loading={i === loadingAccess}
                                    ghost
                                    icon={<FontAwesomeIcon icon={faUser} />}
                                    className="__yellow_color"
                                    onClick={() =>
                                      handleAccess(i, option.datamartName)
                                    }
                                  ></Button>
                                  {option?.type === "modelrun" && (
                                    <Button
                                      loading={i === loadingAccess}
                                      ghost
                                      icon={<FontAwesomeIcon icon={faEye} />}
                                      className="__blue_color"
                                      onClick={() =>
                                        handleDataMartype(
                                          i,
                                          option.datamartName
                                        )
                                      }
                                    ></Button>
                                  )}
                                  {option.status.toString().toLowerCase() ===
                                    "inprogress" && (
                                      <Button disabled className="__orange_color">
                                        In Progress
                                      </Button>
                                    )}
                                </>
                              )}
                              {option?.type === "document" && (
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faComment} />}
                                  className="__blue_color_dark"
                                  onClick={() =>
                                    navigate(
                                      "/chat-document/" + option?.datamartName
                                    )
                                  }
                                >
                                  Talk
                                </Button>
                              )}
                              <Popconfirm
                                title="Delete"
                                description="Are you sure want to delete?"
                                onConfirm={() =>
                                  handleDelete(i, option.datamartName)
                                }
                                onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                loading={i === loadingDelete}
                              >
                                <Button
                                  ghost
                                  icon={<FontAwesomeIcon icon={faTrash} />}
                                  className="__red_color"
                                ></Button>
                              </Popconfirm>
                            </div>
                          </span>
                        </div>
                      </Radio.Button>
                    ))}
                  <div></div>
                  <div></div>
                </Radio.Group>
              )}
            </TabPane>

            <TabPane tab={"Connectors"} key={"3"}>
              <div className="__table_sec">
                <Table
                  {...config}
                  rowKey={(record, index) => record}
                  dataSource={integrationList}
                  loading={isLoadingIntegration}
                  rowSelection={rowSelection}
                  className="__market_table"
                >
                  <Column
                    title={"Name"}
                    dataIndex="integrationName"
                    key="integrationName"
                    className="___col"
                  />

                  <Column
                    title={"Type"}
                    dataIndex="integrationType"
                    key="integrationType"
                    className="___col"
                  />

                  <Column
                    title={"Description"}
                    dataIndex="description"
                    key="description"
                    className="___col"
                  />
                </Table>
              </div>
            </TabPane>

            <TabPane tab={"Apps"} key={"4"}>
              <div className="__table_sec">
                <Table
                  {...config}
                  rowKey={(record, index) => record}
                  dataSource={listApps}
                  loading={listLoadingApps}
                  className="__market_table"
                >
                  <Column
                    title={"App Name"}
                    dataIndex="appName"
                    key="appName"
                    className="___col"
                  />

                  <Column
                    title={"Description"}
                    dataIndex="appDesc"
                    key="appDesc"
                    className="___col"
                  />

                  <Column
                    title='App Link'
                    key="action"
                    render={(text, record) => (
                      <div className="table-action">

                        <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(record)}
                          style={{ fontSize: "18px", color: '#03A9F4' }}
                        />

                        <EyeOutlined
                          onClick={() => handleViewAppLink(record)}
                          style={{ fontSize: "18px", color: '#03A9F4' }}
                        />

                        <TableOutlined
                          onClick={() => handleTableContent(record)}
                          style={{ fontSize: "18px", color: '#03A9F4' }}
                        />
                      </div>
                    )}
                  />

                  <Column
                    title='Actions'
                    key="action"
                    render={(text, record) => (
                      <div className="table-action">
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key={'1'}><a style={{ display: 'flex', gap: '10px', alignItems: 'center' }} onClick={() => handleEditApp(record)}><FontAwesomeIcon icon={faPencil} /> Edit</a></Menu.Item>
                              <Menu.Item key={'2'}><a style={{ display: 'flex', gap: '10px', alignItems: 'center' }} onClick={() => handleCloneApp(record)}><FontAwesomeIcon icon={faClone} /> Clone this app</a></Menu.Item>
                              <Menu.Item key={'3'}>
                                
                                  <a onClick={() => {setInfoContent(record.appDetails); setShowInfo(true)}} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}><FontAwesomeIcon icon={faInfoCircle} /> About this app</a>
                                
                              </Menu.Item>
                              <Menu.Item key={'4'}><a onClick={() => handleDeleteApp(record)} style={{ display: 'flex', gap: '10px', alignItems: 'center' }}><FontAwesomeIcon icon={faTrash} /> Delete</a></Menu.Item>
                            </Menu>
                          }
                          onClick={(e) => e.preventDefault()}
                          trigger={["click"]}
                        >
                          <SettingOutlined
                            style={{ fontSize: "18px", color: '#03A9F4' }}
                          />
                        </Dropdown>
                      </div>
                    )}
                  />
                </Table>
              </div>
            </TabPane>
          </Tabs>
        </div>

        {contextHolder}

        <UploadDataMart open={showMeta} onClose={handleCloseMeta} />

        <ImageDisplay
          open={showImg}
          onClose={() => setShowImg(false)}
          content={dataImg}
        />

        <ModelTransform
          open={showModelTransform}
          onClose={() => setModelTransform(false)}
          dataSource={listOfDataMart}
          datamartList={datamartList}
        />

        <ThreeSixtyView
          open={threeSixty}
          onClose={() => setThreeSixty(false)}
          dataSource={listOfDataMart}
          datamartList={datamartList}
        />

        <AccessModel
          open={showModel}
          onClose={() => setAccessModelShow(false)}
        />
        <ContributionModel
          open={showModelContribution}
          onClose={() => setContributionModel(false)}
        />

        <TalkFileUpload
          open={taklFileUpload}
          onClose={() => setTalkFileUpload(false)}
        />

        <AskLilaq onClose={() => setAskLilaq(false)} open={askLilaq} />

        <SystemIntegrationModal
          onClose={() => setIntegrationOpen(false)}
          open={integrationOpen}
        />

        <DeleteDataMart
          open={deleteDatamartShow}
          onClose={() => setDeleteDatamartShow(false)}
          datamartName={actvtiveDatamart}
          datamartList={datamartList}
        />

        <DeleteApp
          open={deleteApp}
          onClose={() => setDeleteApp(false)}
          appName={activeApp}
          listapps={listapps}
        />

        <GenerateSystemData
          open={generateSystemData}
          onClose={() => setGenerateSystemData(false)}
          datamartList={datamartList}
        />

        <AddEditApp
          open={registerApp}
          onClose={() => setRegisterApp(false)}
          listapps={listapps}
        />

        <ImageDisplay
          open={showImgApp}
          onClose={() => setShowImgApp(false)}
          content={dataImgApp}
        />

        <AppViewHtmlContent
          open={showHtmlContent}
          onClose={() => setShowHtmlContent(false)}
          content={htmlContent}
        />

        <InfoModal
          open={showInfo}
          onClose={() => setShowInfo(false)}
          content={{
            title: "What does this app do",
            description: infoContent
          }}
        />

      </div>
    </>
  );
};

export default DataMarketPlace;
