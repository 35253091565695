// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Modal, message } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import InputField from 'components/commonComponents/InputField';
import { useSelector } from 'react-redux';
import TextArea from 'components/commonComponents/TextArea';

const GenerateSystemData = (props) => {
  const { userDetails } = useSelector((store) => store.commonData);
  const { onClose, open, datamartList } = props;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { datamart, description, count } = data;
  const [messageApi, contextHolder] = message.useMessage();


  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData({})
      setError({})
      onClose();
    }
  };

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null,
    });
    setData({
      ...data,
      [key]: value,
    });
  };


  const handleSubmit = async () => {

    const errors = {
      datasetName: !datamart,
      description: !description,
      count:!count

    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true);

    const payload = {
      datamart,
      description,
      count,
      username:userDetails
    }

    const res = await fetchData(restAPIs.generateSampleDatasets(payload));
    if (res.statusCode === 200) {
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: res?.message,
      });
      datamartList();
      handleClose();
    } else {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: res?.message,
      });
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className=' __pinto_dashboard'
        footer={null}
        closable={false}
      >
        {contextHolder}
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>Generate Sample Datasets</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className='___updt'>
            <InputField
              onChange={handleOnChange("datamart")}
              value={datamart}
              placeholder={'Enter dataset name'}
              label='Dataset Name'
              error={error.datamart}
            />

            <InputField
              onChange={handleOnChange("count")}
              type='number'
              value={count}
              placeholder={'Enter count'}
              label='Approx. record count'
              error={error.count}
            />
            <TextArea
              onChange={handleOnChange("description")}
              value={description}
              type="text"
              placeholder={'Description'}
              label={'Description'}
              error={error.description}
            />
            <Button className='__extra_btn_cls' style={{ border: 0, color: '#fff', marginTop: '10px' }} loading={loading} onClick={() => handleSubmit()} >Register</Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GenerateSystemData;

GenerateSystemData.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  datamartList:PropTypes.func
};
