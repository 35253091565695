// Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Styles
import './styles.scss';

import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'components/commonComponents/InputField';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
// icons
const openNotificationWithIcon = (title, message) => {
    notification["error"]({
        message: title,
        description: message,
    });
};

const AWSKey = () => {
    const { userDetails } = useSelector((store) => store.commonData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [data, setData] = useState({});

    
    const [accessVisible, setAccessVisibility] = useState(false)
    const [secretVisible, setSecretVisibility] = useState(false)

    const handleAWS = async () => {
        const errors = {
            accessKey: !accessKey,
            secretKey: !secretKey,
        };
        setError(errors);
        const isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setLoading(true);

        let payload = {
            "username": userDetails,
            "secretKey": secretKey,
            "accessKey": accessKey
        }

        const res = await fetchData(restAPIs.awsInsertion(payload));
        if (res.statusCode === 200) {
            setLoading(false);
            navigate('/install-app')
        } else {
            setLoading(false);
            openNotificationWithIcon(res?.message);
        }

    }

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null,
        });
        setData({
            ...data,
            [key]: value,
        });
    };

    const { accessKey, secretKey } = data;

    

    const iconClickAccess = () => {
        setAccessVisibility((state) => !state)
      }

      const iconClickSecret = () => {
        setSecretVisibility((state) => !state)
      }

    return (
        <>
            <div className='__susbscription_page'>
                <div className='__inner_wrap'>
                    <div className='__top_head'>
                        <button className="__logo">LiLaQ-Ai</button>
                    </div>
                    <h3 className='__top_head_h1'>Please Provide your AWS Keys here</h3>
                    <div className='__subs_page'>
                        <div className="" >
                            <InputField
                                onChange={handleOnChange("accessKey")}
                                value={accessKey}
                                type={accessVisible ? "text" : "password"}
                                placeholder={'Access Key'}
                                label={'Access Key'}
                                error={error.accessKey}
                                icon={accessVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                onIconClick={() => iconClickAccess()}
                            />
                            <InputField
                                onChange={handleOnChange("secretKey")}
                                value={secretKey}
                                type={secretVisible ? "text" : "password"}
                                placeholder={'Secret Key'}
                                label={'Secret Key'}
                                error={error.secretKey}
                                icon={secretVisible ? <EyeTwoTone /> : <EyeInvisibleOutlined />}
                                onIconClick={() => iconClickSecret()}
                            />

                            <Button type="primary" className="__extra_btn_cls" loading={loading} onClick={() => handleAWS()}>Connect</Button>
                        </div>
                    </div>

                    <p className='__copyright'>Copyright@LiLaQ-Ai, 2024</p>
                </div>
            </div>
        </>
    );
};

export default AWSKey;