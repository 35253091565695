
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { select } from 'd3-selection';
import cloud from 'd3-cloud';
import './styles.scss';
import { restAPIs } from 'utils/restAPIs';

const WordCloud = (props) => {
  const { onClose, open, description } = props;
  const [withRotation, setWithRotation] = useState(true);
  const [spiralType, setSpiralType] = useState('archimedean');
  const [data, setData] = useState('');
  const svgRef = useRef(null);

  const colors = ['#143059', '#2F6B9A', '#82a6c2'];

  const wordFreq = (text) => {
    if (!text) return []; // Handle cases where text is undefined or empty

    const words = text?.replace(/\./g, '').split(/\s+/); // Split by whitespace
    const freqMap = {};

    for (const w of words) {
      if (!freqMap[w]) freqMap[w] = 0;
      freqMap[w] += 1;
    }

    return Object.keys(freqMap).map((word) => ({
      text: word,
      size: freqMap[word] * 10, // Scale size as needed
    }));
  };

  useEffect(() => {
    const fetchWordCloudData = async () => {
      if (description) {
        try {
          const payload = { Answer: description, chartType :'wordcloud' };
          const { endpoint } = restAPIs.processAnswer(payload);
          const url = endpoint;
  
          console.log('Fetching data from:', url); // Debug URL
          console.log('qstn:', payload);
  
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload), // Pass the payload as the request body
          });
  
          console.log('res', response);
  
          if (!response.ok) {
            console.error('Error response:', response.status, response.statusText, await response.text());
            throw new Error('Network response was not ok');
          }
  
          const result = await response.json();
          setData(result.body); // Set the data for the word cloud
        } catch (error) {
          console.error('Error fetching word cloud data:', error);
        }
      }
    };

    fetchWordCloudData();
  }, [description]); // Depend on description for fetching data

  const words = wordFreq(data != {} ? data : '');

  useEffect(() => {
    if (svgRef.current && data) {
      const layout = cloud()
        .size([800, 400])
        .words(words)
        .padding(5)
        .rotate(() => (withRotation ? Math.random() > 0.5 ? 0 : 90 : 0))
        .fontSize((d) => d.size)
        .on('end', draw)
        .spiral(spiralType)
        .start();
    }
  }, [words, withRotation, spiralType, data]); // Include data in the dependency array

  const draw = (words) => {
    const svg = select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous words

    svg
      .append('g')
      .attr('transform', 'translate(400, 200)')
      .selectAll('text')
      .data(words)
      .enter()
      .append('text')
      .style('font-size', (d) => `${d.size}px`)
      .style('fill', (d, i) => colors[i % colors.length])
      .attr('text-anchor', 'middle')
      .attr('transform', (d) => `translate(${d.x}, ${d.y}) rotate(${d.rotate})`)
      .text((d) => d.text);
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="__common_model_class __lilac_blue_2222 __msm"
        footer={null}
        closable={false}
      >
        <div className="__common_model_content">
          <div className="____common_model_head">
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>

          <div className="wordcloud">
            <svg ref={svgRef} width={800} height={400}></svg>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <label>
                Spiral type &nbsp;
                <select
                  onChange={(e) => setSpiralType(e.target.value)}
                  value={spiralType}
                  style={{
                    padding: '4px',
                    width: '130px',
                    borderRadius: '9px'
                  }}
                >
                  <option key={'archimedean'} value={'archimedean'}>
                    archimedean
                  </option>
                  <option key={'rectangular'} value={'rectangular'}>
                    rectangular
                  </option>
                </select>
              </label>
              <label style={{ background: '#ccc2', padding: '3px 10px', borderRadius: '6px' }}>
                With rotation &nbsp;
                <input
                  type="checkbox"
                  checked={withRotation}
                  onChange={() => setWithRotation(!withRotation)}
                />
              </label>
              <br />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

WordCloud.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  description: PropTypes.string,
};

export default WordCloud;
