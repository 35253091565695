// Core
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Modal } from 'antd';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const IframeView = (props) => {
  const { onClose, open } = props;
  const { urlGenerated } = useSelector((store) => store.commonData);
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };



  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class __lilac_blue'
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <div className='____common_model_head'>
            <h3 style={{ marginBottom: '10px' }}>LiLaQ-Ai</h3>
            <button onClick={handleClose}><FontAwesomeIcon icon={faClose} /></button>
          </div>
          <div className="iframe-container">
          <div dangerouslySetInnerHTML={{ __html : urlGenerated}}></div>
            {/* <iframe
              src={urlGenerated}
              frameBorder="0"
              className="custom-iframe"
              title="Custom Iframe"
            /> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IframeView;

IframeView.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
